import {
  Dialog,
  IconWarningCircledFull,
  Table,
  TableColumn,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import React, { useMemo, useState } from 'react'
import './reset.css'
import Icon from './icon'
import {
  IShoppingCartData,
  useShoppingCartContext,
} from 'src/hooks/use-shopping-cart-context'
import { QuantityCounterInput } from './quantity-counter-input/quantity-counter-input'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'
import { IconQuestionMarkCircleFull } from '@ftdr/blueprint-components-react'
import Tooltip from 'src/components/tooltip/tooltip'
import TooltipContent from 'src/components/tooltip/tooltip-content'
import { ConfirmDialog } from 'src/components/confirm-dialog/confirm-dialog'
import AdjustPriceForm from './override-price-form/override-price-form'
import { subCategories } from '../add-fee-form/sub-categories'
import { useCILPriceModalContext } from 'src/hooks/use-cil-price-modal'

const ShoppingCart = () => {
  const { setOpen: reapplyPrice } = useCILPriceModalContext()
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const [isPriceModal, setIsPriceModal] = useState<boolean>(false)
  const {
    setCurrentItem,
    shoppingCartData,
    shouldConfirm,
    updateCurrentItemQuantity,
    setShouldConfirm,
    total,
    resetShoppingCartData,
  } = useShoppingCartContext()
  const buttonHandler = (id: string) => {
    setCurrentItem(id)
  }
  const [resetConfirm, setResetConfirm] = useState(false)
  const prepareTableData = (data: IShoppingCartData[]) => {
    return data.map(
      ({ quantity, unitTax, total, item, price, button, inputData }) => ({
        item,
        quantity,
        price: { ...price, quantity },
        unitTax: { unitTax, quantity },
        total: { total, quantity },
        button: { ...button, quantity },
        inputData: { ...inputData, quantity },
      })
    )
  }
  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_ITEM"
          />
        ),
        accessor: 'item', // accessor is the "key" in the data
        Cell: ({ value }: any) => {
          return (
            <div
              className="cell-one"
              style={{ maxWidth: 580, opacity: value.quantity ? 1 : 0.25 }}
            >
              <Text>
                {!/^AHS-/.test(value.sku) &&
                !/^HSA-/.test(value.sku) &&
                (value.vendor === 'AHS' || value.vendor === 'HSA')
                  ? `${value.vendor}-${value.sku}`
                  : value.sku}
                <span>
                  {' '}
                  (
                  {`${value.category}${
                    value.subcategory ? ', ' + value.subcategory : ''
                  } `}
                  )
                </span>
              </Text>
              <Text className="text-warning-800 font-sm">
                {value.description}
              </Text>
              {value.removalReason ? (
                <div className="flex items-center mt-2">
                  <IconWarningCircledFull
                    color="error"
                    width={16}
                    height={16}
                  />
                  <Text className="text-error font-sm ml-2">
                    {value.removalReason}
                  </Text>
                </div>
              ) : null}
              {!value.isAvailableForZip ? (
                <div className="flex items-center mt-2">
                  <IconWarningCircledFull
                    color="error"
                    width={16}
                    height={16}
                  />

                  <Text
                    className="text-error font-sm ml-2"
                    textTemplateKey="PRODUCT_UNAVAILABLE"
                  />
                </div>
              ) : null}
            </div>
          )
        },
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_PRICE"
          />
        ),
        accessor: 'price', // accessor is the "key" in the data
        Cell: ({
          value: { originalPrice, overrideReason, unitPrice, quantity },
        }: any) => (
          <div
            className="flex w-full items-center"
            style={{ opacity: quantity ? 1 : 0.25 }}
          >
            <Text variant="caption">{`${formatCentsCurrency(unitPrice)}`}</Text>
            {originalPrice !== unitPrice &&
            typeof originalPrice === 'number' ? (
              <Tooltip
                content={
                  <TooltipContent
                    price={originalPrice as number}
                    reason={overrideReason}
                  />
                }
              >
                <IconQuestionMarkCircleFull
                  color="warning"
                  width={18}
                  height={18}
                />
              </Tooltip>
            ) : null}
          </div>
        ),
      },

      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_TAX"
          />
        ),
        accessor: 'unitTax', // accessor is the "key" in the data
        Cell: ({ value: { unitTax, quantity } }: any) => (
          <div
            className="flex w-full items-center"
            style={{ opacity: quantity ? 1 : 0.25 }}
          >
            <Text variant="caption">{`${formatCentsCurrency(unitTax)}`}</Text>
          </div>
        ),
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_QTY"
          />
        ),
        accessor: 'inputData', // accessor is the "key" in the data
        Cell: ({ value: { sku, quantity } }: any) => (
          <QuantityCounterInput sku={sku} quantity={quantity} />
        ),
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_TOTAL"
          />
        ),
        accessor: 'total', // accessor is the "key" in the data
        Cell: ({ value: { total, quantity } }: any) => (
          <div
            className="flex w-full items-center"
            style={{ opacity: quantity ? 1 : 0.25 }}
          >
            <Text variant="caption">{`${formatCentsCurrency(total)}`}</Text>
          </div>
        ),
      },
      {
        accessor: 'button', // accessor is the "key" in the data
        Cell: ({ value: { label, currentID, quantity } }: any) => (
          <Button
            label={label}
            size="small"
            className="ml-auto flex justify-center items-center"
            style={{ width: 135, opacity: quantity ? 1 : 0.5 }}
            disabled={
              quantity === 0 ||
              !!subCategories.find((v) => v.prefix === currentID.slice(0, -3))
            }
            onClick={() => {
              buttonHandler(currentID)
              if (label === 'Reapply') {
                reapplyPrice(true)
              } else {
                setIsPriceModal(true)
              }
            }}
          />
        ),
      },
    ],
    //eslint-disable-next-line
    [shoppingCartData]
  )
  return shoppingCartData?.length > 0 ? (
    <>
      <fieldset className="border border-gray p-3 rounded-2 relative">
        <legend>
          <div style={{ width: 30 }}>
            <Icon color="currentcolor" />
          </div>
        </legend>
        <Table
          id="shoppingCart-info-table-table"
          data={prepareTableData(shoppingCartData)}
          columns={columns}
          variant="light"
          sortable={false}
          paginate={false}
          hover="gray"
        />
        <div className="py-2 flex justify-between items-center pr-2">
          <Button
            label={localizedText('BUTTON_RESET')}
            size="small"
            onClick={() => setResetConfirm(true)}
            variant="outlined"
          />
          <div className="flex justify-end">
            <span className="text-2 block">Total :</span>
            <span className="ml-2 block">
              {formatCentsCurrency(+total.toFixed(2))}
            </span>
          </div>
        </div>
      </fieldset>
      <Dialog
        id="overprice-dialog"
        open={isPriceModal}
        modal={true}
        onClose={() => setIsPriceModal(false)}
        className=""
        usePortal={true}
      >
        <AdjustPriceForm onClose={() => setIsPriceModal(false)} />
      </Dialog>
      <ConfirmDialog
        message={'OUTRIGHT_PAYMENT_CONFIRM'}
        isOpen={!!shouldConfirm}
        data={shouldConfirm}
        onClose={() => setShouldConfirm(null)}
        action={(reason: string) => {
          updateCurrentItemQuantity(
            shouldConfirm!.sku,
            shouldConfirm!.q,
            reason
          )
          setShouldConfirm(null)
        }}
      />
      <ConfirmDialog
        message={'OUTRIGHT_PAYMENT_CONFIRM_RESET'}
        isOpen={resetConfirm}
        onClose={() => setResetConfirm(false)}
        action={() => {
          resetShoppingCartData()
          setResetConfirm(false)
        }}
      />
    </>
  ) : null
}

export default ShoppingCart
