import { ParsedFilter } from 'src/utils/shared-types'
import { isNumber, isRange } from 'src/utils/validation-utils'
import { IAnyObject } from 'src/utils/shared-types'

export const stringToSnakeCase = (str: string) => {
  return str.replace(/ /g, `_`)
}

export const snakeCaseToString = (str: string) => {
  return str.replace(/_/g, ` `)
}

export const paramsToObjectKeys = (obj: IAnyObject) => {
  const state: IAnyObject = {}
  for (const key in obj) {
    state[snakeCaseToString(key)] = obj[key]
  }
  return state
}

export const objectKeysToParams = (obj: IAnyObject): any => {
  const state: IAnyObject = {}
  for (const key in obj) {
    state[stringToSnakeCase(key)] = obj[key]
  }
  return state
}

export const updatePagination = (postsPerPage: number, pageNumber: number) => {
  const updatedPagination: { items_per_page?: number; page?: number } = {}
  if (postsPerPage === 30 || postsPerPage === 60) {
    updatedPagination['items_per_page'] = postsPerPage
  }

  if (pageNumber !== 1 && isNumber(`${pageNumber}`)) {
    updatedPagination['page'] = pageNumber
  }
  return updatedPagination
}

export const updateFilters = (
  filters: ParsedFilter[],
  searchObject: IAnyObject
) => {
  const updatedFilters: IAnyObject = {}

  filters.forEach((filter) => {
    for (const key in filter) {
      if (filter.type === 'enum') {
        const value = filter['values']?.find(
          (v) => v.toLowerCase() === searchObject[filter[key]]
        )
        if (key === 'name' && searchObject[filter[key]] && value) {
          updatedFilters[filter[key]] = value
        }
      } else if (filter.type === 'range') {
        const value = searchObject[filter['name']]

        if (key === 'name' && isRange(value)) {
          const min = +value.split('-')[0]
          const max = +value.split('-')[1]

          if (max >= min) {
            updatedFilters[filter[key]] = value
          } else if ((min === 0 && max !== 0) || (min !== 0 && max === 0)) {
            updatedFilters[filter[key]] = value
          }
        }
      }
    }
  })

  return updatedFilters
}
