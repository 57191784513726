import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultValues } from './payment-default-values'
import {
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import { paymentSchema } from './payment-validation'
import { Input, Select, DateTimeInput } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { useGetVendors } from 'src/hooks/use-get-vendors'
import { IPaymentFormData } from 'src/utils/shared-types'
import { arrOfPaymentParamsKeys, generateFormData } from '../../helpers'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import { useLocation } from 'react-router'
import qs from 'query-string'
import { formDataToParams, getPagination } from '../../helpers'
import './reset.css'
import { today } from 'src/utils/internationalization-helper'
import { regexAllowPriceDecimal } from 'src/utils/validation-utils'

const PaymentForm = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const location = useLocation()

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm<IPaymentFormData>({
    mode: 'onTouched',
    resolver: yupResolver(paymentSchema),
    defaultValues,
  })
  const {
    setPaymentCurrentPagination,
    getPaymentsData,
    paymentPrevParamsString,
    paymentCurrentPagination,
    setIsPaymentFormMounted,
    setPaymentTableData,
  } = useTabsTableContext()

  const submitForm = async (
    data: IPaymentFormData,
    updatedHistory: boolean = true
  ) => {
    const params = formDataToParams(data)

    getPaymentsData(params, updatedHistory)
  }

  const { isVendorsLoading, getVendors } = useGetVendors()

  const [priceMin, orderDateMin, orderDateMax] = watch([
    'priceMin',
    'orderDateMin',
    'orderDateMax',
  ])

  const triggerPriceMax = async () =>
    await trigger('priceMax', { shouldFocus: false })

  useEffect(() => {
    const {
      data: updatedFormData,
      allParamsObject,
      pagination,
    } = generateFormData(
      location.hash,
      defaultValues,
      arrOfPaymentParamsKeys,
      []
    )

    //Previous params string for comparing with current one
    const prevParamsString = qs.stringify(
      qs.parse(paymentPrevParamsString?.replace(/^#tab\d*/, '') || '')
    )

    //Current params string for comparing with previous one
    const currentParamsString = qs.stringify(allParamsObject)

    for (let key in updatedFormData) {
      setValue(key as keyof IPaymentFormData, updatedFormData[key], {
        shouldValidate: true,
        shouldTouch: true,
      })
    }
    if (!currentParamsString && paymentPrevParamsString !== null) {
      setPaymentTableData([])
    } else if (
      prevParamsString !== currentParamsString &&
      (paymentPrevParamsString !== null || location.hash !== '#tab2')
    ) {
      handleSubmit((data) => {
        const updatedData = {
          ...data,
          itemsPerPage: '9',
          page: '1',
          ...pagination,
        }
        submitForm(updatedData, false)
      })()
    }
    //eslint-disable-next-line
  }, [location.hash])

  useEffect(() => {
    setIsPaymentFormMounted(true)
    return () => {
      setIsPaymentFormMounted(false)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    triggerPriceMax()
    //eslint-disable-next-line
  }, [priceMin])

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const itemsPerPage = paymentCurrentPagination?.itemsPerPage
        const pagination = getPagination({
          items_per_page: `${itemsPerPage}`,
          page: '1',
        })

        submitForm({
          ...data,
          ...pagination,
        })
      })}
      className="mt-8 mb-8"
    >
      <div
        id="queue-form-payment-wrapper"
        className="-mt-6 lg:-mt-3 md:p-8 md:border"
      >
        <div className="my-8 grid lg:grid-cols-2 gap-4">
          <Controller
            name="dispatchID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-payment-di"
                error={errors.dispatchID?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_DISPATCH_ID_LABEL')}
                className="w-full"
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="vendor"
            control={control}
            render={({ field }) => (
              <Select
                id="queue-form-payment-vendor"
                autoComplete={true}
                error={errors.vendor?.message}
                selected={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_VENDOR_LABEL')}
                options={getVendors(['BH'], true)}
                onSelect={field.onChange as SelectHandler<SelectOption>}
                onBlur={field.onBlur}
                placeholder="Select an option"
                disabled={isVendorsLoading}
              />
            )}
          />
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-payment-id"
                error={errors.id?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_ID_LABEL')}
                className="w-full"
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="contractID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-payment-cid"
                error={errors.contractID?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_CONTRACT_ID_LABEL')}
                className="w-full"
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="paymentID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-payment-pi"
                error={errors.paymentID?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_PAYMENT_ID_LABEL')}
                className="w-full"
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="companyCode"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-payment-cc"
                error={errors.companyCode?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_COMPANY_CODE_LABEL')}
                className="w-full"
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase().trim())
                }}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="modelNumber"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-payment-mn"
                error={errors.modelNumber?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_MODEL_NUMBER_LABEL')}
                className="w-full"
                onChange={(e) =>
                  field.onChange(e.target.value.toUpperCase().trim())
                }
                onBlur={field.onBlur}
              />
            )}
          />

          <div className="grid lg:grid-cols-2 gap-4">
            <Controller
              name="orderDateMin"
              control={control}
              render={({ field }) => {
                return (
                  <DateTimeInput
                    id="queue-form-payment-date-in"
                    maxDate={orderDateMax || today}
                    error={errors.orderDateMin?.message}
                    label={localizedText(
                      'PAYMENT_SEARCH_FORM_ORDER_DATE_MIN_LABEL'
                    )}
                    formFieldClassName="w-full"
                    className="date-custom-input"
                    selectedDate={field.value}
                    onDateSelect={field.onChange}
                    onBlur={field.onBlur}
                    showMonths={1}
                    onlyDate
                    datePicker
                    showOutsideMonthDates={true}
                    allowSelectOutsideMonthDates
                  />
                )
              }}
            />
            <Controller
              name="orderDateMax"
              control={control}
              render={({ field }) => {
                return (
                  <DateTimeInput
                    id="queue-form-payment-date-out"
                    maxDate={today}
                    minDate={orderDateMin || undefined}
                    error={errors.orderDateMax?.message}
                    label={localizedText(
                      'PAYMENT_SEARCH_FORM_ORDER_DATE_MAX_LABEL'
                    )}
                    formFieldClassName="w-full"
                    className="date-custom-input"
                    selectedDate={field.value}
                    onDateSelect={field.onChange}
                    onBlur={field.onBlur}
                    showMonths={1}
                    onlyDate
                    datePicker
                    showOutsideMonthDates={true}
                    allowSelectOutsideMonthDates
                  />
                )
              }}
            />
          </div>
          <Controller
            name="orderID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-payment-oi"
                error={errors.orderID?.message}
                value={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_ORDER_NUMBER_LABEL')}
                className="w-full"
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <div className="grid lg:grid-cols-2 gap-4">
            <Controller
              name="priceMin"
              control={control}
              render={({ field }) => (
                <Input
                  id="queue-form-payment-min-amount"
                  error={errors.priceMin?.message}
                  value={field.value}
                  inputMode="decimal"
                  startEnhancer={() => <Text color="primary">$</Text>}
                  formField
                  label={localizedText('PAYMENT_SEARCH_FORM_AMOUNT_MIN_LABEL')}
                  className="w-full"
                  onChange={(v) => {
                    if (regexAllowPriceDecimal.test(v.target.value)) {
                      field.onChange(v)
                    }
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />

            <Controller
              name="priceMax"
              control={control}
              render={({ field }) => (
                <Input
                  id="queue-form-payment-max-amount"
                  error={errors.priceMax?.message}
                  value={'' + field.value}
                  inputMode="decimal"
                  startEnhancer={() => <Text>$</Text>}
                  formField
                  label={localizedText('PAYMENT_SEARCH_FORM_AMOUNT_MAX_LABEL')}
                  className="w-full"
                  onChange={(v) => {
                    if (regexAllowPriceDecimal.test(v.target.value)) {
                      field.onChange(v)
                    }
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />
          </div>
        </div>
        <Button
          id="queue-form-payment-search"
          type="submit"
          label="Search"
          disabled={!isValid}
          onClick={() => {
            setPaymentCurrentPagination({
              itemsPerPage: paymentCurrentPagination?.itemsPerPage || 9,
              page: 1,
            })
          }}
        />
      </div>
    </form>
  )
}

export default PaymentForm
