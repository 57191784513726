import React, { useMemo, useCallback } from 'react'

import {
  IconArrowRightFull,
  IconPage,
  IconWarningTriangleFull,
  TableColumn,
  Tag,
  useAppContext,
  IconButton,
  Link,
} from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { TableStatusColorCodes } from './queue-table-status'
import StatusInfo from 'src/components/status-info/status-info'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { useQueueModalContext } from 'src/hooks/use-queue-modal-context'
import ShoppingSurveyDialog from '../../shopping-survey-dialog/shopping-survey-dialog'
import { api } from 'src/utils/api'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'

export const useQueueColumns = () => {
  const {
    setDataInfoModal,
    setInfoModalOpen,
    setCILModalOpen,
    setDataCILModal,
    setSelectQueueItem,
    setOrderDetailModalOpen,
    setCurrentReplacementId,
  } = useQueueModalContext()

  const openDialogInfo = useCallback(
    (notes: appliancepb.INote[], replacementId: string) => {
      setDataInfoModal(notes)
      setCurrentReplacementId(replacementId)
      setInfoModalOpen(true)
    },
    //eslint-disable-next-line
    []
  )

  const openDialogCIL = useCallback(
    (LFL: appliancepb.IPriceInformation[], replacementId: string) => {
      setDataCILModal(LFL)
      setCurrentReplacementId(replacementId)
      setCILModalOpen(true)
    },
    //eslint-disable-next-line
    []
  )

  const isDevOrTest =
    api.config.ENV === 'development' || api.config.ENV === 'test'
  const openDialogOrderDetails = useCallback(
    (value: appliancepb.IFullReplacementDetails | null) => {
      setSelectQueueItem(value)

      setOrderDetailModalOpen(true)
    },
    //eslint-disable-next-line
    []
  )
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_ACTION')}
          </Text>
        ),
        accessor: 'action', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <Tag
            size="small"
            textClassName="font-bold"
            className="cursor-pointer bg-transparent border-none hover:bg-interactive-50"
            startIcon={
              value.status === 'NEEDS_INSTALLER'
                ? IconWarningTriangleFull
                : IconArrowRightFull
            }
            color={
              value.status === 'NEEDS_INSTALLER' ? 'warning' : 'interactive'
            }
            iconColor={
              value.status === 'NEEDS_INSTALLER' ? 'warning' : 'interactive'
            }
            onClick={() => openDialogOrderDetails(value)}
          />
        ),
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_NOTES')}
          </Text>
        ),
        accessor: 'notes', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <IconButton
            icon={<IconPage size={20} />}
            onClick={() => openDialogInfo(value.notes, value.ID)}
            color="interactive"
            label=""
            variant="ghost"
            size="small"
            disabled={!value.notes.length}
          />
        ),
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_DISPATCH')}
          </Text>
        ),
        accessor: 'dispatchID', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_PART_ID')}
          </Text>
        ),
        accessor: 'partRequestID', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_VENDOR_ORDER_NUMBER')}
          </Text>
        ),
        accessor: 'vendorOrderNumber', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_CREATED')}
          </Text>
        ),
        accessor: 'createdAt', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_LAST_UPDATED')}
          </Text>
        ),
        accessor: 'lastUpdated', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_FIRST_NAME')}
          </Text>
        ),
        accessor: 'customerFirstName', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_LAST_NAME')}
          </Text>
        ),
        accessor: 'customerLastName', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            <StatusInfo />
          </Text>
        ),
        accessor: 'status', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return <div>{TableStatusColorCodes(value)}</div>
        },
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_MODEL')}
          </Text>
        ),
        accessor: 'model', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_PRICE')}
          </Text>
        ),
        accessor: 'basePrice', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_VENDOR')}
          </Text>
        ),
        accessor: 'vendor', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_PAID')}
          </Text>
        ),
        accessor: 'paid', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_TABLE_HEAD_CIL')}
          </Text>
        ),
        accessor: 'LFL', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <Text variant="caption">
            {value.likeForLikePrice ? (
              <Link
                onClick={() => openDialogCIL(value.likeForLikePrice, value.ID)}
              >
                {formatCentsCurrency(value.cashInLieu)}
              </Link>
            ) : (
              formatCentsCurrency(value.cashInLieu)
            )}
          </Text>
        ),
      },
      {
        Header: (
          <Text variant="heading-06">
            {localizedText('QUEUE_REPLACEMENT_LABEL')}
          </Text>
        ),
        accessor: 'ID', // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ value: { status, ID, ...rest } }: any) => (
          <>
            {isDevOrTest ? (
              <ShoppingSurveyDialog status={status} id={ID} {...rest} />
            ) : (
              ID
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  )
  return { columns }
}
