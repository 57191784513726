export const validateConfirmationNumber = (inputValue: string) => {
  const re = new RegExp('^[a-zA-Z0-9]{6,15}$')
  if (inputValue === '') {
    return 'AGENT_CONFIRTMATION_MANDATORY_FIELD_ERROR'
  }
  if (!re.test(inputValue)) {
    return 'AGENT_CONFIRMATION_REGEX_ERROR'
  }

  return ''
}
