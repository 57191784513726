export const defaultValues = {
  dispatchID: '',
  id: '',
  contractID: '',
  paymentID: '',
  companyCode: '',
  vendor: { id: '', value: '', label: 'All vendors' },
  modelNumber: '',
  priceMin: '',
  priceMax: '',
  orderDateMin: null,
  orderDateMax: null,
  orderID: '',
}
