import React from 'react'
import { TextComponent as Text } from '../custom-fdr-components'
import { useSelector } from 'react-redux'
import { AppState } from '../../utils/shared-types'
import { getServiceLimit } from 'src/utils/helper'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'

interface IDispatchCard {
  wrapperClasses?: string
  elemsClasses?: string
  style?: any
}
export const DispatchIdCard: React.FC<IDispatchCard> = ({
  wrapperClasses,
  elemsClasses,
  style,
}) => {
  const survey = useSelector((store: AppState) => store.survey.agentSurvey)
  const wrapperProps = wrapperClasses
    ? { className: wrapperClasses }
    : undefined
  const elemProps = elemsClasses ? { className: elemsClasses } : undefined
  const serviceLimit = getServiceLimit(survey)

  return (
    <div {...wrapperProps} style={style}>
      <div {...elemProps}>
        <Text variant="label" textTemplateKey="SURVEY_DISPATCH_ID_LABEL" />
        <Text id="dispatch-id-card-id">{survey.dispatchID}</Text>
      </div>
      <div {...elemProps}>
        <Text variant="label" textTemplateKey="SURVEY_PART_REQUEST_LABEL" />
        <Text id="dispatch-id-card-part-req">{survey.partRequestId}</Text>
      </div>
      <div {...elemProps}>
        <Text variant="label" textTemplateKey="SURVEY_SERVICE_ITEM_LABEL" />
        <Text id="dispatch-id-card-item">{survey.item?.name}</Text>
      </div>
      {serviceLimit != null && (
        <div {...elemProps}>
          <Text variant="label" textTemplateKey="SURVEY_SERVICE_LIMIT_LABEL" />
          <Text id="dispatch-id-card-item">
            {formatCentsCurrency(serviceLimit)}
          </Text>
        </div>
      )}
      <div>
        <Text
          variant="label"
          textTemplateKey="SURVEY_SERVICE_COVERAGE_LIMIT_LABEL"
        />
        <Text id="dispatch-id-card-item">{survey.dcov ? 'True' : 'False'}</Text>
      </div>
    </div>
  )
}
