import React, { useState, useRef, useEffect } from 'react'

import {
  useAppContext,
  Ahs2024LogoColor,
  HsaLogoColor,
} from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from '../custom-fdr-components'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'
import { useAuth } from '@ftdr/use-auth'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { isHSA } from 'src/utils/tenant-helper'
import Hamburger from 'src/components/hamburger/hamburger'
import { useMenuContext } from '../../hooks/use-menu-context'
import { useScrolledContext } from '../../hooks/use-isScrolled-context'
import Compareproducts from '../compare-products/compareProducts'
import CompareMobileButton from '../compare-mobile-button/compare-mobile-button'
import { useDecision } from '@optimizely/react-sdk'
import './triangle.css'

export const NavBar = () => {
  const { isScrolled } = useScrolledContext()
  const [isDesktop, setIsDesktop] = useState<boolean>()
  const { linkToggle } = useMenuContext()

  const {
    appSettings: { localizedText },
  } = useAppContext()
  let { pathname } = useLocation()
  const { replacementId } = useSelector(
    (state: AppState) => state.compareProducts
  )
  const history = useHistory()
  const replacement = useSelector((state: AppState) => state.replacement.data)
  const { oidcUser } = useAuth()

  const [isOptiFlagOn] = useDecision('ecard_whitelist')
  const [isNewPaymentFormFlagOn] = useDecision('payment_form')

  const homeUrl = '/'
  const [compareLink, setCompareLink] = useState<string>('')

  const [isCompareHovered, setCompareHovered] = useState<boolean>(false)
  const [triangleDistance, setTriangleDistance] = useState<number>(0)
  const compareRef = useRef<HTMLLIElement>(null)
  const compareProducts = useSelector(
    (state: AppState) => state.compareProducts.data
  )

  const openCompareBox = () => {
    const distance = compareRef.current?.getBoundingClientRect().right || 0
    setTriangleDistance(distance)
    setCompareHovered(true)
  }
  const handleCompareClick = (e: any) => {
    if (compareProducts.length < 2) e.preventDefault()
  }
  const hasWindow = typeof window !== 'undefined'

  function getWindowWidth() {
    const width = hasWindow ? window.innerWidth : 0

    return width
  }
  useEffect(() => {
    const setNavLinkUrl = (replacementId: string, path: string) => {
      const regex = new RegExp(`^/${replacementId}/`)

      if (regex.test(path)) {
        return `/${replacementId}/compare`
      } else {
        return '/compare'
      }
    }
    setCompareLink(setNavLinkUrl(replacementId, pathname))

    if (pathname === '/compare' || pathname === `/${replacementId}/compare`) {
      setCompareHovered(false)
    }
    //eslint-disable-next-line
  }, [pathname, replacementId])

  useEffect(() => {
    function handleResize() {
      if (getWindowWidth() >= 1024) {
        setIsDesktop(true)
      } else {
        setIsDesktop(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div
        id="nav-bar-wrapper"
        className={`${
          isHSA()
            ? 'bg-white border-b border-gray-400'
            : 'bg-picket-white border-b border-gray-400'
        }  h-16 lg:h-20  fixed left-0 right-0 w-full top-0 z-30`}
        style={
          isDesktop
            ? {
                height: !isScrolled ? '6rem' : '3rem',
                transition: 'all .3s',
              }
            : {
                height: !isScrolled ? '5rem' : '3rem',
                transition: 'all .3s',
              }
        }
      >
        <div className="h-full flex items-stretch px-4 lg:px-12 w-full relative">
          <NavLink
            id="nav-bar-link-home"
            to={homeUrl}
            onClick={linkToggle}
            className={`mr-auto ml-6 flex flex-row items-center ${
              replacement ? 'pointer-events-none' : ''
            }`}
          >
            {isDesktop ? (
              !isHSA() ? (
                <Ahs2024LogoColor
                  data-testid="logo"
                  className="transition-all"
                  height={!isScrolled ? 50 : 24}
                  width={!isScrolled ? 150 : 72}
                />
              ) : (
                <HsaLogoColor
                  data-testid="logo"
                  className="transition-all"
                  height={!isScrolled ? 50 : 24}
                  width={!isScrolled ? 150 : 72}
                />
              )
            ) : !isHSA() ? (
              <Ahs2024LogoColor
                data-testid="logo"
                className="transition-all"
                height={!isScrolled ? 50 : 24}
                width={!isScrolled ? 150 : 72}
              />
            ) : (
              <HsaLogoColor
                data-testid="logo"
                className="transition-all"
                height={!isScrolled ? 40 : 20}
                width={!isScrolled ? 120 : 60}
              />
            )}
          </NavLink>
          {!oidcUser && (
            <>
              <div className="flex items-center lg:hidden">
                <CompareMobileButton />
              </div>
              <div className="ml-auto  items-center hidden lg:flex">
                {compareProducts.length > 0 && (
                  <li
                    id="nav-bar-oid-user-compare-link-wrapper"
                    ref={compareRef}
                    className="flex items-center h-full  mr-4"
                    onMouseEnter={() => {
                      openCompareBox()
                    }}
                    onMouseLeave={() => setCompareHovered(false)}
                  >
                    <NavLink
                      id="nav-bar-oid-user-compare-link"
                      activeClassName="" //confirm that with designer
                      className={`${isScrolled ? 'py-1' : 'py-4'}`}
                      onClick={handleCompareClick}
                      to={compareLink}
                    >
                      <Text
                        className={isHSA() ? 'font-normal' : 'font-bold'}
                        id="nav-bar-oid-user-compare-count-number"
                        color={isHSA() ? 'primary' : 'interactive'}
                      >{`Compare ( ${compareProducts.length} )`}</Text>
                    </NavLink>
                    {isCompareHovered && (
                      <div
                        id="nav-bar-oid-user-compare-box"
                        className="absolute top-16 w-full left-0 h-40"
                        style={
                          isDesktop
                            ? {
                                top: !isScrolled
                                  ? 'calc(6rem - 20px)'
                                  : 'calc(3rem - 10px)',
                                transition: 'all .3s',
                              }
                            : {
                                top: !isScrolled ? '5rem' : '3rem',
                                transition: 'all .3s',
                              }
                        }
                      >
                        <div
                          style={{ height: !isScrolled ? 20 : 10 }}
                          className={`w-full bg-transparent relative overflow-hidden`}
                        >
                          <div
                            className={`bg-interactive-500 relative overflow-hidden  ${
                              isScrolled
                                ? 'triangle-with-shadow-scrolled'
                                : 'triangle-with-shadow'
                            }`}
                            style={{
                              right: getWindowWidth() - triangleDistance - 4,
                              top: !isScrolled ? -20 : -10,
                            }}
                          ></div>
                        </div>
                        <div
                          className="bg-pale-50 shadow-raised"
                          style={{ height: 260 }}
                        >
                          <Compareproducts link={compareLink} />
                        </div>
                      </div>
                    )}
                  </li>
                )}
                <div
                  id="wrapper-01"
                  className="flex justify-center items-center"
                >
                  <Button
                    id="nav-bar-oid-user-faq-d"
                    variant="ghost"
                    color={isHSA() ? 'primary' : 'interactive'}
                    className="mr-4"
                    onClick={() => history.push('/faq')}
                    label={localizedText('NAVBAR_FAQ_LABEL')}
                  />
                  <Text
                    id="nav-bar-oid-user-c-name"
                    className="hidden lg:block font-bold"
                    color={isHSA() ? 'primary' : 'interactive'}
                    textTemplateKey="NAVBAR_HELLO"
                    textTemplateData={{
                      name: replacement?.customer?.name
                        ? replacement?.customer?.name
                        : 'customer',
                    }}
                  />
                </div>
              </div>
              <Button
                id="nav-bar-oid-user-faq-m"
                variant="ghost"
                size="small"
                color={isHSA() ? 'primary' : 'interactive'}
                className="lg:hidden m-0 font-bold  border-0"
                onClick={() => history.push('/faq')}
                label={localizedText('NAVBAR_FAQ_LABEL')}
              />
            </>
          )}
          {oidcUser && (
            <div className="flex items-center ">
              <ul className="hidden lg:flex items-center">
                <li className="flex items-center h-full mr-4">
                  <NavLink
                    id="nav-bar-oid-user-payment-d"
                    activeClassName=""
                    className={`${isScrolled ? 'py-1' : 'py-4'}`}
                    to={
                      isNewPaymentFormFlagOn.enabled
                        ? '/payment'
                        : '/quick-payment'
                    }
                  >
                    <Text
                      color={isHSA() ? 'primary' : 'interactive'}
                      className={isHSA() ? 'font-normal' : 'font-bold'}
                    >
                      {localizedText('NAVBAR_LINK_PAYMENT')}
                    </Text>
                  </NavLink>
                </li>

                <li className="flex items-center h-full mr-4">
                  <NavLink
                    id="nav-bar-oid-user-status-d"
                    activeClassName=""
                    className={`${isScrolled ? 'py-1' : 'py-4'} ${
                      isHSA() ? 'font-normal' : 'font-bold'
                    }`}
                    to="/status"
                  >
                    <Text
                      color={isHSA() ? 'primary' : 'interactive'}
                      className={`${isHSA() ? 'font-normal' : 'font-bold'}`}
                    >
                      {localizedText('NAVBAR_LINK_TRACKING')}
                    </Text>
                  </NavLink>
                </li>

                <li className="flex items-center h-full  mr-4">
                  <NavLink
                    id="nav-bar-oid-user-queue-d"
                    activeClassName=""
                    className={`${isScrolled ? 'py-1' : 'py-4'}`}
                    to="/queue"
                  >
                    <Text
                      color={isHSA() ? 'primary' : 'interactive'}
                      className={`${isHSA() ? 'font-normal' : 'font-bold'}`}
                    >
                      {localizedText('NAVBAR_LINK_QUEUE')}
                    </Text>
                  </NavLink>
                </li>
                {compareProducts.length > 0 && (
                  <li
                    id="nav-bar-oid-user-compare-elem-d"
                    ref={compareRef}
                    className="flex items-center h-full mr-4"
                    onMouseEnter={() =>
                      pathname === '/compare' ? false : openCompareBox()
                    }
                    onMouseLeave={() => setCompareHovered(false)}
                  >
                    <NavLink
                      id="nav-bar-oid-user-compare-link-d"
                      activeClassName=""
                      className={`${isScrolled ? 'py-1' : 'py-4'}`}
                      onClick={handleCompareClick}
                      to={compareLink}
                    >
                      <Text
                        id="nav-bar-oid-user-compare-counter-d"
                        className={`${isHSA() ? 'font-normal' : 'font-bold'}`}
                        color={isHSA() ? 'primary' : 'interactive'}
                      >{`Compare ( ${compareProducts.length} )`}</Text>
                    </NavLink>
                    {isCompareHovered && (
                      <div
                        id="nav-bar-oid-user-compare-box-on-hover"
                        className="absolute top-16 w-full  left-0 h-40"
                        style={
                          isDesktop
                            ? {
                                top: !isScrolled
                                  ? 'calc(6rem - 20px)'
                                  : 'calc(3rem - 10px)',
                                transition: 'all .3s',
                              }
                            : {
                                top: !isScrolled ? '5rem' : '3rem',
                                transition: 'all .3s',
                              }
                        }
                      >
                        <div
                          style={{ height: !isScrolled ? 20 : 10 }}
                          className={`w-full bg-transparent relative overflow-hidden`}
                        >
                          <div
                            className={`relative overflow-hidden  ${
                              isScrolled
                                ? 'triangle-with-shadow-scrolled'
                                : 'triangle-with-shadow'
                            }`}
                            style={{
                              right: getWindowWidth() - triangleDistance - 4,
                              top: !isScrolled ? -20 : -10,
                            }}
                          ></div>
                        </div>
                        <div
                          className="bg-white shadow-raised"
                          style={{ height: 260 }}
                        >
                          <Compareproducts link={compareLink} />
                        </div>
                      </div>
                    )}
                  </li>
                )}
                {isOptiFlagOn.enabled && (
                  <li className="flex items-center h-full mr-4">
                    <NavLink
                      id="nav-bar-oid-user-ecard-d"
                      activeClassName=""
                      className={`${isScrolled ? 'py-1' : 'py-4'}`}
                      to="/ecard"
                    >
                      <Text
                        color={isHSA() ? 'primary' : 'interactive'}
                        className={`${isHSA() ? 'font-normal' : 'font-bold'}`}
                      >
                        {localizedText('NAVBAR_LINK_ECARD')}
                      </Text>
                    </NavLink>
                  </li>
                )}
                <li className="flex items-center h-full mr-4">
                  <NavLink
                    id="nav-bar-oid-user-agent-faq-d"
                    className={`${!isScrolled ? 'py-4' : 'py-1'}`}
                    to="/agent-faq"
                  >
                    <Text
                      color={isHSA() ? 'primary' : 'interactive'}
                      className={`${isHSA() ? 'font-normal' : 'font-bold'}`}
                    >
                      {localizedText('NAVBAR_LINK_AGENTFAQ')}
                    </Text>
                  </NavLink>
                </li>
              </ul>
              <Text
                id="nav-bar-oid-user-profile-d"
                color={isHSA() ? 'primary' : 'interactive'}
                className={`ml-2 hidden lg:block ${
                  isHSA() ? 'font-normal' : 'font-bold'
                }`}
                textTemplateKey="NAVBAR_HELLO"
                textTemplateData={{
                  name: oidcUser.profile.name
                    ? oidcUser.profile.name
                    : 'customer',
                }}
              />
            </div>
          )}
          {oidcUser && <Hamburger />}
        </div>
      </div>
    </>
  )
}
