import { emptyItem } from 'src/components/adminForms/initials'
import { api } from 'src/utils/api'
import { paymentTypes } from 'src/utils/payment-types'

export const initValues = {
  paymentType: emptyItem,
}

// Temporary paymentTypes excluded with Continue Replacement option

export const pTypes = () => {
  if (api.config.CONTINUE_REPLACEMENT) {
    return [
      ...paymentTypes,
      //remove it if protobuff will update
      {
        id: '5',
        value: 'Continue Replacement',
        label: 'Continue Replacement',
      },
    ]
  } else {
    return paymentTypes
  }
}
export const labelsKeys = {
  dispatchID: 'OUTRIGHT_PAYMENT_DISPATCH_ID_LABEL',
  contractID: 'OUTRIGHT_PAYMENT_CONTRACT_ID_LABEL',
  orderID: 'OUTRIGHT_PAYMENT_ORDER_NUMBER_LABEL',
  firstName: 'OUTRIGHT_PAYMENT_FIRST_NAME_LABEL',
  lastName: 'OUTRIGHT_PAYMENT_LAST_NAME_LABEL',
  billingState: 'OUTRIGHT_PAYMENT_STATE_LABEL',
  vendor: 'OUTRIGHT_PAYMENT_VENDOR_LABEL',
  date: 'OUTRIGHT_PAYMENT_ORDER_DATE_LABEL',
  sku: 'OUTRIGHT_PAYMENT_SKU_LABEL',
  tenantAbbreviation: 'OUTRIGHT_PAYMENT_TENANT_ABBREVIATION_LABEL',
}
