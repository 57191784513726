import React, { useRef, RefObject, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { PaymentsMethodMfeExposedApi } from '@ftdr/payment-method-micro-frontend'
import {
  Select,
  Input,
  DateTimeInput,
  useAppContext,
  SelectOption,
  SelectHandler,
} from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from 'src/components/custom-fdr-components'
import { useGetVendors } from 'src/hooks/use-get-vendors'
import { usePayments } from '../../../hooks/use-payment'
import PaymentsForm from './StripeSubForm/outright-payment-stripe-sub-form'
import Description from './Description/outright-payment-description'
import { yupResolver } from '@hookform/resolvers/yup'
import { outrightPaymentSchema } from './utils/outright-payment-validation'
import { paymentTypes } from 'src/utils/payment-types'
import { states } from 'src/utils/states'
import { defaultValues } from './utils/outright-payment-default-values'
import { IForm } from '../../../utils/shared-types'
import { usePaymentContext } from 'src/hooks/use-payment-context'
import Warning from '../Warning/outright-payment-warning'
import './outright-payment-form.css'

const OutrightPaymentForm = () => {
  const submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi> = useRef(null)
  const { submitStripePayment } = usePayments()
  const { status: paymentStatus, errorMsg } = usePaymentContext()
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { isVendorsLoading, getVendors } = useGetVendors()
  const [isWarningOpen, setWarningOpen] = useState<boolean>(false)

  const {
    watch,
    reset,
    resetField,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(outrightPaymentSchema),
    defaultValues,
  })

  const paymentType = watch('paymentType')
  const vendorType = watch('vendor')

  const submitForm = (data: IForm) => {
    data.paymentType.id === '4' && !isWarningOpen && +data.amount > 100
      ? setWarningOpen(true)
      : submitStripePayment(data.amount, submitPaymentRef, data)
  }

  useEffect(() => {
    paymentStatus === 'success' && reset()
    //eslint-disable-next-line
  }, [paymentStatus])

  useEffect(() => {
    if (vendorType.id === 'PC') {
      setValue('modelNumber', 'HVAC', { shouldValidate: true })
      setValue('paymentType', paymentTypes.filter((elem) => elem.id === '1')[0])
    } else {
      setValue('modelNumber', '', {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      })
    }
    //eslint-disable-next-line
  }, [vendorType])
  return (
    <div className="flex flex-col items-center sm:block max-w-sm md:max-w-lg m-auto lg:m-0">
      <Warning
        isOpen={isWarningOpen}
        setIsOpen={setWarningOpen}
        submitForm={handleSubmit(submitForm)}
      />
      <Description payment={paymentType.label} />

      <form onSubmit={handleSubmit(submitForm)}>
        <Controller
          name="paymentType"
          control={control}
          render={({ field }) => (
            <Select
              id="outright-payment-form-type"
              selected={field.value}
              error={errors.paymentType?.message}
              formField
              label={localizedText('OUTRIGHT_PAYMENT_TYPES_LABEL')}
              className="w-full md:w-56 mb-6"
              options={
                vendorType.id === 'PC'
                  ? paymentTypes.filter((elem) => elem.id === '1')
                  : paymentTypes
              }
              onSelect={(e: any) => {
                field.onChange(e)
                resetField('orderID')
                resetField('dispatchID')
                resetField('contractID')
              }}
              onBlur={field.onBlur}
              placeholder="Select an option"
            />
          )}
        />
        {paymentType.id !== '1' && (
          <Controller
            name="dispatchID"
            control={control}
            render={({ field }) => (
              <Input
                id="outright-payment-form-did"
                error={errors.dispatchID?.message}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('OUTRIGHT_PAYMENT_DISPATCH_ID_LABEL')}
                className="w-full"
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
        )}

        <Controller
          name="contractID"
          control={control}
          render={({ field }) => (
            <div className="w-full sm:w-auto md:w-56 mb-6 relative">
              <Input
                id="outright-payment-form-cid"
                error={errors.contractID?.message}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('OUTRIGHT_PAYMENT_CONTRACT_ID_LABEL')}
                className="w-full"
                onChange={(event) => {
                  field.onChange(event)
                }}
                onBlur={field.onBlur}
              />
            </div>
          )}
        />

        {paymentType.id === '3' && (
          <Controller
            name="orderID"
            control={control}
            render={({ field }) => (
              <Input
                id="outright-payment-form-on"
                error={errors.orderID?.message}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('OUTRIGHT_PAYMENT_ORDER_NUMBER_LABEL')}
                className="w-full"
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
        )}
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <Input
              id="outright-payment-form-name"
              error={errors.firstName?.message}
              value={field.value}
              formField
              formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
              label={localizedText('OUTRIGHT_PAYMENT_FIRST_NAME_LABEL')}
              className="w-full"
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              id="outright-payment-form-last-name"
              error={errors.lastName?.message}
              value={field.value}
              formField
              formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
              label={localizedText('OUTRIGHT_PAYMENT_LAST_NAME_LABEL')}
              className="w-full"
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />
        <Controller
          name="billingState"
          control={control}
          render={({ field }) => (
            <Select
              id="outright-payment-form-state"
              autoComplete={true}
              error={errors.billingState?.message}
              selected={field.value}
              formField
              label={localizedText('OUTRIGHT_PAYMENT_STATE_LABEL')}
              className="w-full md:w-56 mb-6"
              options={states}
              onSelect={field.onChange as SelectHandler<SelectOption>}
              onBlur={field.onBlur}
              placeholder="Select an option"
            />
          )}
        />
        <Controller
          name="vendor"
          control={control}
          render={({ field }) => (
            <Select
              id="outright-payment-form-vendor"
              autoComplete={true}
              error={errors.vendor?.message}
              selected={field.value}
              formField
              label={localizedText('OUTRIGHT_PAYMENT_VENDOR_LABEL')}
              className="w-full md:w-56 mb-6"
              options={getVendors(['BH'])}
              onSelect={field.onChange as SelectHandler<SelectOption>}
              onBlur={field.onBlur}
              placeholder="Select an option"
              disabled={isVendorsLoading}
            />
          )}
        />
        <Controller
          name="modelNumber"
          control={control}
          render={({ field }) => (
            <Input
              id="outright-payment-form-model"
              error={errors.modelNumber?.message}
              value={field.value}
              formField
              formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
              label={localizedText('OUTRIGHT_PAYMENT_MODEL_LABEL')}
              className="w-full"
              onChange={(e) => field.onChange(e.target.value.toUpperCase())}
              onBlur={field.onBlur}
              disabled={vendorType.id === 'PC'}
            />
          )}
        />
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <DateTimeInput
              id="outright-payment-form-date"
              error={errors.date?.message}
              label={localizedText('OUTRIGHT_PAYMENT_ORDER_DATE_LABEL')}
              formFieldClassName="w-full sm:w-auto"
              className="oDate w-full md:w-56 mb-6"
              selectedDate={field.value}
              onDateSelect={field.onChange}
              onBlur={field.onBlur}
              showMonths={1}
              maxDate={new Date()}
              onlyDate
              datePicker
              showOutsideMonthDates={true}
              allowSelectOutsideMonthDates
              alwaysShowDateClearButton
            />
          )}
        />
        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <Input
              id="outright-payment-form-amount"
              error={errors.amount?.message}
              value={field.value}
              inputMode="decimal"
              startEnhancer={() => <Text>$</Text>}
              formField
              formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
              label={localizedText('OUTRIGHT_PAYMENT_AMOUNT_LABEL')}
              className="w-full"
              onChange={field.onChange}
              onBlur={(e) => {
                setValue('amount', `${+e.target.value}`)
                field.onBlur()
              }}
            />
          )}
        />
        <PaymentsForm error={errorMsg} submitPaymentRef={submitPaymentRef} />
        <Button
          id="outright-payment-form-submit"
          type="submit"
          label="Submit"
          className="mt-8 w-full md:w-auto flex justify-center lg:inline-block"
          onClick={() => {}}
        />
      </form>
    </div>
  )
}
export default OutrightPaymentForm
