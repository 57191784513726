import { emptyItem } from 'src/components/adminForms/initials'

export const initValues = {
  contractID: '',
  tenantAbbreviation: emptyItem,
  firstName: '',
  lastName: '',
  billingState: emptyItem,
  date: new Date(),
  vendor: emptyItem,
  sku: '',
  agreements: false,
  amount: '',
}

export const labelsKeys = {
  contractID: 'OUTRIGHT_PAYMENT_CONTRACT_ID_LABEL',
  firstName: 'OUTRIGHT_PAYMENT_FIRST_NAME_LABEL',
  lastName: 'OUTRIGHT_PAYMENT_LAST_NAME_LABEL',
  billingState: 'OUTRIGHT_PAYMENT_STATE_LABEL',
  vendor: 'OUTRIGHT_PAYMENT_VENDOR_LABEL',
  date: 'OUTRIGHT_PAYMENT_ORDER_DATE_LABEL',
  sku: 'OUTRIGHT_PAYMENT_SKU_LABEL',
}
