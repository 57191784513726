import { Breadcrumbs, useAppContext } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import React, { useEffect, useState } from 'react'
import { api } from 'src/utils/api'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import Carousel from 'src/components/carousel/carousel'
import OrderProgress from './order-progress'
import { useMedia } from '../../hooks/use-media'
import { useParams } from 'react-router'
import { SelectedSlideProvider } from 'src/hooks/use-slide-context'
interface IParams {
  replacementId: string
}
export const StatusPage = () => {
  const { replacementId } = useParams<IParams>()
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const [requestStatus, setRequestStatus] = useState('loading')
  const [orderDate, setOrderDate] = useState('')
  const [deliveryDate, setDeliveryDate] = useState('')

  const [
    orderStatus,
    setOrderStatus,
  ] = useState<appliancepb.OrderStatus | null>(null)

  const [product, setProduct] = useState<appliancepb.Product | null>(null)
  const isDesktop = useMedia('(min-width:1366px)')
  const fetchData = async (replacementId: string) => {
    try {
      const status = await api.getPageOrderStatus(replacementId)
      const orderDate = (new Date(status.orderDate?.seconds * 1000)).toDateString()
      const deliveryDate = status.deliveryDate ? (new Date(status.deliveryDate.seconds * 1000)).toDateString() : ("")
      setOrderStatus(status)
      setOrderDate(orderDate)
      setDeliveryDate(deliveryDate)
      setProduct(status.product as appliancepb.Product)
      setRequestStatus('ok')
    } catch (err: any) {
      if (err.response.status === 400) {
        setRequestStatus('not found')
      } else {
        setRequestStatus('error')
      }
    }
  }

  useEffect(() => {
    fetchData(replacementId)
    //eslint-disable-next-line
  }, [])

  if (requestStatus === 'error') {
    return (
      <Text id="status-page-order-error">
        {localizedText('TRACKING_DETAILS_ORDER_ERROR')}
      </Text>
    )
  }

  if (requestStatus === 'not found') {
    return <Text>{localizedText('TRACKING_DETAILS_ORDER_NOT_FOUND')}</Text>
  }

  return (
    <div className="overflow-hidden -mt-8 lg:-mt-0">
      <div className="-mx-6 lg:mx-0" id="status-page-breadcrumbs-wrapper">
        <Breadcrumbs
          color="primary"
          className="mb-6"
          currentPageLabel={localizedText(
            'TRACKING_DETAILS_BREADCRUMB_CURRENT'
          )}
          links={[
            {
              id: 'breadcrumb',
              href: '/status',
              children: localizedText('TRACKING_DETAILS_BREADCRUMB_CHILD'),
              underline: 'hover',
            },
          ]}
        />
      </div>
      {requestStatus === 'loading' ? (
        <div className="mb-8" id="status-page-spinner-wrapper">
          <ProgressIndicator size="medium" data-testid="spinner" />
        </div>
      ) : (
        <>
          <div className="mb-8">
            <Text className="mb-1" id="status-page-order-id-m">
              {`${localizedText('TRACKING_DETAILS_ORDER_NUMBER_LABEL')} ${
                orderStatus?.orderID
              }`}
            </Text>
            <Text
              id="status-page-order-id-d"
              variant={`${isDesktop ? 'heading-02' : 'heading-03'}`}
              className="mb-1"
            >
              {orderStatus?.status}
            </Text>
            {orderDate && orderStatus?.status !== 'Order Status Not Available' && (
              <>
                  <Text
                    id="status-page-order-date"
                    variant="heading-04"
                    className="font-light mb-1"
                  >
                    {`${localizedText(
                      'TRACKING_DETAILS_ORDER_DATE_LABEL'
                    )}: ${orderDate}`}
                  </Text>
               {orderStatus!.progress < 3 && (
                  <Text
                    id="status-page-delivery-date"
                    variant="heading-04"
                    className="font-light"
                  >
                    {deliveryDate !== "" ? `${localizedText(
                      'TRACKING_DETAILS_DELIVERY_DATE_LABEL'
                    )}: ${deliveryDate}` : `${localizedText(
                      'TRACKING_DETAILS_DELIVERY_DATE_LABEL'
                    )}: N/A`}
                  </Text>
                )}
                {orderStatus!.progress === 3 && (
                  <Text
                    id="status-page-delivered-date"
                    variant="heading-04"
                    className="font-light"
                  >
                    {`${localizedText(
                      'TRACKING_DETAILS_DELIVERY_DATE_LABEL_DELIVERED'
                    )}: ${deliveryDate}`}
                  </Text>
                )}
             </>
            )}
          </div>
          <div className="my-8 flex justify-center">
            <div className="w-9/12">
              <OrderProgress status={orderStatus?.progress as number} />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="shrink-0 w-full md:w-1/2 order-2 md:order-1 mt-6 md:mt-0">
              <Text variant="heading-05" className="mb-4">
                {localizedText('TRACKING_DETAILS_ORDERINFO_LABEL')}
              </Text>
              <div className="grid gap-2">
                <div>
                  <Text>{localizedText('TRACKING_DETAILS_ITEM_LABEL')}</Text>
                  <Text id="status-page-p-description" className="font-bold">
                    {orderStatus?.product?.description}
                  </Text>
                </div>
                <div>
                  <Text>{localizedText('TRACKING_DETAILS_VENDOR_LABEL')}</Text>
                  <Text id="status-page-p-vendor" className="font-bold">
                    {orderStatus?.vendorName}
                  </Text>
                </div>
                <div>
                  <Text>
                    {localizedText('TRACKING_DETAILS_ORDER_NUMBER_LABEL')}{' '}
                  </Text>
                  <Text id="status-page-order-status-id" className="font-bold">
                    {orderStatus?.orderID || "N/A"}
                  </Text>
                </div>
                <div>
                  <Text>
                    {localizedText('TRACKING_DETAILS_DISPATCH_LABEL')}
                  </Text>
                  <Text id="status-page-p-dispatch-id" className="font-bold">
                    {orderStatus?.dispatchID}
                  </Text>
                </div>
                <div>
                  <Text>
                    {localizedText('TRACKING_DETAILS_PARTREQUEST_LABEL')}
                  </Text>
                  <Text id="status-page-p-part-req--id" className="font-bold">
                    {orderStatus?.partRequestID}
                  </Text>
                </div>
              </div>
            </div>
            {product && (
              <div
                className="w-full md:w-1/2 md:grow-1 order-1 md:order-2"
                style={{
                  height: isDesktop ? 'auto' : 'auto',
                  paddingRight: isDesktop
                    ? 'calc(( 100% - 9 / 12 * 100% ) / 2 )'
                    : 0,
                }}
              >
                <SelectedSlideProvider>
                  <Carousel product={product} />
                </SelectedSlideProvider>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
