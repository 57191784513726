export const initialFunctions = [
  { id: '0', value: 'banners', label: 'Banners' },
  { id: '1', value: 'blocklist', label: 'Blocklist' },

  { id: '2', value: 'caches', label: 'Caches' },

  { id: '3', value: 'coupa', label: 'Coupa' },

  { id: '4', value: 'no_delivery', label: 'No delivery' },

  { id: '5', value: 'refresh_catalog', label: 'Refresh catalog' },

  { id: '6', value: 'special_prices', label: 'Special prices' },
]
export const initialActions = [
  { id: '1', value: 'download', label: 'Download' },
  { id: '2', value: 'add', label: 'Add' },
  { id: '3', value: 'replace', label: 'Replace' },
  { id: '4', value: 'update', label: 'Update' },
]
export const emptyItem = {
  id: '',
  value: '',
  label: '',
}
export const emptyCacheObject = {
  isLoading: false,
  error: false,
  message: null,
}
export const emptyBanner = {
  id: null,
  message: null,
  severity: null,
  activate: null,
  expire: null,
}
export const initialCacheDB = [
  { id: '1', value: 'redis', label: 'Redis' },
  { id: '2', value: 'mongo', label: 'MongoDB' },
]
export const initialCacheKeyMongo = [
  // { id: '1', value: 'all', label: 'All' },
  { id: '2', value: 'availability', label: 'Availability' },
]
export const initialCacheKeyRedis = [
  { id: '1', value: 'all', label: 'All' },
  { id: '2', value: 'address', label: 'Address' },
  { id: '3', value: 'availability', label: 'Availability Lists' },
  { id: '4', value: 'banner', label: 'Banner' },
  { id: '5', value: 'blocklist', label: 'Blocklist' },
  { id: '6', value: 'no-delivery', label: 'No Delivery' },
  { id: '7', value: 'price-model', label: 'Price Model' },
  { id: '8', value: 'product', label: 'Product' },
  { id: '9', value: 'product-available', label: 'Product Availability' },
  { id: '10', value: 'service-codes', label: 'Service Codes' },
]

export const initialBannersAudience = [
  { id: '0', value: 'agent', label: 'Agent' },
  { id: '1', value: 'customer', label: 'Customer' },
  { id: '2', value: 'everyone', label: 'Everyone' },
]
export const initialBannersTypes = [
  { id: '1', value: 'info', label: 'Info' },
  { id: '2', value: 'error', label: 'Error' },
  { id: '3', value: 'warning', label: 'Warning' },
]
export const initialBannersMessage = [
  {
    id: '0',
    value: 'm1',
    label:
      'We are currently experiencing system issues with our site and are unable to access product availability. We apologize for the inconvenience.',
  },
  {
    id: '1',
    value: 'm2',
    label:
      'Our website is temporarily unavailable because our team is working behind the scenes on improvements.',
  },
  {
    id: '2',
    value: 'user-message',
    label: 'other...',
  },
]
