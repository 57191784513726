/* eslint-disable no-useless-escape */
export const guidRegex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-[8|9|aA|bB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/
export const regexDigit = /^\d*$/
export const regexLetters = /^([^0-9]*)$/
export const regexPhoneNumber = /^(\d{3}-)(\d{3}-)(\d{4})$/
export const regexAlphanumeric = /^[a-zA-Z0-9]*$/
export const regexPrice = /^\d*\.?\d*$/
export const regexSKU = /^[A-Z\d/\-]{3,15}$/
export const regexContractID = /^[0-9]{1,50}$/
export const regexOrderID = /^[a-zA-Z0-9]{6,45}$/
export const regexModelNumber = /^[A-Z\d/\-]{3,15}$/
export const regexPaymentID = /^[a-zA-Z0-9]{10,32}$/
export const regexDecimal = /^[0-9]*(\d[.]\d{1,2})?$/
export const regexPriceDecimal = /^\d+(\.\d{1,2})?$/
export const regexPriceDecimalNegative = /^[-]?\d+(\.\d{1,2}?$)/
export const regexRange = /^[0-9]+-[0-9]+$/
export const regexNegativeDigit = /^[-]\d+/
export const regexAllowPriceDecimal = /^$|^0(\.\d{0,2})?$|^[1-9]\d*(\.\d{0,2})?$/

/**
 * Checks if a value is a valid GUID.
 * @param value GUID to validate
 */

export const isValidGUID = (value: string | undefined): boolean => {
  return !!value && guidRegex.test(value)
}

/**
 * Checks if a value is a string with only digits.
 * @param value string to validate
 */

export const isNumber = (value: string): boolean => regexDigit.test(value)

/**
 * Checks if a value is a range string "<number>-<number>"".
 * @param value string to validate
 */

export const isRange = (value: string): boolean => regexRange.test(value)

/**
 * Checks if a value has a price format (100 or 100.50 etc).
 * @param value string to validate
 */

export const isPrice = (value: string): boolean => regexPrice.test(value)

/**
 * Checks if a value is a string with only digits.
 * @param value string to validate
 */

export const isAlphanumberic = (value: string): boolean =>
  regexAlphanumeric.test(value)

/**
 * Checks if a value is a valid US phone number
 * @param value phoneNumber to validate
 */

export const phoneValidation = (value: string): boolean =>
  regexPhoneNumber.test(value)

/**
 * Checks if a value is a valid SKU phone number
 * @param value sku to validate
 */

export const isSKU = (value: string): boolean => regexSKU.test(value)

export const isContractID = (value: string): boolean =>
  regexContractID.test(value)

export const isOrderID = (value: string): boolean => regexOrderID.test(value)
export const isModelNumber = (value: string): boolean =>
  regexModelNumber.test(value)
