import React, { useCallback, useEffect, useState } from 'react'
import {
  DateTimeInput,
  RadioGroup,
  RadioGroupItem,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from 'src/components/custom-fdr-components'
import pickBy from 'lodash.pickby'
import { api } from 'src/utils/api'
import { saveAs } from 'file-saver'
import {
  yesterday,
  minDateForSummary,
  MSTTimezoneOffset,
} from 'src/utils/internationalization-helper'

export const EcardSearch = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    yesterday
  )
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(yesterday)
  const [reportFormat, setReportFormat] = useState<string>('master')
  const reportOptions: RadioGroupItem[] = [
    { value: 'master', label: 'Master ' },
    { value: 'upload', label: 'Upload' },
    { value: 'summary', label: 'Summary' },
  ]

  const [searchQuery, setSearchQuery] = useState<{
    order_date_min: number
    order_date_max: number
    report: string
  }>({
    order_date_min:
      Date.parse(selectedStartDate!.toUTCString()) / 1000 + MSTTimezoneOffset,
    order_date_max:
      Date.parse(selectedEndDate!.toUTCString()) / 1000 + MSTTimezoneOffset,
    report: reportFormat,
  })

  useEffect(() => {
    setSearchQuery({
      order_date_min:
        Date.parse(selectedStartDate!.toUTCString()) / 1000 + MSTTimezoneOffset,
      order_date_max:
        Date.parse(selectedEndDate!.toUTCString()) / 1000 + MSTTimezoneOffset,
      report: reportFormat,
    })
  }, [selectedStartDate, selectedEndDate, reportFormat])

  const download = useCallback(async () => {
    setLoading(true)
    setError('')
    const params = pickBy(searchQuery)
    try {
      const { data: downloadCSV, fileName } = await api.getEcardCSV({
        ...params,
      })
      const file = new Blob([downloadCSV], { type: 'text/csv;charset=utf-8' })
      saveAs(file, fileName)
    } catch (err: any) {
      setError(err.message)
      console.log(err.message)
    }
    setLoading(false)
    //eslint-disable-next-line
  }, [searchQuery, reportFormat])
  const {
    appSettings: { localizedText },
  } = useAppContext()
  return (
    <div className="sm:p-8 sm:border">
      <Text variant="heading-04" className="mb-6">
        {localizedText('ECARD_SEARCH')}
      </Text>
      <div className="flex flex-row">
        <RadioGroup
          id="ecard-search-radios"
          required
          formField
          radios={reportOptions}
          label={localizedText('ECARD_REPORT_FORMAT')}
          name="Radios"
          value={reportFormat}
          onChange={setReportFormat}
        />
      </div>
      <br></br>
      <div className="xl:flex xl:flex-row">
        <DateTimeInput
          id="ecard-search-dates"
          required
          selectsRange
          label={localizedText('ECARD_SEARCH_BY_DATE')}
          className="w-56"
          startDate={selectedStartDate || undefined}
          selectedDate={selectedStartDate || null}
          endDate={selectedEndDate || undefined}
          onDateSelect={(
            startDate: Date | null | undefined,
            endDate: Date | null | undefined
          ) => {
            setSelectedStartDate(new Date(startDate!.setUTCHours(12, 0, 0, 0)))
            setSelectedEndDate(new Date(endDate!.setUTCHours(12, 0, 0, 0)))
          }}
          showMonths={1}
          startRangeMinDate={
            reportFormat === 'summary' ? minDateForSummary : undefined
          }
          startRangeMaxDate={yesterday}
          endRangeMaxDate={yesterday}
          endRangeMinDate={selectedStartDate || undefined}
          onlyDate
          datePicker
          showOutsideMonthDates={true}
          allowSelectOutsideMonthDates
        />
        <Button
          id="ecard-search-download-button"
          label="Download CSV"
          size="medium"
          className="xl:ml-6 mt-5"
          onClick={download}
          disabled={loading}
        />
      </div>
      {error && (
        <p id="ecard-search-error" className="text-error-500 mt-2">
          {error}
        </p>
      )}
    </div>
  )
}
