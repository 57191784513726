import React, { useEffect } from 'react'
import { AppState } from 'src/utils/shared-types'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getBanners } from 'src/store/admin-store'
import AlertBanner from '../adminForms/banners/alert-banner'

export const AlertNotifications = (): JSX.Element => {
  const dispatch = useDispatch()
  const { banners } = useSelector((state: AppState) => state.admin)

  useEffect(() => {
    dispatch(getBanners())
    //eslint-disable-next-line
  }, [])

  return (
    <div className="mt-0" id="alert-notification-wrapper">
      {banners.length > 0 &&
        banners
          .filter(({ severity }) => severity === 2)
          .map((banner) => <AlertBanner {...banner} key={banner.id} />)}
      {banners.length > 0 &&
        banners
          .filter(({ severity }) => severity === 3)
          .map((banner) => <AlertBanner {...banner} key={banner.id} />)}
      {banners.length > 0 &&
        banners
          .filter(({ severity }) => severity === 1)
          .map((banner) => <AlertBanner {...banner} key={banner.id} />)}
    </div>
  )
}
