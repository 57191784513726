export const reasons = [
  {
    id: '1',
    label: 'Price Lower in Vendor Portal',
    value: 'Price Lower in Vendor Portal',
  },
  {
    id: '2',
    label: 'Price Higher in Vendor Portal',
    value: 'Price Higher in Vendor Portal',
  },
  {
    id: '3',
    label: 'Missing Covered Install Code',
    value: 'Missing Covered Install Code',
  },
  {
    id: '4',
    label: 'Order for Frontdoor Employee',
    value: 'Order for Frontdoor Employee',
  },
  {
    id: '5',
    label: 'Deleted Install Code',
    value: 'Deleted Install Code',
  },
]
