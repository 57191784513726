import React, { useState } from 'react'
import { Input, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { api } from 'src/utils/api'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from 'src/store/initial-state'

interface ModelSearchProps {
  itemId: string
  setIsModelSearched: (isSearched: boolean) => void
  setSearchResults: (searchResults: appliancepb.IProduct[]) => void
}

export const ModelSearch = ({
  itemId,
  setIsModelSearched,
  setSearchResults,
}: ModelSearchProps) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const history = useHistory()
  const [model, setModel] = useState('')
  const [error, setError] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [isBlocklisted, setIsBlocklisted] = useState(false)
  const [loading, setLoading] = useState(false)
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon
  )
  const onModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value.trim())
  }

  const onSearch = async () => {
    const { pathname: path } = history.location
    setError(false)
    setNotFound(false)
    setIsBlocklisted(false)
    setLoading(true)

    try {
      const modelText = model.toUpperCase().trim()
      const models = await api.getModel(modelText, {
        category: itemId,
        trim_kit_size: trimKitSize || '',
        oim: !!icemakerAddon || '',
      })

      if (models.products.length === 1) {
        path !== '/browse'
          ? history.push(`shop/${models.products[0].ID}`)
          : history.push(`browse/${models.products[0].ID}`)
      } else if (models.products.length === 0) {
        setNotFound(true)
        setLoading(false)
      } else {
        setIsModelSearched(true)
        setSearchResults(models.products)
        setLoading(false)
      }
    } catch (error: any) {
      if (error?.response.status === 400) {
        setNotFound(true)
      } else if (error?.response.status === 404) {
        setIsBlocklisted(true)
      } else {
        setError(true)
      }
      setLoading(false)
    }
  }

  const onClearButtonClick = () => {
    setModel('')
    setIsModelSearched(false)
    if (error) {
      setError(false)
    }
    if (notFound) {
      setNotFound(false)
    }
    if (isBlocklisted) {
      setIsBlocklisted(false)
    }
  }

  return (
    <>
      <Input
        id="model-search-model"
        onClearButtonClick={onClearButtonClick}
        showClearButton
        formField
        value={model}
        onChange={onModelChange}
        label={localizedText('BROWSE_MODEL_SEARCH_LABEL')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (model.length > 1) {
              onSearch()
            }
          }
        }}
      />
      <Button
        id="mobile-search-buttton"
        className="mt-2"
        width="full"
        size="small"
        label={localizedText('BROWSE_MODEL_SEARCH_BTN_LABEL')}
        onClick={onSearch}
        disabled={model.length === 0}
        loading={loading}
      />
      {error && (
        <Text id="mobile-search-error" color="error">
          {localizedText('BROWSE_MODEL_SEARCH_ERROR')}
        </Text>
      )}

      {notFound && (
        <Text color="error" id="mobile-search-not-found">
          {localizedText('BROWSE_MODEL_SEARCH_NOT_FOUND')}
        </Text>
      )}
      {isBlocklisted && (
        <Text color="error" id="mobile-search-blocklisted">
          {localizedText('BROWSE_MODEL_SEARCH_BLOCKLISTED')}
        </Text>
      )}
    </>
  )
}
