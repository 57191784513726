/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const appliancepb = $root.appliancepb = (() => {

    /**
     * Namespace appliancepb.
     * @exports appliancepb
     * @namespace
     */
    const appliancepb = {};

    appliancepb.Pair = (function() {

        /**
         * Properties of a Pair.
         * @memberof appliancepb
         * @interface IPair
         * @property {string|null} [ID] Pair ID
         * @property {string|null} [name] Pair name
         */

        /**
         * Constructs a new Pair.
         * @memberof appliancepb
         * @classdesc Represents a Pair.
         * @implements IPair
         * @constructor
         * @param {appliancepb.IPair=} [properties] Properties to set
         */
        function Pair(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Pair ID.
         * @member {string} ID
         * @memberof appliancepb.Pair
         * @instance
         */
        Pair.prototype.ID = "";

        /**
         * Pair name.
         * @member {string} name
         * @memberof appliancepb.Pair
         * @instance
         */
        Pair.prototype.name = "";

        /**
         * Creates a new Pair instance using the specified properties.
         * @function create
         * @memberof appliancepb.Pair
         * @static
         * @param {appliancepb.IPair=} [properties] Properties to set
         * @returns {appliancepb.Pair} Pair instance
         */
        Pair.create = function create(properties) {
            return new Pair(properties);
        };

        /**
         * Encodes the specified Pair message. Does not implicitly {@link appliancepb.Pair.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Pair
         * @static
         * @param {appliancepb.IPair} message Pair message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pair.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified Pair message, length delimited. Does not implicitly {@link appliancepb.Pair.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Pair
         * @static
         * @param {appliancepb.IPair} message Pair message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pair.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Pair message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Pair
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Pair} Pair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pair.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Pair();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Pair message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Pair
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Pair} Pair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pair.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Pair message.
         * @function verify
         * @memberof appliancepb.Pair
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pair.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a Pair message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Pair
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Pair} Pair
         */
        Pair.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Pair)
                return object;
            let message = new $root.appliancepb.Pair();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a Pair message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Pair
         * @static
         * @param {appliancepb.Pair} message Pair
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pair.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ID = "";
                object.name = "";
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this Pair to JSON.
         * @function toJSON
         * @memberof appliancepb.Pair
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pair.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Pair;
    })();

    appliancepb.KeyValues = (function() {

        /**
         * Properties of a KeyValues.
         * @memberof appliancepb
         * @interface IKeyValues
         * @property {string|null} [key] KeyValues key
         * @property {Array.<string>|null} [values] KeyValues values
         */

        /**
         * Constructs a new KeyValues.
         * @memberof appliancepb
         * @classdesc Represents a KeyValues.
         * @implements IKeyValues
         * @constructor
         * @param {appliancepb.IKeyValues=} [properties] Properties to set
         */
        function KeyValues(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeyValues key.
         * @member {string} key
         * @memberof appliancepb.KeyValues
         * @instance
         */
        KeyValues.prototype.key = "";

        /**
         * KeyValues values.
         * @member {Array.<string>} values
         * @memberof appliancepb.KeyValues
         * @instance
         */
        KeyValues.prototype.values = $util.emptyArray;

        /**
         * Creates a new KeyValues instance using the specified properties.
         * @function create
         * @memberof appliancepb.KeyValues
         * @static
         * @param {appliancepb.IKeyValues=} [properties] Properties to set
         * @returns {appliancepb.KeyValues} KeyValues instance
         */
        KeyValues.create = function create(properties) {
            return new KeyValues(properties);
        };

        /**
         * Encodes the specified KeyValues message. Does not implicitly {@link appliancepb.KeyValues.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.KeyValues
         * @static
         * @param {appliancepb.IKeyValues} message KeyValues message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyValues.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.values != null && message.values.length)
                for (let i = 0; i < message.values.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
            return writer;
        };

        /**
         * Encodes the specified KeyValues message, length delimited. Does not implicitly {@link appliancepb.KeyValues.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.KeyValues
         * @static
         * @param {appliancepb.IKeyValues} message KeyValues message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyValues.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeyValues message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.KeyValues
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.KeyValues} KeyValues
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyValues.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.KeyValues();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KeyValues message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.KeyValues
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.KeyValues} KeyValues
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyValues.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeyValues message.
         * @function verify
         * @memberof appliancepb.KeyValues
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeyValues.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i)
                    if (!$util.isString(message.values[i]))
                        return "values: string[] expected";
            }
            return null;
        };

        /**
         * Creates a KeyValues message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.KeyValues
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.KeyValues} KeyValues
         */
        KeyValues.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.KeyValues)
                return object;
            let message = new $root.appliancepb.KeyValues();
            if (object.key != null)
                message.key = String(object.key);
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".appliancepb.KeyValues.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i)
                    message.values[i] = String(object.values[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a KeyValues message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.KeyValues
         * @static
         * @param {appliancepb.KeyValues} message KeyValues
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeyValues.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (options.defaults)
                object.key = "";
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = message.values[j];
            }
            return object;
        };

        /**
         * Converts this KeyValues to JSON.
         * @function toJSON
         * @memberof appliancepb.KeyValues
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeyValues.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KeyValues;
    })();

    appliancepb.ReplacementOfferRequest = (function() {

        /**
         * Properties of a ReplacementOfferRequest.
         * @memberof appliancepb
         * @interface IReplacementOfferRequest
         * @property {string|null} [userID] ReplacementOfferRequest userID
         * @property {string|null} [paymentID] ReplacementOfferRequest paymentID
         * @property {string|null} [addressID] ReplacementOfferRequest addressID
         * @property {string|null} [serviceRequestID] ReplacementOfferRequest serviceRequestID
         * @property {number|null} [discount] ReplacementOfferRequest discount
         * @property {appliancepb.IPair|null} [item] ReplacementOfferRequest item
         * @property {appliancepb.IPair|null} [brand] ReplacementOfferRequest brand
         * @property {appliancepb.IPair|null} [style] ReplacementOfferRequest style
         */

        /**
         * Constructs a new ReplacementOfferRequest.
         * @memberof appliancepb
         * @classdesc Represents a ReplacementOfferRequest.
         * @implements IReplacementOfferRequest
         * @constructor
         * @param {appliancepb.IReplacementOfferRequest=} [properties] Properties to set
         */
        function ReplacementOfferRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReplacementOfferRequest userID.
         * @member {string} userID
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.userID = "";

        /**
         * ReplacementOfferRequest paymentID.
         * @member {string} paymentID
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.paymentID = "";

        /**
         * ReplacementOfferRequest addressID.
         * @member {string} addressID
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.addressID = "";

        /**
         * ReplacementOfferRequest serviceRequestID.
         * @member {string} serviceRequestID
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.serviceRequestID = "";

        /**
         * ReplacementOfferRequest discount.
         * @member {number} discount
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.discount = 0;

        /**
         * ReplacementOfferRequest item.
         * @member {appliancepb.IPair|null|undefined} item
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.item = null;

        /**
         * ReplacementOfferRequest brand.
         * @member {appliancepb.IPair|null|undefined} brand
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.brand = null;

        /**
         * ReplacementOfferRequest style.
         * @member {appliancepb.IPair|null|undefined} style
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         */
        ReplacementOfferRequest.prototype.style = null;

        /**
         * Creates a new ReplacementOfferRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {appliancepb.IReplacementOfferRequest=} [properties] Properties to set
         * @returns {appliancepb.ReplacementOfferRequest} ReplacementOfferRequest instance
         */
        ReplacementOfferRequest.create = function create(properties) {
            return new ReplacementOfferRequest(properties);
        };

        /**
         * Encodes the specified ReplacementOfferRequest message. Does not implicitly {@link appliancepb.ReplacementOfferRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {appliancepb.IReplacementOfferRequest} message ReplacementOfferRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementOfferRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userID != null && Object.hasOwnProperty.call(message, "userID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userID);
            if (message.paymentID != null && Object.hasOwnProperty.call(message, "paymentID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentID);
            if (message.addressID != null && Object.hasOwnProperty.call(message, "addressID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.addressID);
            if (message.serviceRequestID != null && Object.hasOwnProperty.call(message, "serviceRequestID"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceRequestID);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.discount);
            if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                $root.appliancepb.Pair.encode(message.item, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.brand != null && Object.hasOwnProperty.call(message, "brand"))
                $root.appliancepb.Pair.encode(message.brand, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.style != null && Object.hasOwnProperty.call(message, "style"))
                $root.appliancepb.Pair.encode(message.style, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReplacementOfferRequest message, length delimited. Does not implicitly {@link appliancepb.ReplacementOfferRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {appliancepb.IReplacementOfferRequest} message ReplacementOfferRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementOfferRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReplacementOfferRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ReplacementOfferRequest} ReplacementOfferRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementOfferRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementOfferRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userID = reader.string();
                    break;
                case 2:
                    message.paymentID = reader.string();
                    break;
                case 3:
                    message.addressID = reader.string();
                    break;
                case 4:
                    message.serviceRequestID = reader.string();
                    break;
                case 5:
                    message.discount = reader.int32();
                    break;
                case 6:
                    message.item = $root.appliancepb.Pair.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.brand = $root.appliancepb.Pair.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.style = $root.appliancepb.Pair.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReplacementOfferRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ReplacementOfferRequest} ReplacementOfferRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementOfferRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReplacementOfferRequest message.
         * @function verify
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReplacementOfferRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userID != null && message.hasOwnProperty("userID"))
                if (!$util.isString(message.userID))
                    return "userID: string expected";
            if (message.paymentID != null && message.hasOwnProperty("paymentID"))
                if (!$util.isString(message.paymentID))
                    return "paymentID: string expected";
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                if (!$util.isString(message.addressID))
                    return "addressID: string expected";
            if (message.serviceRequestID != null && message.hasOwnProperty("serviceRequestID"))
                if (!$util.isString(message.serviceRequestID))
                    return "serviceRequestID: string expected";
            if (message.discount != null && message.hasOwnProperty("discount"))
                if (!$util.isInteger(message.discount))
                    return "discount: integer expected";
            if (message.item != null && message.hasOwnProperty("item")) {
                let error = $root.appliancepb.Pair.verify(message.item);
                if (error)
                    return "item." + error;
            }
            if (message.brand != null && message.hasOwnProperty("brand")) {
                let error = $root.appliancepb.Pair.verify(message.brand);
                if (error)
                    return "brand." + error;
            }
            if (message.style != null && message.hasOwnProperty("style")) {
                let error = $root.appliancepb.Pair.verify(message.style);
                if (error)
                    return "style." + error;
            }
            return null;
        };

        /**
         * Creates a ReplacementOfferRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ReplacementOfferRequest} ReplacementOfferRequest
         */
        ReplacementOfferRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ReplacementOfferRequest)
                return object;
            let message = new $root.appliancepb.ReplacementOfferRequest();
            if (object.userID != null)
                message.userID = String(object.userID);
            if (object.paymentID != null)
                message.paymentID = String(object.paymentID);
            if (object.addressID != null)
                message.addressID = String(object.addressID);
            if (object.serviceRequestID != null)
                message.serviceRequestID = String(object.serviceRequestID);
            if (object.discount != null)
                message.discount = object.discount | 0;
            if (object.item != null) {
                if (typeof object.item !== "object")
                    throw TypeError(".appliancepb.ReplacementOfferRequest.item: object expected");
                message.item = $root.appliancepb.Pair.fromObject(object.item);
            }
            if (object.brand != null) {
                if (typeof object.brand !== "object")
                    throw TypeError(".appliancepb.ReplacementOfferRequest.brand: object expected");
                message.brand = $root.appliancepb.Pair.fromObject(object.brand);
            }
            if (object.style != null) {
                if (typeof object.style !== "object")
                    throw TypeError(".appliancepb.ReplacementOfferRequest.style: object expected");
                message.style = $root.appliancepb.Pair.fromObject(object.style);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReplacementOfferRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ReplacementOfferRequest
         * @static
         * @param {appliancepb.ReplacementOfferRequest} message ReplacementOfferRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReplacementOfferRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userID = "";
                object.paymentID = "";
                object.addressID = "";
                object.serviceRequestID = "";
                object.discount = 0;
                object.item = null;
                object.brand = null;
                object.style = null;
            }
            if (message.userID != null && message.hasOwnProperty("userID"))
                object.userID = message.userID;
            if (message.paymentID != null && message.hasOwnProperty("paymentID"))
                object.paymentID = message.paymentID;
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                object.addressID = message.addressID;
            if (message.serviceRequestID != null && message.hasOwnProperty("serviceRequestID"))
                object.serviceRequestID = message.serviceRequestID;
            if (message.discount != null && message.hasOwnProperty("discount"))
                object.discount = message.discount;
            if (message.item != null && message.hasOwnProperty("item"))
                object.item = $root.appliancepb.Pair.toObject(message.item, options);
            if (message.brand != null && message.hasOwnProperty("brand"))
                object.brand = $root.appliancepb.Pair.toObject(message.brand, options);
            if (message.style != null && message.hasOwnProperty("style"))
                object.style = $root.appliancepb.Pair.toObject(message.style, options);
            return object;
        };

        /**
         * Converts this ReplacementOfferRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.ReplacementOfferRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReplacementOfferRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReplacementOfferRequest;
    })();

    appliancepb.AHSReplacementOfferRequest = (function() {

        /**
         * Properties of a AHSReplacementOfferRequest.
         * @memberof appliancepb
         * @interface IAHSReplacementOfferRequest
         * @property {string|null} [dispatchID] AHSReplacementOfferRequest dispatchID
         * @property {string|null} [likeForLikeProductID] AHSReplacementOfferRequest likeForLikeProductID
         * @property {string|null} [itemID] AHSReplacementOfferRequest itemID
         * @property {string|null} [addressID] AHSReplacementOfferRequest addressID
         * @property {string|null} [customerID] AHSReplacementOfferRequest customerID
         * @property {string|null} [contractID] AHSReplacementOfferRequest contractID
         * @property {string|null} [partRequestID] AHSReplacementOfferRequest partRequestID
         * @property {boolean|null} [hasDisposalCoverage] AHSReplacementOfferRequest hasDisposalCoverage
         * @property {appliancepb.AHSReplacementOfferRequest.ContractType|null} [contractType] AHSReplacementOfferRequest contractType
         * @property {appliancepb.INotification|null} [notification] AHSReplacementOfferRequest notification
         * @property {number|null} [serviceLimit] AHSReplacementOfferRequest serviceLimit
         * @property {Array.<appliancepb.IProductAccessory>|null} [productAccessories] AHSReplacementOfferRequest productAccessories
         */

        /**
         * Constructs a new AHSReplacementOfferRequest.
         * @memberof appliancepb
         * @classdesc Represents a AHSReplacementOfferRequest.
         * @implements IAHSReplacementOfferRequest
         * @constructor
         * @param {appliancepb.IAHSReplacementOfferRequest=} [properties] Properties to set
         */
        function AHSReplacementOfferRequest(properties) {
            this.productAccessories = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AHSReplacementOfferRequest dispatchID.
         * @member {string} dispatchID
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.dispatchID = "";

        /**
         * AHSReplacementOfferRequest likeForLikeProductID.
         * @member {string} likeForLikeProductID
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.likeForLikeProductID = "";

        /**
         * AHSReplacementOfferRequest itemID.
         * @member {string} itemID
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.itemID = "";

        /**
         * AHSReplacementOfferRequest addressID.
         * @member {string} addressID
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.addressID = "";

        /**
         * AHSReplacementOfferRequest customerID.
         * @member {string} customerID
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.customerID = "";

        /**
         * AHSReplacementOfferRequest contractID.
         * @member {string} contractID
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.contractID = "";

        /**
         * AHSReplacementOfferRequest partRequestID.
         * @member {string} partRequestID
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.partRequestID = "";

        /**
         * AHSReplacementOfferRequest hasDisposalCoverage.
         * @member {boolean} hasDisposalCoverage
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.hasDisposalCoverage = false;

        /**
         * AHSReplacementOfferRequest contractType.
         * @member {appliancepb.AHSReplacementOfferRequest.ContractType} contractType
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.contractType = 0;

        /**
         * AHSReplacementOfferRequest notification.
         * @member {appliancepb.INotification|null|undefined} notification
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.notification = null;

        /**
         * AHSReplacementOfferRequest serviceLimit.
         * @member {number} serviceLimit
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.serviceLimit = 0;

        /**
         * AHSReplacementOfferRequest productAccessories.
         * @member {Array.<appliancepb.IProductAccessory>} productAccessories
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         */
        AHSReplacementOfferRequest.prototype.productAccessories = $util.emptyArray;

        /**
         * Creates a new AHSReplacementOfferRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {appliancepb.IAHSReplacementOfferRequest=} [properties] Properties to set
         * @returns {appliancepb.AHSReplacementOfferRequest} AHSReplacementOfferRequest instance
         */
        AHSReplacementOfferRequest.create = function create(properties) {
            return new AHSReplacementOfferRequest(properties);
        };

        /**
         * Encodes the specified AHSReplacementOfferRequest message. Does not implicitly {@link appliancepb.AHSReplacementOfferRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {appliancepb.IAHSReplacementOfferRequest} message AHSReplacementOfferRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AHSReplacementOfferRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dispatchID != null && Object.hasOwnProperty.call(message, "dispatchID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dispatchID);
            if (message.likeForLikeProductID != null && Object.hasOwnProperty.call(message, "likeForLikeProductID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.likeForLikeProductID);
            if (message.itemID != null && Object.hasOwnProperty.call(message, "itemID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.itemID);
            if (message.addressID != null && Object.hasOwnProperty.call(message, "addressID"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.addressID);
            if (message.customerID != null && Object.hasOwnProperty.call(message, "customerID"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.customerID);
            if (message.contractID != null && Object.hasOwnProperty.call(message, "contractID"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.contractID);
            if (message.partRequestID != null && Object.hasOwnProperty.call(message, "partRequestID"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.partRequestID);
            if (message.hasDisposalCoverage != null && Object.hasOwnProperty.call(message, "hasDisposalCoverage"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.hasDisposalCoverage);
            if (message.contractType != null && Object.hasOwnProperty.call(message, "contractType"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.contractType);
            if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                $root.appliancepb.Notification.encode(message.notification, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.serviceLimit != null && Object.hasOwnProperty.call(message, "serviceLimit"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.serviceLimit);
            if (message.productAccessories != null && message.productAccessories.length)
                for (let i = 0; i < message.productAccessories.length; ++i)
                    $root.appliancepb.ProductAccessory.encode(message.productAccessories[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AHSReplacementOfferRequest message, length delimited. Does not implicitly {@link appliancepb.AHSReplacementOfferRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {appliancepb.IAHSReplacementOfferRequest} message AHSReplacementOfferRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AHSReplacementOfferRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a AHSReplacementOfferRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.AHSReplacementOfferRequest} AHSReplacementOfferRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AHSReplacementOfferRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.AHSReplacementOfferRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.dispatchID = reader.string();
                    break;
                case 2:
                    message.likeForLikeProductID = reader.string();
                    break;
                case 3:
                    message.itemID = reader.string();
                    break;
                case 4:
                    message.addressID = reader.string();
                    break;
                case 5:
                    message.customerID = reader.string();
                    break;
                case 6:
                    message.contractID = reader.string();
                    break;
                case 7:
                    message.partRequestID = reader.string();
                    break;
                case 8:
                    message.hasDisposalCoverage = reader.bool();
                    break;
                case 9:
                    message.contractType = reader.int32();
                    break;
                case 10:
                    message.notification = $root.appliancepb.Notification.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.serviceLimit = reader.int32();
                    break;
                case 12:
                    if (!(message.productAccessories && message.productAccessories.length))
                        message.productAccessories = [];
                    message.productAccessories.push($root.appliancepb.ProductAccessory.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a AHSReplacementOfferRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.AHSReplacementOfferRequest} AHSReplacementOfferRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AHSReplacementOfferRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a AHSReplacementOfferRequest message.
         * @function verify
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AHSReplacementOfferRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                if (!$util.isString(message.dispatchID))
                    return "dispatchID: string expected";
            if (message.likeForLikeProductID != null && message.hasOwnProperty("likeForLikeProductID"))
                if (!$util.isString(message.likeForLikeProductID))
                    return "likeForLikeProductID: string expected";
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                if (!$util.isString(message.itemID))
                    return "itemID: string expected";
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                if (!$util.isString(message.addressID))
                    return "addressID: string expected";
            if (message.customerID != null && message.hasOwnProperty("customerID"))
                if (!$util.isString(message.customerID))
                    return "customerID: string expected";
            if (message.contractID != null && message.hasOwnProperty("contractID"))
                if (!$util.isString(message.contractID))
                    return "contractID: string expected";
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                if (!$util.isString(message.partRequestID))
                    return "partRequestID: string expected";
            if (message.hasDisposalCoverage != null && message.hasOwnProperty("hasDisposalCoverage"))
                if (typeof message.hasDisposalCoverage !== "boolean")
                    return "hasDisposalCoverage: boolean expected";
            if (message.contractType != null && message.hasOwnProperty("contractType"))
                switch (message.contractType) {
                default:
                    return "contractType: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.notification != null && message.hasOwnProperty("notification")) {
                let error = $root.appliancepb.Notification.verify(message.notification);
                if (error)
                    return "notification." + error;
            }
            if (message.serviceLimit != null && message.hasOwnProperty("serviceLimit"))
                if (!$util.isInteger(message.serviceLimit))
                    return "serviceLimit: integer expected";
            if (message.productAccessories != null && message.hasOwnProperty("productAccessories")) {
                if (!Array.isArray(message.productAccessories))
                    return "productAccessories: array expected";
                for (let i = 0; i < message.productAccessories.length; ++i) {
                    let error = $root.appliancepb.ProductAccessory.verify(message.productAccessories[i]);
                    if (error)
                        return "productAccessories." + error;
                }
            }
            return null;
        };

        /**
         * Creates a AHSReplacementOfferRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.AHSReplacementOfferRequest} AHSReplacementOfferRequest
         */
        AHSReplacementOfferRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.AHSReplacementOfferRequest)
                return object;
            let message = new $root.appliancepb.AHSReplacementOfferRequest();
            if (object.dispatchID != null)
                message.dispatchID = String(object.dispatchID);
            if (object.likeForLikeProductID != null)
                message.likeForLikeProductID = String(object.likeForLikeProductID);
            if (object.itemID != null)
                message.itemID = String(object.itemID);
            if (object.addressID != null)
                message.addressID = String(object.addressID);
            if (object.customerID != null)
                message.customerID = String(object.customerID);
            if (object.contractID != null)
                message.contractID = String(object.contractID);
            if (object.partRequestID != null)
                message.partRequestID = String(object.partRequestID);
            if (object.hasDisposalCoverage != null)
                message.hasDisposalCoverage = Boolean(object.hasDisposalCoverage);
            switch (object.contractType) {
            case "CONTRACT_TYPE_UNSPECIFIED":
            case 0:
                message.contractType = 0;
                break;
            case "CONTRACT_TYPE_PLATINUM":
            case 1:
                message.contractType = 1;
                break;
            }
            if (object.notification != null) {
                if (typeof object.notification !== "object")
                    throw TypeError(".appliancepb.AHSReplacementOfferRequest.notification: object expected");
                message.notification = $root.appliancepb.Notification.fromObject(object.notification);
            }
            if (object.serviceLimit != null)
                message.serviceLimit = object.serviceLimit | 0;
            if (object.productAccessories) {
                if (!Array.isArray(object.productAccessories))
                    throw TypeError(".appliancepb.AHSReplacementOfferRequest.productAccessories: array expected");
                message.productAccessories = [];
                for (let i = 0; i < object.productAccessories.length; ++i) {
                    if (typeof object.productAccessories[i] !== "object")
                        throw TypeError(".appliancepb.AHSReplacementOfferRequest.productAccessories: object expected");
                    message.productAccessories[i] = $root.appliancepb.ProductAccessory.fromObject(object.productAccessories[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a AHSReplacementOfferRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @static
         * @param {appliancepb.AHSReplacementOfferRequest} message AHSReplacementOfferRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AHSReplacementOfferRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.productAccessories = [];
            if (options.defaults) {
                object.dispatchID = "";
                object.likeForLikeProductID = "";
                object.itemID = "";
                object.addressID = "";
                object.customerID = "";
                object.contractID = "";
                object.partRequestID = "";
                object.hasDisposalCoverage = false;
                object.contractType = options.enums === String ? "CONTRACT_TYPE_UNSPECIFIED" : 0;
                object.notification = null;
                object.serviceLimit = 0;
            }
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                object.dispatchID = message.dispatchID;
            if (message.likeForLikeProductID != null && message.hasOwnProperty("likeForLikeProductID"))
                object.likeForLikeProductID = message.likeForLikeProductID;
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                object.itemID = message.itemID;
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                object.addressID = message.addressID;
            if (message.customerID != null && message.hasOwnProperty("customerID"))
                object.customerID = message.customerID;
            if (message.contractID != null && message.hasOwnProperty("contractID"))
                object.contractID = message.contractID;
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                object.partRequestID = message.partRequestID;
            if (message.hasDisposalCoverage != null && message.hasOwnProperty("hasDisposalCoverage"))
                object.hasDisposalCoverage = message.hasDisposalCoverage;
            if (message.contractType != null && message.hasOwnProperty("contractType"))
                object.contractType = options.enums === String ? $root.appliancepb.AHSReplacementOfferRequest.ContractType[message.contractType] : message.contractType;
            if (message.notification != null && message.hasOwnProperty("notification"))
                object.notification = $root.appliancepb.Notification.toObject(message.notification, options);
            if (message.serviceLimit != null && message.hasOwnProperty("serviceLimit"))
                object.serviceLimit = message.serviceLimit;
            if (message.productAccessories && message.productAccessories.length) {
                object.productAccessories = [];
                for (let j = 0; j < message.productAccessories.length; ++j)
                    object.productAccessories[j] = $root.appliancepb.ProductAccessory.toObject(message.productAccessories[j], options);
            }
            return object;
        };

        /**
         * Converts this AHSReplacementOfferRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.AHSReplacementOfferRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AHSReplacementOfferRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * ContractType enum.
         * @name appliancepb.AHSReplacementOfferRequest.ContractType
         * @enum {number}
         * @property {number} CONTRACT_TYPE_UNSPECIFIED=0 CONTRACT_TYPE_UNSPECIFIED value
         * @property {number} CONTRACT_TYPE_PLATINUM=1 CONTRACT_TYPE_PLATINUM value
         */
        AHSReplacementOfferRequest.ContractType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CONTRACT_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "CONTRACT_TYPE_PLATINUM"] = 1;
            return values;
        })();

        return AHSReplacementOfferRequest;
    })();

    appliancepb.ReplacementOfferResponse = (function() {

        /**
         * Properties of a ReplacementOfferResponse.
         * @memberof appliancepb
         * @interface IReplacementOfferResponse
         * @property {string|null} [uuid] ReplacementOfferResponse uuid
         */

        /**
         * Constructs a new ReplacementOfferResponse.
         * @memberof appliancepb
         * @classdesc Represents a ReplacementOfferResponse.
         * @implements IReplacementOfferResponse
         * @constructor
         * @param {appliancepb.IReplacementOfferResponse=} [properties] Properties to set
         */
        function ReplacementOfferResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReplacementOfferResponse uuid.
         * @member {string} uuid
         * @memberof appliancepb.ReplacementOfferResponse
         * @instance
         */
        ReplacementOfferResponse.prototype.uuid = "";

        /**
         * Creates a new ReplacementOfferResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {appliancepb.IReplacementOfferResponse=} [properties] Properties to set
         * @returns {appliancepb.ReplacementOfferResponse} ReplacementOfferResponse instance
         */
        ReplacementOfferResponse.create = function create(properties) {
            return new ReplacementOfferResponse(properties);
        };

        /**
         * Encodes the specified ReplacementOfferResponse message. Does not implicitly {@link appliancepb.ReplacementOfferResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {appliancepb.IReplacementOfferResponse} message ReplacementOfferResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementOfferResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            return writer;
        };

        /**
         * Encodes the specified ReplacementOfferResponse message, length delimited. Does not implicitly {@link appliancepb.ReplacementOfferResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {appliancepb.IReplacementOfferResponse} message ReplacementOfferResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementOfferResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReplacementOfferResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ReplacementOfferResponse} ReplacementOfferResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementOfferResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementOfferResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uuid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReplacementOfferResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ReplacementOfferResponse} ReplacementOfferResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementOfferResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReplacementOfferResponse message.
         * @function verify
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReplacementOfferResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            return null;
        };

        /**
         * Creates a ReplacementOfferResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ReplacementOfferResponse} ReplacementOfferResponse
         */
        ReplacementOfferResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ReplacementOfferResponse)
                return object;
            let message = new $root.appliancepb.ReplacementOfferResponse();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            return message;
        };

        /**
         * Creates a plain object from a ReplacementOfferResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ReplacementOfferResponse
         * @static
         * @param {appliancepb.ReplacementOfferResponse} message ReplacementOfferResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReplacementOfferResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.uuid = "";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            return object;
        };

        /**
         * Converts this ReplacementOfferResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.ReplacementOfferResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReplacementOfferResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReplacementOfferResponse;
    })();

    appliancepb.ReplacementResponse = (function() {

        /**
         * Properties of a ReplacementResponse.
         * @memberof appliancepb
         * @interface IReplacementResponse
         * @property {appliancepb.ReplacementResponse.ICustomer|null} [customer] ReplacementResponse customer
         * @property {appliancepb.ReplacementResponse.IPaymentDetails|null} [paymentDetails] ReplacementResponse paymentDetails
         * @property {appliancepb.ReplacementResponse.IReplacementDetails|null} [replacementDetails] ReplacementResponse replacementDetails
         */

        /**
         * Constructs a new ReplacementResponse.
         * @memberof appliancepb
         * @classdesc Represents a ReplacementResponse.
         * @implements IReplacementResponse
         * @constructor
         * @param {appliancepb.IReplacementResponse=} [properties] Properties to set
         */
        function ReplacementResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReplacementResponse customer.
         * @member {appliancepb.ReplacementResponse.ICustomer|null|undefined} customer
         * @memberof appliancepb.ReplacementResponse
         * @instance
         */
        ReplacementResponse.prototype.customer = null;

        /**
         * ReplacementResponse paymentDetails.
         * @member {appliancepb.ReplacementResponse.IPaymentDetails|null|undefined} paymentDetails
         * @memberof appliancepb.ReplacementResponse
         * @instance
         */
        ReplacementResponse.prototype.paymentDetails = null;

        /**
         * ReplacementResponse replacementDetails.
         * @member {appliancepb.ReplacementResponse.IReplacementDetails|null|undefined} replacementDetails
         * @memberof appliancepb.ReplacementResponse
         * @instance
         */
        ReplacementResponse.prototype.replacementDetails = null;

        /**
         * Creates a new ReplacementResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {appliancepb.IReplacementResponse=} [properties] Properties to set
         * @returns {appliancepb.ReplacementResponse} ReplacementResponse instance
         */
        ReplacementResponse.create = function create(properties) {
            return new ReplacementResponse(properties);
        };

        /**
         * Encodes the specified ReplacementResponse message. Does not implicitly {@link appliancepb.ReplacementResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {appliancepb.IReplacementResponse} message ReplacementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.appliancepb.ReplacementResponse.Customer.encode(message.customer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.paymentDetails != null && Object.hasOwnProperty.call(message, "paymentDetails"))
                $root.appliancepb.ReplacementResponse.PaymentDetails.encode(message.paymentDetails, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.replacementDetails != null && Object.hasOwnProperty.call(message, "replacementDetails"))
                $root.appliancepb.ReplacementResponse.ReplacementDetails.encode(message.replacementDetails, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReplacementResponse message, length delimited. Does not implicitly {@link appliancepb.ReplacementResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {appliancepb.IReplacementResponse} message ReplacementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReplacementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ReplacementResponse} ReplacementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.customer = $root.appliancepb.ReplacementResponse.Customer.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.paymentDetails = $root.appliancepb.ReplacementResponse.PaymentDetails.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.replacementDetails = $root.appliancepb.ReplacementResponse.ReplacementDetails.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReplacementResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ReplacementResponse} ReplacementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReplacementResponse message.
         * @function verify
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReplacementResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.customer != null && message.hasOwnProperty("customer")) {
                let error = $root.appliancepb.ReplacementResponse.Customer.verify(message.customer);
                if (error)
                    return "customer." + error;
            }
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails")) {
                let error = $root.appliancepb.ReplacementResponse.PaymentDetails.verify(message.paymentDetails);
                if (error)
                    return "paymentDetails." + error;
            }
            if (message.replacementDetails != null && message.hasOwnProperty("replacementDetails")) {
                let error = $root.appliancepb.ReplacementResponse.ReplacementDetails.verify(message.replacementDetails);
                if (error)
                    return "replacementDetails." + error;
            }
            return null;
        };

        /**
         * Creates a ReplacementResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ReplacementResponse} ReplacementResponse
         */
        ReplacementResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ReplacementResponse)
                return object;
            let message = new $root.appliancepb.ReplacementResponse();
            if (object.customer != null) {
                if (typeof object.customer !== "object")
                    throw TypeError(".appliancepb.ReplacementResponse.customer: object expected");
                message.customer = $root.appliancepb.ReplacementResponse.Customer.fromObject(object.customer);
            }
            if (object.paymentDetails != null) {
                if (typeof object.paymentDetails !== "object")
                    throw TypeError(".appliancepb.ReplacementResponse.paymentDetails: object expected");
                message.paymentDetails = $root.appliancepb.ReplacementResponse.PaymentDetails.fromObject(object.paymentDetails);
            }
            if (object.replacementDetails != null) {
                if (typeof object.replacementDetails !== "object")
                    throw TypeError(".appliancepb.ReplacementResponse.replacementDetails: object expected");
                message.replacementDetails = $root.appliancepb.ReplacementResponse.ReplacementDetails.fromObject(object.replacementDetails);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReplacementResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ReplacementResponse
         * @static
         * @param {appliancepb.ReplacementResponse} message ReplacementResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReplacementResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.customer = null;
                object.paymentDetails = null;
                object.replacementDetails = null;
            }
            if (message.customer != null && message.hasOwnProperty("customer"))
                object.customer = $root.appliancepb.ReplacementResponse.Customer.toObject(message.customer, options);
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails"))
                object.paymentDetails = $root.appliancepb.ReplacementResponse.PaymentDetails.toObject(message.paymentDetails, options);
            if (message.replacementDetails != null && message.hasOwnProperty("replacementDetails"))
                object.replacementDetails = $root.appliancepb.ReplacementResponse.ReplacementDetails.toObject(message.replacementDetails, options);
            return object;
        };

        /**
         * Converts this ReplacementResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.ReplacementResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReplacementResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ReplacementResponse.Customer = (function() {

            /**
             * Properties of a Customer.
             * @memberof appliancepb.ReplacementResponse
             * @interface ICustomer
             * @property {string|null} [name] Customer name
             * @property {string|null} [email] Customer email
             * @property {string|null} [phone] Customer phone
             * @property {appliancepb.ReplacementResponse.Customer.IAddress|null} [address] Customer address
             */

            /**
             * Constructs a new Customer.
             * @memberof appliancepb.ReplacementResponse
             * @classdesc Represents a Customer.
             * @implements ICustomer
             * @constructor
             * @param {appliancepb.ReplacementResponse.ICustomer=} [properties] Properties to set
             */
            function Customer(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Customer name.
             * @member {string} name
             * @memberof appliancepb.ReplacementResponse.Customer
             * @instance
             */
            Customer.prototype.name = "";

            /**
             * Customer email.
             * @member {string} email
             * @memberof appliancepb.ReplacementResponse.Customer
             * @instance
             */
            Customer.prototype.email = "";

            /**
             * Customer phone.
             * @member {string} phone
             * @memberof appliancepb.ReplacementResponse.Customer
             * @instance
             */
            Customer.prototype.phone = "";

            /**
             * Customer address.
             * @member {appliancepb.ReplacementResponse.Customer.IAddress|null|undefined} address
             * @memberof appliancepb.ReplacementResponse.Customer
             * @instance
             */
            Customer.prototype.address = null;

            /**
             * Creates a new Customer instance using the specified properties.
             * @function create
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {appliancepb.ReplacementResponse.ICustomer=} [properties] Properties to set
             * @returns {appliancepb.ReplacementResponse.Customer} Customer instance
             */
            Customer.create = function create(properties) {
                return new Customer(properties);
            };

            /**
             * Encodes the specified Customer message. Does not implicitly {@link appliancepb.ReplacementResponse.Customer.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {appliancepb.ReplacementResponse.ICustomer} message Customer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Customer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.appliancepb.ReplacementResponse.Customer.Address.encode(message.address, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Customer message, length delimited. Does not implicitly {@link appliancepb.ReplacementResponse.Customer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {appliancepb.ReplacementResponse.ICustomer} message Customer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Customer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Customer message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.ReplacementResponse.Customer} Customer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Customer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementResponse.Customer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.email = reader.string();
                        break;
                    case 3:
                        message.phone = reader.string();
                        break;
                    case 4:
                        message.address = $root.appliancepb.ReplacementResponse.Customer.Address.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Customer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.ReplacementResponse.Customer} Customer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Customer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Customer message.
             * @function verify
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Customer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    let error = $root.appliancepb.ReplacementResponse.Customer.Address.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                return null;
            };

            /**
             * Creates a Customer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.ReplacementResponse.Customer} Customer
             */
            Customer.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.ReplacementResponse.Customer)
                    return object;
                let message = new $root.appliancepb.ReplacementResponse.Customer();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".appliancepb.ReplacementResponse.Customer.address: object expected");
                    message.address = $root.appliancepb.ReplacementResponse.Customer.Address.fromObject(object.address);
                }
                return message;
            };

            /**
             * Creates a plain object from a Customer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.ReplacementResponse.Customer
             * @static
             * @param {appliancepb.ReplacementResponse.Customer} message Customer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Customer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.email = "";
                    object.phone = "";
                    object.address = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.appliancepb.ReplacementResponse.Customer.Address.toObject(message.address, options);
                return object;
            };

            /**
             * Converts this Customer to JSON.
             * @function toJSON
             * @memberof appliancepb.ReplacementResponse.Customer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Customer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Customer.Address = (function() {

                /**
                 * Properties of an Address.
                 * @memberof appliancepb.ReplacementResponse.Customer
                 * @interface IAddress
                 * @property {string|null} [street1] Address street1
                 * @property {string|null} [street2] Address street2
                 * @property {string|null} [unitValue] Address unitValue
                 * @property {string|null} [city] Address city
                 * @property {string|null} [state] Address state
                 * @property {string|null} [zip] Address zip
                 * @property {string|null} [zipLocal] Address zipLocal
                 * @property {string|null} [countryISO3] Address countryISO3
                 * @property {string|null} [ID] Address ID
                 * @property {string|null} [unitType] Address unitType
                 */

                /**
                 * Constructs a new Address.
                 * @memberof appliancepb.ReplacementResponse.Customer
                 * @classdesc Represents an Address.
                 * @implements IAddress
                 * @constructor
                 * @param {appliancepb.ReplacementResponse.Customer.IAddress=} [properties] Properties to set
                 */
                function Address(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Address street1.
                 * @member {string} street1
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.street1 = "";

                /**
                 * Address street2.
                 * @member {string} street2
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.street2 = "";

                /**
                 * Address unitValue.
                 * @member {string} unitValue
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.unitValue = "";

                /**
                 * Address city.
                 * @member {string} city
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.city = "";

                /**
                 * Address state.
                 * @member {string} state
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.state = "";

                /**
                 * Address zip.
                 * @member {string} zip
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.zip = "";

                /**
                 * Address zipLocal.
                 * @member {string} zipLocal
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.zipLocal = "";

                /**
                 * Address countryISO3.
                 * @member {string} countryISO3
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.countryISO3 = "";

                /**
                 * Address ID.
                 * @member {string} ID
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.ID = "";

                /**
                 * Address unitType.
                 * @member {string} unitType
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 */
                Address.prototype.unitType = "";

                /**
                 * Creates a new Address instance using the specified properties.
                 * @function create
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {appliancepb.ReplacementResponse.Customer.IAddress=} [properties] Properties to set
                 * @returns {appliancepb.ReplacementResponse.Customer.Address} Address instance
                 */
                Address.create = function create(properties) {
                    return new Address(properties);
                };

                /**
                 * Encodes the specified Address message. Does not implicitly {@link appliancepb.ReplacementResponse.Customer.Address.verify|verify} messages.
                 * @function encode
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {appliancepb.ReplacementResponse.Customer.IAddress} message Address message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Address.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.street1 != null && Object.hasOwnProperty.call(message, "street1"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.street1);
                    if (message.street2 != null && Object.hasOwnProperty.call(message, "street2"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.street2);
                    if (message.unitValue != null && Object.hasOwnProperty.call(message, "unitValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.unitValue);
                    if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.city);
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.state);
                    if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.zip);
                    if (message.zipLocal != null && Object.hasOwnProperty.call(message, "zipLocal"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.zipLocal);
                    if (message.countryISO3 != null && Object.hasOwnProperty.call(message, "countryISO3"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.countryISO3);
                    if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.ID);
                    if (message.unitType != null && Object.hasOwnProperty.call(message, "unitType"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.unitType);
                    return writer;
                };

                /**
                 * Encodes the specified Address message, length delimited. Does not implicitly {@link appliancepb.ReplacementResponse.Customer.Address.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {appliancepb.ReplacementResponse.Customer.IAddress} message Address message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Address.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Address message from the specified reader or buffer.
                 * @function decode
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {appliancepb.ReplacementResponse.Customer.Address} Address
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Address.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementResponse.Customer.Address();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.street1 = reader.string();
                            break;
                        case 2:
                            message.street2 = reader.string();
                            break;
                        case 3:
                            message.unitValue = reader.string();
                            break;
                        case 4:
                            message.city = reader.string();
                            break;
                        case 5:
                            message.state = reader.string();
                            break;
                        case 6:
                            message.zip = reader.string();
                            break;
                        case 7:
                            message.zipLocal = reader.string();
                            break;
                        case 8:
                            message.countryISO3 = reader.string();
                            break;
                        case 9:
                            message.ID = reader.string();
                            break;
                        case 10:
                            message.unitType = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Address message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {appliancepb.ReplacementResponse.Customer.Address} Address
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Address.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Address message.
                 * @function verify
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Address.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.street1 != null && message.hasOwnProperty("street1"))
                        if (!$util.isString(message.street1))
                            return "street1: string expected";
                    if (message.street2 != null && message.hasOwnProperty("street2"))
                        if (!$util.isString(message.street2))
                            return "street2: string expected";
                    if (message.unitValue != null && message.hasOwnProperty("unitValue"))
                        if (!$util.isString(message.unitValue))
                            return "unitValue: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        if (!$util.isString(message.zip))
                            return "zip: string expected";
                    if (message.zipLocal != null && message.hasOwnProperty("zipLocal"))
                        if (!$util.isString(message.zipLocal))
                            return "zipLocal: string expected";
                    if (message.countryISO3 != null && message.hasOwnProperty("countryISO3"))
                        if (!$util.isString(message.countryISO3))
                            return "countryISO3: string expected";
                    if (message.ID != null && message.hasOwnProperty("ID"))
                        if (!$util.isString(message.ID))
                            return "ID: string expected";
                    if (message.unitType != null && message.hasOwnProperty("unitType"))
                        if (!$util.isString(message.unitType))
                            return "unitType: string expected";
                    return null;
                };

                /**
                 * Creates an Address message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {appliancepb.ReplacementResponse.Customer.Address} Address
                 */
                Address.fromObject = function fromObject(object) {
                    if (object instanceof $root.appliancepb.ReplacementResponse.Customer.Address)
                        return object;
                    let message = new $root.appliancepb.ReplacementResponse.Customer.Address();
                    if (object.street1 != null)
                        message.street1 = String(object.street1);
                    if (object.street2 != null)
                        message.street2 = String(object.street2);
                    if (object.unitValue != null)
                        message.unitValue = String(object.unitValue);
                    if (object.city != null)
                        message.city = String(object.city);
                    if (object.state != null)
                        message.state = String(object.state);
                    if (object.zip != null)
                        message.zip = String(object.zip);
                    if (object.zipLocal != null)
                        message.zipLocal = String(object.zipLocal);
                    if (object.countryISO3 != null)
                        message.countryISO3 = String(object.countryISO3);
                    if (object.ID != null)
                        message.ID = String(object.ID);
                    if (object.unitType != null)
                        message.unitType = String(object.unitType);
                    return message;
                };

                /**
                 * Creates a plain object from an Address message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @static
                 * @param {appliancepb.ReplacementResponse.Customer.Address} message Address
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Address.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.street1 = "";
                        object.street2 = "";
                        object.unitValue = "";
                        object.city = "";
                        object.state = "";
                        object.zip = "";
                        object.zipLocal = "";
                        object.countryISO3 = "";
                        object.ID = "";
                        object.unitType = "";
                    }
                    if (message.street1 != null && message.hasOwnProperty("street1"))
                        object.street1 = message.street1;
                    if (message.street2 != null && message.hasOwnProperty("street2"))
                        object.street2 = message.street2;
                    if (message.unitValue != null && message.hasOwnProperty("unitValue"))
                        object.unitValue = message.unitValue;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        object.zip = message.zip;
                    if (message.zipLocal != null && message.hasOwnProperty("zipLocal"))
                        object.zipLocal = message.zipLocal;
                    if (message.countryISO3 != null && message.hasOwnProperty("countryISO3"))
                        object.countryISO3 = message.countryISO3;
                    if (message.ID != null && message.hasOwnProperty("ID"))
                        object.ID = message.ID;
                    if (message.unitType != null && message.hasOwnProperty("unitType"))
                        object.unitType = message.unitType;
                    return object;
                };

                /**
                 * Converts this Address to JSON.
                 * @function toJSON
                 * @memberof appliancepb.ReplacementResponse.Customer.Address
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Address.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Address;
            })();

            return Customer;
        })();

        ReplacementResponse.PaymentDetails = (function() {

            /**
             * Properties of a PaymentDetails.
             * @memberof appliancepb.ReplacementResponse
             * @interface IPaymentDetails
             * @property {string|null} [expirationMonth] PaymentDetails expirationMonth
             * @property {string|null} [expirationYear] PaymentDetails expirationYear
             * @property {string|null} [last4] PaymentDetails last4
             */

            /**
             * Constructs a new PaymentDetails.
             * @memberof appliancepb.ReplacementResponse
             * @classdesc Represents a PaymentDetails.
             * @implements IPaymentDetails
             * @constructor
             * @param {appliancepb.ReplacementResponse.IPaymentDetails=} [properties] Properties to set
             */
            function PaymentDetails(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PaymentDetails expirationMonth.
             * @member {string} expirationMonth
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @instance
             */
            PaymentDetails.prototype.expirationMonth = "";

            /**
             * PaymentDetails expirationYear.
             * @member {string} expirationYear
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @instance
             */
            PaymentDetails.prototype.expirationYear = "";

            /**
             * PaymentDetails last4.
             * @member {string} last4
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @instance
             */
            PaymentDetails.prototype.last4 = "";

            /**
             * Creates a new PaymentDetails instance using the specified properties.
             * @function create
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {appliancepb.ReplacementResponse.IPaymentDetails=} [properties] Properties to set
             * @returns {appliancepb.ReplacementResponse.PaymentDetails} PaymentDetails instance
             */
            PaymentDetails.create = function create(properties) {
                return new PaymentDetails(properties);
            };

            /**
             * Encodes the specified PaymentDetails message. Does not implicitly {@link appliancepb.ReplacementResponse.PaymentDetails.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {appliancepb.ReplacementResponse.IPaymentDetails} message PaymentDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentDetails.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.expirationMonth != null && Object.hasOwnProperty.call(message, "expirationMonth"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.expirationMonth);
                if (message.expirationYear != null && Object.hasOwnProperty.call(message, "expirationYear"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.expirationYear);
                if (message.last4 != null && Object.hasOwnProperty.call(message, "last4"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.last4);
                return writer;
            };

            /**
             * Encodes the specified PaymentDetails message, length delimited. Does not implicitly {@link appliancepb.ReplacementResponse.PaymentDetails.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {appliancepb.ReplacementResponse.IPaymentDetails} message PaymentDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentDetails.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PaymentDetails message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.ReplacementResponse.PaymentDetails} PaymentDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentDetails.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementResponse.PaymentDetails();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.expirationMonth = reader.string();
                        break;
                    case 2:
                        message.expirationYear = reader.string();
                        break;
                    case 3:
                        message.last4 = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PaymentDetails message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.ReplacementResponse.PaymentDetails} PaymentDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentDetails.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PaymentDetails message.
             * @function verify
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaymentDetails.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.expirationMonth != null && message.hasOwnProperty("expirationMonth"))
                    if (!$util.isString(message.expirationMonth))
                        return "expirationMonth: string expected";
                if (message.expirationYear != null && message.hasOwnProperty("expirationYear"))
                    if (!$util.isString(message.expirationYear))
                        return "expirationYear: string expected";
                if (message.last4 != null && message.hasOwnProperty("last4"))
                    if (!$util.isString(message.last4))
                        return "last4: string expected";
                return null;
            };

            /**
             * Creates a PaymentDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.ReplacementResponse.PaymentDetails} PaymentDetails
             */
            PaymentDetails.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.ReplacementResponse.PaymentDetails)
                    return object;
                let message = new $root.appliancepb.ReplacementResponse.PaymentDetails();
                if (object.expirationMonth != null)
                    message.expirationMonth = String(object.expirationMonth);
                if (object.expirationYear != null)
                    message.expirationYear = String(object.expirationYear);
                if (object.last4 != null)
                    message.last4 = String(object.last4);
                return message;
            };

            /**
             * Creates a plain object from a PaymentDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @static
             * @param {appliancepb.ReplacementResponse.PaymentDetails} message PaymentDetails
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaymentDetails.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.expirationMonth = "";
                    object.expirationYear = "";
                    object.last4 = "";
                }
                if (message.expirationMonth != null && message.hasOwnProperty("expirationMonth"))
                    object.expirationMonth = message.expirationMonth;
                if (message.expirationYear != null && message.hasOwnProperty("expirationYear"))
                    object.expirationYear = message.expirationYear;
                if (message.last4 != null && message.hasOwnProperty("last4"))
                    object.last4 = message.last4;
                return object;
            };

            /**
             * Converts this PaymentDetails to JSON.
             * @function toJSON
             * @memberof appliancepb.ReplacementResponse.PaymentDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaymentDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PaymentDetails;
        })();

        ReplacementResponse.ReplacementDetails = (function() {

            /**
             * Properties of a ReplacementDetails.
             * @memberof appliancepb.ReplacementResponse
             * @interface IReplacementDetails
             * @property {string|null} [itemType] ReplacementDetails itemType
             * @property {number|Long|null} [cashInLieu] ReplacementDetails cashInLieu
             * @property {string|null} [likeForLikeProductID] ReplacementDetails likeForLikeProductID
             * @property {string|null} [ID] ReplacementDetails ID
             * @property {string|null} [dispatchID] ReplacementDetails dispatchID
             * @property {string|null} [partRequestID] ReplacementDetails partRequestID
             */

            /**
             * Constructs a new ReplacementDetails.
             * @memberof appliancepb.ReplacementResponse
             * @classdesc Represents a ReplacementDetails.
             * @implements IReplacementDetails
             * @constructor
             * @param {appliancepb.ReplacementResponse.IReplacementDetails=} [properties] Properties to set
             */
            function ReplacementDetails(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReplacementDetails itemType.
             * @member {string} itemType
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @instance
             */
            ReplacementDetails.prototype.itemType = "";

            /**
             * ReplacementDetails cashInLieu.
             * @member {number|Long} cashInLieu
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @instance
             */
            ReplacementDetails.prototype.cashInLieu = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ReplacementDetails likeForLikeProductID.
             * @member {string} likeForLikeProductID
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @instance
             */
            ReplacementDetails.prototype.likeForLikeProductID = "";

            /**
             * ReplacementDetails ID.
             * @member {string} ID
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @instance
             */
            ReplacementDetails.prototype.ID = "";

            /**
             * ReplacementDetails dispatchID.
             * @member {string} dispatchID
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @instance
             */
            ReplacementDetails.prototype.dispatchID = "";

            /**
             * ReplacementDetails partRequestID.
             * @member {string} partRequestID
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @instance
             */
            ReplacementDetails.prototype.partRequestID = "";

            /**
             * Creates a new ReplacementDetails instance using the specified properties.
             * @function create
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {appliancepb.ReplacementResponse.IReplacementDetails=} [properties] Properties to set
             * @returns {appliancepb.ReplacementResponse.ReplacementDetails} ReplacementDetails instance
             */
            ReplacementDetails.create = function create(properties) {
                return new ReplacementDetails(properties);
            };

            /**
             * Encodes the specified ReplacementDetails message. Does not implicitly {@link appliancepb.ReplacementResponse.ReplacementDetails.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {appliancepb.ReplacementResponse.IReplacementDetails} message ReplacementDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReplacementDetails.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.itemType != null && Object.hasOwnProperty.call(message, "itemType"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.itemType);
                if (message.cashInLieu != null && Object.hasOwnProperty.call(message, "cashInLieu"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cashInLieu);
                if (message.likeForLikeProductID != null && Object.hasOwnProperty.call(message, "likeForLikeProductID"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.likeForLikeProductID);
                if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.ID);
                if (message.dispatchID != null && Object.hasOwnProperty.call(message, "dispatchID"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.dispatchID);
                if (message.partRequestID != null && Object.hasOwnProperty.call(message, "partRequestID"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.partRequestID);
                return writer;
            };

            /**
             * Encodes the specified ReplacementDetails message, length delimited. Does not implicitly {@link appliancepb.ReplacementResponse.ReplacementDetails.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {appliancepb.ReplacementResponse.IReplacementDetails} message ReplacementDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReplacementDetails.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReplacementDetails message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.ReplacementResponse.ReplacementDetails} ReplacementDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReplacementDetails.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementResponse.ReplacementDetails();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.itemType = reader.string();
                        break;
                    case 2:
                        message.cashInLieu = reader.int64();
                        break;
                    case 3:
                        message.likeForLikeProductID = reader.string();
                        break;
                    case 4:
                        message.ID = reader.string();
                        break;
                    case 5:
                        message.dispatchID = reader.string();
                        break;
                    case 6:
                        message.partRequestID = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReplacementDetails message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.ReplacementResponse.ReplacementDetails} ReplacementDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReplacementDetails.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReplacementDetails message.
             * @function verify
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReplacementDetails.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.itemType != null && message.hasOwnProperty("itemType"))
                    if (!$util.isString(message.itemType))
                        return "itemType: string expected";
                if (message.cashInLieu != null && message.hasOwnProperty("cashInLieu"))
                    if (!$util.isInteger(message.cashInLieu) && !(message.cashInLieu && $util.isInteger(message.cashInLieu.low) && $util.isInteger(message.cashInLieu.high)))
                        return "cashInLieu: integer|Long expected";
                if (message.likeForLikeProductID != null && message.hasOwnProperty("likeForLikeProductID"))
                    if (!$util.isString(message.likeForLikeProductID))
                        return "likeForLikeProductID: string expected";
                if (message.ID != null && message.hasOwnProperty("ID"))
                    if (!$util.isString(message.ID))
                        return "ID: string expected";
                if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                    if (!$util.isString(message.dispatchID))
                        return "dispatchID: string expected";
                if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                    if (!$util.isString(message.partRequestID))
                        return "partRequestID: string expected";
                return null;
            };

            /**
             * Creates a ReplacementDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.ReplacementResponse.ReplacementDetails} ReplacementDetails
             */
            ReplacementDetails.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.ReplacementResponse.ReplacementDetails)
                    return object;
                let message = new $root.appliancepb.ReplacementResponse.ReplacementDetails();
                if (object.itemType != null)
                    message.itemType = String(object.itemType);
                if (object.cashInLieu != null)
                    if ($util.Long)
                        (message.cashInLieu = $util.Long.fromValue(object.cashInLieu)).unsigned = false;
                    else if (typeof object.cashInLieu === "string")
                        message.cashInLieu = parseInt(object.cashInLieu, 10);
                    else if (typeof object.cashInLieu === "number")
                        message.cashInLieu = object.cashInLieu;
                    else if (typeof object.cashInLieu === "object")
                        message.cashInLieu = new $util.LongBits(object.cashInLieu.low >>> 0, object.cashInLieu.high >>> 0).toNumber();
                if (object.likeForLikeProductID != null)
                    message.likeForLikeProductID = String(object.likeForLikeProductID);
                if (object.ID != null)
                    message.ID = String(object.ID);
                if (object.dispatchID != null)
                    message.dispatchID = String(object.dispatchID);
                if (object.partRequestID != null)
                    message.partRequestID = String(object.partRequestID);
                return message;
            };

            /**
             * Creates a plain object from a ReplacementDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @static
             * @param {appliancepb.ReplacementResponse.ReplacementDetails} message ReplacementDetails
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReplacementDetails.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.itemType = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.cashInLieu = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.cashInLieu = options.longs === String ? "0" : 0;
                    object.likeForLikeProductID = "";
                    object.ID = "";
                    object.dispatchID = "";
                    object.partRequestID = "";
                }
                if (message.itemType != null && message.hasOwnProperty("itemType"))
                    object.itemType = message.itemType;
                if (message.cashInLieu != null && message.hasOwnProperty("cashInLieu"))
                    if (typeof message.cashInLieu === "number")
                        object.cashInLieu = options.longs === String ? String(message.cashInLieu) : message.cashInLieu;
                    else
                        object.cashInLieu = options.longs === String ? $util.Long.prototype.toString.call(message.cashInLieu) : options.longs === Number ? new $util.LongBits(message.cashInLieu.low >>> 0, message.cashInLieu.high >>> 0).toNumber() : message.cashInLieu;
                if (message.likeForLikeProductID != null && message.hasOwnProperty("likeForLikeProductID"))
                    object.likeForLikeProductID = message.likeForLikeProductID;
                if (message.ID != null && message.hasOwnProperty("ID"))
                    object.ID = message.ID;
                if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                    object.dispatchID = message.dispatchID;
                if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                    object.partRequestID = message.partRequestID;
                return object;
            };

            /**
             * Converts this ReplacementDetails to JSON.
             * @function toJSON
             * @memberof appliancepb.ReplacementResponse.ReplacementDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReplacementDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ReplacementDetails;
        })();

        return ReplacementResponse;
    })();

    appliancepb.Product = (function() {

        /**
         * Properties of a Product.
         * @memberof appliancepb
         * @interface IProduct
         * @property {string|null} [ID] Product ID
         * @property {number|null} [customerPrice] Product customerPrice
         * @property {string|null} [modelNumber] Product modelNumber
         * @property {string|null} [description] Product description
         * @property {Array.<appliancepb.Product.IFeature>|null} [bulletFeatures] Product bulletFeatures
         * @property {Array.<appliancepb.Product.IAttributeGroup>|null} [attributeGroups] Product attributeGroups
         * @property {Array.<appliancepb.Product.IImage>|null} [images] Product images
         * @property {Array.<string>|null} [marketClaimsAndCertifications] Product marketClaimsAndCertifications
         * @property {string|null} [applianceType] Product applianceType
         * @property {string|null} [color] Product color
         * @property {string|null} [fuel] Product fuel
         * @property {appliancepb.IDimensions|null} [dimensions] Product dimensions
         * @property {string|null} [itemID] Product itemID
         * @property {boolean|null} [IsAvailableForZip] Product IsAvailableForZip
         * @property {boolean|null} [IsCoupa] Product IsCoupa
         * @property {number|null} [basePrice] Product basePrice
         * @property {number|null} [defaultPriceWithTax] Product defaultPriceWithTax
         * @property {number|null} [upgradePriceWithTax] Product upgradePriceWithTax
         * @property {google.protobuf.ITimestamp|null} [shipDate] Product shipDate
         * @property {google.protobuf.ITimestamp|null} [deliveryDate] Product deliveryDate
         * @property {string|null} [category] Product category
         * @property {string|null} [subcategory] Product subcategory
         * @property {string|null} [warrantyCoverageMessage] Product warrantyCoverageMessage
         */

        /**
         * Constructs a new Product.
         * @memberof appliancepb
         * @classdesc Represents a Product.
         * @implements IProduct
         * @constructor
         * @param {appliancepb.IProduct=} [properties] Properties to set
         */
        function Product(properties) {
            this.bulletFeatures = [];
            this.attributeGroups = [];
            this.images = [];
            this.marketClaimsAndCertifications = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Product ID.
         * @member {string} ID
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.ID = "";

        /**
         * Product customerPrice.
         * @member {number} customerPrice
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.customerPrice = 0;

        /**
         * Product modelNumber.
         * @member {string} modelNumber
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.modelNumber = "";

        /**
         * Product description.
         * @member {string} description
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.description = "";

        /**
         * Product bulletFeatures.
         * @member {Array.<appliancepb.Product.IFeature>} bulletFeatures
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.bulletFeatures = $util.emptyArray;

        /**
         * Product attributeGroups.
         * @member {Array.<appliancepb.Product.IAttributeGroup>} attributeGroups
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.attributeGroups = $util.emptyArray;

        /**
         * Product images.
         * @member {Array.<appliancepb.Product.IImage>} images
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.images = $util.emptyArray;

        /**
         * Product marketClaimsAndCertifications.
         * @member {Array.<string>} marketClaimsAndCertifications
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.marketClaimsAndCertifications = $util.emptyArray;

        /**
         * Product applianceType.
         * @member {string} applianceType
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.applianceType = "";

        /**
         * Product color.
         * @member {string} color
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.color = "";

        /**
         * Product fuel.
         * @member {string} fuel
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.fuel = "";

        /**
         * Product dimensions.
         * @member {appliancepb.IDimensions|null|undefined} dimensions
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.dimensions = null;

        /**
         * Product itemID.
         * @member {string} itemID
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.itemID = "";

        /**
         * Product IsAvailableForZip.
         * @member {boolean} IsAvailableForZip
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.IsAvailableForZip = false;

        /**
         * Product IsCoupa.
         * @member {boolean} IsCoupa
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.IsCoupa = false;

        /**
         * Product basePrice.
         * @member {number} basePrice
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.basePrice = 0;

        /**
         * Product defaultPriceWithTax.
         * @member {number} defaultPriceWithTax
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.defaultPriceWithTax = 0;

        /**
         * Product upgradePriceWithTax.
         * @member {number} upgradePriceWithTax
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.upgradePriceWithTax = 0;

        /**
         * Product shipDate.
         * @member {google.protobuf.ITimestamp|null|undefined} shipDate
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.shipDate = null;

        /**
         * Product deliveryDate.
         * @member {google.protobuf.ITimestamp|null|undefined} deliveryDate
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.deliveryDate = null;

        /**
         * Product category.
         * @member {string} category
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.category = "";

        /**
         * Product subcategory.
         * @member {string} subcategory
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.subcategory = "";

        /**
         * Product warrantyCoverageMessage.
         * @member {string} warrantyCoverageMessage
         * @memberof appliancepb.Product
         * @instance
         */
        Product.prototype.warrantyCoverageMessage = "";

        /**
         * Creates a new Product instance using the specified properties.
         * @function create
         * @memberof appliancepb.Product
         * @static
         * @param {appliancepb.IProduct=} [properties] Properties to set
         * @returns {appliancepb.Product} Product instance
         */
        Product.create = function create(properties) {
            return new Product(properties);
        };

        /**
         * Encodes the specified Product message. Does not implicitly {@link appliancepb.Product.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Product
         * @static
         * @param {appliancepb.IProduct} message Product message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Product.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.customerPrice != null && Object.hasOwnProperty.call(message, "customerPrice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.customerPrice);
            if (message.modelNumber != null && Object.hasOwnProperty.call(message, "modelNumber"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.modelNumber);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
            if (message.bulletFeatures != null && message.bulletFeatures.length)
                for (let i = 0; i < message.bulletFeatures.length; ++i)
                    $root.appliancepb.Product.Feature.encode(message.bulletFeatures[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.attributeGroups != null && message.attributeGroups.length)
                for (let i = 0; i < message.attributeGroups.length; ++i)
                    $root.appliancepb.Product.AttributeGroup.encode(message.attributeGroups[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.images != null && message.images.length)
                for (let i = 0; i < message.images.length; ++i)
                    $root.appliancepb.Product.Image.encode(message.images[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.marketClaimsAndCertifications != null && message.marketClaimsAndCertifications.length)
                for (let i = 0; i < message.marketClaimsAndCertifications.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.marketClaimsAndCertifications[i]);
            if (message.applianceType != null && Object.hasOwnProperty.call(message, "applianceType"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.applianceType);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.color);
            if (message.fuel != null && Object.hasOwnProperty.call(message, "fuel"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.fuel);
            if (message.dimensions != null && Object.hasOwnProperty.call(message, "dimensions"))
                $root.appliancepb.Dimensions.encode(message.dimensions, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.itemID != null && Object.hasOwnProperty.call(message, "itemID"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.itemID);
            if (message.IsAvailableForZip != null && Object.hasOwnProperty.call(message, "IsAvailableForZip"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.IsAvailableForZip);
            if (message.IsCoupa != null && Object.hasOwnProperty.call(message, "IsCoupa"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.IsCoupa);
            if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.basePrice);
            if (message.defaultPriceWithTax != null && Object.hasOwnProperty.call(message, "defaultPriceWithTax"))
                writer.uint32(/* id 17, wireType 0 =*/136).int32(message.defaultPriceWithTax);
            if (message.upgradePriceWithTax != null && Object.hasOwnProperty.call(message, "upgradePriceWithTax"))
                writer.uint32(/* id 18, wireType 0 =*/144).int32(message.upgradePriceWithTax);
            if (message.shipDate != null && Object.hasOwnProperty.call(message, "shipDate"))
                $root.google.protobuf.Timestamp.encode(message.shipDate, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.deliveryDate != null && Object.hasOwnProperty.call(message, "deliveryDate"))
                $root.google.protobuf.Timestamp.encode(message.deliveryDate, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.category);
            if (message.subcategory != null && Object.hasOwnProperty.call(message, "subcategory"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.subcategory);
            if (message.warrantyCoverageMessage != null && Object.hasOwnProperty.call(message, "warrantyCoverageMessage"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.warrantyCoverageMessage);
            return writer;
        };

        /**
         * Encodes the specified Product message, length delimited. Does not implicitly {@link appliancepb.Product.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Product
         * @static
         * @param {appliancepb.IProduct} message Product message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Product.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Product message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Product
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Product} Product
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Product.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Product();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.customerPrice = reader.int32();
                    break;
                case 3:
                    message.modelNumber = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                case 5:
                    if (!(message.bulletFeatures && message.bulletFeatures.length))
                        message.bulletFeatures = [];
                    message.bulletFeatures.push($root.appliancepb.Product.Feature.decode(reader, reader.uint32()));
                    break;
                case 6:
                    if (!(message.attributeGroups && message.attributeGroups.length))
                        message.attributeGroups = [];
                    message.attributeGroups.push($root.appliancepb.Product.AttributeGroup.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.images && message.images.length))
                        message.images = [];
                    message.images.push($root.appliancepb.Product.Image.decode(reader, reader.uint32()));
                    break;
                case 8:
                    if (!(message.marketClaimsAndCertifications && message.marketClaimsAndCertifications.length))
                        message.marketClaimsAndCertifications = [];
                    message.marketClaimsAndCertifications.push(reader.string());
                    break;
                case 9:
                    message.applianceType = reader.string();
                    break;
                case 10:
                    message.color = reader.string();
                    break;
                case 11:
                    message.fuel = reader.string();
                    break;
                case 12:
                    message.dimensions = $root.appliancepb.Dimensions.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.itemID = reader.string();
                    break;
                case 14:
                    message.IsAvailableForZip = reader.bool();
                    break;
                case 15:
                    message.IsCoupa = reader.bool();
                    break;
                case 16:
                    message.basePrice = reader.int32();
                    break;
                case 17:
                    message.defaultPriceWithTax = reader.int32();
                    break;
                case 18:
                    message.upgradePriceWithTax = reader.int32();
                    break;
                case 19:
                    message.shipDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.deliveryDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.category = reader.string();
                    break;
                case 22:
                    message.subcategory = reader.string();
                    break;
                case 23:
                    message.warrantyCoverageMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Product message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Product
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Product} Product
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Product.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Product message.
         * @function verify
         * @memberof appliancepb.Product
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Product.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.customerPrice != null && message.hasOwnProperty("customerPrice"))
                if (!$util.isInteger(message.customerPrice))
                    return "customerPrice: integer expected";
            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                if (!$util.isString(message.modelNumber))
                    return "modelNumber: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.bulletFeatures != null && message.hasOwnProperty("bulletFeatures")) {
                if (!Array.isArray(message.bulletFeatures))
                    return "bulletFeatures: array expected";
                for (let i = 0; i < message.bulletFeatures.length; ++i) {
                    let error = $root.appliancepb.Product.Feature.verify(message.bulletFeatures[i]);
                    if (error)
                        return "bulletFeatures." + error;
                }
            }
            if (message.attributeGroups != null && message.hasOwnProperty("attributeGroups")) {
                if (!Array.isArray(message.attributeGroups))
                    return "attributeGroups: array expected";
                for (let i = 0; i < message.attributeGroups.length; ++i) {
                    let error = $root.appliancepb.Product.AttributeGroup.verify(message.attributeGroups[i]);
                    if (error)
                        return "attributeGroups." + error;
                }
            }
            if (message.images != null && message.hasOwnProperty("images")) {
                if (!Array.isArray(message.images))
                    return "images: array expected";
                for (let i = 0; i < message.images.length; ++i) {
                    let error = $root.appliancepb.Product.Image.verify(message.images[i]);
                    if (error)
                        return "images." + error;
                }
            }
            if (message.marketClaimsAndCertifications != null && message.hasOwnProperty("marketClaimsAndCertifications")) {
                if (!Array.isArray(message.marketClaimsAndCertifications))
                    return "marketClaimsAndCertifications: array expected";
                for (let i = 0; i < message.marketClaimsAndCertifications.length; ++i)
                    if (!$util.isString(message.marketClaimsAndCertifications[i]))
                        return "marketClaimsAndCertifications: string[] expected";
            }
            if (message.applianceType != null && message.hasOwnProperty("applianceType"))
                if (!$util.isString(message.applianceType))
                    return "applianceType: string expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isString(message.color))
                    return "color: string expected";
            if (message.fuel != null && message.hasOwnProperty("fuel"))
                if (!$util.isString(message.fuel))
                    return "fuel: string expected";
            if (message.dimensions != null && message.hasOwnProperty("dimensions")) {
                let error = $root.appliancepb.Dimensions.verify(message.dimensions);
                if (error)
                    return "dimensions." + error;
            }
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                if (!$util.isString(message.itemID))
                    return "itemID: string expected";
            if (message.IsAvailableForZip != null && message.hasOwnProperty("IsAvailableForZip"))
                if (typeof message.IsAvailableForZip !== "boolean")
                    return "IsAvailableForZip: boolean expected";
            if (message.IsCoupa != null && message.hasOwnProperty("IsCoupa"))
                if (typeof message.IsCoupa !== "boolean")
                    return "IsCoupa: boolean expected";
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                if (!$util.isInteger(message.basePrice))
                    return "basePrice: integer expected";
            if (message.defaultPriceWithTax != null && message.hasOwnProperty("defaultPriceWithTax"))
                if (!$util.isInteger(message.defaultPriceWithTax))
                    return "defaultPriceWithTax: integer expected";
            if (message.upgradePriceWithTax != null && message.hasOwnProperty("upgradePriceWithTax"))
                if (!$util.isInteger(message.upgradePriceWithTax))
                    return "upgradePriceWithTax: integer expected";
            if (message.shipDate != null && message.hasOwnProperty("shipDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.shipDate);
                if (error)
                    return "shipDate." + error;
            }
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.deliveryDate);
                if (error)
                    return "deliveryDate." + error;
            }
            if (message.category != null && message.hasOwnProperty("category"))
                if (!$util.isString(message.category))
                    return "category: string expected";
            if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                if (!$util.isString(message.subcategory))
                    return "subcategory: string expected";
            if (message.warrantyCoverageMessage != null && message.hasOwnProperty("warrantyCoverageMessage"))
                if (!$util.isString(message.warrantyCoverageMessage))
                    return "warrantyCoverageMessage: string expected";
            return null;
        };

        /**
         * Creates a Product message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Product
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Product} Product
         */
        Product.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Product)
                return object;
            let message = new $root.appliancepb.Product();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.customerPrice != null)
                message.customerPrice = object.customerPrice | 0;
            if (object.modelNumber != null)
                message.modelNumber = String(object.modelNumber);
            if (object.description != null)
                message.description = String(object.description);
            if (object.bulletFeatures) {
                if (!Array.isArray(object.bulletFeatures))
                    throw TypeError(".appliancepb.Product.bulletFeatures: array expected");
                message.bulletFeatures = [];
                for (let i = 0; i < object.bulletFeatures.length; ++i) {
                    if (typeof object.bulletFeatures[i] !== "object")
                        throw TypeError(".appliancepb.Product.bulletFeatures: object expected");
                    message.bulletFeatures[i] = $root.appliancepb.Product.Feature.fromObject(object.bulletFeatures[i]);
                }
            }
            if (object.attributeGroups) {
                if (!Array.isArray(object.attributeGroups))
                    throw TypeError(".appliancepb.Product.attributeGroups: array expected");
                message.attributeGroups = [];
                for (let i = 0; i < object.attributeGroups.length; ++i) {
                    if (typeof object.attributeGroups[i] !== "object")
                        throw TypeError(".appliancepb.Product.attributeGroups: object expected");
                    message.attributeGroups[i] = $root.appliancepb.Product.AttributeGroup.fromObject(object.attributeGroups[i]);
                }
            }
            if (object.images) {
                if (!Array.isArray(object.images))
                    throw TypeError(".appliancepb.Product.images: array expected");
                message.images = [];
                for (let i = 0; i < object.images.length; ++i) {
                    if (typeof object.images[i] !== "object")
                        throw TypeError(".appliancepb.Product.images: object expected");
                    message.images[i] = $root.appliancepb.Product.Image.fromObject(object.images[i]);
                }
            }
            if (object.marketClaimsAndCertifications) {
                if (!Array.isArray(object.marketClaimsAndCertifications))
                    throw TypeError(".appliancepb.Product.marketClaimsAndCertifications: array expected");
                message.marketClaimsAndCertifications = [];
                for (let i = 0; i < object.marketClaimsAndCertifications.length; ++i)
                    message.marketClaimsAndCertifications[i] = String(object.marketClaimsAndCertifications[i]);
            }
            if (object.applianceType != null)
                message.applianceType = String(object.applianceType);
            if (object.color != null)
                message.color = String(object.color);
            if (object.fuel != null)
                message.fuel = String(object.fuel);
            if (object.dimensions != null) {
                if (typeof object.dimensions !== "object")
                    throw TypeError(".appliancepb.Product.dimensions: object expected");
                message.dimensions = $root.appliancepb.Dimensions.fromObject(object.dimensions);
            }
            if (object.itemID != null)
                message.itemID = String(object.itemID);
            if (object.IsAvailableForZip != null)
                message.IsAvailableForZip = Boolean(object.IsAvailableForZip);
            if (object.IsCoupa != null)
                message.IsCoupa = Boolean(object.IsCoupa);
            if (object.basePrice != null)
                message.basePrice = object.basePrice | 0;
            if (object.defaultPriceWithTax != null)
                message.defaultPriceWithTax = object.defaultPriceWithTax | 0;
            if (object.upgradePriceWithTax != null)
                message.upgradePriceWithTax = object.upgradePriceWithTax | 0;
            if (object.shipDate != null) {
                if (typeof object.shipDate !== "object")
                    throw TypeError(".appliancepb.Product.shipDate: object expected");
                message.shipDate = $root.google.protobuf.Timestamp.fromObject(object.shipDate);
            }
            if (object.deliveryDate != null) {
                if (typeof object.deliveryDate !== "object")
                    throw TypeError(".appliancepb.Product.deliveryDate: object expected");
                message.deliveryDate = $root.google.protobuf.Timestamp.fromObject(object.deliveryDate);
            }
            if (object.category != null)
                message.category = String(object.category);
            if (object.subcategory != null)
                message.subcategory = String(object.subcategory);
            if (object.warrantyCoverageMessage != null)
                message.warrantyCoverageMessage = String(object.warrantyCoverageMessage);
            return message;
        };

        /**
         * Creates a plain object from a Product message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Product
         * @static
         * @param {appliancepb.Product} message Product
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Product.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.bulletFeatures = [];
                object.attributeGroups = [];
                object.images = [];
                object.marketClaimsAndCertifications = [];
            }
            if (options.defaults) {
                object.ID = "";
                object.customerPrice = 0;
                object.modelNumber = "";
                object.description = "";
                object.applianceType = "";
                object.color = "";
                object.fuel = "";
                object.dimensions = null;
                object.itemID = "";
                object.IsAvailableForZip = false;
                object.IsCoupa = false;
                object.basePrice = 0;
                object.defaultPriceWithTax = 0;
                object.upgradePriceWithTax = 0;
                object.shipDate = null;
                object.deliveryDate = null;
                object.category = "";
                object.subcategory = "";
                object.warrantyCoverageMessage = "";
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.customerPrice != null && message.hasOwnProperty("customerPrice"))
                object.customerPrice = message.customerPrice;
            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                object.modelNumber = message.modelNumber;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.bulletFeatures && message.bulletFeatures.length) {
                object.bulletFeatures = [];
                for (let j = 0; j < message.bulletFeatures.length; ++j)
                    object.bulletFeatures[j] = $root.appliancepb.Product.Feature.toObject(message.bulletFeatures[j], options);
            }
            if (message.attributeGroups && message.attributeGroups.length) {
                object.attributeGroups = [];
                for (let j = 0; j < message.attributeGroups.length; ++j)
                    object.attributeGroups[j] = $root.appliancepb.Product.AttributeGroup.toObject(message.attributeGroups[j], options);
            }
            if (message.images && message.images.length) {
                object.images = [];
                for (let j = 0; j < message.images.length; ++j)
                    object.images[j] = $root.appliancepb.Product.Image.toObject(message.images[j], options);
            }
            if (message.marketClaimsAndCertifications && message.marketClaimsAndCertifications.length) {
                object.marketClaimsAndCertifications = [];
                for (let j = 0; j < message.marketClaimsAndCertifications.length; ++j)
                    object.marketClaimsAndCertifications[j] = message.marketClaimsAndCertifications[j];
            }
            if (message.applianceType != null && message.hasOwnProperty("applianceType"))
                object.applianceType = message.applianceType;
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = message.color;
            if (message.fuel != null && message.hasOwnProperty("fuel"))
                object.fuel = message.fuel;
            if (message.dimensions != null && message.hasOwnProperty("dimensions"))
                object.dimensions = $root.appliancepb.Dimensions.toObject(message.dimensions, options);
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                object.itemID = message.itemID;
            if (message.IsAvailableForZip != null && message.hasOwnProperty("IsAvailableForZip"))
                object.IsAvailableForZip = message.IsAvailableForZip;
            if (message.IsCoupa != null && message.hasOwnProperty("IsCoupa"))
                object.IsCoupa = message.IsCoupa;
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                object.basePrice = message.basePrice;
            if (message.defaultPriceWithTax != null && message.hasOwnProperty("defaultPriceWithTax"))
                object.defaultPriceWithTax = message.defaultPriceWithTax;
            if (message.upgradePriceWithTax != null && message.hasOwnProperty("upgradePriceWithTax"))
                object.upgradePriceWithTax = message.upgradePriceWithTax;
            if (message.shipDate != null && message.hasOwnProperty("shipDate"))
                object.shipDate = $root.google.protobuf.Timestamp.toObject(message.shipDate, options);
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate"))
                object.deliveryDate = $root.google.protobuf.Timestamp.toObject(message.deliveryDate, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = message.category;
            if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                object.subcategory = message.subcategory;
            if (message.warrantyCoverageMessage != null && message.hasOwnProperty("warrantyCoverageMessage"))
                object.warrantyCoverageMessage = message.warrantyCoverageMessage;
            return object;
        };

        /**
         * Converts this Product to JSON.
         * @function toJSON
         * @memberof appliancepb.Product
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Product.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Product.AttributeValuesPair = (function() {

            /**
             * Properties of an AttributeValuesPair.
             * @memberof appliancepb.Product
             * @interface IAttributeValuesPair
             * @property {string|null} [attribute] AttributeValuesPair attribute
             * @property {Array.<string>|null} [values] AttributeValuesPair values
             */

            /**
             * Constructs a new AttributeValuesPair.
             * @memberof appliancepb.Product
             * @classdesc Represents an AttributeValuesPair.
             * @implements IAttributeValuesPair
             * @constructor
             * @param {appliancepb.Product.IAttributeValuesPair=} [properties] Properties to set
             */
            function AttributeValuesPair(properties) {
                this.values = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AttributeValuesPair attribute.
             * @member {string} attribute
             * @memberof appliancepb.Product.AttributeValuesPair
             * @instance
             */
            AttributeValuesPair.prototype.attribute = "";

            /**
             * AttributeValuesPair values.
             * @member {Array.<string>} values
             * @memberof appliancepb.Product.AttributeValuesPair
             * @instance
             */
            AttributeValuesPair.prototype.values = $util.emptyArray;

            /**
             * Creates a new AttributeValuesPair instance using the specified properties.
             * @function create
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {appliancepb.Product.IAttributeValuesPair=} [properties] Properties to set
             * @returns {appliancepb.Product.AttributeValuesPair} AttributeValuesPair instance
             */
            AttributeValuesPair.create = function create(properties) {
                return new AttributeValuesPair(properties);
            };

            /**
             * Encodes the specified AttributeValuesPair message. Does not implicitly {@link appliancepb.Product.AttributeValuesPair.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {appliancepb.Product.IAttributeValuesPair} message AttributeValuesPair message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AttributeValuesPair.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.attribute != null && Object.hasOwnProperty.call(message, "attribute"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.attribute);
                if (message.values != null && message.values.length)
                    for (let i = 0; i < message.values.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
                return writer;
            };

            /**
             * Encodes the specified AttributeValuesPair message, length delimited. Does not implicitly {@link appliancepb.Product.AttributeValuesPair.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {appliancepb.Product.IAttributeValuesPair} message AttributeValuesPair message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AttributeValuesPair.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AttributeValuesPair message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.Product.AttributeValuesPair} AttributeValuesPair
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AttributeValuesPair.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Product.AttributeValuesPair();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.attribute = reader.string();
                        break;
                    case 2:
                        if (!(message.values && message.values.length))
                            message.values = [];
                        message.values.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AttributeValuesPair message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.Product.AttributeValuesPair} AttributeValuesPair
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AttributeValuesPair.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AttributeValuesPair message.
             * @function verify
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AttributeValuesPair.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.attribute != null && message.hasOwnProperty("attribute"))
                    if (!$util.isString(message.attribute))
                        return "attribute: string expected";
                if (message.values != null && message.hasOwnProperty("values")) {
                    if (!Array.isArray(message.values))
                        return "values: array expected";
                    for (let i = 0; i < message.values.length; ++i)
                        if (!$util.isString(message.values[i]))
                            return "values: string[] expected";
                }
                return null;
            };

            /**
             * Creates an AttributeValuesPair message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.Product.AttributeValuesPair} AttributeValuesPair
             */
            AttributeValuesPair.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.Product.AttributeValuesPair)
                    return object;
                let message = new $root.appliancepb.Product.AttributeValuesPair();
                if (object.attribute != null)
                    message.attribute = String(object.attribute);
                if (object.values) {
                    if (!Array.isArray(object.values))
                        throw TypeError(".appliancepb.Product.AttributeValuesPair.values: array expected");
                    message.values = [];
                    for (let i = 0; i < object.values.length; ++i)
                        message.values[i] = String(object.values[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from an AttributeValuesPair message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.Product.AttributeValuesPair
             * @static
             * @param {appliancepb.Product.AttributeValuesPair} message AttributeValuesPair
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AttributeValuesPair.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.values = [];
                if (options.defaults)
                    object.attribute = "";
                if (message.attribute != null && message.hasOwnProperty("attribute"))
                    object.attribute = message.attribute;
                if (message.values && message.values.length) {
                    object.values = [];
                    for (let j = 0; j < message.values.length; ++j)
                        object.values[j] = message.values[j];
                }
                return object;
            };

            /**
             * Converts this AttributeValuesPair to JSON.
             * @function toJSON
             * @memberof appliancepb.Product.AttributeValuesPair
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AttributeValuesPair.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AttributeValuesPair;
        })();

        Product.AttributeGroup = (function() {

            /**
             * Properties of an AttributeGroup.
             * @memberof appliancepb.Product
             * @interface IAttributeGroup
             * @property {string|null} [attributeGroupName] AttributeGroup attributeGroupName
             * @property {Array.<appliancepb.Product.IAttributeValuesPair>|null} [attributes] AttributeGroup attributes
             */

            /**
             * Constructs a new AttributeGroup.
             * @memberof appliancepb.Product
             * @classdesc Represents an AttributeGroup.
             * @implements IAttributeGroup
             * @constructor
             * @param {appliancepb.Product.IAttributeGroup=} [properties] Properties to set
             */
            function AttributeGroup(properties) {
                this.attributes = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AttributeGroup attributeGroupName.
             * @member {string} attributeGroupName
             * @memberof appliancepb.Product.AttributeGroup
             * @instance
             */
            AttributeGroup.prototype.attributeGroupName = "";

            /**
             * AttributeGroup attributes.
             * @member {Array.<appliancepb.Product.IAttributeValuesPair>} attributes
             * @memberof appliancepb.Product.AttributeGroup
             * @instance
             */
            AttributeGroup.prototype.attributes = $util.emptyArray;

            /**
             * Creates a new AttributeGroup instance using the specified properties.
             * @function create
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {appliancepb.Product.IAttributeGroup=} [properties] Properties to set
             * @returns {appliancepb.Product.AttributeGroup} AttributeGroup instance
             */
            AttributeGroup.create = function create(properties) {
                return new AttributeGroup(properties);
            };

            /**
             * Encodes the specified AttributeGroup message. Does not implicitly {@link appliancepb.Product.AttributeGroup.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {appliancepb.Product.IAttributeGroup} message AttributeGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AttributeGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.attributeGroupName != null && Object.hasOwnProperty.call(message, "attributeGroupName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.attributeGroupName);
                if (message.attributes != null && message.attributes.length)
                    for (let i = 0; i < message.attributes.length; ++i)
                        $root.appliancepb.Product.AttributeValuesPair.encode(message.attributes[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AttributeGroup message, length delimited. Does not implicitly {@link appliancepb.Product.AttributeGroup.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {appliancepb.Product.IAttributeGroup} message AttributeGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AttributeGroup.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AttributeGroup message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.Product.AttributeGroup} AttributeGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AttributeGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Product.AttributeGroup();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.attributeGroupName = reader.string();
                        break;
                    case 2:
                        if (!(message.attributes && message.attributes.length))
                            message.attributes = [];
                        message.attributes.push($root.appliancepb.Product.AttributeValuesPair.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AttributeGroup message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.Product.AttributeGroup} AttributeGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AttributeGroup.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AttributeGroup message.
             * @function verify
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AttributeGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.attributeGroupName != null && message.hasOwnProperty("attributeGroupName"))
                    if (!$util.isString(message.attributeGroupName))
                        return "attributeGroupName: string expected";
                if (message.attributes != null && message.hasOwnProperty("attributes")) {
                    if (!Array.isArray(message.attributes))
                        return "attributes: array expected";
                    for (let i = 0; i < message.attributes.length; ++i) {
                        let error = $root.appliancepb.Product.AttributeValuesPair.verify(message.attributes[i]);
                        if (error)
                            return "attributes." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AttributeGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.Product.AttributeGroup} AttributeGroup
             */
            AttributeGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.Product.AttributeGroup)
                    return object;
                let message = new $root.appliancepb.Product.AttributeGroup();
                if (object.attributeGroupName != null)
                    message.attributeGroupName = String(object.attributeGroupName);
                if (object.attributes) {
                    if (!Array.isArray(object.attributes))
                        throw TypeError(".appliancepb.Product.AttributeGroup.attributes: array expected");
                    message.attributes = [];
                    for (let i = 0; i < object.attributes.length; ++i) {
                        if (typeof object.attributes[i] !== "object")
                            throw TypeError(".appliancepb.Product.AttributeGroup.attributes: object expected");
                        message.attributes[i] = $root.appliancepb.Product.AttributeValuesPair.fromObject(object.attributes[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AttributeGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.Product.AttributeGroup
             * @static
             * @param {appliancepb.Product.AttributeGroup} message AttributeGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AttributeGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.attributes = [];
                if (options.defaults)
                    object.attributeGroupName = "";
                if (message.attributeGroupName != null && message.hasOwnProperty("attributeGroupName"))
                    object.attributeGroupName = message.attributeGroupName;
                if (message.attributes && message.attributes.length) {
                    object.attributes = [];
                    for (let j = 0; j < message.attributes.length; ++j)
                        object.attributes[j] = $root.appliancepb.Product.AttributeValuesPair.toObject(message.attributes[j], options);
                }
                return object;
            };

            /**
             * Converts this AttributeGroup to JSON.
             * @function toJSON
             * @memberof appliancepb.Product.AttributeGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AttributeGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AttributeGroup;
        })();

        Product.Feature = (function() {

            /**
             * Properties of a Feature.
             * @memberof appliancepb.Product
             * @interface IFeature
             * @property {string|null} [feature] Feature feature
             * @property {string|null} [description] Feature description
             */

            /**
             * Constructs a new Feature.
             * @memberof appliancepb.Product
             * @classdesc Represents a Feature.
             * @implements IFeature
             * @constructor
             * @param {appliancepb.Product.IFeature=} [properties] Properties to set
             */
            function Feature(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Feature feature.
             * @member {string} feature
             * @memberof appliancepb.Product.Feature
             * @instance
             */
            Feature.prototype.feature = "";

            /**
             * Feature description.
             * @member {string} description
             * @memberof appliancepb.Product.Feature
             * @instance
             */
            Feature.prototype.description = "";

            /**
             * Creates a new Feature instance using the specified properties.
             * @function create
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {appliancepb.Product.IFeature=} [properties] Properties to set
             * @returns {appliancepb.Product.Feature} Feature instance
             */
            Feature.create = function create(properties) {
                return new Feature(properties);
            };

            /**
             * Encodes the specified Feature message. Does not implicitly {@link appliancepb.Product.Feature.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {appliancepb.Product.IFeature} message Feature message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Feature.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.feature != null && Object.hasOwnProperty.call(message, "feature"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.feature);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Encodes the specified Feature message, length delimited. Does not implicitly {@link appliancepb.Product.Feature.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {appliancepb.Product.IFeature} message Feature message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Feature.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Feature message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.Product.Feature} Feature
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Feature.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Product.Feature();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.feature = reader.string();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Feature message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.Product.Feature} Feature
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Feature.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Feature message.
             * @function verify
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Feature.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.feature != null && message.hasOwnProperty("feature"))
                    if (!$util.isString(message.feature))
                        return "feature: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a Feature message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.Product.Feature} Feature
             */
            Feature.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.Product.Feature)
                    return object;
                let message = new $root.appliancepb.Product.Feature();
                if (object.feature != null)
                    message.feature = String(object.feature);
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a Feature message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.Product.Feature
             * @static
             * @param {appliancepb.Product.Feature} message Feature
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Feature.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.feature = "";
                    object.description = "";
                }
                if (message.feature != null && message.hasOwnProperty("feature"))
                    object.feature = message.feature;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this Feature to JSON.
             * @function toJSON
             * @memberof appliancepb.Product.Feature
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Feature.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Feature;
        })();

        Product.Image = (function() {

            /**
             * Properties of an Image.
             * @memberof appliancepb.Product
             * @interface IImage
             * @property {string|null} [imageName] Image imageName
             * @property {string|null} [fileName] Image fileName
             * @property {string|null} [url] Image url
             */

            /**
             * Constructs a new Image.
             * @memberof appliancepb.Product
             * @classdesc Represents an Image.
             * @implements IImage
             * @constructor
             * @param {appliancepb.Product.IImage=} [properties] Properties to set
             */
            function Image(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Image imageName.
             * @member {string} imageName
             * @memberof appliancepb.Product.Image
             * @instance
             */
            Image.prototype.imageName = "";

            /**
             * Image fileName.
             * @member {string} fileName
             * @memberof appliancepb.Product.Image
             * @instance
             */
            Image.prototype.fileName = "";

            /**
             * Image url.
             * @member {string} url
             * @memberof appliancepb.Product.Image
             * @instance
             */
            Image.prototype.url = "";

            /**
             * Creates a new Image instance using the specified properties.
             * @function create
             * @memberof appliancepb.Product.Image
             * @static
             * @param {appliancepb.Product.IImage=} [properties] Properties to set
             * @returns {appliancepb.Product.Image} Image instance
             */
            Image.create = function create(properties) {
                return new Image(properties);
            };

            /**
             * Encodes the specified Image message. Does not implicitly {@link appliancepb.Product.Image.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.Product.Image
             * @static
             * @param {appliancepb.Product.IImage} message Image message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Image.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.imageName != null && Object.hasOwnProperty.call(message, "imageName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageName);
                if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileName);
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
                return writer;
            };

            /**
             * Encodes the specified Image message, length delimited. Does not implicitly {@link appliancepb.Product.Image.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.Product.Image
             * @static
             * @param {appliancepb.Product.IImage} message Image message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Image.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Image message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.Product.Image
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.Product.Image} Image
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Image.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Product.Image();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.imageName = reader.string();
                        break;
                    case 2:
                        message.fileName = reader.string();
                        break;
                    case 3:
                        message.url = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Image message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.Product.Image
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.Product.Image} Image
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Image.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Image message.
             * @function verify
             * @memberof appliancepb.Product.Image
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Image.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.imageName != null && message.hasOwnProperty("imageName"))
                    if (!$util.isString(message.imageName))
                        return "imageName: string expected";
                if (message.fileName != null && message.hasOwnProperty("fileName"))
                    if (!$util.isString(message.fileName))
                        return "fileName: string expected";
                if (message.url != null && message.hasOwnProperty("url"))
                    if (!$util.isString(message.url))
                        return "url: string expected";
                return null;
            };

            /**
             * Creates an Image message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.Product.Image
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.Product.Image} Image
             */
            Image.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.Product.Image)
                    return object;
                let message = new $root.appliancepb.Product.Image();
                if (object.imageName != null)
                    message.imageName = String(object.imageName);
                if (object.fileName != null)
                    message.fileName = String(object.fileName);
                if (object.url != null)
                    message.url = String(object.url);
                return message;
            };

            /**
             * Creates a plain object from an Image message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.Product.Image
             * @static
             * @param {appliancepb.Product.Image} message Image
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Image.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.imageName = "";
                    object.fileName = "";
                    object.url = "";
                }
                if (message.imageName != null && message.hasOwnProperty("imageName"))
                    object.imageName = message.imageName;
                if (message.fileName != null && message.hasOwnProperty("fileName"))
                    object.fileName = message.fileName;
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                return object;
            };

            /**
             * Converts this Image to JSON.
             * @function toJSON
             * @memberof appliancepb.Product.Image
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Image.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Image;
        })();

        return Product;
    })();

    appliancepb.Dimensions = (function() {

        /**
         * Properties of a Dimensions.
         * @memberof appliancepb
         * @interface IDimensions
         * @property {string|null} [unit] Dimensions unit
         * @property {string|null} [height] Dimensions height
         * @property {string|null} [width] Dimensions width
         * @property {string|null} [depth] Dimensions depth
         */

        /**
         * Constructs a new Dimensions.
         * @memberof appliancepb
         * @classdesc Represents a Dimensions.
         * @implements IDimensions
         * @constructor
         * @param {appliancepb.IDimensions=} [properties] Properties to set
         */
        function Dimensions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Dimensions unit.
         * @member {string} unit
         * @memberof appliancepb.Dimensions
         * @instance
         */
        Dimensions.prototype.unit = "";

        /**
         * Dimensions height.
         * @member {string} height
         * @memberof appliancepb.Dimensions
         * @instance
         */
        Dimensions.prototype.height = "";

        /**
         * Dimensions width.
         * @member {string} width
         * @memberof appliancepb.Dimensions
         * @instance
         */
        Dimensions.prototype.width = "";

        /**
         * Dimensions depth.
         * @member {string} depth
         * @memberof appliancepb.Dimensions
         * @instance
         */
        Dimensions.prototype.depth = "";

        /**
         * Creates a new Dimensions instance using the specified properties.
         * @function create
         * @memberof appliancepb.Dimensions
         * @static
         * @param {appliancepb.IDimensions=} [properties] Properties to set
         * @returns {appliancepb.Dimensions} Dimensions instance
         */
        Dimensions.create = function create(properties) {
            return new Dimensions(properties);
        };

        /**
         * Encodes the specified Dimensions message. Does not implicitly {@link appliancepb.Dimensions.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Dimensions
         * @static
         * @param {appliancepb.IDimensions} message Dimensions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Dimensions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.unit);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.height);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.width);
            if (message.depth != null && Object.hasOwnProperty.call(message, "depth"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.depth);
            return writer;
        };

        /**
         * Encodes the specified Dimensions message, length delimited. Does not implicitly {@link appliancepb.Dimensions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Dimensions
         * @static
         * @param {appliancepb.IDimensions} message Dimensions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Dimensions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Dimensions message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Dimensions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Dimensions} Dimensions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Dimensions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Dimensions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.unit = reader.string();
                    break;
                case 2:
                    message.height = reader.string();
                    break;
                case 3:
                    message.width = reader.string();
                    break;
                case 4:
                    message.depth = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Dimensions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Dimensions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Dimensions} Dimensions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Dimensions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Dimensions message.
         * @function verify
         * @memberof appliancepb.Dimensions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Dimensions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.unit != null && message.hasOwnProperty("unit"))
                if (!$util.isString(message.unit))
                    return "unit: string expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isString(message.height))
                    return "height: string expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isString(message.width))
                    return "width: string expected";
            if (message.depth != null && message.hasOwnProperty("depth"))
                if (!$util.isString(message.depth))
                    return "depth: string expected";
            return null;
        };

        /**
         * Creates a Dimensions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Dimensions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Dimensions} Dimensions
         */
        Dimensions.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Dimensions)
                return object;
            let message = new $root.appliancepb.Dimensions();
            if (object.unit != null)
                message.unit = String(object.unit);
            if (object.height != null)
                message.height = String(object.height);
            if (object.width != null)
                message.width = String(object.width);
            if (object.depth != null)
                message.depth = String(object.depth);
            return message;
        };

        /**
         * Creates a plain object from a Dimensions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Dimensions
         * @static
         * @param {appliancepb.Dimensions} message Dimensions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Dimensions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.unit = "";
                object.height = "";
                object.width = "";
                object.depth = "";
            }
            if (message.unit != null && message.hasOwnProperty("unit"))
                object.unit = message.unit;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.depth != null && message.hasOwnProperty("depth"))
                object.depth = message.depth;
            return object;
        };

        /**
         * Converts this Dimensions to JSON.
         * @function toJSON
         * @memberof appliancepb.Dimensions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Dimensions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Dimensions;
    })();

    appliancepb.Pagination = (function() {

        /**
         * Properties of a Pagination.
         * @memberof appliancepb
         * @interface IPagination
         * @property {number|null} [itemsPerPage] Pagination itemsPerPage
         * @property {number|null} [totalItems] Pagination totalItems
         * @property {number|null} [page] Pagination page
         */

        /**
         * Constructs a new Pagination.
         * @memberof appliancepb
         * @classdesc Represents a Pagination.
         * @implements IPagination
         * @constructor
         * @param {appliancepb.IPagination=} [properties] Properties to set
         */
        function Pagination(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Pagination itemsPerPage.
         * @member {number} itemsPerPage
         * @memberof appliancepb.Pagination
         * @instance
         */
        Pagination.prototype.itemsPerPage = 0;

        /**
         * Pagination totalItems.
         * @member {number} totalItems
         * @memberof appliancepb.Pagination
         * @instance
         */
        Pagination.prototype.totalItems = 0;

        /**
         * Pagination page.
         * @member {number} page
         * @memberof appliancepb.Pagination
         * @instance
         */
        Pagination.prototype.page = 0;

        /**
         * Creates a new Pagination instance using the specified properties.
         * @function create
         * @memberof appliancepb.Pagination
         * @static
         * @param {appliancepb.IPagination=} [properties] Properties to set
         * @returns {appliancepb.Pagination} Pagination instance
         */
        Pagination.create = function create(properties) {
            return new Pagination(properties);
        };

        /**
         * Encodes the specified Pagination message. Does not implicitly {@link appliancepb.Pagination.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Pagination
         * @static
         * @param {appliancepb.IPagination} message Pagination message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pagination.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemsPerPage != null && Object.hasOwnProperty.call(message, "itemsPerPage"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.itemsPerPage);
            if (message.totalItems != null && Object.hasOwnProperty.call(message, "totalItems"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalItems);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.page);
            return writer;
        };

        /**
         * Encodes the specified Pagination message, length delimited. Does not implicitly {@link appliancepb.Pagination.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Pagination
         * @static
         * @param {appliancepb.IPagination} message Pagination message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pagination.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Pagination message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Pagination
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Pagination} Pagination
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pagination.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Pagination();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.itemsPerPage = reader.uint32();
                    break;
                case 2:
                    message.totalItems = reader.uint32();
                    break;
                case 3:
                    message.page = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Pagination message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Pagination
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Pagination} Pagination
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pagination.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Pagination message.
         * @function verify
         * @memberof appliancepb.Pagination
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pagination.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                if (!$util.isInteger(message.itemsPerPage))
                    return "itemsPerPage: integer expected";
            if (message.totalItems != null && message.hasOwnProperty("totalItems"))
                if (!$util.isInteger(message.totalItems))
                    return "totalItems: integer expected";
            if (message.page != null && message.hasOwnProperty("page"))
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            return null;
        };

        /**
         * Creates a Pagination message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Pagination
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Pagination} Pagination
         */
        Pagination.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Pagination)
                return object;
            let message = new $root.appliancepb.Pagination();
            if (object.itemsPerPage != null)
                message.itemsPerPage = object.itemsPerPage >>> 0;
            if (object.totalItems != null)
                message.totalItems = object.totalItems >>> 0;
            if (object.page != null)
                message.page = object.page >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Pagination message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Pagination
         * @static
         * @param {appliancepb.Pagination} message Pagination
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pagination.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.itemsPerPage = 0;
                object.totalItems = 0;
                object.page = 0;
            }
            if (message.itemsPerPage != null && message.hasOwnProperty("itemsPerPage"))
                object.itemsPerPage = message.itemsPerPage;
            if (message.totalItems != null && message.hasOwnProperty("totalItems"))
                object.totalItems = message.totalItems;
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = message.page;
            return object;
        };

        /**
         * Converts this Pagination to JSON.
         * @function toJSON
         * @memberof appliancepb.Pagination
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pagination.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Pagination;
    })();

    appliancepb.ProductsResponse = (function() {

        /**
         * Properties of a ProductsResponse.
         * @memberof appliancepb
         * @interface IProductsResponse
         * @property {Array.<appliancepb.IProduct>|null} [products] ProductsResponse products
         * @property {appliancepb.IPagination|null} [pagination] ProductsResponse pagination
         */

        /**
         * Constructs a new ProductsResponse.
         * @memberof appliancepb
         * @classdesc Represents a ProductsResponse.
         * @implements IProductsResponse
         * @constructor
         * @param {appliancepb.IProductsResponse=} [properties] Properties to set
         */
        function ProductsResponse(properties) {
            this.products = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductsResponse products.
         * @member {Array.<appliancepb.IProduct>} products
         * @memberof appliancepb.ProductsResponse
         * @instance
         */
        ProductsResponse.prototype.products = $util.emptyArray;

        /**
         * ProductsResponse pagination.
         * @member {appliancepb.IPagination|null|undefined} pagination
         * @memberof appliancepb.ProductsResponse
         * @instance
         */
        ProductsResponse.prototype.pagination = null;

        /**
         * Creates a new ProductsResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {appliancepb.IProductsResponse=} [properties] Properties to set
         * @returns {appliancepb.ProductsResponse} ProductsResponse instance
         */
        ProductsResponse.create = function create(properties) {
            return new ProductsResponse(properties);
        };

        /**
         * Encodes the specified ProductsResponse message. Does not implicitly {@link appliancepb.ProductsResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {appliancepb.IProductsResponse} message ProductsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.products != null && message.products.length)
                for (let i = 0; i < message.products.length; ++i)
                    $root.appliancepb.Product.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.appliancepb.Pagination.encode(message.pagination, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProductsResponse message, length delimited. Does not implicitly {@link appliancepb.ProductsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {appliancepb.IProductsResponse} message ProductsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProductsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ProductsResponse} ProductsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ProductsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.appliancepb.Product.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.pagination = $root.appliancepb.Pagination.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProductsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ProductsResponse} ProductsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProductsResponse message.
         * @function verify
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProductsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.products != null && message.hasOwnProperty("products")) {
                if (!Array.isArray(message.products))
                    return "products: array expected";
                for (let i = 0; i < message.products.length; ++i) {
                    let error = $root.appliancepb.Product.verify(message.products[i]);
                    if (error)
                        return "products." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.appliancepb.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            return null;
        };

        /**
         * Creates a ProductsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ProductsResponse} ProductsResponse
         */
        ProductsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ProductsResponse)
                return object;
            let message = new $root.appliancepb.ProductsResponse();
            if (object.products) {
                if (!Array.isArray(object.products))
                    throw TypeError(".appliancepb.ProductsResponse.products: array expected");
                message.products = [];
                for (let i = 0; i < object.products.length; ++i) {
                    if (typeof object.products[i] !== "object")
                        throw TypeError(".appliancepb.ProductsResponse.products: object expected");
                    message.products[i] = $root.appliancepb.Product.fromObject(object.products[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".appliancepb.ProductsResponse.pagination: object expected");
                message.pagination = $root.appliancepb.Pagination.fromObject(object.pagination);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProductsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ProductsResponse
         * @static
         * @param {appliancepb.ProductsResponse} message ProductsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProductsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.products = [];
            if (options.defaults)
                object.pagination = null;
            if (message.products && message.products.length) {
                object.products = [];
                for (let j = 0; j < message.products.length; ++j)
                    object.products[j] = $root.appliancepb.Product.toObject(message.products[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.appliancepb.Pagination.toObject(message.pagination, options);
            return object;
        };

        /**
         * Converts this ProductsResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.ProductsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProductsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProductsResponse;
    })();

    appliancepb.FilterRange = (function() {

        /**
         * Properties of a FilterRange.
         * @memberof appliancepb
         * @interface IFilterRange
         * @property {string|null} [label] FilterRange label
         * @property {number|Long|null} [min] FilterRange min
         * @property {number|Long|null} [max] FilterRange max
         */

        /**
         * Constructs a new FilterRange.
         * @memberof appliancepb
         * @classdesc Represents a FilterRange.
         * @implements IFilterRange
         * @constructor
         * @param {appliancepb.IFilterRange=} [properties] Properties to set
         */
        function FilterRange(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FilterRange label.
         * @member {string} label
         * @memberof appliancepb.FilterRange
         * @instance
         */
        FilterRange.prototype.label = "";

        /**
         * FilterRange min.
         * @member {number|Long} min
         * @memberof appliancepb.FilterRange
         * @instance
         */
        FilterRange.prototype.min = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * FilterRange max.
         * @member {number|Long} max
         * @memberof appliancepb.FilterRange
         * @instance
         */
        FilterRange.prototype.max = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new FilterRange instance using the specified properties.
         * @function create
         * @memberof appliancepb.FilterRange
         * @static
         * @param {appliancepb.IFilterRange=} [properties] Properties to set
         * @returns {appliancepb.FilterRange} FilterRange instance
         */
        FilterRange.create = function create(properties) {
            return new FilterRange(properties);
        };

        /**
         * Encodes the specified FilterRange message. Does not implicitly {@link appliancepb.FilterRange.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.FilterRange
         * @static
         * @param {appliancepb.IFilterRange} message FilterRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FilterRange.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.min);
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.max);
            return writer;
        };

        /**
         * Encodes the specified FilterRange message, length delimited. Does not implicitly {@link appliancepb.FilterRange.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.FilterRange
         * @static
         * @param {appliancepb.IFilterRange} message FilterRange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FilterRange.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FilterRange message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.FilterRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.FilterRange} FilterRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FilterRange.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.FilterRange();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.min = reader.uint64();
                    break;
                case 3:
                    message.max = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FilterRange message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.FilterRange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.FilterRange} FilterRange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FilterRange.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FilterRange message.
         * @function verify
         * @memberof appliancepb.FilterRange
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FilterRange.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.min != null && message.hasOwnProperty("min"))
                if (!$util.isInteger(message.min) && !(message.min && $util.isInteger(message.min.low) && $util.isInteger(message.min.high)))
                    return "min: integer|Long expected";
            if (message.max != null && message.hasOwnProperty("max"))
                if (!$util.isInteger(message.max) && !(message.max && $util.isInteger(message.max.low) && $util.isInteger(message.max.high)))
                    return "max: integer|Long expected";
            return null;
        };

        /**
         * Creates a FilterRange message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.FilterRange
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.FilterRange} FilterRange
         */
        FilterRange.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.FilterRange)
                return object;
            let message = new $root.appliancepb.FilterRange();
            if (object.label != null)
                message.label = String(object.label);
            if (object.min != null)
                if ($util.Long)
                    (message.min = $util.Long.fromValue(object.min)).unsigned = true;
                else if (typeof object.min === "string")
                    message.min = parseInt(object.min, 10);
                else if (typeof object.min === "number")
                    message.min = object.min;
                else if (typeof object.min === "object")
                    message.min = new $util.LongBits(object.min.low >>> 0, object.min.high >>> 0).toNumber(true);
            if (object.max != null)
                if ($util.Long)
                    (message.max = $util.Long.fromValue(object.max)).unsigned = true;
                else if (typeof object.max === "string")
                    message.max = parseInt(object.max, 10);
                else if (typeof object.max === "number")
                    message.max = object.max;
                else if (typeof object.max === "object")
                    message.max = new $util.LongBits(object.max.low >>> 0, object.max.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a FilterRange message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.FilterRange
         * @static
         * @param {appliancepb.FilterRange} message FilterRange
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FilterRange.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.label = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.min = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.min = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.max = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.max = options.longs === String ? "0" : 0;
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.min != null && message.hasOwnProperty("min"))
                if (typeof message.min === "number")
                    object.min = options.longs === String ? String(message.min) : message.min;
                else
                    object.min = options.longs === String ? $util.Long.prototype.toString.call(message.min) : options.longs === Number ? new $util.LongBits(message.min.low >>> 0, message.min.high >>> 0).toNumber(true) : message.min;
            if (message.max != null && message.hasOwnProperty("max"))
                if (typeof message.max === "number")
                    object.max = options.longs === String ? String(message.max) : message.max;
                else
                    object.max = options.longs === String ? $util.Long.prototype.toString.call(message.max) : options.longs === Number ? new $util.LongBits(message.max.low >>> 0, message.max.high >>> 0).toNumber(true) : message.max;
            return object;
        };

        /**
         * Converts this FilterRange to JSON.
         * @function toJSON
         * @memberof appliancepb.FilterRange
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FilterRange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FilterRange;
    })();

    appliancepb.ValueApplianceFilter = (function() {

        /**
         * Properties of a ValueApplianceFilter.
         * @memberof appliancepb
         * @interface IValueApplianceFilter
         * @property {string|null} [name] ValueApplianceFilter name
         * @property {Array.<string>|null} [values] ValueApplianceFilter values
         */

        /**
         * Constructs a new ValueApplianceFilter.
         * @memberof appliancepb
         * @classdesc Represents a ValueApplianceFilter.
         * @implements IValueApplianceFilter
         * @constructor
         * @param {appliancepb.IValueApplianceFilter=} [properties] Properties to set
         */
        function ValueApplianceFilter(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValueApplianceFilter name.
         * @member {string} name
         * @memberof appliancepb.ValueApplianceFilter
         * @instance
         */
        ValueApplianceFilter.prototype.name = "";

        /**
         * ValueApplianceFilter values.
         * @member {Array.<string>} values
         * @memberof appliancepb.ValueApplianceFilter
         * @instance
         */
        ValueApplianceFilter.prototype.values = $util.emptyArray;

        /**
         * Creates a new ValueApplianceFilter instance using the specified properties.
         * @function create
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {appliancepb.IValueApplianceFilter=} [properties] Properties to set
         * @returns {appliancepb.ValueApplianceFilter} ValueApplianceFilter instance
         */
        ValueApplianceFilter.create = function create(properties) {
            return new ValueApplianceFilter(properties);
        };

        /**
         * Encodes the specified ValueApplianceFilter message. Does not implicitly {@link appliancepb.ValueApplianceFilter.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {appliancepb.IValueApplianceFilter} message ValueApplianceFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValueApplianceFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.values != null && message.values.length)
                for (let i = 0; i < message.values.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
            return writer;
        };

        /**
         * Encodes the specified ValueApplianceFilter message, length delimited. Does not implicitly {@link appliancepb.ValueApplianceFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {appliancepb.IValueApplianceFilter} message ValueApplianceFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValueApplianceFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValueApplianceFilter message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ValueApplianceFilter} ValueApplianceFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValueApplianceFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ValueApplianceFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValueApplianceFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ValueApplianceFilter} ValueApplianceFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValueApplianceFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValueApplianceFilter message.
         * @function verify
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValueApplianceFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i)
                    if (!$util.isString(message.values[i]))
                        return "values: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ValueApplianceFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ValueApplianceFilter} ValueApplianceFilter
         */
        ValueApplianceFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ValueApplianceFilter)
                return object;
            let message = new $root.appliancepb.ValueApplianceFilter();
            if (object.name != null)
                message.name = String(object.name);
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".appliancepb.ValueApplianceFilter.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i)
                    message.values[i] = String(object.values[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ValueApplianceFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ValueApplianceFilter
         * @static
         * @param {appliancepb.ValueApplianceFilter} message ValueApplianceFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValueApplianceFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = message.values[j];
            }
            return object;
        };

        /**
         * Converts this ValueApplianceFilter to JSON.
         * @function toJSON
         * @memberof appliancepb.ValueApplianceFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValueApplianceFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ValueApplianceFilter;
    })();

    appliancepb.RangeApplianceFilter = (function() {

        /**
         * Properties of a RangeApplianceFilter.
         * @memberof appliancepb
         * @interface IRangeApplianceFilter
         * @property {string|null} [name] RangeApplianceFilter name
         * @property {Array.<appliancepb.IFilterRange>|null} [values] RangeApplianceFilter values
         */

        /**
         * Constructs a new RangeApplianceFilter.
         * @memberof appliancepb
         * @classdesc Represents a RangeApplianceFilter.
         * @implements IRangeApplianceFilter
         * @constructor
         * @param {appliancepb.IRangeApplianceFilter=} [properties] Properties to set
         */
        function RangeApplianceFilter(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RangeApplianceFilter name.
         * @member {string} name
         * @memberof appliancepb.RangeApplianceFilter
         * @instance
         */
        RangeApplianceFilter.prototype.name = "";

        /**
         * RangeApplianceFilter values.
         * @member {Array.<appliancepb.IFilterRange>} values
         * @memberof appliancepb.RangeApplianceFilter
         * @instance
         */
        RangeApplianceFilter.prototype.values = $util.emptyArray;

        /**
         * Creates a new RangeApplianceFilter instance using the specified properties.
         * @function create
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {appliancepb.IRangeApplianceFilter=} [properties] Properties to set
         * @returns {appliancepb.RangeApplianceFilter} RangeApplianceFilter instance
         */
        RangeApplianceFilter.create = function create(properties) {
            return new RangeApplianceFilter(properties);
        };

        /**
         * Encodes the specified RangeApplianceFilter message. Does not implicitly {@link appliancepb.RangeApplianceFilter.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {appliancepb.IRangeApplianceFilter} message RangeApplianceFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeApplianceFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.values != null && message.values.length)
                for (let i = 0; i < message.values.length; ++i)
                    $root.appliancepb.FilterRange.encode(message.values[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RangeApplianceFilter message, length delimited. Does not implicitly {@link appliancepb.RangeApplianceFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {appliancepb.IRangeApplianceFilter} message RangeApplianceFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RangeApplianceFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RangeApplianceFilter message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.RangeApplianceFilter} RangeApplianceFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeApplianceFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.RangeApplianceFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push($root.appliancepb.FilterRange.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RangeApplianceFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.RangeApplianceFilter} RangeApplianceFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RangeApplianceFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RangeApplianceFilter message.
         * @function verify
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RangeApplianceFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i) {
                    let error = $root.appliancepb.FilterRange.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RangeApplianceFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.RangeApplianceFilter} RangeApplianceFilter
         */
        RangeApplianceFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.RangeApplianceFilter)
                return object;
            let message = new $root.appliancepb.RangeApplianceFilter();
            if (object.name != null)
                message.name = String(object.name);
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".appliancepb.RangeApplianceFilter.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".appliancepb.RangeApplianceFilter.values: object expected");
                    message.values[i] = $root.appliancepb.FilterRange.fromObject(object.values[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RangeApplianceFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.RangeApplianceFilter
         * @static
         * @param {appliancepb.RangeApplianceFilter} message RangeApplianceFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RangeApplianceFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.appliancepb.FilterRange.toObject(message.values[j], options);
            }
            return object;
        };

        /**
         * Converts this RangeApplianceFilter to JSON.
         * @function toJSON
         * @memberof appliancepb.RangeApplianceFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RangeApplianceFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RangeApplianceFilter;
    })();

    appliancepb.ApplianceFilter = (function() {

        /**
         * Properties of an ApplianceFilter.
         * @memberof appliancepb
         * @interface IApplianceFilter
         * @property {appliancepb.IValueApplianceFilter|null} [enumFilter] ApplianceFilter enumFilter
         * @property {appliancepb.IRangeApplianceFilter|null} [rangeFilter] ApplianceFilter rangeFilter
         */

        /**
         * Constructs a new ApplianceFilter.
         * @memberof appliancepb
         * @classdesc Represents an ApplianceFilter.
         * @implements IApplianceFilter
         * @constructor
         * @param {appliancepb.IApplianceFilter=} [properties] Properties to set
         */
        function ApplianceFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApplianceFilter enumFilter.
         * @member {appliancepb.IValueApplianceFilter|null|undefined} enumFilter
         * @memberof appliancepb.ApplianceFilter
         * @instance
         */
        ApplianceFilter.prototype.enumFilter = null;

        /**
         * ApplianceFilter rangeFilter.
         * @member {appliancepb.IRangeApplianceFilter|null|undefined} rangeFilter
         * @memberof appliancepb.ApplianceFilter
         * @instance
         */
        ApplianceFilter.prototype.rangeFilter = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ApplianceFilter filter.
         * @member {"enumFilter"|"rangeFilter"|undefined} filter
         * @memberof appliancepb.ApplianceFilter
         * @instance
         */
        Object.defineProperty(ApplianceFilter.prototype, "filter", {
            get: $util.oneOfGetter($oneOfFields = ["enumFilter", "rangeFilter"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ApplianceFilter instance using the specified properties.
         * @function create
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {appliancepb.IApplianceFilter=} [properties] Properties to set
         * @returns {appliancepb.ApplianceFilter} ApplianceFilter instance
         */
        ApplianceFilter.create = function create(properties) {
            return new ApplianceFilter(properties);
        };

        /**
         * Encodes the specified ApplianceFilter message. Does not implicitly {@link appliancepb.ApplianceFilter.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {appliancepb.IApplianceFilter} message ApplianceFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplianceFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.enumFilter != null && Object.hasOwnProperty.call(message, "enumFilter"))
                $root.appliancepb.ValueApplianceFilter.encode(message.enumFilter, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rangeFilter != null && Object.hasOwnProperty.call(message, "rangeFilter"))
                $root.appliancepb.RangeApplianceFilter.encode(message.rangeFilter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApplianceFilter message, length delimited. Does not implicitly {@link appliancepb.ApplianceFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {appliancepb.IApplianceFilter} message ApplianceFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplianceFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApplianceFilter message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ApplianceFilter} ApplianceFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplianceFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ApplianceFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.enumFilter = $root.appliancepb.ValueApplianceFilter.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.rangeFilter = $root.appliancepb.RangeApplianceFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApplianceFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ApplianceFilter} ApplianceFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplianceFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApplianceFilter message.
         * @function verify
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApplianceFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.enumFilter != null && message.hasOwnProperty("enumFilter")) {
                properties.filter = 1;
                {
                    let error = $root.appliancepb.ValueApplianceFilter.verify(message.enumFilter);
                    if (error)
                        return "enumFilter." + error;
                }
            }
            if (message.rangeFilter != null && message.hasOwnProperty("rangeFilter")) {
                if (properties.filter === 1)
                    return "filter: multiple values";
                properties.filter = 1;
                {
                    let error = $root.appliancepb.RangeApplianceFilter.verify(message.rangeFilter);
                    if (error)
                        return "rangeFilter." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApplianceFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ApplianceFilter} ApplianceFilter
         */
        ApplianceFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ApplianceFilter)
                return object;
            let message = new $root.appliancepb.ApplianceFilter();
            if (object.enumFilter != null) {
                if (typeof object.enumFilter !== "object")
                    throw TypeError(".appliancepb.ApplianceFilter.enumFilter: object expected");
                message.enumFilter = $root.appliancepb.ValueApplianceFilter.fromObject(object.enumFilter);
            }
            if (object.rangeFilter != null) {
                if (typeof object.rangeFilter !== "object")
                    throw TypeError(".appliancepb.ApplianceFilter.rangeFilter: object expected");
                message.rangeFilter = $root.appliancepb.RangeApplianceFilter.fromObject(object.rangeFilter);
            }
            return message;
        };

        /**
         * Creates a plain object from an ApplianceFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ApplianceFilter
         * @static
         * @param {appliancepb.ApplianceFilter} message ApplianceFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApplianceFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.enumFilter != null && message.hasOwnProperty("enumFilter")) {
                object.enumFilter = $root.appliancepb.ValueApplianceFilter.toObject(message.enumFilter, options);
                if (options.oneofs)
                    object.filter = "enumFilter";
            }
            if (message.rangeFilter != null && message.hasOwnProperty("rangeFilter")) {
                object.rangeFilter = $root.appliancepb.RangeApplianceFilter.toObject(message.rangeFilter, options);
                if (options.oneofs)
                    object.filter = "rangeFilter";
            }
            return object;
        };

        /**
         * Converts this ApplianceFilter to JSON.
         * @function toJSON
         * @memberof appliancepb.ApplianceFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApplianceFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ApplianceFilter;
    })();

    appliancepb.ApplianceFiltersResponse = (function() {

        /**
         * Properties of an ApplianceFiltersResponse.
         * @memberof appliancepb
         * @interface IApplianceFiltersResponse
         * @property {string|null} [applianceType] ApplianceFiltersResponse applianceType
         * @property {Array.<appliancepb.IApplianceFilter>|null} [filters] ApplianceFiltersResponse filters
         */

        /**
         * Constructs a new ApplianceFiltersResponse.
         * @memberof appliancepb
         * @classdesc Represents an ApplianceFiltersResponse.
         * @implements IApplianceFiltersResponse
         * @constructor
         * @param {appliancepb.IApplianceFiltersResponse=} [properties] Properties to set
         */
        function ApplianceFiltersResponse(properties) {
            this.filters = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApplianceFiltersResponse applianceType.
         * @member {string} applianceType
         * @memberof appliancepb.ApplianceFiltersResponse
         * @instance
         */
        ApplianceFiltersResponse.prototype.applianceType = "";

        /**
         * ApplianceFiltersResponse filters.
         * @member {Array.<appliancepb.IApplianceFilter>} filters
         * @memberof appliancepb.ApplianceFiltersResponse
         * @instance
         */
        ApplianceFiltersResponse.prototype.filters = $util.emptyArray;

        /**
         * Creates a new ApplianceFiltersResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {appliancepb.IApplianceFiltersResponse=} [properties] Properties to set
         * @returns {appliancepb.ApplianceFiltersResponse} ApplianceFiltersResponse instance
         */
        ApplianceFiltersResponse.create = function create(properties) {
            return new ApplianceFiltersResponse(properties);
        };

        /**
         * Encodes the specified ApplianceFiltersResponse message. Does not implicitly {@link appliancepb.ApplianceFiltersResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {appliancepb.IApplianceFiltersResponse} message ApplianceFiltersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplianceFiltersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.applianceType != null && Object.hasOwnProperty.call(message, "applianceType"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.applianceType);
            if (message.filters != null && message.filters.length)
                for (let i = 0; i < message.filters.length; ++i)
                    $root.appliancepb.ApplianceFilter.encode(message.filters[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApplianceFiltersResponse message, length delimited. Does not implicitly {@link appliancepb.ApplianceFiltersResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {appliancepb.IApplianceFiltersResponse} message ApplianceFiltersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplianceFiltersResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApplianceFiltersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ApplianceFiltersResponse} ApplianceFiltersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplianceFiltersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ApplianceFiltersResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.applianceType = reader.string();
                    break;
                case 2:
                    if (!(message.filters && message.filters.length))
                        message.filters = [];
                    message.filters.push($root.appliancepb.ApplianceFilter.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApplianceFiltersResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ApplianceFiltersResponse} ApplianceFiltersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplianceFiltersResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApplianceFiltersResponse message.
         * @function verify
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApplianceFiltersResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.applianceType != null && message.hasOwnProperty("applianceType"))
                if (!$util.isString(message.applianceType))
                    return "applianceType: string expected";
            if (message.filters != null && message.hasOwnProperty("filters")) {
                if (!Array.isArray(message.filters))
                    return "filters: array expected";
                for (let i = 0; i < message.filters.length; ++i) {
                    let error = $root.appliancepb.ApplianceFilter.verify(message.filters[i]);
                    if (error)
                        return "filters." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApplianceFiltersResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ApplianceFiltersResponse} ApplianceFiltersResponse
         */
        ApplianceFiltersResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ApplianceFiltersResponse)
                return object;
            let message = new $root.appliancepb.ApplianceFiltersResponse();
            if (object.applianceType != null)
                message.applianceType = String(object.applianceType);
            if (object.filters) {
                if (!Array.isArray(object.filters))
                    throw TypeError(".appliancepb.ApplianceFiltersResponse.filters: array expected");
                message.filters = [];
                for (let i = 0; i < object.filters.length; ++i) {
                    if (typeof object.filters[i] !== "object")
                        throw TypeError(".appliancepb.ApplianceFiltersResponse.filters: object expected");
                    message.filters[i] = $root.appliancepb.ApplianceFilter.fromObject(object.filters[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApplianceFiltersResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ApplianceFiltersResponse
         * @static
         * @param {appliancepb.ApplianceFiltersResponse} message ApplianceFiltersResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApplianceFiltersResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.filters = [];
            if (options.defaults)
                object.applianceType = "";
            if (message.applianceType != null && message.hasOwnProperty("applianceType"))
                object.applianceType = message.applianceType;
            if (message.filters && message.filters.length) {
                object.filters = [];
                for (let j = 0; j < message.filters.length; ++j)
                    object.filters[j] = $root.appliancepb.ApplianceFilter.toObject(message.filters[j], options);
            }
            return object;
        };

        /**
         * Converts this ApplianceFiltersResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.ApplianceFiltersResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApplianceFiltersResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ApplianceFiltersResponse;
    })();

    appliancepb.ConfirmationResponse = (function() {

        /**
         * Properties of a ConfirmationResponse.
         * @memberof appliancepb
         * @interface IConfirmationResponse
         * @property {number|null} [basePrice] ConfirmationResponse basePrice
         * @property {number|null} [moveApplianceFee] ConfirmationResponse moveApplianceFee
         * @property {number|null} [disconnectFee] ConfirmationResponse disconnectFee
         * @property {number|null} [installFee] ConfirmationResponse installFee
         * @property {number|null} [deliveryFee] ConfirmationResponse deliveryFee
         * @property {number|null} [disposalFee] ConfirmationResponse disposalFee
         * @property {number|null} [taxes] ConfirmationResponse taxes
         * @property {number|null} [total] ConfirmationResponse total
         * @property {appliancepb.ConfirmationResponse.WarrantyMessage|null} [warrantyMessage] ConfirmationResponse warrantyMessage
         */

        /**
         * Constructs a new ConfirmationResponse.
         * @memberof appliancepb
         * @classdesc Represents a ConfirmationResponse.
         * @implements IConfirmationResponse
         * @constructor
         * @param {appliancepb.IConfirmationResponse=} [properties] Properties to set
         */
        function ConfirmationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfirmationResponse basePrice.
         * @member {number} basePrice
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.basePrice = 0;

        /**
         * ConfirmationResponse moveApplianceFee.
         * @member {number} moveApplianceFee
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.moveApplianceFee = 0;

        /**
         * ConfirmationResponse disconnectFee.
         * @member {number} disconnectFee
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.disconnectFee = 0;

        /**
         * ConfirmationResponse installFee.
         * @member {number} installFee
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.installFee = 0;

        /**
         * ConfirmationResponse deliveryFee.
         * @member {number} deliveryFee
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.deliveryFee = 0;

        /**
         * ConfirmationResponse disposalFee.
         * @member {number} disposalFee
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.disposalFee = 0;

        /**
         * ConfirmationResponse taxes.
         * @member {number} taxes
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.taxes = 0;

        /**
         * ConfirmationResponse total.
         * @member {number} total
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.total = 0;

        /**
         * ConfirmationResponse warrantyMessage.
         * @member {appliancepb.ConfirmationResponse.WarrantyMessage} warrantyMessage
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         */
        ConfirmationResponse.prototype.warrantyMessage = 0;

        /**
         * Creates a new ConfirmationResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {appliancepb.IConfirmationResponse=} [properties] Properties to set
         * @returns {appliancepb.ConfirmationResponse} ConfirmationResponse instance
         */
        ConfirmationResponse.create = function create(properties) {
            return new ConfirmationResponse(properties);
        };

        /**
         * Encodes the specified ConfirmationResponse message. Does not implicitly {@link appliancepb.ConfirmationResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {appliancepb.IConfirmationResponse} message ConfirmationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.basePrice);
            if (message.moveApplianceFee != null && Object.hasOwnProperty.call(message, "moveApplianceFee"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.moveApplianceFee);
            if (message.disconnectFee != null && Object.hasOwnProperty.call(message, "disconnectFee"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.disconnectFee);
            if (message.installFee != null && Object.hasOwnProperty.call(message, "installFee"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.installFee);
            if (message.deliveryFee != null && Object.hasOwnProperty.call(message, "deliveryFee"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.deliveryFee);
            if (message.disposalFee != null && Object.hasOwnProperty.call(message, "disposalFee"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.disposalFee);
            if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.taxes);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.total);
            if (message.warrantyMessage != null && Object.hasOwnProperty.call(message, "warrantyMessage"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.warrantyMessage);
            return writer;
        };

        /**
         * Encodes the specified ConfirmationResponse message, length delimited. Does not implicitly {@link appliancepb.ConfirmationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {appliancepb.IConfirmationResponse} message ConfirmationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfirmationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ConfirmationResponse} ConfirmationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ConfirmationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.basePrice = reader.uint32();
                    break;
                case 2:
                    message.moveApplianceFee = reader.uint32();
                    break;
                case 3:
                    message.disconnectFee = reader.uint32();
                    break;
                case 4:
                    message.installFee = reader.uint32();
                    break;
                case 5:
                    message.deliveryFee = reader.uint32();
                    break;
                case 6:
                    message.disposalFee = reader.uint32();
                    break;
                case 7:
                    message.taxes = reader.uint32();
                    break;
                case 8:
                    message.total = reader.uint32();
                    break;
                case 9:
                    message.warrantyMessage = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfirmationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ConfirmationResponse} ConfirmationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfirmationResponse message.
         * @function verify
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfirmationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                if (!$util.isInteger(message.basePrice))
                    return "basePrice: integer expected";
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                if (!$util.isInteger(message.moveApplianceFee))
                    return "moveApplianceFee: integer expected";
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                if (!$util.isInteger(message.disconnectFee))
                    return "disconnectFee: integer expected";
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                if (!$util.isInteger(message.installFee))
                    return "installFee: integer expected";
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                if (!$util.isInteger(message.deliveryFee))
                    return "deliveryFee: integer expected";
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                if (!$util.isInteger(message.disposalFee))
                    return "disposalFee: integer expected";
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                if (!$util.isInteger(message.taxes))
                    return "taxes: integer expected";
            if (message.total != null && message.hasOwnProperty("total"))
                if (!$util.isInteger(message.total))
                    return "total: integer expected";
            if (message.warrantyMessage != null && message.hasOwnProperty("warrantyMessage"))
                switch (message.warrantyMessage) {
                default:
                    return "warrantyMessage: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a ConfirmationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ConfirmationResponse} ConfirmationResponse
         */
        ConfirmationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ConfirmationResponse)
                return object;
            let message = new $root.appliancepb.ConfirmationResponse();
            if (object.basePrice != null)
                message.basePrice = object.basePrice >>> 0;
            if (object.moveApplianceFee != null)
                message.moveApplianceFee = object.moveApplianceFee >>> 0;
            if (object.disconnectFee != null)
                message.disconnectFee = object.disconnectFee >>> 0;
            if (object.installFee != null)
                message.installFee = object.installFee >>> 0;
            if (object.deliveryFee != null)
                message.deliveryFee = object.deliveryFee >>> 0;
            if (object.disposalFee != null)
                message.disposalFee = object.disposalFee >>> 0;
            if (object.taxes != null)
                message.taxes = object.taxes >>> 0;
            if (object.total != null)
                message.total = object.total >>> 0;
            switch (object.warrantyMessage) {
            case "WARRANTY_MESSAGE_UNSPECIFIED":
            case 0:
                message.warrantyMessage = 0;
                break;
            case "WARRANTY_MESSAGE_COVERED":
            case 1:
                message.warrantyMessage = 1;
                break;
            case "WARRANTY_MESSAGE_INSTALLATION_OVER_3K":
            case 2:
                message.warrantyMessage = 2;
                break;
            case "WARRANTY_MESSAGE_INSTALLATION_PLATINUM_OVER_6K":
            case 3:
                message.warrantyMessage = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfirmationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ConfirmationResponse
         * @static
         * @param {appliancepb.ConfirmationResponse} message ConfirmationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfirmationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.basePrice = 0;
                object.moveApplianceFee = 0;
                object.disconnectFee = 0;
                object.installFee = 0;
                object.deliveryFee = 0;
                object.disposalFee = 0;
                object.taxes = 0;
                object.total = 0;
                object.warrantyMessage = options.enums === String ? "WARRANTY_MESSAGE_UNSPECIFIED" : 0;
            }
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                object.basePrice = message.basePrice;
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                object.moveApplianceFee = message.moveApplianceFee;
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                object.disconnectFee = message.disconnectFee;
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                object.installFee = message.installFee;
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                object.deliveryFee = message.deliveryFee;
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                object.disposalFee = message.disposalFee;
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                object.taxes = message.taxes;
            if (message.total != null && message.hasOwnProperty("total"))
                object.total = message.total;
            if (message.warrantyMessage != null && message.hasOwnProperty("warrantyMessage"))
                object.warrantyMessage = options.enums === String ? $root.appliancepb.ConfirmationResponse.WarrantyMessage[message.warrantyMessage] : message.warrantyMessage;
            return object;
        };

        /**
         * Converts this ConfirmationResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.ConfirmationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfirmationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * WarrantyMessage enum.
         * @name appliancepb.ConfirmationResponse.WarrantyMessage
         * @enum {number}
         * @property {number} WARRANTY_MESSAGE_UNSPECIFIED=0 WARRANTY_MESSAGE_UNSPECIFIED value
         * @property {number} WARRANTY_MESSAGE_COVERED=1 WARRANTY_MESSAGE_COVERED value
         * @property {number} WARRANTY_MESSAGE_INSTALLATION_OVER_3K=2 WARRANTY_MESSAGE_INSTALLATION_OVER_3K value
         * @property {number} WARRANTY_MESSAGE_INSTALLATION_PLATINUM_OVER_6K=3 WARRANTY_MESSAGE_INSTALLATION_PLATINUM_OVER_6K value
         */
        ConfirmationResponse.WarrantyMessage = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WARRANTY_MESSAGE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "WARRANTY_MESSAGE_COVERED"] = 1;
            values[valuesById[2] = "WARRANTY_MESSAGE_INSTALLATION_OVER_3K"] = 2;
            values[valuesById[3] = "WARRANTY_MESSAGE_INSTALLATION_PLATINUM_OVER_6K"] = 3;
            return values;
        })();

        return ConfirmationResponse;
    })();

    appliancepb.HealthCheck = (function() {

        /**
         * Properties of a HealthCheck.
         * @memberof appliancepb
         * @interface IHealthCheck
         * @property {string|null} [status] HealthCheck status
         * @property {string|null} [version] HealthCheck version
         * @property {string|null} [releaseID] HealthCheck releaseID
         * @property {Array.<string>|null} [notes] HealthCheck notes
         * @property {string|null} [output] HealthCheck output
         * @property {Array.<appliancepb.HealthCheck.INamedHealthCheckDetail>|null} [details] HealthCheck details
         */

        /**
         * Constructs a new HealthCheck.
         * @memberof appliancepb
         * @classdesc Represents a HealthCheck.
         * @implements IHealthCheck
         * @constructor
         * @param {appliancepb.IHealthCheck=} [properties] Properties to set
         */
        function HealthCheck(properties) {
            this.notes = [];
            this.details = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HealthCheck status.
         * @member {string} status
         * @memberof appliancepb.HealthCheck
         * @instance
         */
        HealthCheck.prototype.status = "";

        /**
         * HealthCheck version.
         * @member {string} version
         * @memberof appliancepb.HealthCheck
         * @instance
         */
        HealthCheck.prototype.version = "";

        /**
         * HealthCheck releaseID.
         * @member {string} releaseID
         * @memberof appliancepb.HealthCheck
         * @instance
         */
        HealthCheck.prototype.releaseID = "";

        /**
         * HealthCheck notes.
         * @member {Array.<string>} notes
         * @memberof appliancepb.HealthCheck
         * @instance
         */
        HealthCheck.prototype.notes = $util.emptyArray;

        /**
         * HealthCheck output.
         * @member {string} output
         * @memberof appliancepb.HealthCheck
         * @instance
         */
        HealthCheck.prototype.output = "";

        /**
         * HealthCheck details.
         * @member {Array.<appliancepb.HealthCheck.INamedHealthCheckDetail>} details
         * @memberof appliancepb.HealthCheck
         * @instance
         */
        HealthCheck.prototype.details = $util.emptyArray;

        /**
         * Creates a new HealthCheck instance using the specified properties.
         * @function create
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {appliancepb.IHealthCheck=} [properties] Properties to set
         * @returns {appliancepb.HealthCheck} HealthCheck instance
         */
        HealthCheck.create = function create(properties) {
            return new HealthCheck(properties);
        };

        /**
         * Encodes the specified HealthCheck message. Does not implicitly {@link appliancepb.HealthCheck.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {appliancepb.IHealthCheck} message HealthCheck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
            if (message.releaseID != null && Object.hasOwnProperty.call(message, "releaseID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.releaseID);
            if (message.notes != null && message.notes.length)
                for (let i = 0; i < message.notes.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notes[i]);
            if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.output);
            if (message.details != null && message.details.length)
                for (let i = 0; i < message.details.length; ++i)
                    $root.appliancepb.HealthCheck.NamedHealthCheckDetail.encode(message.details[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HealthCheck message, length delimited. Does not implicitly {@link appliancepb.HealthCheck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {appliancepb.IHealthCheck} message HealthCheck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HealthCheck message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.HealthCheck} HealthCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.HealthCheck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.string();
                    break;
                case 2:
                    message.version = reader.string();
                    break;
                case 3:
                    message.releaseID = reader.string();
                    break;
                case 4:
                    if (!(message.notes && message.notes.length))
                        message.notes = [];
                    message.notes.push(reader.string());
                    break;
                case 5:
                    message.output = reader.string();
                    break;
                case 6:
                    if (!(message.details && message.details.length))
                        message.details = [];
                    message.details.push($root.appliancepb.HealthCheck.NamedHealthCheckDetail.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HealthCheck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.HealthCheck} HealthCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HealthCheck message.
         * @function verify
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HealthCheck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.releaseID != null && message.hasOwnProperty("releaseID"))
                if (!$util.isString(message.releaseID))
                    return "releaseID: string expected";
            if (message.notes != null && message.hasOwnProperty("notes")) {
                if (!Array.isArray(message.notes))
                    return "notes: array expected";
                for (let i = 0; i < message.notes.length; ++i)
                    if (!$util.isString(message.notes[i]))
                        return "notes: string[] expected";
            }
            if (message.output != null && message.hasOwnProperty("output"))
                if (!$util.isString(message.output))
                    return "output: string expected";
            if (message.details != null && message.hasOwnProperty("details")) {
                if (!Array.isArray(message.details))
                    return "details: array expected";
                for (let i = 0; i < message.details.length; ++i) {
                    let error = $root.appliancepb.HealthCheck.NamedHealthCheckDetail.verify(message.details[i]);
                    if (error)
                        return "details." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HealthCheck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.HealthCheck} HealthCheck
         */
        HealthCheck.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.HealthCheck)
                return object;
            let message = new $root.appliancepb.HealthCheck();
            if (object.status != null)
                message.status = String(object.status);
            if (object.version != null)
                message.version = String(object.version);
            if (object.releaseID != null)
                message.releaseID = String(object.releaseID);
            if (object.notes) {
                if (!Array.isArray(object.notes))
                    throw TypeError(".appliancepb.HealthCheck.notes: array expected");
                message.notes = [];
                for (let i = 0; i < object.notes.length; ++i)
                    message.notes[i] = String(object.notes[i]);
            }
            if (object.output != null)
                message.output = String(object.output);
            if (object.details) {
                if (!Array.isArray(object.details))
                    throw TypeError(".appliancepb.HealthCheck.details: array expected");
                message.details = [];
                for (let i = 0; i < object.details.length; ++i) {
                    if (typeof object.details[i] !== "object")
                        throw TypeError(".appliancepb.HealthCheck.details: object expected");
                    message.details[i] = $root.appliancepb.HealthCheck.NamedHealthCheckDetail.fromObject(object.details[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HealthCheck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.HealthCheck
         * @static
         * @param {appliancepb.HealthCheck} message HealthCheck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HealthCheck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.notes = [];
                object.details = [];
            }
            if (options.defaults) {
                object.status = "";
                object.version = "";
                object.releaseID = "";
                object.output = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.releaseID != null && message.hasOwnProperty("releaseID"))
                object.releaseID = message.releaseID;
            if (message.notes && message.notes.length) {
                object.notes = [];
                for (let j = 0; j < message.notes.length; ++j)
                    object.notes[j] = message.notes[j];
            }
            if (message.output != null && message.hasOwnProperty("output"))
                object.output = message.output;
            if (message.details && message.details.length) {
                object.details = [];
                for (let j = 0; j < message.details.length; ++j)
                    object.details[j] = $root.appliancepb.HealthCheck.NamedHealthCheckDetail.toObject(message.details[j], options);
            }
            return object;
        };

        /**
         * Converts this HealthCheck to JSON.
         * @function toJSON
         * @memberof appliancepb.HealthCheck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HealthCheck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        HealthCheck.NamedHealthCheckDetail = (function() {

            /**
             * Properties of a NamedHealthCheckDetail.
             * @memberof appliancepb.HealthCheck
             * @interface INamedHealthCheckDetail
             * @property {string|null} [name] NamedHealthCheckDetail name
             * @property {Array.<appliancepb.HealthCheck.NamedHealthCheckDetail.IHealthCheckDetail>|null} [details] NamedHealthCheckDetail details
             */

            /**
             * Constructs a new NamedHealthCheckDetail.
             * @memberof appliancepb.HealthCheck
             * @classdesc Represents a NamedHealthCheckDetail.
             * @implements INamedHealthCheckDetail
             * @constructor
             * @param {appliancepb.HealthCheck.INamedHealthCheckDetail=} [properties] Properties to set
             */
            function NamedHealthCheckDetail(properties) {
                this.details = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NamedHealthCheckDetail name.
             * @member {string} name
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @instance
             */
            NamedHealthCheckDetail.prototype.name = "";

            /**
             * NamedHealthCheckDetail details.
             * @member {Array.<appliancepb.HealthCheck.NamedHealthCheckDetail.IHealthCheckDetail>} details
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @instance
             */
            NamedHealthCheckDetail.prototype.details = $util.emptyArray;

            /**
             * Creates a new NamedHealthCheckDetail instance using the specified properties.
             * @function create
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {appliancepb.HealthCheck.INamedHealthCheckDetail=} [properties] Properties to set
             * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail} NamedHealthCheckDetail instance
             */
            NamedHealthCheckDetail.create = function create(properties) {
                return new NamedHealthCheckDetail(properties);
            };

            /**
             * Encodes the specified NamedHealthCheckDetail message. Does not implicitly {@link appliancepb.HealthCheck.NamedHealthCheckDetail.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {appliancepb.HealthCheck.INamedHealthCheckDetail} message NamedHealthCheckDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NamedHealthCheckDetail.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.details != null && message.details.length)
                    for (let i = 0; i < message.details.length; ++i)
                        $root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.encode(message.details[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified NamedHealthCheckDetail message, length delimited. Does not implicitly {@link appliancepb.HealthCheck.NamedHealthCheckDetail.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {appliancepb.HealthCheck.INamedHealthCheckDetail} message NamedHealthCheckDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NamedHealthCheckDetail.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NamedHealthCheckDetail message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail} NamedHealthCheckDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NamedHealthCheckDetail.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.HealthCheck.NamedHealthCheckDetail();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.details && message.details.length))
                            message.details = [];
                        message.details.push($root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NamedHealthCheckDetail message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail} NamedHealthCheckDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NamedHealthCheckDetail.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NamedHealthCheckDetail message.
             * @function verify
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NamedHealthCheckDetail.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.details != null && message.hasOwnProperty("details")) {
                    if (!Array.isArray(message.details))
                        return "details: array expected";
                    for (let i = 0; i < message.details.length; ++i) {
                        let error = $root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.verify(message.details[i]);
                        if (error)
                            return "details." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a NamedHealthCheckDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail} NamedHealthCheckDetail
             */
            NamedHealthCheckDetail.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.HealthCheck.NamedHealthCheckDetail)
                    return object;
                let message = new $root.appliancepb.HealthCheck.NamedHealthCheckDetail();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.details) {
                    if (!Array.isArray(object.details))
                        throw TypeError(".appliancepb.HealthCheck.NamedHealthCheckDetail.details: array expected");
                    message.details = [];
                    for (let i = 0; i < object.details.length; ++i) {
                        if (typeof object.details[i] !== "object")
                            throw TypeError(".appliancepb.HealthCheck.NamedHealthCheckDetail.details: object expected");
                        message.details[i] = $root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.fromObject(object.details[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a NamedHealthCheckDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @static
             * @param {appliancepb.HealthCheck.NamedHealthCheckDetail} message NamedHealthCheckDetail
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NamedHealthCheckDetail.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.details = [];
                if (options.defaults)
                    object.name = "";
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.details && message.details.length) {
                    object.details = [];
                    for (let j = 0; j < message.details.length; ++j)
                        object.details[j] = $root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.toObject(message.details[j], options);
                }
                return object;
            };

            /**
             * Converts this NamedHealthCheckDetail to JSON.
             * @function toJSON
             * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NamedHealthCheckDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            NamedHealthCheckDetail.HealthCheckDetail = (function() {

                /**
                 * Properties of a HealthCheckDetail.
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
                 * @interface IHealthCheckDetail
                 * @property {string|null} [componentID] HealthCheckDetail componentID
                 * @property {string|null} [type] HealthCheckDetail type
                 * @property {number|null} [observedValue] HealthCheckDetail observedValue
                 * @property {string|null} [observedUnit] HealthCheckDetail observedUnit
                 * @property {string|null} [status] HealthCheckDetail status
                 * @property {google.protobuf.ITimestamp|null} [time] HealthCheckDetail time
                 * @property {string|null} [output] HealthCheckDetail output
                 */

                /**
                 * Constructs a new HealthCheckDetail.
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail
                 * @classdesc Represents a HealthCheckDetail.
                 * @implements IHealthCheckDetail
                 * @constructor
                 * @param {appliancepb.HealthCheck.NamedHealthCheckDetail.IHealthCheckDetail=} [properties] Properties to set
                 */
                function HealthCheckDetail(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * HealthCheckDetail componentID.
                 * @member {string} componentID
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 */
                HealthCheckDetail.prototype.componentID = "";

                /**
                 * HealthCheckDetail type.
                 * @member {string} type
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 */
                HealthCheckDetail.prototype.type = "";

                /**
                 * HealthCheckDetail observedValue.
                 * @member {number} observedValue
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 */
                HealthCheckDetail.prototype.observedValue = 0;

                /**
                 * HealthCheckDetail observedUnit.
                 * @member {string} observedUnit
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 */
                HealthCheckDetail.prototype.observedUnit = "";

                /**
                 * HealthCheckDetail status.
                 * @member {string} status
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 */
                HealthCheckDetail.prototype.status = "";

                /**
                 * HealthCheckDetail time.
                 * @member {google.protobuf.ITimestamp|null|undefined} time
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 */
                HealthCheckDetail.prototype.time = null;

                /**
                 * HealthCheckDetail output.
                 * @member {string} output
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 */
                HealthCheckDetail.prototype.output = "";

                /**
                 * Creates a new HealthCheckDetail instance using the specified properties.
                 * @function create
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {appliancepb.HealthCheck.NamedHealthCheckDetail.IHealthCheckDetail=} [properties] Properties to set
                 * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail} HealthCheckDetail instance
                 */
                HealthCheckDetail.create = function create(properties) {
                    return new HealthCheckDetail(properties);
                };

                /**
                 * Encodes the specified HealthCheckDetail message. Does not implicitly {@link appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.verify|verify} messages.
                 * @function encode
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {appliancepb.HealthCheck.NamedHealthCheckDetail.IHealthCheckDetail} message HealthCheckDetail message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HealthCheckDetail.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.componentID != null && Object.hasOwnProperty.call(message, "componentID"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.componentID);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
                    if (message.observedValue != null && Object.hasOwnProperty.call(message, "observedValue"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.observedValue);
                    if (message.observedUnit != null && Object.hasOwnProperty.call(message, "observedUnit"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.observedUnit);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.status);
                    if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                        $root.google.protobuf.Timestamp.encode(message.time, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.output);
                    return writer;
                };

                /**
                 * Encodes the specified HealthCheckDetail message, length delimited. Does not implicitly {@link appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {appliancepb.HealthCheck.NamedHealthCheckDetail.IHealthCheckDetail} message HealthCheckDetail message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                HealthCheckDetail.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a HealthCheckDetail message from the specified reader or buffer.
                 * @function decode
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail} HealthCheckDetail
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HealthCheckDetail.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.componentID = reader.string();
                            break;
                        case 2:
                            message.type = reader.string();
                            break;
                        case 3:
                            message.observedValue = reader.double();
                            break;
                        case 4:
                            message.observedUnit = reader.string();
                            break;
                        case 5:
                            message.status = reader.string();
                            break;
                        case 6:
                            message.time = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.output = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a HealthCheckDetail message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail} HealthCheckDetail
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                HealthCheckDetail.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a HealthCheckDetail message.
                 * @function verify
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                HealthCheckDetail.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.componentID != null && message.hasOwnProperty("componentID"))
                        if (!$util.isString(message.componentID))
                            return "componentID: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.observedValue != null && message.hasOwnProperty("observedValue"))
                        if (typeof message.observedValue !== "number")
                            return "observedValue: number expected";
                    if (message.observedUnit != null && message.hasOwnProperty("observedUnit"))
                        if (!$util.isString(message.observedUnit))
                            return "observedUnit: string expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        if (!$util.isString(message.status))
                            return "status: string expected";
                    if (message.time != null && message.hasOwnProperty("time")) {
                        let error = $root.google.protobuf.Timestamp.verify(message.time);
                        if (error)
                            return "time." + error;
                    }
                    if (message.output != null && message.hasOwnProperty("output"))
                        if (!$util.isString(message.output))
                            return "output: string expected";
                    return null;
                };

                /**
                 * Creates a HealthCheckDetail message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail} HealthCheckDetail
                 */
                HealthCheckDetail.fromObject = function fromObject(object) {
                    if (object instanceof $root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail)
                        return object;
                    let message = new $root.appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail();
                    if (object.componentID != null)
                        message.componentID = String(object.componentID);
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.observedValue != null)
                        message.observedValue = Number(object.observedValue);
                    if (object.observedUnit != null)
                        message.observedUnit = String(object.observedUnit);
                    if (object.status != null)
                        message.status = String(object.status);
                    if (object.time != null) {
                        if (typeof object.time !== "object")
                            throw TypeError(".appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail.time: object expected");
                        message.time = $root.google.protobuf.Timestamp.fromObject(object.time);
                    }
                    if (object.output != null)
                        message.output = String(object.output);
                    return message;
                };

                /**
                 * Creates a plain object from a HealthCheckDetail message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @static
                 * @param {appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail} message HealthCheckDetail
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                HealthCheckDetail.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.componentID = "";
                        object.type = "";
                        object.observedValue = 0;
                        object.observedUnit = "";
                        object.status = "";
                        object.time = null;
                        object.output = "";
                    }
                    if (message.componentID != null && message.hasOwnProperty("componentID"))
                        object.componentID = message.componentID;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.observedValue != null && message.hasOwnProperty("observedValue"))
                        object.observedValue = options.json && !isFinite(message.observedValue) ? String(message.observedValue) : message.observedValue;
                    if (message.observedUnit != null && message.hasOwnProperty("observedUnit"))
                        object.observedUnit = message.observedUnit;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = message.status;
                    if (message.time != null && message.hasOwnProperty("time"))
                        object.time = $root.google.protobuf.Timestamp.toObject(message.time, options);
                    if (message.output != null && message.hasOwnProperty("output"))
                        object.output = message.output;
                    return object;
                };

                /**
                 * Converts this HealthCheckDetail to JSON.
                 * @function toJSON
                 * @memberof appliancepb.HealthCheck.NamedHealthCheckDetail.HealthCheckDetail
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                HealthCheckDetail.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return HealthCheckDetail;
            })();

            return NamedHealthCheckDetail;
        })();

        return HealthCheck;
    })();

    appliancepb.DeliveryInstructionsRequest = (function() {

        /**
         * Properties of a DeliveryInstructionsRequest.
         * @memberof appliancepb
         * @interface IDeliveryInstructionsRequest
         * @property {string|null} [deliveryInstructions] DeliveryInstructionsRequest deliveryInstructions
         * @property {boolean|null} [confirmedDimensions] DeliveryInstructionsRequest confirmedDimensions
         * @property {boolean|null} [agreesToTermsAndConditions] DeliveryInstructionsRequest agreesToTermsAndConditions
         */

        /**
         * Constructs a new DeliveryInstructionsRequest.
         * @memberof appliancepb
         * @classdesc Represents a DeliveryInstructionsRequest.
         * @implements IDeliveryInstructionsRequest
         * @constructor
         * @param {appliancepb.IDeliveryInstructionsRequest=} [properties] Properties to set
         */
        function DeliveryInstructionsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeliveryInstructionsRequest deliveryInstructions.
         * @member {string} deliveryInstructions
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @instance
         */
        DeliveryInstructionsRequest.prototype.deliveryInstructions = "";

        /**
         * DeliveryInstructionsRequest confirmedDimensions.
         * @member {boolean} confirmedDimensions
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @instance
         */
        DeliveryInstructionsRequest.prototype.confirmedDimensions = false;

        /**
         * DeliveryInstructionsRequest agreesToTermsAndConditions.
         * @member {boolean} agreesToTermsAndConditions
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @instance
         */
        DeliveryInstructionsRequest.prototype.agreesToTermsAndConditions = false;

        /**
         * Creates a new DeliveryInstructionsRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {appliancepb.IDeliveryInstructionsRequest=} [properties] Properties to set
         * @returns {appliancepb.DeliveryInstructionsRequest} DeliveryInstructionsRequest instance
         */
        DeliveryInstructionsRequest.create = function create(properties) {
            return new DeliveryInstructionsRequest(properties);
        };

        /**
         * Encodes the specified DeliveryInstructionsRequest message. Does not implicitly {@link appliancepb.DeliveryInstructionsRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {appliancepb.IDeliveryInstructionsRequest} message DeliveryInstructionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeliveryInstructionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deliveryInstructions != null && Object.hasOwnProperty.call(message, "deliveryInstructions"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.deliveryInstructions);
            if (message.confirmedDimensions != null && Object.hasOwnProperty.call(message, "confirmedDimensions"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.confirmedDimensions);
            if (message.agreesToTermsAndConditions != null && Object.hasOwnProperty.call(message, "agreesToTermsAndConditions"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.agreesToTermsAndConditions);
            return writer;
        };

        /**
         * Encodes the specified DeliveryInstructionsRequest message, length delimited. Does not implicitly {@link appliancepb.DeliveryInstructionsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {appliancepb.IDeliveryInstructionsRequest} message DeliveryInstructionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeliveryInstructionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeliveryInstructionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.DeliveryInstructionsRequest} DeliveryInstructionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeliveryInstructionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.DeliveryInstructionsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.deliveryInstructions = reader.string();
                    break;
                case 2:
                    message.confirmedDimensions = reader.bool();
                    break;
                case 3:
                    message.agreesToTermsAndConditions = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeliveryInstructionsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.DeliveryInstructionsRequest} DeliveryInstructionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeliveryInstructionsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeliveryInstructionsRequest message.
         * @function verify
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeliveryInstructionsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                if (!$util.isString(message.deliveryInstructions))
                    return "deliveryInstructions: string expected";
            if (message.confirmedDimensions != null && message.hasOwnProperty("confirmedDimensions"))
                if (typeof message.confirmedDimensions !== "boolean")
                    return "confirmedDimensions: boolean expected";
            if (message.agreesToTermsAndConditions != null && message.hasOwnProperty("agreesToTermsAndConditions"))
                if (typeof message.agreesToTermsAndConditions !== "boolean")
                    return "agreesToTermsAndConditions: boolean expected";
            return null;
        };

        /**
         * Creates a DeliveryInstructionsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.DeliveryInstructionsRequest} DeliveryInstructionsRequest
         */
        DeliveryInstructionsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.DeliveryInstructionsRequest)
                return object;
            let message = new $root.appliancepb.DeliveryInstructionsRequest();
            if (object.deliveryInstructions != null)
                message.deliveryInstructions = String(object.deliveryInstructions);
            if (object.confirmedDimensions != null)
                message.confirmedDimensions = Boolean(object.confirmedDimensions);
            if (object.agreesToTermsAndConditions != null)
                message.agreesToTermsAndConditions = Boolean(object.agreesToTermsAndConditions);
            return message;
        };

        /**
         * Creates a plain object from a DeliveryInstructionsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @static
         * @param {appliancepb.DeliveryInstructionsRequest} message DeliveryInstructionsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeliveryInstructionsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deliveryInstructions = "";
                object.confirmedDimensions = false;
                object.agreesToTermsAndConditions = false;
            }
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                object.deliveryInstructions = message.deliveryInstructions;
            if (message.confirmedDimensions != null && message.hasOwnProperty("confirmedDimensions"))
                object.confirmedDimensions = message.confirmedDimensions;
            if (message.agreesToTermsAndConditions != null && message.hasOwnProperty("agreesToTermsAndConditions"))
                object.agreesToTermsAndConditions = message.agreesToTermsAndConditions;
            return object;
        };

        /**
         * Converts this DeliveryInstructionsRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.DeliveryInstructionsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeliveryInstructionsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeliveryInstructionsRequest;
    })();

    appliancepb.WorldpayPaymentDetails = (function() {

        /**
         * Properties of a WorldpayPaymentDetails.
         * @memberof appliancepb
         * @interface IWorldpayPaymentDetails
         * @property {string|null} [ID] WorldpayPaymentDetails ID
         * @property {string|null} [iframeToken] WorldpayPaymentDetails iframeToken
         * @property {string|null} [brand] WorldpayPaymentDetails brand
         * @property {string|null} [expMonth] WorldpayPaymentDetails expMonth
         * @property {string|null} [expYear] WorldpayPaymentDetails expYear
         * @property {string|null} [bin] WorldpayPaymentDetails bin
         * @property {string|null} [targetServer] WorldpayPaymentDetails targetServer
         * @property {string|null} [worldpayTxnID] WorldpayPaymentDetails worldpayTxnID
         * @property {string|null} [orderID] WorldpayPaymentDetails orderID
         * @property {string|null} [response] WorldpayPaymentDetails response
         * @property {string|null} [responseTime] WorldpayPaymentDetails responseTime
         * @property {string|null} [transactionMessage] WorldpayPaymentDetails transactionMessage
         * @property {string|null} [reportGroup] WorldpayPaymentDetails reportGroup
         * @property {string|null} [firstSix] WorldpayPaymentDetails firstSix
         * @property {string|null} [lastFour] WorldpayPaymentDetails lastFour
         * @property {boolean|null} [invokeMerchantCallback] WorldpayPaymentDetails invokeMerchantCallback
         */

        /**
         * Constructs a new WorldpayPaymentDetails.
         * @memberof appliancepb
         * @classdesc Represents a WorldpayPaymentDetails.
         * @implements IWorldpayPaymentDetails
         * @constructor
         * @param {appliancepb.IWorldpayPaymentDetails=} [properties] Properties to set
         */
        function WorldpayPaymentDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorldpayPaymentDetails ID.
         * @member {string} ID
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.ID = "";

        /**
         * WorldpayPaymentDetails iframeToken.
         * @member {string} iframeToken
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.iframeToken = "";

        /**
         * WorldpayPaymentDetails brand.
         * @member {string} brand
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.brand = "";

        /**
         * WorldpayPaymentDetails expMonth.
         * @member {string} expMonth
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.expMonth = "";

        /**
         * WorldpayPaymentDetails expYear.
         * @member {string} expYear
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.expYear = "";

        /**
         * WorldpayPaymentDetails bin.
         * @member {string} bin
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.bin = "";

        /**
         * WorldpayPaymentDetails targetServer.
         * @member {string} targetServer
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.targetServer = "";

        /**
         * WorldpayPaymentDetails worldpayTxnID.
         * @member {string} worldpayTxnID
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.worldpayTxnID = "";

        /**
         * WorldpayPaymentDetails orderID.
         * @member {string} orderID
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.orderID = "";

        /**
         * WorldpayPaymentDetails response.
         * @member {string} response
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.response = "";

        /**
         * WorldpayPaymentDetails responseTime.
         * @member {string} responseTime
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.responseTime = "";

        /**
         * WorldpayPaymentDetails transactionMessage.
         * @member {string} transactionMessage
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.transactionMessage = "";

        /**
         * WorldpayPaymentDetails reportGroup.
         * @member {string} reportGroup
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.reportGroup = "";

        /**
         * WorldpayPaymentDetails firstSix.
         * @member {string} firstSix
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.firstSix = "";

        /**
         * WorldpayPaymentDetails lastFour.
         * @member {string} lastFour
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.lastFour = "";

        /**
         * WorldpayPaymentDetails invokeMerchantCallback.
         * @member {boolean} invokeMerchantCallback
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         */
        WorldpayPaymentDetails.prototype.invokeMerchantCallback = false;

        /**
         * Creates a new WorldpayPaymentDetails instance using the specified properties.
         * @function create
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {appliancepb.IWorldpayPaymentDetails=} [properties] Properties to set
         * @returns {appliancepb.WorldpayPaymentDetails} WorldpayPaymentDetails instance
         */
        WorldpayPaymentDetails.create = function create(properties) {
            return new WorldpayPaymentDetails(properties);
        };

        /**
         * Encodes the specified WorldpayPaymentDetails message. Does not implicitly {@link appliancepb.WorldpayPaymentDetails.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {appliancepb.IWorldpayPaymentDetails} message WorldpayPaymentDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorldpayPaymentDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.iframeToken != null && Object.hasOwnProperty.call(message, "iframeToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.iframeToken);
            if (message.brand != null && Object.hasOwnProperty.call(message, "brand"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.brand);
            if (message.expMonth != null && Object.hasOwnProperty.call(message, "expMonth"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.expMonth);
            if (message.expYear != null && Object.hasOwnProperty.call(message, "expYear"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.expYear);
            if (message.bin != null && Object.hasOwnProperty.call(message, "bin"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.bin);
            if (message.targetServer != null && Object.hasOwnProperty.call(message, "targetServer"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.targetServer);
            if (message.worldpayTxnID != null && Object.hasOwnProperty.call(message, "worldpayTxnID"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.worldpayTxnID);
            if (message.orderID != null && Object.hasOwnProperty.call(message, "orderID"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.orderID);
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.response);
            if (message.responseTime != null && Object.hasOwnProperty.call(message, "responseTime"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.responseTime);
            if (message.transactionMessage != null && Object.hasOwnProperty.call(message, "transactionMessage"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.transactionMessage);
            if (message.reportGroup != null && Object.hasOwnProperty.call(message, "reportGroup"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.reportGroup);
            if (message.firstSix != null && Object.hasOwnProperty.call(message, "firstSix"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.firstSix);
            if (message.lastFour != null && Object.hasOwnProperty.call(message, "lastFour"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.lastFour);
            if (message.invokeMerchantCallback != null && Object.hasOwnProperty.call(message, "invokeMerchantCallback"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.invokeMerchantCallback);
            return writer;
        };

        /**
         * Encodes the specified WorldpayPaymentDetails message, length delimited. Does not implicitly {@link appliancepb.WorldpayPaymentDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {appliancepb.IWorldpayPaymentDetails} message WorldpayPaymentDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorldpayPaymentDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorldpayPaymentDetails message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.WorldpayPaymentDetails} WorldpayPaymentDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorldpayPaymentDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.WorldpayPaymentDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.iframeToken = reader.string();
                    break;
                case 3:
                    message.brand = reader.string();
                    break;
                case 4:
                    message.expMonth = reader.string();
                    break;
                case 5:
                    message.expYear = reader.string();
                    break;
                case 6:
                    message.bin = reader.string();
                    break;
                case 7:
                    message.targetServer = reader.string();
                    break;
                case 8:
                    message.worldpayTxnID = reader.string();
                    break;
                case 9:
                    message.orderID = reader.string();
                    break;
                case 10:
                    message.response = reader.string();
                    break;
                case 11:
                    message.responseTime = reader.string();
                    break;
                case 12:
                    message.transactionMessage = reader.string();
                    break;
                case 13:
                    message.reportGroup = reader.string();
                    break;
                case 14:
                    message.firstSix = reader.string();
                    break;
                case 15:
                    message.lastFour = reader.string();
                    break;
                case 16:
                    message.invokeMerchantCallback = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorldpayPaymentDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.WorldpayPaymentDetails} WorldpayPaymentDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorldpayPaymentDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorldpayPaymentDetails message.
         * @function verify
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorldpayPaymentDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.iframeToken != null && message.hasOwnProperty("iframeToken"))
                if (!$util.isString(message.iframeToken))
                    return "iframeToken: string expected";
            if (message.brand != null && message.hasOwnProperty("brand"))
                if (!$util.isString(message.brand))
                    return "brand: string expected";
            if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                if (!$util.isString(message.expMonth))
                    return "expMonth: string expected";
            if (message.expYear != null && message.hasOwnProperty("expYear"))
                if (!$util.isString(message.expYear))
                    return "expYear: string expected";
            if (message.bin != null && message.hasOwnProperty("bin"))
                if (!$util.isString(message.bin))
                    return "bin: string expected";
            if (message.targetServer != null && message.hasOwnProperty("targetServer"))
                if (!$util.isString(message.targetServer))
                    return "targetServer: string expected";
            if (message.worldpayTxnID != null && message.hasOwnProperty("worldpayTxnID"))
                if (!$util.isString(message.worldpayTxnID))
                    return "worldpayTxnID: string expected";
            if (message.orderID != null && message.hasOwnProperty("orderID"))
                if (!$util.isString(message.orderID))
                    return "orderID: string expected";
            if (message.response != null && message.hasOwnProperty("response"))
                if (!$util.isString(message.response))
                    return "response: string expected";
            if (message.responseTime != null && message.hasOwnProperty("responseTime"))
                if (!$util.isString(message.responseTime))
                    return "responseTime: string expected";
            if (message.transactionMessage != null && message.hasOwnProperty("transactionMessage"))
                if (!$util.isString(message.transactionMessage))
                    return "transactionMessage: string expected";
            if (message.reportGroup != null && message.hasOwnProperty("reportGroup"))
                if (!$util.isString(message.reportGroup))
                    return "reportGroup: string expected";
            if (message.firstSix != null && message.hasOwnProperty("firstSix"))
                if (!$util.isString(message.firstSix))
                    return "firstSix: string expected";
            if (message.lastFour != null && message.hasOwnProperty("lastFour"))
                if (!$util.isString(message.lastFour))
                    return "lastFour: string expected";
            if (message.invokeMerchantCallback != null && message.hasOwnProperty("invokeMerchantCallback"))
                if (typeof message.invokeMerchantCallback !== "boolean")
                    return "invokeMerchantCallback: boolean expected";
            return null;
        };

        /**
         * Creates a WorldpayPaymentDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.WorldpayPaymentDetails} WorldpayPaymentDetails
         */
        WorldpayPaymentDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.WorldpayPaymentDetails)
                return object;
            let message = new $root.appliancepb.WorldpayPaymentDetails();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.iframeToken != null)
                message.iframeToken = String(object.iframeToken);
            if (object.brand != null)
                message.brand = String(object.brand);
            if (object.expMonth != null)
                message.expMonth = String(object.expMonth);
            if (object.expYear != null)
                message.expYear = String(object.expYear);
            if (object.bin != null)
                message.bin = String(object.bin);
            if (object.targetServer != null)
                message.targetServer = String(object.targetServer);
            if (object.worldpayTxnID != null)
                message.worldpayTxnID = String(object.worldpayTxnID);
            if (object.orderID != null)
                message.orderID = String(object.orderID);
            if (object.response != null)
                message.response = String(object.response);
            if (object.responseTime != null)
                message.responseTime = String(object.responseTime);
            if (object.transactionMessage != null)
                message.transactionMessage = String(object.transactionMessage);
            if (object.reportGroup != null)
                message.reportGroup = String(object.reportGroup);
            if (object.firstSix != null)
                message.firstSix = String(object.firstSix);
            if (object.lastFour != null)
                message.lastFour = String(object.lastFour);
            if (object.invokeMerchantCallback != null)
                message.invokeMerchantCallback = Boolean(object.invokeMerchantCallback);
            return message;
        };

        /**
         * Creates a plain object from a WorldpayPaymentDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.WorldpayPaymentDetails
         * @static
         * @param {appliancepb.WorldpayPaymentDetails} message WorldpayPaymentDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorldpayPaymentDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ID = "";
                object.iframeToken = "";
                object.brand = "";
                object.expMonth = "";
                object.expYear = "";
                object.bin = "";
                object.targetServer = "";
                object.worldpayTxnID = "";
                object.orderID = "";
                object.response = "";
                object.responseTime = "";
                object.transactionMessage = "";
                object.reportGroup = "";
                object.firstSix = "";
                object.lastFour = "";
                object.invokeMerchantCallback = false;
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.iframeToken != null && message.hasOwnProperty("iframeToken"))
                object.iframeToken = message.iframeToken;
            if (message.brand != null && message.hasOwnProperty("brand"))
                object.brand = message.brand;
            if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                object.expMonth = message.expMonth;
            if (message.expYear != null && message.hasOwnProperty("expYear"))
                object.expYear = message.expYear;
            if (message.bin != null && message.hasOwnProperty("bin"))
                object.bin = message.bin;
            if (message.targetServer != null && message.hasOwnProperty("targetServer"))
                object.targetServer = message.targetServer;
            if (message.worldpayTxnID != null && message.hasOwnProperty("worldpayTxnID"))
                object.worldpayTxnID = message.worldpayTxnID;
            if (message.orderID != null && message.hasOwnProperty("orderID"))
                object.orderID = message.orderID;
            if (message.response != null && message.hasOwnProperty("response"))
                object.response = message.response;
            if (message.responseTime != null && message.hasOwnProperty("responseTime"))
                object.responseTime = message.responseTime;
            if (message.transactionMessage != null && message.hasOwnProperty("transactionMessage"))
                object.transactionMessage = message.transactionMessage;
            if (message.reportGroup != null && message.hasOwnProperty("reportGroup"))
                object.reportGroup = message.reportGroup;
            if (message.firstSix != null && message.hasOwnProperty("firstSix"))
                object.firstSix = message.firstSix;
            if (message.lastFour != null && message.hasOwnProperty("lastFour"))
                object.lastFour = message.lastFour;
            if (message.invokeMerchantCallback != null && message.hasOwnProperty("invokeMerchantCallback"))
                object.invokeMerchantCallback = message.invokeMerchantCallback;
            return object;
        };

        /**
         * Converts this WorldpayPaymentDetails to JSON.
         * @function toJSON
         * @memberof appliancepb.WorldpayPaymentDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorldpayPaymentDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorldpayPaymentDetails;
    })();

    appliancepb.PaymentDetails = (function() {

        /**
         * Properties of a PaymentDetails.
         * @memberof appliancepb
         * @interface IPaymentDetails
         * @property {string|null} [ID] PaymentDetails ID
         * @property {string|null} [iframeToken] PaymentDetails iframeToken
         * @property {string|null} [brand] PaymentDetails brand
         * @property {string|null} [expMonth] PaymentDetails expMonth
         * @property {string|null} [expYear] PaymentDetails expYear
         * @property {string|null} [bin] PaymentDetails bin
         * @property {string|null} [targetServer] PaymentDetails targetServer
         * @property {string|null} [worldpayTxnID] PaymentDetails worldpayTxnID
         * @property {string|null} [orderID] PaymentDetails orderID
         * @property {string|null} [response] PaymentDetails response
         * @property {string|null} [responseTime] PaymentDetails responseTime
         * @property {string|null} [transactionMessage] PaymentDetails transactionMessage
         * @property {string|null} [reportGroup] PaymentDetails reportGroup
         * @property {string|null} [firstSix] PaymentDetails firstSix
         * @property {string|null} [lastFour] PaymentDetails lastFour
         * @property {boolean|null} [invokeMerchantCallback] PaymentDetails invokeMerchantCallback
         * @property {appliancepb.PaymentDetails.processor|null} [paymentProcessor] PaymentDetails paymentProcessor
         * @property {string|null} [country] PaymentDetails country
         * @property {appliancepb.IPriceSetter|null} [totalPrice] PaymentDetails totalPrice
         */

        /**
         * Constructs a new PaymentDetails.
         * @memberof appliancepb
         * @classdesc Represents a PaymentDetails.
         * @implements IPaymentDetails
         * @constructor
         * @param {appliancepb.IPaymentDetails=} [properties] Properties to set
         */
        function PaymentDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentDetails ID.
         * @member {string} ID
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.ID = "";

        /**
         * PaymentDetails iframeToken.
         * @member {string} iframeToken
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.iframeToken = "";

        /**
         * PaymentDetails brand.
         * @member {string} brand
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.brand = "";

        /**
         * PaymentDetails expMonth.
         * @member {string} expMonth
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.expMonth = "";

        /**
         * PaymentDetails expYear.
         * @member {string} expYear
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.expYear = "";

        /**
         * PaymentDetails bin.
         * @member {string} bin
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.bin = "";

        /**
         * PaymentDetails targetServer.
         * @member {string} targetServer
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.targetServer = "";

        /**
         * PaymentDetails worldpayTxnID.
         * @member {string} worldpayTxnID
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.worldpayTxnID = "";

        /**
         * PaymentDetails orderID.
         * @member {string} orderID
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.orderID = "";

        /**
         * PaymentDetails response.
         * @member {string} response
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.response = "";

        /**
         * PaymentDetails responseTime.
         * @member {string} responseTime
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.responseTime = "";

        /**
         * PaymentDetails transactionMessage.
         * @member {string} transactionMessage
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.transactionMessage = "";

        /**
         * PaymentDetails reportGroup.
         * @member {string} reportGroup
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.reportGroup = "";

        /**
         * PaymentDetails firstSix.
         * @member {string} firstSix
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.firstSix = "";

        /**
         * PaymentDetails lastFour.
         * @member {string} lastFour
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.lastFour = "";

        /**
         * PaymentDetails invokeMerchantCallback.
         * @member {boolean} invokeMerchantCallback
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.invokeMerchantCallback = false;

        /**
         * PaymentDetails paymentProcessor.
         * @member {appliancepb.PaymentDetails.processor} paymentProcessor
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.paymentProcessor = 0;

        /**
         * PaymentDetails country.
         * @member {string} country
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.country = "";

        /**
         * PaymentDetails totalPrice.
         * @member {appliancepb.IPriceSetter|null|undefined} totalPrice
         * @memberof appliancepb.PaymentDetails
         * @instance
         */
        PaymentDetails.prototype.totalPrice = null;

        /**
         * Creates a new PaymentDetails instance using the specified properties.
         * @function create
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {appliancepb.IPaymentDetails=} [properties] Properties to set
         * @returns {appliancepb.PaymentDetails} PaymentDetails instance
         */
        PaymentDetails.create = function create(properties) {
            return new PaymentDetails(properties);
        };

        /**
         * Encodes the specified PaymentDetails message. Does not implicitly {@link appliancepb.PaymentDetails.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {appliancepb.IPaymentDetails} message PaymentDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.iframeToken != null && Object.hasOwnProperty.call(message, "iframeToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.iframeToken);
            if (message.brand != null && Object.hasOwnProperty.call(message, "brand"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.brand);
            if (message.expMonth != null && Object.hasOwnProperty.call(message, "expMonth"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.expMonth);
            if (message.expYear != null && Object.hasOwnProperty.call(message, "expYear"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.expYear);
            if (message.bin != null && Object.hasOwnProperty.call(message, "bin"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.bin);
            if (message.targetServer != null && Object.hasOwnProperty.call(message, "targetServer"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.targetServer);
            if (message.worldpayTxnID != null && Object.hasOwnProperty.call(message, "worldpayTxnID"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.worldpayTxnID);
            if (message.orderID != null && Object.hasOwnProperty.call(message, "orderID"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.orderID);
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.response);
            if (message.responseTime != null && Object.hasOwnProperty.call(message, "responseTime"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.responseTime);
            if (message.transactionMessage != null && Object.hasOwnProperty.call(message, "transactionMessage"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.transactionMessage);
            if (message.reportGroup != null && Object.hasOwnProperty.call(message, "reportGroup"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.reportGroup);
            if (message.firstSix != null && Object.hasOwnProperty.call(message, "firstSix"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.firstSix);
            if (message.lastFour != null && Object.hasOwnProperty.call(message, "lastFour"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.lastFour);
            if (message.invokeMerchantCallback != null && Object.hasOwnProperty.call(message, "invokeMerchantCallback"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.invokeMerchantCallback);
            if (message.paymentProcessor != null && Object.hasOwnProperty.call(message, "paymentProcessor"))
                writer.uint32(/* id 20, wireType 0 =*/160).int32(message.paymentProcessor);
            if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.country);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                $root.appliancepb.PriceSetter.encode(message.totalPrice, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PaymentDetails message, length delimited. Does not implicitly {@link appliancepb.PaymentDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {appliancepb.IPaymentDetails} message PaymentDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaymentDetails message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.PaymentDetails} PaymentDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.PaymentDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.iframeToken = reader.string();
                    break;
                case 3:
                    message.brand = reader.string();
                    break;
                case 4:
                    message.expMonth = reader.string();
                    break;
                case 5:
                    message.expYear = reader.string();
                    break;
                case 6:
                    message.bin = reader.string();
                    break;
                case 7:
                    message.targetServer = reader.string();
                    break;
                case 8:
                    message.worldpayTxnID = reader.string();
                    break;
                case 9:
                    message.orderID = reader.string();
                    break;
                case 10:
                    message.response = reader.string();
                    break;
                case 11:
                    message.responseTime = reader.string();
                    break;
                case 12:
                    message.transactionMessage = reader.string();
                    break;
                case 13:
                    message.reportGroup = reader.string();
                    break;
                case 14:
                    message.firstSix = reader.string();
                    break;
                case 15:
                    message.lastFour = reader.string();
                    break;
                case 16:
                    message.invokeMerchantCallback = reader.bool();
                    break;
                case 20:
                    message.paymentProcessor = reader.int32();
                    break;
                case 30:
                    message.country = reader.string();
                    break;
                case 31:
                    message.totalPrice = $root.appliancepb.PriceSetter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PaymentDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.PaymentDetails} PaymentDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PaymentDetails message.
         * @function verify
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PaymentDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.iframeToken != null && message.hasOwnProperty("iframeToken"))
                if (!$util.isString(message.iframeToken))
                    return "iframeToken: string expected";
            if (message.brand != null && message.hasOwnProperty("brand"))
                if (!$util.isString(message.brand))
                    return "brand: string expected";
            if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                if (!$util.isString(message.expMonth))
                    return "expMonth: string expected";
            if (message.expYear != null && message.hasOwnProperty("expYear"))
                if (!$util.isString(message.expYear))
                    return "expYear: string expected";
            if (message.bin != null && message.hasOwnProperty("bin"))
                if (!$util.isString(message.bin))
                    return "bin: string expected";
            if (message.targetServer != null && message.hasOwnProperty("targetServer"))
                if (!$util.isString(message.targetServer))
                    return "targetServer: string expected";
            if (message.worldpayTxnID != null && message.hasOwnProperty("worldpayTxnID"))
                if (!$util.isString(message.worldpayTxnID))
                    return "worldpayTxnID: string expected";
            if (message.orderID != null && message.hasOwnProperty("orderID"))
                if (!$util.isString(message.orderID))
                    return "orderID: string expected";
            if (message.response != null && message.hasOwnProperty("response"))
                if (!$util.isString(message.response))
                    return "response: string expected";
            if (message.responseTime != null && message.hasOwnProperty("responseTime"))
                if (!$util.isString(message.responseTime))
                    return "responseTime: string expected";
            if (message.transactionMessage != null && message.hasOwnProperty("transactionMessage"))
                if (!$util.isString(message.transactionMessage))
                    return "transactionMessage: string expected";
            if (message.reportGroup != null && message.hasOwnProperty("reportGroup"))
                if (!$util.isString(message.reportGroup))
                    return "reportGroup: string expected";
            if (message.firstSix != null && message.hasOwnProperty("firstSix"))
                if (!$util.isString(message.firstSix))
                    return "firstSix: string expected";
            if (message.lastFour != null && message.hasOwnProperty("lastFour"))
                if (!$util.isString(message.lastFour))
                    return "lastFour: string expected";
            if (message.invokeMerchantCallback != null && message.hasOwnProperty("invokeMerchantCallback"))
                if (typeof message.invokeMerchantCallback !== "boolean")
                    return "invokeMerchantCallback: boolean expected";
            if (message.paymentProcessor != null && message.hasOwnProperty("paymentProcessor"))
                switch (message.paymentProcessor) {
                default:
                    return "paymentProcessor: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.country != null && message.hasOwnProperty("country"))
                if (!$util.isString(message.country))
                    return "country: string expected";
            if (message.totalPrice != null && message.hasOwnProperty("totalPrice")) {
                let error = $root.appliancepb.PriceSetter.verify(message.totalPrice);
                if (error)
                    return "totalPrice." + error;
            }
            return null;
        };

        /**
         * Creates a PaymentDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.PaymentDetails} PaymentDetails
         */
        PaymentDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.PaymentDetails)
                return object;
            let message = new $root.appliancepb.PaymentDetails();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.iframeToken != null)
                message.iframeToken = String(object.iframeToken);
            if (object.brand != null)
                message.brand = String(object.brand);
            if (object.expMonth != null)
                message.expMonth = String(object.expMonth);
            if (object.expYear != null)
                message.expYear = String(object.expYear);
            if (object.bin != null)
                message.bin = String(object.bin);
            if (object.targetServer != null)
                message.targetServer = String(object.targetServer);
            if (object.worldpayTxnID != null)
                message.worldpayTxnID = String(object.worldpayTxnID);
            if (object.orderID != null)
                message.orderID = String(object.orderID);
            if (object.response != null)
                message.response = String(object.response);
            if (object.responseTime != null)
                message.responseTime = String(object.responseTime);
            if (object.transactionMessage != null)
                message.transactionMessage = String(object.transactionMessage);
            if (object.reportGroup != null)
                message.reportGroup = String(object.reportGroup);
            if (object.firstSix != null)
                message.firstSix = String(object.firstSix);
            if (object.lastFour != null)
                message.lastFour = String(object.lastFour);
            if (object.invokeMerchantCallback != null)
                message.invokeMerchantCallback = Boolean(object.invokeMerchantCallback);
            switch (object.paymentProcessor) {
            case "WORLDPAY":
            case 0:
                message.paymentProcessor = 0;
                break;
            case "STRIPE":
            case 1:
                message.paymentProcessor = 1;
                break;
            }
            if (object.country != null)
                message.country = String(object.country);
            if (object.totalPrice != null) {
                if (typeof object.totalPrice !== "object")
                    throw TypeError(".appliancepb.PaymentDetails.totalPrice: object expected");
                message.totalPrice = $root.appliancepb.PriceSetter.fromObject(object.totalPrice);
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.PaymentDetails
         * @static
         * @param {appliancepb.PaymentDetails} message PaymentDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ID = "";
                object.iframeToken = "";
                object.brand = "";
                object.expMonth = "";
                object.expYear = "";
                object.bin = "";
                object.targetServer = "";
                object.worldpayTxnID = "";
                object.orderID = "";
                object.response = "";
                object.responseTime = "";
                object.transactionMessage = "";
                object.reportGroup = "";
                object.firstSix = "";
                object.lastFour = "";
                object.invokeMerchantCallback = false;
                object.paymentProcessor = options.enums === String ? "WORLDPAY" : 0;
                object.country = "";
                object.totalPrice = null;
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.iframeToken != null && message.hasOwnProperty("iframeToken"))
                object.iframeToken = message.iframeToken;
            if (message.brand != null && message.hasOwnProperty("brand"))
                object.brand = message.brand;
            if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                object.expMonth = message.expMonth;
            if (message.expYear != null && message.hasOwnProperty("expYear"))
                object.expYear = message.expYear;
            if (message.bin != null && message.hasOwnProperty("bin"))
                object.bin = message.bin;
            if (message.targetServer != null && message.hasOwnProperty("targetServer"))
                object.targetServer = message.targetServer;
            if (message.worldpayTxnID != null && message.hasOwnProperty("worldpayTxnID"))
                object.worldpayTxnID = message.worldpayTxnID;
            if (message.orderID != null && message.hasOwnProperty("orderID"))
                object.orderID = message.orderID;
            if (message.response != null && message.hasOwnProperty("response"))
                object.response = message.response;
            if (message.responseTime != null && message.hasOwnProperty("responseTime"))
                object.responseTime = message.responseTime;
            if (message.transactionMessage != null && message.hasOwnProperty("transactionMessage"))
                object.transactionMessage = message.transactionMessage;
            if (message.reportGroup != null && message.hasOwnProperty("reportGroup"))
                object.reportGroup = message.reportGroup;
            if (message.firstSix != null && message.hasOwnProperty("firstSix"))
                object.firstSix = message.firstSix;
            if (message.lastFour != null && message.hasOwnProperty("lastFour"))
                object.lastFour = message.lastFour;
            if (message.invokeMerchantCallback != null && message.hasOwnProperty("invokeMerchantCallback"))
                object.invokeMerchantCallback = message.invokeMerchantCallback;
            if (message.paymentProcessor != null && message.hasOwnProperty("paymentProcessor"))
                object.paymentProcessor = options.enums === String ? $root.appliancepb.PaymentDetails.processor[message.paymentProcessor] : message.paymentProcessor;
            if (message.country != null && message.hasOwnProperty("country"))
                object.country = message.country;
            if (message.totalPrice != null && message.hasOwnProperty("totalPrice"))
                object.totalPrice = $root.appliancepb.PriceSetter.toObject(message.totalPrice, options);
            return object;
        };

        /**
         * Converts this PaymentDetails to JSON.
         * @function toJSON
         * @memberof appliancepb.PaymentDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * processor enum.
         * @name appliancepb.PaymentDetails.processor
         * @enum {number}
         * @property {number} WORLDPAY=0 WORLDPAY value
         * @property {number} STRIPE=1 STRIPE value
         */
        PaymentDetails.processor = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WORLDPAY"] = 0;
            values[valuesById[1] = "STRIPE"] = 1;
            return values;
        })();

        return PaymentDetails;
    })();

    appliancepb.Payment = (function() {

        /**
         * Properties of a Payment.
         * @memberof appliancepb
         * @interface IPayment
         * @property {string|null} [ID] Payment ID
         * @property {appliancepb.IPaymentDetails|null} [paymentDetails] Payment paymentDetails
         * @property {google.protobuf.ITimestamp|null} [created] Payment created
         * @property {google.protobuf.ITimestamp|null} [updated] Payment updated
         * @property {string|null} [location] Payment location
         */

        /**
         * Constructs a new Payment.
         * @memberof appliancepb
         * @classdesc Represents a Payment.
         * @implements IPayment
         * @constructor
         * @param {appliancepb.IPayment=} [properties] Properties to set
         */
        function Payment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Payment ID.
         * @member {string} ID
         * @memberof appliancepb.Payment
         * @instance
         */
        Payment.prototype.ID = "";

        /**
         * Payment paymentDetails.
         * @member {appliancepb.IPaymentDetails|null|undefined} paymentDetails
         * @memberof appliancepb.Payment
         * @instance
         */
        Payment.prototype.paymentDetails = null;

        /**
         * Payment created.
         * @member {google.protobuf.ITimestamp|null|undefined} created
         * @memberof appliancepb.Payment
         * @instance
         */
        Payment.prototype.created = null;

        /**
         * Payment updated.
         * @member {google.protobuf.ITimestamp|null|undefined} updated
         * @memberof appliancepb.Payment
         * @instance
         */
        Payment.prototype.updated = null;

        /**
         * Payment location.
         * @member {string} location
         * @memberof appliancepb.Payment
         * @instance
         */
        Payment.prototype.location = "";

        /**
         * Creates a new Payment instance using the specified properties.
         * @function create
         * @memberof appliancepb.Payment
         * @static
         * @param {appliancepb.IPayment=} [properties] Properties to set
         * @returns {appliancepb.Payment} Payment instance
         */
        Payment.create = function create(properties) {
            return new Payment(properties);
        };

        /**
         * Encodes the specified Payment message. Does not implicitly {@link appliancepb.Payment.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Payment
         * @static
         * @param {appliancepb.IPayment} message Payment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.paymentDetails != null && Object.hasOwnProperty.call(message, "paymentDetails"))
                $root.appliancepb.PaymentDetails.encode(message.paymentDetails, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                $root.google.protobuf.Timestamp.encode(message.created, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
                $root.google.protobuf.Timestamp.encode(message.updated, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.location);
            return writer;
        };

        /**
         * Encodes the specified Payment message, length delimited. Does not implicitly {@link appliancepb.Payment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Payment
         * @static
         * @param {appliancepb.IPayment} message Payment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Payment message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Payment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Payment} Payment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Payment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.paymentDetails = $root.appliancepb.PaymentDetails.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.created = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.updated = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.location = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Payment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Payment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Payment} Payment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Payment message.
         * @function verify
         * @memberof appliancepb.Payment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Payment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails")) {
                let error = $root.appliancepb.PaymentDetails.verify(message.paymentDetails);
                if (error)
                    return "paymentDetails." + error;
            }
            if (message.created != null && message.hasOwnProperty("created")) {
                let error = $root.google.protobuf.Timestamp.verify(message.created);
                if (error)
                    return "created." + error;
            }
            if (message.updated != null && message.hasOwnProperty("updated")) {
                let error = $root.google.protobuf.Timestamp.verify(message.updated);
                if (error)
                    return "updated." + error;
            }
            if (message.location != null && message.hasOwnProperty("location"))
                if (!$util.isString(message.location))
                    return "location: string expected";
            return null;
        };

        /**
         * Creates a Payment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Payment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Payment} Payment
         */
        Payment.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Payment)
                return object;
            let message = new $root.appliancepb.Payment();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.paymentDetails != null) {
                if (typeof object.paymentDetails !== "object")
                    throw TypeError(".appliancepb.Payment.paymentDetails: object expected");
                message.paymentDetails = $root.appliancepb.PaymentDetails.fromObject(object.paymentDetails);
            }
            if (object.created != null) {
                if (typeof object.created !== "object")
                    throw TypeError(".appliancepb.Payment.created: object expected");
                message.created = $root.google.protobuf.Timestamp.fromObject(object.created);
            }
            if (object.updated != null) {
                if (typeof object.updated !== "object")
                    throw TypeError(".appliancepb.Payment.updated: object expected");
                message.updated = $root.google.protobuf.Timestamp.fromObject(object.updated);
            }
            if (object.location != null)
                message.location = String(object.location);
            return message;
        };

        /**
         * Creates a plain object from a Payment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Payment
         * @static
         * @param {appliancepb.Payment} message Payment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Payment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ID = "";
                object.paymentDetails = null;
                object.created = null;
                object.updated = null;
                object.location = "";
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails"))
                object.paymentDetails = $root.appliancepb.PaymentDetails.toObject(message.paymentDetails, options);
            if (message.created != null && message.hasOwnProperty("created"))
                object.created = $root.google.protobuf.Timestamp.toObject(message.created, options);
            if (message.updated != null && message.hasOwnProperty("updated"))
                object.updated = $root.google.protobuf.Timestamp.toObject(message.updated, options);
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = message.location;
            return object;
        };

        /**
         * Converts this Payment to JSON.
         * @function toJSON
         * @memberof appliancepb.Payment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Payment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Payment;
    })();

    appliancepb.PostPurchaseRequest = (function() {

        /**
         * Properties of a PostPurchaseRequest.
         * @memberof appliancepb
         * @interface IPostPurchaseRequest
         * @property {string|null} [deliveryInstructions] PostPurchaseRequest deliveryInstructions
         * @property {boolean|null} [confirmedDimensions] PostPurchaseRequest confirmedDimensions
         * @property {boolean|null} [agreesToTermsAndConditions] PostPurchaseRequest agreesToTermsAndConditions
         * @property {appliancepb.IWorldpayPaymentDetails|null} [worldpayPaymentDetails] PostPurchaseRequest worldpayPaymentDetails
         * @property {appliancepb.IPaymentDetails|null} [paymentDetails] PostPurchaseRequest paymentDetails
         */

        /**
         * Constructs a new PostPurchaseRequest.
         * @memberof appliancepb
         * @classdesc Represents a PostPurchaseRequest.
         * @implements IPostPurchaseRequest
         * @constructor
         * @param {appliancepb.IPostPurchaseRequest=} [properties] Properties to set
         */
        function PostPurchaseRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PostPurchaseRequest deliveryInstructions.
         * @member {string} deliveryInstructions
         * @memberof appliancepb.PostPurchaseRequest
         * @instance
         */
        PostPurchaseRequest.prototype.deliveryInstructions = "";

        /**
         * PostPurchaseRequest confirmedDimensions.
         * @member {boolean} confirmedDimensions
         * @memberof appliancepb.PostPurchaseRequest
         * @instance
         */
        PostPurchaseRequest.prototype.confirmedDimensions = false;

        /**
         * PostPurchaseRequest agreesToTermsAndConditions.
         * @member {boolean} agreesToTermsAndConditions
         * @memberof appliancepb.PostPurchaseRequest
         * @instance
         */
        PostPurchaseRequest.prototype.agreesToTermsAndConditions = false;

        /**
         * PostPurchaseRequest worldpayPaymentDetails.
         * @member {appliancepb.IWorldpayPaymentDetails|null|undefined} worldpayPaymentDetails
         * @memberof appliancepb.PostPurchaseRequest
         * @instance
         */
        PostPurchaseRequest.prototype.worldpayPaymentDetails = null;

        /**
         * PostPurchaseRequest paymentDetails.
         * @member {appliancepb.IPaymentDetails|null|undefined} paymentDetails
         * @memberof appliancepb.PostPurchaseRequest
         * @instance
         */
        PostPurchaseRequest.prototype.paymentDetails = null;

        /**
         * Creates a new PostPurchaseRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {appliancepb.IPostPurchaseRequest=} [properties] Properties to set
         * @returns {appliancepb.PostPurchaseRequest} PostPurchaseRequest instance
         */
        PostPurchaseRequest.create = function create(properties) {
            return new PostPurchaseRequest(properties);
        };

        /**
         * Encodes the specified PostPurchaseRequest message. Does not implicitly {@link appliancepb.PostPurchaseRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {appliancepb.IPostPurchaseRequest} message PostPurchaseRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostPurchaseRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deliveryInstructions != null && Object.hasOwnProperty.call(message, "deliveryInstructions"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.deliveryInstructions);
            if (message.confirmedDimensions != null && Object.hasOwnProperty.call(message, "confirmedDimensions"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.confirmedDimensions);
            if (message.agreesToTermsAndConditions != null && Object.hasOwnProperty.call(message, "agreesToTermsAndConditions"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.agreesToTermsAndConditions);
            if (message.worldpayPaymentDetails != null && Object.hasOwnProperty.call(message, "worldpayPaymentDetails"))
                $root.appliancepb.WorldpayPaymentDetails.encode(message.worldpayPaymentDetails, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.paymentDetails != null && Object.hasOwnProperty.call(message, "paymentDetails"))
                $root.appliancepb.PaymentDetails.encode(message.paymentDetails, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PostPurchaseRequest message, length delimited. Does not implicitly {@link appliancepb.PostPurchaseRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {appliancepb.IPostPurchaseRequest} message PostPurchaseRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostPurchaseRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PostPurchaseRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.PostPurchaseRequest} PostPurchaseRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostPurchaseRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.PostPurchaseRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.deliveryInstructions = reader.string();
                    break;
                case 2:
                    message.confirmedDimensions = reader.bool();
                    break;
                case 3:
                    message.agreesToTermsAndConditions = reader.bool();
                    break;
                case 4:
                    message.worldpayPaymentDetails = $root.appliancepb.WorldpayPaymentDetails.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.paymentDetails = $root.appliancepb.PaymentDetails.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PostPurchaseRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.PostPurchaseRequest} PostPurchaseRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostPurchaseRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PostPurchaseRequest message.
         * @function verify
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PostPurchaseRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                if (!$util.isString(message.deliveryInstructions))
                    return "deliveryInstructions: string expected";
            if (message.confirmedDimensions != null && message.hasOwnProperty("confirmedDimensions"))
                if (typeof message.confirmedDimensions !== "boolean")
                    return "confirmedDimensions: boolean expected";
            if (message.agreesToTermsAndConditions != null && message.hasOwnProperty("agreesToTermsAndConditions"))
                if (typeof message.agreesToTermsAndConditions !== "boolean")
                    return "agreesToTermsAndConditions: boolean expected";
            if (message.worldpayPaymentDetails != null && message.hasOwnProperty("worldpayPaymentDetails")) {
                let error = $root.appliancepb.WorldpayPaymentDetails.verify(message.worldpayPaymentDetails);
                if (error)
                    return "worldpayPaymentDetails." + error;
            }
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails")) {
                let error = $root.appliancepb.PaymentDetails.verify(message.paymentDetails);
                if (error)
                    return "paymentDetails." + error;
            }
            return null;
        };

        /**
         * Creates a PostPurchaseRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.PostPurchaseRequest} PostPurchaseRequest
         */
        PostPurchaseRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.PostPurchaseRequest)
                return object;
            let message = new $root.appliancepb.PostPurchaseRequest();
            if (object.deliveryInstructions != null)
                message.deliveryInstructions = String(object.deliveryInstructions);
            if (object.confirmedDimensions != null)
                message.confirmedDimensions = Boolean(object.confirmedDimensions);
            if (object.agreesToTermsAndConditions != null)
                message.agreesToTermsAndConditions = Boolean(object.agreesToTermsAndConditions);
            if (object.worldpayPaymentDetails != null) {
                if (typeof object.worldpayPaymentDetails !== "object")
                    throw TypeError(".appliancepb.PostPurchaseRequest.worldpayPaymentDetails: object expected");
                message.worldpayPaymentDetails = $root.appliancepb.WorldpayPaymentDetails.fromObject(object.worldpayPaymentDetails);
            }
            if (object.paymentDetails != null) {
                if (typeof object.paymentDetails !== "object")
                    throw TypeError(".appliancepb.PostPurchaseRequest.paymentDetails: object expected");
                message.paymentDetails = $root.appliancepb.PaymentDetails.fromObject(object.paymentDetails);
            }
            return message;
        };

        /**
         * Creates a plain object from a PostPurchaseRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.PostPurchaseRequest
         * @static
         * @param {appliancepb.PostPurchaseRequest} message PostPurchaseRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PostPurchaseRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deliveryInstructions = "";
                object.confirmedDimensions = false;
                object.agreesToTermsAndConditions = false;
                object.worldpayPaymentDetails = null;
                object.paymentDetails = null;
            }
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                object.deliveryInstructions = message.deliveryInstructions;
            if (message.confirmedDimensions != null && message.hasOwnProperty("confirmedDimensions"))
                object.confirmedDimensions = message.confirmedDimensions;
            if (message.agreesToTermsAndConditions != null && message.hasOwnProperty("agreesToTermsAndConditions"))
                object.agreesToTermsAndConditions = message.agreesToTermsAndConditions;
            if (message.worldpayPaymentDetails != null && message.hasOwnProperty("worldpayPaymentDetails"))
                object.worldpayPaymentDetails = $root.appliancepb.WorldpayPaymentDetails.toObject(message.worldpayPaymentDetails, options);
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails"))
                object.paymentDetails = $root.appliancepb.PaymentDetails.toObject(message.paymentDetails, options);
            return object;
        };

        /**
         * Converts this PostPurchaseRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.PostPurchaseRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PostPurchaseRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PostPurchaseRequest;
    })();

    appliancepb.HealthCheckResponse = (function() {

        /**
         * Properties of a HealthCheckResponse.
         * @memberof appliancepb
         * @interface IHealthCheckResponse
         * @property {appliancepb.IHealthCheck|null} [health] HealthCheckResponse health
         */

        /**
         * Constructs a new HealthCheckResponse.
         * @memberof appliancepb
         * @classdesc Represents a HealthCheckResponse.
         * @implements IHealthCheckResponse
         * @constructor
         * @param {appliancepb.IHealthCheckResponse=} [properties] Properties to set
         */
        function HealthCheckResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HealthCheckResponse health.
         * @member {appliancepb.IHealthCheck|null|undefined} health
         * @memberof appliancepb.HealthCheckResponse
         * @instance
         */
        HealthCheckResponse.prototype.health = null;

        /**
         * Creates a new HealthCheckResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {appliancepb.IHealthCheckResponse=} [properties] Properties to set
         * @returns {appliancepb.HealthCheckResponse} HealthCheckResponse instance
         */
        HealthCheckResponse.create = function create(properties) {
            return new HealthCheckResponse(properties);
        };

        /**
         * Encodes the specified HealthCheckResponse message. Does not implicitly {@link appliancepb.HealthCheckResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {appliancepb.IHealthCheckResponse} message HealthCheckResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.health != null && Object.hasOwnProperty.call(message, "health"))
                $root.appliancepb.HealthCheck.encode(message.health, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HealthCheckResponse message, length delimited. Does not implicitly {@link appliancepb.HealthCheckResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {appliancepb.IHealthCheckResponse} message HealthCheckResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HealthCheckResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.HealthCheckResponse} HealthCheckResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.HealthCheckResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.health = $root.appliancepb.HealthCheck.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HealthCheckResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.HealthCheckResponse} HealthCheckResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HealthCheckResponse message.
         * @function verify
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HealthCheckResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.health != null && message.hasOwnProperty("health")) {
                let error = $root.appliancepb.HealthCheck.verify(message.health);
                if (error)
                    return "health." + error;
            }
            return null;
        };

        /**
         * Creates a HealthCheckResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.HealthCheckResponse} HealthCheckResponse
         */
        HealthCheckResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.HealthCheckResponse)
                return object;
            let message = new $root.appliancepb.HealthCheckResponse();
            if (object.health != null) {
                if (typeof object.health !== "object")
                    throw TypeError(".appliancepb.HealthCheckResponse.health: object expected");
                message.health = $root.appliancepb.HealthCheck.fromObject(object.health);
            }
            return message;
        };

        /**
         * Creates a plain object from a HealthCheckResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.HealthCheckResponse
         * @static
         * @param {appliancepb.HealthCheckResponse} message HealthCheckResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HealthCheckResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.health = null;
            if (message.health != null && message.hasOwnProperty("health"))
                object.health = $root.appliancepb.HealthCheck.toObject(message.health, options);
            return object;
        };

        /**
         * Converts this HealthCheckResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.HealthCheckResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HealthCheckResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HealthCheckResponse;
    })();

    appliancepb.Installer = (function() {

        /**
         * Properties of an Installer.
         * @memberof appliancepb
         * @interface IInstaller
         * @property {string|null} [name] Installer name
         * @property {string|null} [phone] Installer phone
         * @property {string|null} [street1] Installer street1
         * @property {string|null} [street2] Installer street2
         * @property {string|null} [city] Installer city
         * @property {string|null} [state] Installer state
         * @property {string|null} [zip] Installer zip
         */

        /**
         * Constructs a new Installer.
         * @memberof appliancepb
         * @classdesc Represents an Installer.
         * @implements IInstaller
         * @constructor
         * @param {appliancepb.IInstaller=} [properties] Properties to set
         */
        function Installer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Installer name.
         * @member {string} name
         * @memberof appliancepb.Installer
         * @instance
         */
        Installer.prototype.name = "";

        /**
         * Installer phone.
         * @member {string} phone
         * @memberof appliancepb.Installer
         * @instance
         */
        Installer.prototype.phone = "";

        /**
         * Installer street1.
         * @member {string} street1
         * @memberof appliancepb.Installer
         * @instance
         */
        Installer.prototype.street1 = "";

        /**
         * Installer street2.
         * @member {string} street2
         * @memberof appliancepb.Installer
         * @instance
         */
        Installer.prototype.street2 = "";

        /**
         * Installer city.
         * @member {string} city
         * @memberof appliancepb.Installer
         * @instance
         */
        Installer.prototype.city = "";

        /**
         * Installer state.
         * @member {string} state
         * @memberof appliancepb.Installer
         * @instance
         */
        Installer.prototype.state = "";

        /**
         * Installer zip.
         * @member {string} zip
         * @memberof appliancepb.Installer
         * @instance
         */
        Installer.prototype.zip = "";

        /**
         * Creates a new Installer instance using the specified properties.
         * @function create
         * @memberof appliancepb.Installer
         * @static
         * @param {appliancepb.IInstaller=} [properties] Properties to set
         * @returns {appliancepb.Installer} Installer instance
         */
        Installer.create = function create(properties) {
            return new Installer(properties);
        };

        /**
         * Encodes the specified Installer message. Does not implicitly {@link appliancepb.Installer.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Installer
         * @static
         * @param {appliancepb.IInstaller} message Installer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Installer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.phone);
            if (message.street1 != null && Object.hasOwnProperty.call(message, "street1"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.street1);
            if (message.street2 != null && Object.hasOwnProperty.call(message, "street2"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.street2);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.city);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.state);
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.zip);
            return writer;
        };

        /**
         * Encodes the specified Installer message, length delimited. Does not implicitly {@link appliancepb.Installer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Installer
         * @static
         * @param {appliancepb.IInstaller} message Installer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Installer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Installer message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Installer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Installer} Installer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Installer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Installer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.phone = reader.string();
                    break;
                case 3:
                    message.street1 = reader.string();
                    break;
                case 4:
                    message.street2 = reader.string();
                    break;
                case 5:
                    message.city = reader.string();
                    break;
                case 6:
                    message.state = reader.string();
                    break;
                case 7:
                    message.zip = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Installer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Installer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Installer} Installer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Installer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Installer message.
         * @function verify
         * @memberof appliancepb.Installer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Installer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.street1 != null && message.hasOwnProperty("street1"))
                if (!$util.isString(message.street1))
                    return "street1: string expected";
            if (message.street2 != null && message.hasOwnProperty("street2"))
                if (!$util.isString(message.street2))
                    return "street2: string expected";
            if (message.city != null && message.hasOwnProperty("city"))
                if (!$util.isString(message.city))
                    return "city: string expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!$util.isString(message.state))
                    return "state: string expected";
            if (message.zip != null && message.hasOwnProperty("zip"))
                if (!$util.isString(message.zip))
                    return "zip: string expected";
            return null;
        };

        /**
         * Creates an Installer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Installer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Installer} Installer
         */
        Installer.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Installer)
                return object;
            let message = new $root.appliancepb.Installer();
            if (object.name != null)
                message.name = String(object.name);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.street1 != null)
                message.street1 = String(object.street1);
            if (object.street2 != null)
                message.street2 = String(object.street2);
            if (object.city != null)
                message.city = String(object.city);
            if (object.state != null)
                message.state = String(object.state);
            if (object.zip != null)
                message.zip = String(object.zip);
            return message;
        };

        /**
         * Creates a plain object from an Installer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Installer
         * @static
         * @param {appliancepb.Installer} message Installer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Installer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.phone = "";
                object.street1 = "";
                object.street2 = "";
                object.city = "";
                object.state = "";
                object.zip = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.street1 != null && message.hasOwnProperty("street1"))
                object.street1 = message.street1;
            if (message.street2 != null && message.hasOwnProperty("street2"))
                object.street2 = message.street2;
            if (message.city != null && message.hasOwnProperty("city"))
                object.city = message.city;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            if (message.zip != null && message.hasOwnProperty("zip"))
                object.zip = message.zip;
            return object;
        };

        /**
         * Converts this Installer to JSON.
         * @function toJSON
         * @memberof appliancepb.Installer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Installer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Installer;
    })();

    appliancepb.Address = (function() {

        /**
         * Properties of an Address.
         * @memberof appliancepb
         * @interface IAddress
         * @property {string|null} [street1] Address street1
         * @property {string|null} [street2] Address street2
         * @property {string|null} [unitType] Address unitType
         * @property {string|null} [unitValue] Address unitValue
         * @property {string|null} [city] Address city
         * @property {string|null} [state] Address state
         * @property {string|null} [zip] Address zip
         * @property {string|null} [zipLocal] Address zipLocal
         * @property {string|null} [countryISO3] Address countryISO3
         * @property {string|null} [id] Address id
         */

        /**
         * Constructs a new Address.
         * @memberof appliancepb
         * @classdesc Represents an Address.
         * @implements IAddress
         * @constructor
         * @param {appliancepb.IAddress=} [properties] Properties to set
         */
        function Address(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Address street1.
         * @member {string} street1
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.street1 = "";

        /**
         * Address street2.
         * @member {string} street2
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.street2 = "";

        /**
         * Address unitType.
         * @member {string} unitType
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.unitType = "";

        /**
         * Address unitValue.
         * @member {string} unitValue
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.unitValue = "";

        /**
         * Address city.
         * @member {string} city
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.city = "";

        /**
         * Address state.
         * @member {string} state
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.state = "";

        /**
         * Address zip.
         * @member {string} zip
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.zip = "";

        /**
         * Address zipLocal.
         * @member {string} zipLocal
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.zipLocal = "";

        /**
         * Address countryISO3.
         * @member {string} countryISO3
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.countryISO3 = "";

        /**
         * Address id.
         * @member {string} id
         * @memberof appliancepb.Address
         * @instance
         */
        Address.prototype.id = "";

        /**
         * Creates a new Address instance using the specified properties.
         * @function create
         * @memberof appliancepb.Address
         * @static
         * @param {appliancepb.IAddress=} [properties] Properties to set
         * @returns {appliancepb.Address} Address instance
         */
        Address.create = function create(properties) {
            return new Address(properties);
        };

        /**
         * Encodes the specified Address message. Does not implicitly {@link appliancepb.Address.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Address
         * @static
         * @param {appliancepb.IAddress} message Address message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Address.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.street1 != null && Object.hasOwnProperty.call(message, "street1"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.street1);
            if (message.street2 != null && Object.hasOwnProperty.call(message, "street2"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.street2);
            if (message.unitType != null && Object.hasOwnProperty.call(message, "unitType"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.unitType);
            if (message.unitValue != null && Object.hasOwnProperty.call(message, "unitValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unitValue);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.city);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.state);
            if (message.zip != null && Object.hasOwnProperty.call(message, "zip"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.zip);
            if (message.zipLocal != null && Object.hasOwnProperty.call(message, "zipLocal"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.zipLocal);
            if (message.countryISO3 != null && Object.hasOwnProperty.call(message, "countryISO3"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.countryISO3);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Address message, length delimited. Does not implicitly {@link appliancepb.Address.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Address
         * @static
         * @param {appliancepb.IAddress} message Address message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Address.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Address message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Address
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Address} Address
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Address.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Address();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.street1 = reader.string();
                    break;
                case 2:
                    message.street2 = reader.string();
                    break;
                case 3:
                    message.unitType = reader.string();
                    break;
                case 4:
                    message.unitValue = reader.string();
                    break;
                case 5:
                    message.city = reader.string();
                    break;
                case 6:
                    message.state = reader.string();
                    break;
                case 7:
                    message.zip = reader.string();
                    break;
                case 8:
                    message.zipLocal = reader.string();
                    break;
                case 9:
                    message.countryISO3 = reader.string();
                    break;
                case 10:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Address message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Address
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Address} Address
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Address.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Address message.
         * @function verify
         * @memberof appliancepb.Address
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Address.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.street1 != null && message.hasOwnProperty("street1"))
                if (!$util.isString(message.street1))
                    return "street1: string expected";
            if (message.street2 != null && message.hasOwnProperty("street2"))
                if (!$util.isString(message.street2))
                    return "street2: string expected";
            if (message.unitType != null && message.hasOwnProperty("unitType"))
                if (!$util.isString(message.unitType))
                    return "unitType: string expected";
            if (message.unitValue != null && message.hasOwnProperty("unitValue"))
                if (!$util.isString(message.unitValue))
                    return "unitValue: string expected";
            if (message.city != null && message.hasOwnProperty("city"))
                if (!$util.isString(message.city))
                    return "city: string expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!$util.isString(message.state))
                    return "state: string expected";
            if (message.zip != null && message.hasOwnProperty("zip"))
                if (!$util.isString(message.zip))
                    return "zip: string expected";
            if (message.zipLocal != null && message.hasOwnProperty("zipLocal"))
                if (!$util.isString(message.zipLocal))
                    return "zipLocal: string expected";
            if (message.countryISO3 != null && message.hasOwnProperty("countryISO3"))
                if (!$util.isString(message.countryISO3))
                    return "countryISO3: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates an Address message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Address
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Address} Address
         */
        Address.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Address)
                return object;
            let message = new $root.appliancepb.Address();
            if (object.street1 != null)
                message.street1 = String(object.street1);
            if (object.street2 != null)
                message.street2 = String(object.street2);
            if (object.unitType != null)
                message.unitType = String(object.unitType);
            if (object.unitValue != null)
                message.unitValue = String(object.unitValue);
            if (object.city != null)
                message.city = String(object.city);
            if (object.state != null)
                message.state = String(object.state);
            if (object.zip != null)
                message.zip = String(object.zip);
            if (object.zipLocal != null)
                message.zipLocal = String(object.zipLocal);
            if (object.countryISO3 != null)
                message.countryISO3 = String(object.countryISO3);
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from an Address message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Address
         * @static
         * @param {appliancepb.Address} message Address
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Address.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.street1 = "";
                object.street2 = "";
                object.unitType = "";
                object.unitValue = "";
                object.city = "";
                object.state = "";
                object.zip = "";
                object.zipLocal = "";
                object.countryISO3 = "";
                object.id = "";
            }
            if (message.street1 != null && message.hasOwnProperty("street1"))
                object.street1 = message.street1;
            if (message.street2 != null && message.hasOwnProperty("street2"))
                object.street2 = message.street2;
            if (message.unitType != null && message.hasOwnProperty("unitType"))
                object.unitType = message.unitType;
            if (message.unitValue != null && message.hasOwnProperty("unitValue"))
                object.unitValue = message.unitValue;
            if (message.city != null && message.hasOwnProperty("city"))
                object.city = message.city;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            if (message.zip != null && message.hasOwnProperty("zip"))
                object.zip = message.zip;
            if (message.zipLocal != null && message.hasOwnProperty("zipLocal"))
                object.zipLocal = message.zipLocal;
            if (message.countryISO3 != null && message.hasOwnProperty("countryISO3"))
                object.countryISO3 = message.countryISO3;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this Address to JSON.
         * @function toJSON
         * @memberof appliancepb.Address
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Address.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Address;
    })();

    appliancepb.Customer = (function() {

        /**
         * Properties of a Customer.
         * @memberof appliancepb
         * @interface ICustomer
         * @property {string|null} [firstName] Customer firstName
         * @property {string|null} [lastName] Customer lastName
         * @property {string|null} [email] Customer email
         * @property {string|null} [phone] Customer phone
         * @property {appliancepb.IAddress|null} [deliveryAddress] Customer deliveryAddress
         * @property {string|null} [businessName] Customer businessName
         * @property {appliancepb.IAddress|null} [billingAddress] Customer billingAddress
         * @property {string|null} [id] Customer id
         */

        /**
         * Constructs a new Customer.
         * @memberof appliancepb
         * @classdesc Represents a Customer.
         * @implements ICustomer
         * @constructor
         * @param {appliancepb.ICustomer=} [properties] Properties to set
         */
        function Customer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Customer firstName.
         * @member {string} firstName
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.firstName = "";

        /**
         * Customer lastName.
         * @member {string} lastName
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.lastName = "";

        /**
         * Customer email.
         * @member {string} email
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.email = "";

        /**
         * Customer phone.
         * @member {string} phone
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.phone = "";

        /**
         * Customer deliveryAddress.
         * @member {appliancepb.IAddress|null|undefined} deliveryAddress
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.deliveryAddress = null;

        /**
         * Customer businessName.
         * @member {string} businessName
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.businessName = "";

        /**
         * Customer billingAddress.
         * @member {appliancepb.IAddress|null|undefined} billingAddress
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.billingAddress = null;

        /**
         * Customer id.
         * @member {string} id
         * @memberof appliancepb.Customer
         * @instance
         */
        Customer.prototype.id = "";

        /**
         * Creates a new Customer instance using the specified properties.
         * @function create
         * @memberof appliancepb.Customer
         * @static
         * @param {appliancepb.ICustomer=} [properties] Properties to set
         * @returns {appliancepb.Customer} Customer instance
         */
        Customer.create = function create(properties) {
            return new Customer(properties);
        };

        /**
         * Encodes the specified Customer message. Does not implicitly {@link appliancepb.Customer.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Customer
         * @static
         * @param {appliancepb.ICustomer} message Customer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Customer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
            if (message.deliveryAddress != null && Object.hasOwnProperty.call(message, "deliveryAddress"))
                $root.appliancepb.Address.encode(message.deliveryAddress, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.businessName);
            if (message.billingAddress != null && Object.hasOwnProperty.call(message, "billingAddress"))
                $root.appliancepb.Address.encode(message.billingAddress, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Customer message, length delimited. Does not implicitly {@link appliancepb.Customer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Customer
         * @static
         * @param {appliancepb.ICustomer} message Customer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Customer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Customer message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Customer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Customer} Customer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Customer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Customer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.firstName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.email = reader.string();
                    break;
                case 4:
                    message.phone = reader.string();
                    break;
                case 5:
                    message.deliveryAddress = $root.appliancepb.Address.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.businessName = reader.string();
                    break;
                case 7:
                    message.billingAddress = $root.appliancepb.Address.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Customer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Customer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Customer} Customer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Customer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Customer message.
         * @function verify
         * @memberof appliancepb.Customer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Customer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress")) {
                let error = $root.appliancepb.Address.verify(message.deliveryAddress);
                if (error)
                    return "deliveryAddress." + error;
            }
            if (message.businessName != null && message.hasOwnProperty("businessName"))
                if (!$util.isString(message.businessName))
                    return "businessName: string expected";
            if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
                let error = $root.appliancepb.Address.verify(message.billingAddress);
                if (error)
                    return "billingAddress." + error;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a Customer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Customer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Customer} Customer
         */
        Customer.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Customer)
                return object;
            let message = new $root.appliancepb.Customer();
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.email != null)
                message.email = String(object.email);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.deliveryAddress != null) {
                if (typeof object.deliveryAddress !== "object")
                    throw TypeError(".appliancepb.Customer.deliveryAddress: object expected");
                message.deliveryAddress = $root.appliancepb.Address.fromObject(object.deliveryAddress);
            }
            if (object.businessName != null)
                message.businessName = String(object.businessName);
            if (object.billingAddress != null) {
                if (typeof object.billingAddress !== "object")
                    throw TypeError(".appliancepb.Customer.billingAddress: object expected");
                message.billingAddress = $root.appliancepb.Address.fromObject(object.billingAddress);
            }
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a Customer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Customer
         * @static
         * @param {appliancepb.Customer} message Customer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Customer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.firstName = "";
                object.lastName = "";
                object.email = "";
                object.phone = "";
                object.deliveryAddress = null;
                object.businessName = "";
                object.billingAddress = null;
                object.id = "";
            }
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress"))
                object.deliveryAddress = $root.appliancepb.Address.toObject(message.deliveryAddress, options);
            if (message.businessName != null && message.hasOwnProperty("businessName"))
                object.businessName = message.businessName;
            if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                object.billingAddress = $root.appliancepb.Address.toObject(message.billingAddress, options);
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this Customer to JSON.
         * @function toJSON
         * @memberof appliancepb.Customer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Customer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Customer;
    })();

    appliancepb.CreateReplacementNoteRequest = (function() {

        /**
         * Properties of a CreateReplacementNoteRequest.
         * @memberof appliancepb
         * @interface ICreateReplacementNoteRequest
         * @property {string|null} [message] CreateReplacementNoteRequest message
         */

        /**
         * Constructs a new CreateReplacementNoteRequest.
         * @memberof appliancepb
         * @classdesc Represents a CreateReplacementNoteRequest.
         * @implements ICreateReplacementNoteRequest
         * @constructor
         * @param {appliancepb.ICreateReplacementNoteRequest=} [properties] Properties to set
         */
        function CreateReplacementNoteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateReplacementNoteRequest message.
         * @member {string} message
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @instance
         */
        CreateReplacementNoteRequest.prototype.message = "";

        /**
         * Creates a new CreateReplacementNoteRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {appliancepb.ICreateReplacementNoteRequest=} [properties] Properties to set
         * @returns {appliancepb.CreateReplacementNoteRequest} CreateReplacementNoteRequest instance
         */
        CreateReplacementNoteRequest.create = function create(properties) {
            return new CreateReplacementNoteRequest(properties);
        };

        /**
         * Encodes the specified CreateReplacementNoteRequest message. Does not implicitly {@link appliancepb.CreateReplacementNoteRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {appliancepb.ICreateReplacementNoteRequest} message CreateReplacementNoteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateReplacementNoteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified CreateReplacementNoteRequest message, length delimited. Does not implicitly {@link appliancepb.CreateReplacementNoteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {appliancepb.ICreateReplacementNoteRequest} message CreateReplacementNoteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateReplacementNoteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateReplacementNoteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.CreateReplacementNoteRequest} CreateReplacementNoteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateReplacementNoteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.CreateReplacementNoteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateReplacementNoteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.CreateReplacementNoteRequest} CreateReplacementNoteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateReplacementNoteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateReplacementNoteRequest message.
         * @function verify
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateReplacementNoteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a CreateReplacementNoteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.CreateReplacementNoteRequest} CreateReplacementNoteRequest
         */
        CreateReplacementNoteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.CreateReplacementNoteRequest)
                return object;
            let message = new $root.appliancepb.CreateReplacementNoteRequest();
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a CreateReplacementNoteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @static
         * @param {appliancepb.CreateReplacementNoteRequest} message CreateReplacementNoteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateReplacementNoteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.message = "";
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this CreateReplacementNoteRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.CreateReplacementNoteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateReplacementNoteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateReplacementNoteRequest;
    })();

    appliancepb.Note = (function() {

        /**
         * Properties of a Note.
         * @memberof appliancepb
         * @interface INote
         * @property {google.protobuf.ITimestamp|null} [created] Note created
         * @property {string|null} [message] Note message
         * @property {string|null} [creator] Note creator
         */

        /**
         * Constructs a new Note.
         * @memberof appliancepb
         * @classdesc Represents a Note.
         * @implements INote
         * @constructor
         * @param {appliancepb.INote=} [properties] Properties to set
         */
        function Note(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Note created.
         * @member {google.protobuf.ITimestamp|null|undefined} created
         * @memberof appliancepb.Note
         * @instance
         */
        Note.prototype.created = null;

        /**
         * Note message.
         * @member {string} message
         * @memberof appliancepb.Note
         * @instance
         */
        Note.prototype.message = "";

        /**
         * Note creator.
         * @member {string} creator
         * @memberof appliancepb.Note
         * @instance
         */
        Note.prototype.creator = "";

        /**
         * Creates a new Note instance using the specified properties.
         * @function create
         * @memberof appliancepb.Note
         * @static
         * @param {appliancepb.INote=} [properties] Properties to set
         * @returns {appliancepb.Note} Note instance
         */
        Note.create = function create(properties) {
            return new Note(properties);
        };

        /**
         * Encodes the specified Note message. Does not implicitly {@link appliancepb.Note.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Note
         * @static
         * @param {appliancepb.INote} message Note message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Note.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                $root.google.protobuf.Timestamp.encode(message.created, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.creator != null && Object.hasOwnProperty.call(message, "creator"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.creator);
            return writer;
        };

        /**
         * Encodes the specified Note message, length delimited. Does not implicitly {@link appliancepb.Note.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Note
         * @static
         * @param {appliancepb.INote} message Note message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Note.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Note message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Note
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Note} Note
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Note.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Note();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.created = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.creator = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Note message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Note
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Note} Note
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Note.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Note message.
         * @function verify
         * @memberof appliancepb.Note
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Note.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.created != null && message.hasOwnProperty("created")) {
                let error = $root.google.protobuf.Timestamp.verify(message.created);
                if (error)
                    return "created." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.creator != null && message.hasOwnProperty("creator"))
                if (!$util.isString(message.creator))
                    return "creator: string expected";
            return null;
        };

        /**
         * Creates a Note message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Note
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Note} Note
         */
        Note.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Note)
                return object;
            let message = new $root.appliancepb.Note();
            if (object.created != null) {
                if (typeof object.created !== "object")
                    throw TypeError(".appliancepb.Note.created: object expected");
                message.created = $root.google.protobuf.Timestamp.fromObject(object.created);
            }
            if (object.message != null)
                message.message = String(object.message);
            if (object.creator != null)
                message.creator = String(object.creator);
            return message;
        };

        /**
         * Creates a plain object from a Note message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Note
         * @static
         * @param {appliancepb.Note} message Note
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Note.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.created = null;
                object.message = "";
                object.creator = "";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                object.created = $root.google.protobuf.Timestamp.toObject(message.created, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.creator != null && message.hasOwnProperty("creator"))
                object.creator = message.creator;
            return object;
        };

        /**
         * Converts this Note to JSON.
         * @function toJSON
         * @memberof appliancepb.Note
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Note.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Note;
    })();

    appliancepb.FullReplacementDetails = (function() {

        /**
         * Properties of a FullReplacementDetails.
         * @memberof appliancepb
         * @interface IFullReplacementDetails
         * @property {string|null} [ID] FullReplacementDetails ID
         * @property {string|null} [tenantID] FullReplacementDetails tenantID
         * @property {string|null} [userID] FullReplacementDetails userID
         * @property {string|null} [paymentID] FullReplacementDetails paymentID
         * @property {string|null} [addressID] FullReplacementDetails addressID
         * @property {string|null} [transactionID] FullReplacementDetails transactionID
         * @property {string|null} [serviceRequestID] FullReplacementDetails serviceRequestID
         * @property {number|null} [discount] FullReplacementDetails discount
         * @property {appliancepb.IPair|null} [item] FullReplacementDetails item
         * @property {appliancepb.IPair|null} [brand] FullReplacementDetails brand
         * @property {appliancepb.IPair|null} [style] FullReplacementDetails style
         * @property {appliancepb.FullReplacementDetails.IPurchase|null} [purchase] FullReplacementDetails purchase
         * @property {google.protobuf.ITimestamp|null} [createdAt] FullReplacementDetails createdAt
         * @property {google.protobuf.ITimestamp|null} [lastUpdated] FullReplacementDetails lastUpdated
         * @property {string|null} [status] FullReplacementDetails status
         * @property {string|null} [location] FullReplacementDetails location
         * @property {string|null} [deliveryInstructions] FullReplacementDetails deliveryInstructions
         * @property {boolean|null} [confirmedDimensions] FullReplacementDetails confirmedDimensions
         * @property {boolean|null} [agreesToTermsAndConditions] FullReplacementDetails agreesToTermsAndConditions
         * @property {appliancepb.IInstaller|null} [installer] FullReplacementDetails installer
         * @property {string|null} [dispatchID] FullReplacementDetails dispatchID
         * @property {string|null} [likeForLikeProductID] FullReplacementDetails likeForLikeProductID
         * @property {boolean|null} [hasDisposalCoverage] FullReplacementDetails hasDisposalCoverage
         * @property {number|Long|null} [cashInLieu] FullReplacementDetails cashInLieu
         * @property {string|null} [contractID] FullReplacementDetails contractID
         * @property {string|null} [partRequestID] FullReplacementDetails partRequestID
         * @property {appliancepb.IPayment|null} [payment] FullReplacementDetails payment
         * @property {google.protobuf.ITimestamp|null} [orderDate] FullReplacementDetails orderDate
         * @property {google.protobuf.ITimestamp|null} [deliveryDate] FullReplacementDetails deliveryDate
         * @property {appliancepb.ICustomer|null} [customer] FullReplacementDetails customer
         * @property {appliancepb.IProduct|null} [product] FullReplacementDetails product
         * @property {string|null} [vendorOrderNumber] FullReplacementDetails vendorOrderNumber
         * @property {string|null} [shopLink] FullReplacementDetails shopLink
         * @property {string|null} [orderStatusLink] FullReplacementDetails orderStatusLink
         * @property {Array.<appliancepb.INote>|null} [notes] FullReplacementDetails notes
         * @property {appliancepb.IPriceInformation|null} [likeForLikePrice] FullReplacementDetails likeForLikePrice
         */

        /**
         * Constructs a new FullReplacementDetails.
         * @memberof appliancepb
         * @classdesc Represents a FullReplacementDetails.
         * @implements IFullReplacementDetails
         * @constructor
         * @param {appliancepb.IFullReplacementDetails=} [properties] Properties to set
         */
        function FullReplacementDetails(properties) {
            this.notes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FullReplacementDetails ID.
         * @member {string} ID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.ID = "";

        /**
         * FullReplacementDetails tenantID.
         * @member {string} tenantID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.tenantID = "";

        /**
         * FullReplacementDetails userID.
         * @member {string} userID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.userID = "";

        /**
         * FullReplacementDetails paymentID.
         * @member {string} paymentID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.paymentID = "";

        /**
         * FullReplacementDetails addressID.
         * @member {string} addressID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.addressID = "";

        /**
         * FullReplacementDetails transactionID.
         * @member {string} transactionID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.transactionID = "";

        /**
         * FullReplacementDetails serviceRequestID.
         * @member {string} serviceRequestID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.serviceRequestID = "";

        /**
         * FullReplacementDetails discount.
         * @member {number} discount
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.discount = 0;

        /**
         * FullReplacementDetails item.
         * @member {appliancepb.IPair|null|undefined} item
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.item = null;

        /**
         * FullReplacementDetails brand.
         * @member {appliancepb.IPair|null|undefined} brand
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.brand = null;

        /**
         * FullReplacementDetails style.
         * @member {appliancepb.IPair|null|undefined} style
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.style = null;

        /**
         * FullReplacementDetails purchase.
         * @member {appliancepb.FullReplacementDetails.IPurchase|null|undefined} purchase
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.purchase = null;

        /**
         * FullReplacementDetails createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.createdAt = null;

        /**
         * FullReplacementDetails lastUpdated.
         * @member {google.protobuf.ITimestamp|null|undefined} lastUpdated
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.lastUpdated = null;

        /**
         * FullReplacementDetails status.
         * @member {string} status
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.status = "";

        /**
         * FullReplacementDetails location.
         * @member {string} location
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.location = "";

        /**
         * FullReplacementDetails deliveryInstructions.
         * @member {string} deliveryInstructions
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.deliveryInstructions = "";

        /**
         * FullReplacementDetails confirmedDimensions.
         * @member {boolean} confirmedDimensions
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.confirmedDimensions = false;

        /**
         * FullReplacementDetails agreesToTermsAndConditions.
         * @member {boolean} agreesToTermsAndConditions
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.agreesToTermsAndConditions = false;

        /**
         * FullReplacementDetails installer.
         * @member {appliancepb.IInstaller|null|undefined} installer
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.installer = null;

        /**
         * FullReplacementDetails dispatchID.
         * @member {string} dispatchID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.dispatchID = "";

        /**
         * FullReplacementDetails likeForLikeProductID.
         * @member {string} likeForLikeProductID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.likeForLikeProductID = "";

        /**
         * FullReplacementDetails hasDisposalCoverage.
         * @member {boolean} hasDisposalCoverage
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.hasDisposalCoverage = false;

        /**
         * FullReplacementDetails cashInLieu.
         * @member {number|Long} cashInLieu
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.cashInLieu = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FullReplacementDetails contractID.
         * @member {string} contractID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.contractID = "";

        /**
         * FullReplacementDetails partRequestID.
         * @member {string} partRequestID
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.partRequestID = "";

        /**
         * FullReplacementDetails payment.
         * @member {appliancepb.IPayment|null|undefined} payment
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.payment = null;

        /**
         * FullReplacementDetails orderDate.
         * @member {google.protobuf.ITimestamp|null|undefined} orderDate
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.orderDate = null;

        /**
         * FullReplacementDetails deliveryDate.
         * @member {google.protobuf.ITimestamp|null|undefined} deliveryDate
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.deliveryDate = null;

        /**
         * FullReplacementDetails customer.
         * @member {appliancepb.ICustomer|null|undefined} customer
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.customer = null;

        /**
         * FullReplacementDetails product.
         * @member {appliancepb.IProduct|null|undefined} product
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.product = null;

        /**
         * FullReplacementDetails vendorOrderNumber.
         * @member {string} vendorOrderNumber
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.vendorOrderNumber = "";

        /**
         * FullReplacementDetails shopLink.
         * @member {string} shopLink
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.shopLink = "";

        /**
         * FullReplacementDetails orderStatusLink.
         * @member {string} orderStatusLink
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.orderStatusLink = "";

        /**
         * FullReplacementDetails notes.
         * @member {Array.<appliancepb.INote>} notes
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.notes = $util.emptyArray;

        /**
         * FullReplacementDetails likeForLikePrice.
         * @member {appliancepb.IPriceInformation|null|undefined} likeForLikePrice
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         */
        FullReplacementDetails.prototype.likeForLikePrice = null;

        /**
         * Creates a new FullReplacementDetails instance using the specified properties.
         * @function create
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {appliancepb.IFullReplacementDetails=} [properties] Properties to set
         * @returns {appliancepb.FullReplacementDetails} FullReplacementDetails instance
         */
        FullReplacementDetails.create = function create(properties) {
            return new FullReplacementDetails(properties);
        };

        /**
         * Encodes the specified FullReplacementDetails message. Does not implicitly {@link appliancepb.FullReplacementDetails.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {appliancepb.IFullReplacementDetails} message FullReplacementDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullReplacementDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.tenantID != null && Object.hasOwnProperty.call(message, "tenantID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tenantID);
            if (message.userID != null && Object.hasOwnProperty.call(message, "userID"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userID);
            if (message.paymentID != null && Object.hasOwnProperty.call(message, "paymentID"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.paymentID);
            if (message.addressID != null && Object.hasOwnProperty.call(message, "addressID"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.addressID);
            if (message.transactionID != null && Object.hasOwnProperty.call(message, "transactionID"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.transactionID);
            if (message.serviceRequestID != null && Object.hasOwnProperty.call(message, "serviceRequestID"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.serviceRequestID);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.discount);
            if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                $root.appliancepb.Pair.encode(message.item, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.brand != null && Object.hasOwnProperty.call(message, "brand"))
                $root.appliancepb.Pair.encode(message.brand, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.style != null && Object.hasOwnProperty.call(message, "style"))
                $root.appliancepb.Pair.encode(message.style, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.purchase != null && Object.hasOwnProperty.call(message, "purchase"))
                $root.appliancepb.FullReplacementDetails.Purchase.encode(message.purchase, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.lastUpdated != null && Object.hasOwnProperty.call(message, "lastUpdated"))
                $root.google.protobuf.Timestamp.encode(message.lastUpdated, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.status);
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.location);
            if (message.deliveryInstructions != null && Object.hasOwnProperty.call(message, "deliveryInstructions"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.deliveryInstructions);
            if (message.confirmedDimensions != null && Object.hasOwnProperty.call(message, "confirmedDimensions"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.confirmedDimensions);
            if (message.agreesToTermsAndConditions != null && Object.hasOwnProperty.call(message, "agreesToTermsAndConditions"))
                writer.uint32(/* id 19, wireType 0 =*/152).bool(message.agreesToTermsAndConditions);
            if (message.installer != null && Object.hasOwnProperty.call(message, "installer"))
                $root.appliancepb.Installer.encode(message.installer, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.dispatchID != null && Object.hasOwnProperty.call(message, "dispatchID"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.dispatchID);
            if (message.likeForLikeProductID != null && Object.hasOwnProperty.call(message, "likeForLikeProductID"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.likeForLikeProductID);
            if (message.hasDisposalCoverage != null && Object.hasOwnProperty.call(message, "hasDisposalCoverage"))
                writer.uint32(/* id 23, wireType 0 =*/184).bool(message.hasDisposalCoverage);
            if (message.cashInLieu != null && Object.hasOwnProperty.call(message, "cashInLieu"))
                writer.uint32(/* id 24, wireType 0 =*/192).int64(message.cashInLieu);
            if (message.contractID != null && Object.hasOwnProperty.call(message, "contractID"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.contractID);
            if (message.partRequestID != null && Object.hasOwnProperty.call(message, "partRequestID"))
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.partRequestID);
            if (message.payment != null && Object.hasOwnProperty.call(message, "payment"))
                $root.appliancepb.Payment.encode(message.payment, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.orderDate != null && Object.hasOwnProperty.call(message, "orderDate"))
                $root.google.protobuf.Timestamp.encode(message.orderDate, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.deliveryDate != null && Object.hasOwnProperty.call(message, "deliveryDate"))
                $root.google.protobuf.Timestamp.encode(message.deliveryDate, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.appliancepb.Customer.encode(message.customer, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.appliancepb.Product.encode(message.product, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.vendorOrderNumber != null && Object.hasOwnProperty.call(message, "vendorOrderNumber"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.vendorOrderNumber);
            if (message.shopLink != null && Object.hasOwnProperty.call(message, "shopLink"))
                writer.uint32(/* id 33, wireType 2 =*/266).string(message.shopLink);
            if (message.orderStatusLink != null && Object.hasOwnProperty.call(message, "orderStatusLink"))
                writer.uint32(/* id 34, wireType 2 =*/274).string(message.orderStatusLink);
            if (message.notes != null && message.notes.length)
                for (let i = 0; i < message.notes.length; ++i)
                    $root.appliancepb.Note.encode(message.notes[i], writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.likeForLikePrice != null && Object.hasOwnProperty.call(message, "likeForLikePrice"))
                $root.appliancepb.PriceInformation.encode(message.likeForLikePrice, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FullReplacementDetails message, length delimited. Does not implicitly {@link appliancepb.FullReplacementDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {appliancepb.IFullReplacementDetails} message FullReplacementDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullReplacementDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FullReplacementDetails message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.FullReplacementDetails} FullReplacementDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullReplacementDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.FullReplacementDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.tenantID = reader.string();
                    break;
                case 3:
                    message.userID = reader.string();
                    break;
                case 4:
                    message.paymentID = reader.string();
                    break;
                case 5:
                    message.addressID = reader.string();
                    break;
                case 6:
                    message.transactionID = reader.string();
                    break;
                case 7:
                    message.serviceRequestID = reader.string();
                    break;
                case 8:
                    message.discount = reader.int32();
                    break;
                case 9:
                    message.item = $root.appliancepb.Pair.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.brand = $root.appliancepb.Pair.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.style = $root.appliancepb.Pair.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.purchase = $root.appliancepb.FullReplacementDetails.Purchase.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.lastUpdated = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.status = reader.string();
                    break;
                case 16:
                    message.location = reader.string();
                    break;
                case 17:
                    message.deliveryInstructions = reader.string();
                    break;
                case 18:
                    message.confirmedDimensions = reader.bool();
                    break;
                case 19:
                    message.agreesToTermsAndConditions = reader.bool();
                    break;
                case 20:
                    message.installer = $root.appliancepb.Installer.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.dispatchID = reader.string();
                    break;
                case 22:
                    message.likeForLikeProductID = reader.string();
                    break;
                case 23:
                    message.hasDisposalCoverage = reader.bool();
                    break;
                case 24:
                    message.cashInLieu = reader.int64();
                    break;
                case 25:
                    message.contractID = reader.string();
                    break;
                case 26:
                    message.partRequestID = reader.string();
                    break;
                case 27:
                    message.payment = $root.appliancepb.Payment.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.orderDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.deliveryDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.customer = $root.appliancepb.Customer.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.product = $root.appliancepb.Product.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.vendorOrderNumber = reader.string();
                    break;
                case 33:
                    message.shopLink = reader.string();
                    break;
                case 34:
                    message.orderStatusLink = reader.string();
                    break;
                case 35:
                    if (!(message.notes && message.notes.length))
                        message.notes = [];
                    message.notes.push($root.appliancepb.Note.decode(reader, reader.uint32()));
                    break;
                case 36:
                    message.likeForLikePrice = $root.appliancepb.PriceInformation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FullReplacementDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.FullReplacementDetails} FullReplacementDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullReplacementDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FullReplacementDetails message.
         * @function verify
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FullReplacementDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.tenantID != null && message.hasOwnProperty("tenantID"))
                if (!$util.isString(message.tenantID))
                    return "tenantID: string expected";
            if (message.userID != null && message.hasOwnProperty("userID"))
                if (!$util.isString(message.userID))
                    return "userID: string expected";
            if (message.paymentID != null && message.hasOwnProperty("paymentID"))
                if (!$util.isString(message.paymentID))
                    return "paymentID: string expected";
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                if (!$util.isString(message.addressID))
                    return "addressID: string expected";
            if (message.transactionID != null && message.hasOwnProperty("transactionID"))
                if (!$util.isString(message.transactionID))
                    return "transactionID: string expected";
            if (message.serviceRequestID != null && message.hasOwnProperty("serviceRequestID"))
                if (!$util.isString(message.serviceRequestID))
                    return "serviceRequestID: string expected";
            if (message.discount != null && message.hasOwnProperty("discount"))
                if (!$util.isInteger(message.discount))
                    return "discount: integer expected";
            if (message.item != null && message.hasOwnProperty("item")) {
                let error = $root.appliancepb.Pair.verify(message.item);
                if (error)
                    return "item." + error;
            }
            if (message.brand != null && message.hasOwnProperty("brand")) {
                let error = $root.appliancepb.Pair.verify(message.brand);
                if (error)
                    return "brand." + error;
            }
            if (message.style != null && message.hasOwnProperty("style")) {
                let error = $root.appliancepb.Pair.verify(message.style);
                if (error)
                    return "style." + error;
            }
            if (message.purchase != null && message.hasOwnProperty("purchase")) {
                let error = $root.appliancepb.FullReplacementDetails.Purchase.verify(message.purchase);
                if (error)
                    return "purchase." + error;
            }
            if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                let error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                if (error)
                    return "createdAt." + error;
            }
            if (message.lastUpdated != null && message.hasOwnProperty("lastUpdated")) {
                let error = $root.google.protobuf.Timestamp.verify(message.lastUpdated);
                if (error)
                    return "lastUpdated." + error;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.location != null && message.hasOwnProperty("location"))
                if (!$util.isString(message.location))
                    return "location: string expected";
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                if (!$util.isString(message.deliveryInstructions))
                    return "deliveryInstructions: string expected";
            if (message.confirmedDimensions != null && message.hasOwnProperty("confirmedDimensions"))
                if (typeof message.confirmedDimensions !== "boolean")
                    return "confirmedDimensions: boolean expected";
            if (message.agreesToTermsAndConditions != null && message.hasOwnProperty("agreesToTermsAndConditions"))
                if (typeof message.agreesToTermsAndConditions !== "boolean")
                    return "agreesToTermsAndConditions: boolean expected";
            if (message.installer != null && message.hasOwnProperty("installer")) {
                let error = $root.appliancepb.Installer.verify(message.installer);
                if (error)
                    return "installer." + error;
            }
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                if (!$util.isString(message.dispatchID))
                    return "dispatchID: string expected";
            if (message.likeForLikeProductID != null && message.hasOwnProperty("likeForLikeProductID"))
                if (!$util.isString(message.likeForLikeProductID))
                    return "likeForLikeProductID: string expected";
            if (message.hasDisposalCoverage != null && message.hasOwnProperty("hasDisposalCoverage"))
                if (typeof message.hasDisposalCoverage !== "boolean")
                    return "hasDisposalCoverage: boolean expected";
            if (message.cashInLieu != null && message.hasOwnProperty("cashInLieu"))
                if (!$util.isInteger(message.cashInLieu) && !(message.cashInLieu && $util.isInteger(message.cashInLieu.low) && $util.isInteger(message.cashInLieu.high)))
                    return "cashInLieu: integer|Long expected";
            if (message.contractID != null && message.hasOwnProperty("contractID"))
                if (!$util.isString(message.contractID))
                    return "contractID: string expected";
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                if (!$util.isString(message.partRequestID))
                    return "partRequestID: string expected";
            if (message.payment != null && message.hasOwnProperty("payment")) {
                let error = $root.appliancepb.Payment.verify(message.payment);
                if (error)
                    return "payment." + error;
            }
            if (message.orderDate != null && message.hasOwnProperty("orderDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.orderDate);
                if (error)
                    return "orderDate." + error;
            }
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.deliveryDate);
                if (error)
                    return "deliveryDate." + error;
            }
            if (message.customer != null && message.hasOwnProperty("customer")) {
                let error = $root.appliancepb.Customer.verify(message.customer);
                if (error)
                    return "customer." + error;
            }
            if (message.product != null && message.hasOwnProperty("product")) {
                let error = $root.appliancepb.Product.verify(message.product);
                if (error)
                    return "product." + error;
            }
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                if (!$util.isString(message.vendorOrderNumber))
                    return "vendorOrderNumber: string expected";
            if (message.shopLink != null && message.hasOwnProperty("shopLink"))
                if (!$util.isString(message.shopLink))
                    return "shopLink: string expected";
            if (message.orderStatusLink != null && message.hasOwnProperty("orderStatusLink"))
                if (!$util.isString(message.orderStatusLink))
                    return "orderStatusLink: string expected";
            if (message.notes != null && message.hasOwnProperty("notes")) {
                if (!Array.isArray(message.notes))
                    return "notes: array expected";
                for (let i = 0; i < message.notes.length; ++i) {
                    let error = $root.appliancepb.Note.verify(message.notes[i]);
                    if (error)
                        return "notes." + error;
                }
            }
            if (message.likeForLikePrice != null && message.hasOwnProperty("likeForLikePrice")) {
                let error = $root.appliancepb.PriceInformation.verify(message.likeForLikePrice);
                if (error)
                    return "likeForLikePrice." + error;
            }
            return null;
        };

        /**
         * Creates a FullReplacementDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.FullReplacementDetails} FullReplacementDetails
         */
        FullReplacementDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.FullReplacementDetails)
                return object;
            let message = new $root.appliancepb.FullReplacementDetails();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.tenantID != null)
                message.tenantID = String(object.tenantID);
            if (object.userID != null)
                message.userID = String(object.userID);
            if (object.paymentID != null)
                message.paymentID = String(object.paymentID);
            if (object.addressID != null)
                message.addressID = String(object.addressID);
            if (object.transactionID != null)
                message.transactionID = String(object.transactionID);
            if (object.serviceRequestID != null)
                message.serviceRequestID = String(object.serviceRequestID);
            if (object.discount != null)
                message.discount = object.discount | 0;
            if (object.item != null) {
                if (typeof object.item !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.item: object expected");
                message.item = $root.appliancepb.Pair.fromObject(object.item);
            }
            if (object.brand != null) {
                if (typeof object.brand !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.brand: object expected");
                message.brand = $root.appliancepb.Pair.fromObject(object.brand);
            }
            if (object.style != null) {
                if (typeof object.style !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.style: object expected");
                message.style = $root.appliancepb.Pair.fromObject(object.style);
            }
            if (object.purchase != null) {
                if (typeof object.purchase !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.purchase: object expected");
                message.purchase = $root.appliancepb.FullReplacementDetails.Purchase.fromObject(object.purchase);
            }
            if (object.createdAt != null) {
                if (typeof object.createdAt !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.createdAt: object expected");
                message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
            }
            if (object.lastUpdated != null) {
                if (typeof object.lastUpdated !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.lastUpdated: object expected");
                message.lastUpdated = $root.google.protobuf.Timestamp.fromObject(object.lastUpdated);
            }
            if (object.status != null)
                message.status = String(object.status);
            if (object.location != null)
                message.location = String(object.location);
            if (object.deliveryInstructions != null)
                message.deliveryInstructions = String(object.deliveryInstructions);
            if (object.confirmedDimensions != null)
                message.confirmedDimensions = Boolean(object.confirmedDimensions);
            if (object.agreesToTermsAndConditions != null)
                message.agreesToTermsAndConditions = Boolean(object.agreesToTermsAndConditions);
            if (object.installer != null) {
                if (typeof object.installer !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.installer: object expected");
                message.installer = $root.appliancepb.Installer.fromObject(object.installer);
            }
            if (object.dispatchID != null)
                message.dispatchID = String(object.dispatchID);
            if (object.likeForLikeProductID != null)
                message.likeForLikeProductID = String(object.likeForLikeProductID);
            if (object.hasDisposalCoverage != null)
                message.hasDisposalCoverage = Boolean(object.hasDisposalCoverage);
            if (object.cashInLieu != null)
                if ($util.Long)
                    (message.cashInLieu = $util.Long.fromValue(object.cashInLieu)).unsigned = false;
                else if (typeof object.cashInLieu === "string")
                    message.cashInLieu = parseInt(object.cashInLieu, 10);
                else if (typeof object.cashInLieu === "number")
                    message.cashInLieu = object.cashInLieu;
                else if (typeof object.cashInLieu === "object")
                    message.cashInLieu = new $util.LongBits(object.cashInLieu.low >>> 0, object.cashInLieu.high >>> 0).toNumber();
            if (object.contractID != null)
                message.contractID = String(object.contractID);
            if (object.partRequestID != null)
                message.partRequestID = String(object.partRequestID);
            if (object.payment != null) {
                if (typeof object.payment !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.payment: object expected");
                message.payment = $root.appliancepb.Payment.fromObject(object.payment);
            }
            if (object.orderDate != null) {
                if (typeof object.orderDate !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.orderDate: object expected");
                message.orderDate = $root.google.protobuf.Timestamp.fromObject(object.orderDate);
            }
            if (object.deliveryDate != null) {
                if (typeof object.deliveryDate !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.deliveryDate: object expected");
                message.deliveryDate = $root.google.protobuf.Timestamp.fromObject(object.deliveryDate);
            }
            if (object.customer != null) {
                if (typeof object.customer !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.customer: object expected");
                message.customer = $root.appliancepb.Customer.fromObject(object.customer);
            }
            if (object.product != null) {
                if (typeof object.product !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.product: object expected");
                message.product = $root.appliancepb.Product.fromObject(object.product);
            }
            if (object.vendorOrderNumber != null)
                message.vendorOrderNumber = String(object.vendorOrderNumber);
            if (object.shopLink != null)
                message.shopLink = String(object.shopLink);
            if (object.orderStatusLink != null)
                message.orderStatusLink = String(object.orderStatusLink);
            if (object.notes) {
                if (!Array.isArray(object.notes))
                    throw TypeError(".appliancepb.FullReplacementDetails.notes: array expected");
                message.notes = [];
                for (let i = 0; i < object.notes.length; ++i) {
                    if (typeof object.notes[i] !== "object")
                        throw TypeError(".appliancepb.FullReplacementDetails.notes: object expected");
                    message.notes[i] = $root.appliancepb.Note.fromObject(object.notes[i]);
                }
            }
            if (object.likeForLikePrice != null) {
                if (typeof object.likeForLikePrice !== "object")
                    throw TypeError(".appliancepb.FullReplacementDetails.likeForLikePrice: object expected");
                message.likeForLikePrice = $root.appliancepb.PriceInformation.fromObject(object.likeForLikePrice);
            }
            return message;
        };

        /**
         * Creates a plain object from a FullReplacementDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.FullReplacementDetails
         * @static
         * @param {appliancepb.FullReplacementDetails} message FullReplacementDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FullReplacementDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.notes = [];
            if (options.defaults) {
                object.ID = "";
                object.tenantID = "";
                object.userID = "";
                object.paymentID = "";
                object.addressID = "";
                object.transactionID = "";
                object.serviceRequestID = "";
                object.discount = 0;
                object.item = null;
                object.brand = null;
                object.style = null;
                object.purchase = null;
                object.createdAt = null;
                object.lastUpdated = null;
                object.status = "";
                object.location = "";
                object.deliveryInstructions = "";
                object.confirmedDimensions = false;
                object.agreesToTermsAndConditions = false;
                object.installer = null;
                object.dispatchID = "";
                object.likeForLikeProductID = "";
                object.hasDisposalCoverage = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.cashInLieu = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.cashInLieu = options.longs === String ? "0" : 0;
                object.contractID = "";
                object.partRequestID = "";
                object.payment = null;
                object.orderDate = null;
                object.deliveryDate = null;
                object.customer = null;
                object.product = null;
                object.vendorOrderNumber = "";
                object.shopLink = "";
                object.orderStatusLink = "";
                object.likeForLikePrice = null;
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.tenantID != null && message.hasOwnProperty("tenantID"))
                object.tenantID = message.tenantID;
            if (message.userID != null && message.hasOwnProperty("userID"))
                object.userID = message.userID;
            if (message.paymentID != null && message.hasOwnProperty("paymentID"))
                object.paymentID = message.paymentID;
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                object.addressID = message.addressID;
            if (message.transactionID != null && message.hasOwnProperty("transactionID"))
                object.transactionID = message.transactionID;
            if (message.serviceRequestID != null && message.hasOwnProperty("serviceRequestID"))
                object.serviceRequestID = message.serviceRequestID;
            if (message.discount != null && message.hasOwnProperty("discount"))
                object.discount = message.discount;
            if (message.item != null && message.hasOwnProperty("item"))
                object.item = $root.appliancepb.Pair.toObject(message.item, options);
            if (message.brand != null && message.hasOwnProperty("brand"))
                object.brand = $root.appliancepb.Pair.toObject(message.brand, options);
            if (message.style != null && message.hasOwnProperty("style"))
                object.style = $root.appliancepb.Pair.toObject(message.style, options);
            if (message.purchase != null && message.hasOwnProperty("purchase"))
                object.purchase = $root.appliancepb.FullReplacementDetails.Purchase.toObject(message.purchase, options);
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
            if (message.lastUpdated != null && message.hasOwnProperty("lastUpdated"))
                object.lastUpdated = $root.google.protobuf.Timestamp.toObject(message.lastUpdated, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = message.location;
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                object.deliveryInstructions = message.deliveryInstructions;
            if (message.confirmedDimensions != null && message.hasOwnProperty("confirmedDimensions"))
                object.confirmedDimensions = message.confirmedDimensions;
            if (message.agreesToTermsAndConditions != null && message.hasOwnProperty("agreesToTermsAndConditions"))
                object.agreesToTermsAndConditions = message.agreesToTermsAndConditions;
            if (message.installer != null && message.hasOwnProperty("installer"))
                object.installer = $root.appliancepb.Installer.toObject(message.installer, options);
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                object.dispatchID = message.dispatchID;
            if (message.likeForLikeProductID != null && message.hasOwnProperty("likeForLikeProductID"))
                object.likeForLikeProductID = message.likeForLikeProductID;
            if (message.hasDisposalCoverage != null && message.hasOwnProperty("hasDisposalCoverage"))
                object.hasDisposalCoverage = message.hasDisposalCoverage;
            if (message.cashInLieu != null && message.hasOwnProperty("cashInLieu"))
                if (typeof message.cashInLieu === "number")
                    object.cashInLieu = options.longs === String ? String(message.cashInLieu) : message.cashInLieu;
                else
                    object.cashInLieu = options.longs === String ? $util.Long.prototype.toString.call(message.cashInLieu) : options.longs === Number ? new $util.LongBits(message.cashInLieu.low >>> 0, message.cashInLieu.high >>> 0).toNumber() : message.cashInLieu;
            if (message.contractID != null && message.hasOwnProperty("contractID"))
                object.contractID = message.contractID;
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                object.partRequestID = message.partRequestID;
            if (message.payment != null && message.hasOwnProperty("payment"))
                object.payment = $root.appliancepb.Payment.toObject(message.payment, options);
            if (message.orderDate != null && message.hasOwnProperty("orderDate"))
                object.orderDate = $root.google.protobuf.Timestamp.toObject(message.orderDate, options);
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate"))
                object.deliveryDate = $root.google.protobuf.Timestamp.toObject(message.deliveryDate, options);
            if (message.customer != null && message.hasOwnProperty("customer"))
                object.customer = $root.appliancepb.Customer.toObject(message.customer, options);
            if (message.product != null && message.hasOwnProperty("product"))
                object.product = $root.appliancepb.Product.toObject(message.product, options);
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                object.vendorOrderNumber = message.vendorOrderNumber;
            if (message.shopLink != null && message.hasOwnProperty("shopLink"))
                object.shopLink = message.shopLink;
            if (message.orderStatusLink != null && message.hasOwnProperty("orderStatusLink"))
                object.orderStatusLink = message.orderStatusLink;
            if (message.notes && message.notes.length) {
                object.notes = [];
                for (let j = 0; j < message.notes.length; ++j)
                    object.notes[j] = $root.appliancepb.Note.toObject(message.notes[j], options);
            }
            if (message.likeForLikePrice != null && message.hasOwnProperty("likeForLikePrice"))
                object.likeForLikePrice = $root.appliancepb.PriceInformation.toObject(message.likeForLikePrice, options);
            return object;
        };

        /**
         * Converts this FullReplacementDetails to JSON.
         * @function toJSON
         * @memberof appliancepb.FullReplacementDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FullReplacementDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        FullReplacementDetails.Purchase = (function() {

            /**
             * Properties of a Purchase.
             * @memberof appliancepb.FullReplacementDetails
             * @interface IPurchase
             * @property {string|null} [productID] Purchase productID
             * @property {number|Long|null} [basePrice] Purchase basePrice
             * @property {number|Long|null} [moveApplianceFee] Purchase moveApplianceFee
             * @property {number|Long|null} [disconnectFee] Purchase disconnectFee
             * @property {number|Long|null} [installFee] Purchase installFee
             * @property {number|Long|null} [deliveryFee] Purchase deliveryFee
             * @property {number|Long|null} [disposalFee] Purchase disposalFee
             * @property {number|Long|null} [adminFee] Purchase adminFee
             * @property {number|Long|null} [basePlusFees] Purchase basePlusFees
             * @property {number|Long|null} [taxes] Purchase taxes
             * @property {number|Long|null} [total] Purchase total
             */

            /**
             * Constructs a new Purchase.
             * @memberof appliancepb.FullReplacementDetails
             * @classdesc Represents a Purchase.
             * @implements IPurchase
             * @constructor
             * @param {appliancepb.FullReplacementDetails.IPurchase=} [properties] Properties to set
             */
            function Purchase(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Purchase productID.
             * @member {string} productID
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.productID = "";

            /**
             * Purchase basePrice.
             * @member {number|Long} basePrice
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.basePrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase moveApplianceFee.
             * @member {number|Long} moveApplianceFee
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.moveApplianceFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase disconnectFee.
             * @member {number|Long} disconnectFee
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.disconnectFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase installFee.
             * @member {number|Long} installFee
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.installFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase deliveryFee.
             * @member {number|Long} deliveryFee
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.deliveryFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase disposalFee.
             * @member {number|Long} disposalFee
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.disposalFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase adminFee.
             * @member {number|Long} adminFee
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.adminFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase basePlusFees.
             * @member {number|Long} basePlusFees
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.basePlusFees = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase taxes.
             * @member {number|Long} taxes
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.taxes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Purchase total.
             * @member {number|Long} total
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             */
            Purchase.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Purchase instance using the specified properties.
             * @function create
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {appliancepb.FullReplacementDetails.IPurchase=} [properties] Properties to set
             * @returns {appliancepb.FullReplacementDetails.Purchase} Purchase instance
             */
            Purchase.create = function create(properties) {
                return new Purchase(properties);
            };

            /**
             * Encodes the specified Purchase message. Does not implicitly {@link appliancepb.FullReplacementDetails.Purchase.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {appliancepb.FullReplacementDetails.IPurchase} message Purchase message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Purchase.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.productID != null && Object.hasOwnProperty.call(message, "productID"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.productID);
                if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.basePrice);
                if (message.moveApplianceFee != null && Object.hasOwnProperty.call(message, "moveApplianceFee"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.moveApplianceFee);
                if (message.disconnectFee != null && Object.hasOwnProperty.call(message, "disconnectFee"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.disconnectFee);
                if (message.installFee != null && Object.hasOwnProperty.call(message, "installFee"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.installFee);
                if (message.deliveryFee != null && Object.hasOwnProperty.call(message, "deliveryFee"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.deliveryFee);
                if (message.disposalFee != null && Object.hasOwnProperty.call(message, "disposalFee"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.disposalFee);
                if (message.adminFee != null && Object.hasOwnProperty.call(message, "adminFee"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.adminFee);
                if (message.basePlusFees != null && Object.hasOwnProperty.call(message, "basePlusFees"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.basePlusFees);
                if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.taxes);
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int64(message.total);
                return writer;
            };

            /**
             * Encodes the specified Purchase message, length delimited. Does not implicitly {@link appliancepb.FullReplacementDetails.Purchase.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {appliancepb.FullReplacementDetails.IPurchase} message Purchase message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Purchase.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Purchase message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.FullReplacementDetails.Purchase} Purchase
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Purchase.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.FullReplacementDetails.Purchase();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.productID = reader.string();
                        break;
                    case 2:
                        message.basePrice = reader.int64();
                        break;
                    case 3:
                        message.moveApplianceFee = reader.int64();
                        break;
                    case 4:
                        message.disconnectFee = reader.int64();
                        break;
                    case 5:
                        message.installFee = reader.int64();
                        break;
                    case 6:
                        message.deliveryFee = reader.int64();
                        break;
                    case 7:
                        message.disposalFee = reader.int64();
                        break;
                    case 8:
                        message.adminFee = reader.int64();
                        break;
                    case 9:
                        message.basePlusFees = reader.int64();
                        break;
                    case 10:
                        message.taxes = reader.int64();
                        break;
                    case 11:
                        message.total = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Purchase message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.FullReplacementDetails.Purchase} Purchase
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Purchase.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Purchase message.
             * @function verify
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Purchase.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.productID != null && message.hasOwnProperty("productID"))
                    if (!$util.isString(message.productID))
                        return "productID: string expected";
                if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                    if (!$util.isInteger(message.basePrice) && !(message.basePrice && $util.isInteger(message.basePrice.low) && $util.isInteger(message.basePrice.high)))
                        return "basePrice: integer|Long expected";
                if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                    if (!$util.isInteger(message.moveApplianceFee) && !(message.moveApplianceFee && $util.isInteger(message.moveApplianceFee.low) && $util.isInteger(message.moveApplianceFee.high)))
                        return "moveApplianceFee: integer|Long expected";
                if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                    if (!$util.isInteger(message.disconnectFee) && !(message.disconnectFee && $util.isInteger(message.disconnectFee.low) && $util.isInteger(message.disconnectFee.high)))
                        return "disconnectFee: integer|Long expected";
                if (message.installFee != null && message.hasOwnProperty("installFee"))
                    if (!$util.isInteger(message.installFee) && !(message.installFee && $util.isInteger(message.installFee.low) && $util.isInteger(message.installFee.high)))
                        return "installFee: integer|Long expected";
                if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                    if (!$util.isInteger(message.deliveryFee) && !(message.deliveryFee && $util.isInteger(message.deliveryFee.low) && $util.isInteger(message.deliveryFee.high)))
                        return "deliveryFee: integer|Long expected";
                if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                    if (!$util.isInteger(message.disposalFee) && !(message.disposalFee && $util.isInteger(message.disposalFee.low) && $util.isInteger(message.disposalFee.high)))
                        return "disposalFee: integer|Long expected";
                if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                    if (!$util.isInteger(message.adminFee) && !(message.adminFee && $util.isInteger(message.adminFee.low) && $util.isInteger(message.adminFee.high)))
                        return "adminFee: integer|Long expected";
                if (message.basePlusFees != null && message.hasOwnProperty("basePlusFees"))
                    if (!$util.isInteger(message.basePlusFees) && !(message.basePlusFees && $util.isInteger(message.basePlusFees.low) && $util.isInteger(message.basePlusFees.high)))
                        return "basePlusFees: integer|Long expected";
                if (message.taxes != null && message.hasOwnProperty("taxes"))
                    if (!$util.isInteger(message.taxes) && !(message.taxes && $util.isInteger(message.taxes.low) && $util.isInteger(message.taxes.high)))
                        return "taxes: integer|Long expected";
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                        return "total: integer|Long expected";
                return null;
            };

            /**
             * Creates a Purchase message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.FullReplacementDetails.Purchase} Purchase
             */
            Purchase.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.FullReplacementDetails.Purchase)
                    return object;
                let message = new $root.appliancepb.FullReplacementDetails.Purchase();
                if (object.productID != null)
                    message.productID = String(object.productID);
                if (object.basePrice != null)
                    if ($util.Long)
                        (message.basePrice = $util.Long.fromValue(object.basePrice)).unsigned = false;
                    else if (typeof object.basePrice === "string")
                        message.basePrice = parseInt(object.basePrice, 10);
                    else if (typeof object.basePrice === "number")
                        message.basePrice = object.basePrice;
                    else if (typeof object.basePrice === "object")
                        message.basePrice = new $util.LongBits(object.basePrice.low >>> 0, object.basePrice.high >>> 0).toNumber();
                if (object.moveApplianceFee != null)
                    if ($util.Long)
                        (message.moveApplianceFee = $util.Long.fromValue(object.moveApplianceFee)).unsigned = false;
                    else if (typeof object.moveApplianceFee === "string")
                        message.moveApplianceFee = parseInt(object.moveApplianceFee, 10);
                    else if (typeof object.moveApplianceFee === "number")
                        message.moveApplianceFee = object.moveApplianceFee;
                    else if (typeof object.moveApplianceFee === "object")
                        message.moveApplianceFee = new $util.LongBits(object.moveApplianceFee.low >>> 0, object.moveApplianceFee.high >>> 0).toNumber();
                if (object.disconnectFee != null)
                    if ($util.Long)
                        (message.disconnectFee = $util.Long.fromValue(object.disconnectFee)).unsigned = false;
                    else if (typeof object.disconnectFee === "string")
                        message.disconnectFee = parseInt(object.disconnectFee, 10);
                    else if (typeof object.disconnectFee === "number")
                        message.disconnectFee = object.disconnectFee;
                    else if (typeof object.disconnectFee === "object")
                        message.disconnectFee = new $util.LongBits(object.disconnectFee.low >>> 0, object.disconnectFee.high >>> 0).toNumber();
                if (object.installFee != null)
                    if ($util.Long)
                        (message.installFee = $util.Long.fromValue(object.installFee)).unsigned = false;
                    else if (typeof object.installFee === "string")
                        message.installFee = parseInt(object.installFee, 10);
                    else if (typeof object.installFee === "number")
                        message.installFee = object.installFee;
                    else if (typeof object.installFee === "object")
                        message.installFee = new $util.LongBits(object.installFee.low >>> 0, object.installFee.high >>> 0).toNumber();
                if (object.deliveryFee != null)
                    if ($util.Long)
                        (message.deliveryFee = $util.Long.fromValue(object.deliveryFee)).unsigned = false;
                    else if (typeof object.deliveryFee === "string")
                        message.deliveryFee = parseInt(object.deliveryFee, 10);
                    else if (typeof object.deliveryFee === "number")
                        message.deliveryFee = object.deliveryFee;
                    else if (typeof object.deliveryFee === "object")
                        message.deliveryFee = new $util.LongBits(object.deliveryFee.low >>> 0, object.deliveryFee.high >>> 0).toNumber();
                if (object.disposalFee != null)
                    if ($util.Long)
                        (message.disposalFee = $util.Long.fromValue(object.disposalFee)).unsigned = false;
                    else if (typeof object.disposalFee === "string")
                        message.disposalFee = parseInt(object.disposalFee, 10);
                    else if (typeof object.disposalFee === "number")
                        message.disposalFee = object.disposalFee;
                    else if (typeof object.disposalFee === "object")
                        message.disposalFee = new $util.LongBits(object.disposalFee.low >>> 0, object.disposalFee.high >>> 0).toNumber();
                if (object.adminFee != null)
                    if ($util.Long)
                        (message.adminFee = $util.Long.fromValue(object.adminFee)).unsigned = false;
                    else if (typeof object.adminFee === "string")
                        message.adminFee = parseInt(object.adminFee, 10);
                    else if (typeof object.adminFee === "number")
                        message.adminFee = object.adminFee;
                    else if (typeof object.adminFee === "object")
                        message.adminFee = new $util.LongBits(object.adminFee.low >>> 0, object.adminFee.high >>> 0).toNumber();
                if (object.basePlusFees != null)
                    if ($util.Long)
                        (message.basePlusFees = $util.Long.fromValue(object.basePlusFees)).unsigned = false;
                    else if (typeof object.basePlusFees === "string")
                        message.basePlusFees = parseInt(object.basePlusFees, 10);
                    else if (typeof object.basePlusFees === "number")
                        message.basePlusFees = object.basePlusFees;
                    else if (typeof object.basePlusFees === "object")
                        message.basePlusFees = new $util.LongBits(object.basePlusFees.low >>> 0, object.basePlusFees.high >>> 0).toNumber();
                if (object.taxes != null)
                    if ($util.Long)
                        (message.taxes = $util.Long.fromValue(object.taxes)).unsigned = false;
                    else if (typeof object.taxes === "string")
                        message.taxes = parseInt(object.taxes, 10);
                    else if (typeof object.taxes === "number")
                        message.taxes = object.taxes;
                    else if (typeof object.taxes === "object")
                        message.taxes = new $util.LongBits(object.taxes.low >>> 0, object.taxes.high >>> 0).toNumber();
                if (object.total != null)
                    if ($util.Long)
                        (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                    else if (typeof object.total === "string")
                        message.total = parseInt(object.total, 10);
                    else if (typeof object.total === "number")
                        message.total = object.total;
                    else if (typeof object.total === "object")
                        message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a Purchase message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @static
             * @param {appliancepb.FullReplacementDetails.Purchase} message Purchase
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Purchase.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.productID = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.basePrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.basePrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.moveApplianceFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.moveApplianceFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.disconnectFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.disconnectFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.installFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.installFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.deliveryFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deliveryFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.disposalFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.disposalFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.adminFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.adminFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.basePlusFees = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.basePlusFees = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.taxes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.taxes = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.total = options.longs === String ? "0" : 0;
                }
                if (message.productID != null && message.hasOwnProperty("productID"))
                    object.productID = message.productID;
                if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                    if (typeof message.basePrice === "number")
                        object.basePrice = options.longs === String ? String(message.basePrice) : message.basePrice;
                    else
                        object.basePrice = options.longs === String ? $util.Long.prototype.toString.call(message.basePrice) : options.longs === Number ? new $util.LongBits(message.basePrice.low >>> 0, message.basePrice.high >>> 0).toNumber() : message.basePrice;
                if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                    if (typeof message.moveApplianceFee === "number")
                        object.moveApplianceFee = options.longs === String ? String(message.moveApplianceFee) : message.moveApplianceFee;
                    else
                        object.moveApplianceFee = options.longs === String ? $util.Long.prototype.toString.call(message.moveApplianceFee) : options.longs === Number ? new $util.LongBits(message.moveApplianceFee.low >>> 0, message.moveApplianceFee.high >>> 0).toNumber() : message.moveApplianceFee;
                if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                    if (typeof message.disconnectFee === "number")
                        object.disconnectFee = options.longs === String ? String(message.disconnectFee) : message.disconnectFee;
                    else
                        object.disconnectFee = options.longs === String ? $util.Long.prototype.toString.call(message.disconnectFee) : options.longs === Number ? new $util.LongBits(message.disconnectFee.low >>> 0, message.disconnectFee.high >>> 0).toNumber() : message.disconnectFee;
                if (message.installFee != null && message.hasOwnProperty("installFee"))
                    if (typeof message.installFee === "number")
                        object.installFee = options.longs === String ? String(message.installFee) : message.installFee;
                    else
                        object.installFee = options.longs === String ? $util.Long.prototype.toString.call(message.installFee) : options.longs === Number ? new $util.LongBits(message.installFee.low >>> 0, message.installFee.high >>> 0).toNumber() : message.installFee;
                if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                    if (typeof message.deliveryFee === "number")
                        object.deliveryFee = options.longs === String ? String(message.deliveryFee) : message.deliveryFee;
                    else
                        object.deliveryFee = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryFee) : options.longs === Number ? new $util.LongBits(message.deliveryFee.low >>> 0, message.deliveryFee.high >>> 0).toNumber() : message.deliveryFee;
                if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                    if (typeof message.disposalFee === "number")
                        object.disposalFee = options.longs === String ? String(message.disposalFee) : message.disposalFee;
                    else
                        object.disposalFee = options.longs === String ? $util.Long.prototype.toString.call(message.disposalFee) : options.longs === Number ? new $util.LongBits(message.disposalFee.low >>> 0, message.disposalFee.high >>> 0).toNumber() : message.disposalFee;
                if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                    if (typeof message.adminFee === "number")
                        object.adminFee = options.longs === String ? String(message.adminFee) : message.adminFee;
                    else
                        object.adminFee = options.longs === String ? $util.Long.prototype.toString.call(message.adminFee) : options.longs === Number ? new $util.LongBits(message.adminFee.low >>> 0, message.adminFee.high >>> 0).toNumber() : message.adminFee;
                if (message.basePlusFees != null && message.hasOwnProperty("basePlusFees"))
                    if (typeof message.basePlusFees === "number")
                        object.basePlusFees = options.longs === String ? String(message.basePlusFees) : message.basePlusFees;
                    else
                        object.basePlusFees = options.longs === String ? $util.Long.prototype.toString.call(message.basePlusFees) : options.longs === Number ? new $util.LongBits(message.basePlusFees.low >>> 0, message.basePlusFees.high >>> 0).toNumber() : message.basePlusFees;
                if (message.taxes != null && message.hasOwnProperty("taxes"))
                    if (typeof message.taxes === "number")
                        object.taxes = options.longs === String ? String(message.taxes) : message.taxes;
                    else
                        object.taxes = options.longs === String ? $util.Long.prototype.toString.call(message.taxes) : options.longs === Number ? new $util.LongBits(message.taxes.low >>> 0, message.taxes.high >>> 0).toNumber() : message.taxes;
                if (message.total != null && message.hasOwnProperty("total"))
                    if (typeof message.total === "number")
                        object.total = options.longs === String ? String(message.total) : message.total;
                    else
                        object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                return object;
            };

            /**
             * Converts this Purchase to JSON.
             * @function toJSON
             * @memberof appliancepb.FullReplacementDetails.Purchase
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Purchase.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Purchase;
        })();

        return FullReplacementDetails;
    })();

    appliancepb.FullReplacements = (function() {

        /**
         * Properties of a FullReplacements.
         * @memberof appliancepb
         * @interface IFullReplacements
         * @property {Array.<appliancepb.IFullReplacementDetails>|null} [replacements] FullReplacements replacements
         * @property {appliancepb.IPagination|null} [pagination] FullReplacements pagination
         */

        /**
         * Constructs a new FullReplacements.
         * @memberof appliancepb
         * @classdesc Represents a FullReplacements.
         * @implements IFullReplacements
         * @constructor
         * @param {appliancepb.IFullReplacements=} [properties] Properties to set
         */
        function FullReplacements(properties) {
            this.replacements = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FullReplacements replacements.
         * @member {Array.<appliancepb.IFullReplacementDetails>} replacements
         * @memberof appliancepb.FullReplacements
         * @instance
         */
        FullReplacements.prototype.replacements = $util.emptyArray;

        /**
         * FullReplacements pagination.
         * @member {appliancepb.IPagination|null|undefined} pagination
         * @memberof appliancepb.FullReplacements
         * @instance
         */
        FullReplacements.prototype.pagination = null;

        /**
         * Creates a new FullReplacements instance using the specified properties.
         * @function create
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {appliancepb.IFullReplacements=} [properties] Properties to set
         * @returns {appliancepb.FullReplacements} FullReplacements instance
         */
        FullReplacements.create = function create(properties) {
            return new FullReplacements(properties);
        };

        /**
         * Encodes the specified FullReplacements message. Does not implicitly {@link appliancepb.FullReplacements.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {appliancepb.IFullReplacements} message FullReplacements message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullReplacements.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.replacements != null && message.replacements.length)
                for (let i = 0; i < message.replacements.length; ++i)
                    $root.appliancepb.FullReplacementDetails.encode(message.replacements[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.appliancepb.Pagination.encode(message.pagination, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FullReplacements message, length delimited. Does not implicitly {@link appliancepb.FullReplacements.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {appliancepb.IFullReplacements} message FullReplacements message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullReplacements.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FullReplacements message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.FullReplacements} FullReplacements
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullReplacements.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.FullReplacements();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.replacements && message.replacements.length))
                        message.replacements = [];
                    message.replacements.push($root.appliancepb.FullReplacementDetails.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.pagination = $root.appliancepb.Pagination.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FullReplacements message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.FullReplacements} FullReplacements
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullReplacements.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FullReplacements message.
         * @function verify
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FullReplacements.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.replacements != null && message.hasOwnProperty("replacements")) {
                if (!Array.isArray(message.replacements))
                    return "replacements: array expected";
                for (let i = 0; i < message.replacements.length; ++i) {
                    let error = $root.appliancepb.FullReplacementDetails.verify(message.replacements[i]);
                    if (error)
                        return "replacements." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.appliancepb.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            return null;
        };

        /**
         * Creates a FullReplacements message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.FullReplacements} FullReplacements
         */
        FullReplacements.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.FullReplacements)
                return object;
            let message = new $root.appliancepb.FullReplacements();
            if (object.replacements) {
                if (!Array.isArray(object.replacements))
                    throw TypeError(".appliancepb.FullReplacements.replacements: array expected");
                message.replacements = [];
                for (let i = 0; i < object.replacements.length; ++i) {
                    if (typeof object.replacements[i] !== "object")
                        throw TypeError(".appliancepb.FullReplacements.replacements: object expected");
                    message.replacements[i] = $root.appliancepb.FullReplacementDetails.fromObject(object.replacements[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".appliancepb.FullReplacements.pagination: object expected");
                message.pagination = $root.appliancepb.Pagination.fromObject(object.pagination);
            }
            return message;
        };

        /**
         * Creates a plain object from a FullReplacements message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.FullReplacements
         * @static
         * @param {appliancepb.FullReplacements} message FullReplacements
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FullReplacements.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.replacements = [];
            if (options.defaults)
                object.pagination = null;
            if (message.replacements && message.replacements.length) {
                object.replacements = [];
                for (let j = 0; j < message.replacements.length; ++j)
                    object.replacements[j] = $root.appliancepb.FullReplacementDetails.toObject(message.replacements[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.appliancepb.Pagination.toObject(message.pagination, options);
            return object;
        };

        /**
         * Converts this FullReplacements to JSON.
         * @function toJSON
         * @memberof appliancepb.FullReplacements
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FullReplacements.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FullReplacements;
    })();

    appliancepb.InstallerUpdateRequest = (function() {

        /**
         * Properties of an InstallerUpdateRequest.
         * @memberof appliancepb
         * @interface IInstallerUpdateRequest
         * @property {appliancepb.IInstaller|null} [installer] InstallerUpdateRequest installer
         */

        /**
         * Constructs a new InstallerUpdateRequest.
         * @memberof appliancepb
         * @classdesc Represents an InstallerUpdateRequest.
         * @implements IInstallerUpdateRequest
         * @constructor
         * @param {appliancepb.IInstallerUpdateRequest=} [properties] Properties to set
         */
        function InstallerUpdateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InstallerUpdateRequest installer.
         * @member {appliancepb.IInstaller|null|undefined} installer
         * @memberof appliancepb.InstallerUpdateRequest
         * @instance
         */
        InstallerUpdateRequest.prototype.installer = null;

        /**
         * Creates a new InstallerUpdateRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {appliancepb.IInstallerUpdateRequest=} [properties] Properties to set
         * @returns {appliancepb.InstallerUpdateRequest} InstallerUpdateRequest instance
         */
        InstallerUpdateRequest.create = function create(properties) {
            return new InstallerUpdateRequest(properties);
        };

        /**
         * Encodes the specified InstallerUpdateRequest message. Does not implicitly {@link appliancepb.InstallerUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {appliancepb.IInstallerUpdateRequest} message InstallerUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InstallerUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.installer != null && Object.hasOwnProperty.call(message, "installer"))
                $root.appliancepb.Installer.encode(message.installer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified InstallerUpdateRequest message, length delimited. Does not implicitly {@link appliancepb.InstallerUpdateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {appliancepb.IInstallerUpdateRequest} message InstallerUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InstallerUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InstallerUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.InstallerUpdateRequest} InstallerUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InstallerUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.InstallerUpdateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.installer = $root.appliancepb.Installer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InstallerUpdateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.InstallerUpdateRequest} InstallerUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InstallerUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InstallerUpdateRequest message.
         * @function verify
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InstallerUpdateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.installer != null && message.hasOwnProperty("installer")) {
                let error = $root.appliancepb.Installer.verify(message.installer);
                if (error)
                    return "installer." + error;
            }
            return null;
        };

        /**
         * Creates an InstallerUpdateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.InstallerUpdateRequest} InstallerUpdateRequest
         */
        InstallerUpdateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.InstallerUpdateRequest)
                return object;
            let message = new $root.appliancepb.InstallerUpdateRequest();
            if (object.installer != null) {
                if (typeof object.installer !== "object")
                    throw TypeError(".appliancepb.InstallerUpdateRequest.installer: object expected");
                message.installer = $root.appliancepb.Installer.fromObject(object.installer);
            }
            return message;
        };

        /**
         * Creates a plain object from an InstallerUpdateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.InstallerUpdateRequest
         * @static
         * @param {appliancepb.InstallerUpdateRequest} message InstallerUpdateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InstallerUpdateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.installer = null;
            if (message.installer != null && message.hasOwnProperty("installer"))
                object.installer = $root.appliancepb.Installer.toObject(message.installer, options);
            return object;
        };

        /**
         * Converts this InstallerUpdateRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.InstallerUpdateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InstallerUpdateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InstallerUpdateRequest;
    })();

    appliancepb.DispatchInfoResponse = (function() {

        /**
         * Properties of a DispatchInfoResponse.
         * @memberof appliancepb
         * @interface IDispatchInfoResponse
         * @property {string|null} [contractID] DispatchInfoResponse contractID
         * @property {string|null} [addressID] DispatchInfoResponse addressID
         * @property {string|null} [firstName] DispatchInfoResponse firstName
         * @property {string|null} [lastName] DispatchInfoResponse lastName
         * @property {string|null} [phone] DispatchInfoResponse phone
         * @property {string|null} [customerID] DispatchInfoResponse customerID
         * @property {string|null} [dispatchID] DispatchInfoResponse dispatchID
         * @property {Array.<appliancepb.IServiceItem>|null} [serviceItems] DispatchInfoResponse serviceItems
         * @property {Array.<string>|null} [messages] DispatchInfoResponse messages
         * @property {string|null} [tenantAbbreviation] DispatchInfoResponse tenantAbbreviation
         * @property {appliancepb.IAddress|null} [address] DispatchInfoResponse address
         */

        /**
         * Constructs a new DispatchInfoResponse.
         * @memberof appliancepb
         * @classdesc Represents a DispatchInfoResponse.
         * @implements IDispatchInfoResponse
         * @constructor
         * @param {appliancepb.IDispatchInfoResponse=} [properties] Properties to set
         */
        function DispatchInfoResponse(properties) {
            this.serviceItems = [];
            this.messages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DispatchInfoResponse contractID.
         * @member {string} contractID
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.contractID = "";

        /**
         * DispatchInfoResponse addressID.
         * @member {string} addressID
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.addressID = "";

        /**
         * DispatchInfoResponse firstName.
         * @member {string} firstName
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.firstName = "";

        /**
         * DispatchInfoResponse lastName.
         * @member {string} lastName
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.lastName = "";

        /**
         * DispatchInfoResponse phone.
         * @member {string} phone
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.phone = "";

        /**
         * DispatchInfoResponse customerID.
         * @member {string} customerID
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.customerID = "";

        /**
         * DispatchInfoResponse dispatchID.
         * @member {string} dispatchID
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.dispatchID = "";

        /**
         * DispatchInfoResponse serviceItems.
         * @member {Array.<appliancepb.IServiceItem>} serviceItems
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.serviceItems = $util.emptyArray;

        /**
         * DispatchInfoResponse messages.
         * @member {Array.<string>} messages
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.messages = $util.emptyArray;

        /**
         * DispatchInfoResponse tenantAbbreviation.
         * @member {string} tenantAbbreviation
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.tenantAbbreviation = "";

        /**
         * DispatchInfoResponse address.
         * @member {appliancepb.IAddress|null|undefined} address
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         */
        DispatchInfoResponse.prototype.address = null;

        /**
         * Creates a new DispatchInfoResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {appliancepb.IDispatchInfoResponse=} [properties] Properties to set
         * @returns {appliancepb.DispatchInfoResponse} DispatchInfoResponse instance
         */
        DispatchInfoResponse.create = function create(properties) {
            return new DispatchInfoResponse(properties);
        };

        /**
         * Encodes the specified DispatchInfoResponse message. Does not implicitly {@link appliancepb.DispatchInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {appliancepb.IDispatchInfoResponse} message DispatchInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DispatchInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contractID != null && Object.hasOwnProperty.call(message, "contractID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.contractID);
            if (message.addressID != null && Object.hasOwnProperty.call(message, "addressID"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.addressID);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
            if (message.customerID != null && Object.hasOwnProperty.call(message, "customerID"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.customerID);
            if (message.dispatchID != null && Object.hasOwnProperty.call(message, "dispatchID"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.dispatchID);
            if (message.serviceItems != null && message.serviceItems.length)
                for (let i = 0; i < message.serviceItems.length; ++i)
                    $root.appliancepb.ServiceItem.encode(message.serviceItems[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.messages != null && message.messages.length)
                for (let i = 0; i < message.messages.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.messages[i]);
            if (message.tenantAbbreviation != null && Object.hasOwnProperty.call(message, "tenantAbbreviation"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.tenantAbbreviation);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.appliancepb.Address.encode(message.address, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DispatchInfoResponse message, length delimited. Does not implicitly {@link appliancepb.DispatchInfoResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {appliancepb.IDispatchInfoResponse} message DispatchInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DispatchInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DispatchInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.DispatchInfoResponse} DispatchInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DispatchInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.DispatchInfoResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.contractID = reader.string();
                    break;
                case 2:
                    message.addressID = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.phone = reader.string();
                    break;
                case 6:
                    message.customerID = reader.string();
                    break;
                case 7:
                    message.dispatchID = reader.string();
                    break;
                case 8:
                    if (!(message.serviceItems && message.serviceItems.length))
                        message.serviceItems = [];
                    message.serviceItems.push($root.appliancepb.ServiceItem.decode(reader, reader.uint32()));
                    break;
                case 9:
                    if (!(message.messages && message.messages.length))
                        message.messages = [];
                    message.messages.push(reader.string());
                    break;
                case 10:
                    message.tenantAbbreviation = reader.string();
                    break;
                case 11:
                    message.address = $root.appliancepb.Address.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DispatchInfoResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.DispatchInfoResponse} DispatchInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DispatchInfoResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DispatchInfoResponse message.
         * @function verify
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DispatchInfoResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.contractID != null && message.hasOwnProperty("contractID"))
                if (!$util.isString(message.contractID))
                    return "contractID: string expected";
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                if (!$util.isString(message.addressID))
                    return "addressID: string expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.customerID != null && message.hasOwnProperty("customerID"))
                if (!$util.isString(message.customerID))
                    return "customerID: string expected";
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                if (!$util.isString(message.dispatchID))
                    return "dispatchID: string expected";
            if (message.serviceItems != null && message.hasOwnProperty("serviceItems")) {
                if (!Array.isArray(message.serviceItems))
                    return "serviceItems: array expected";
                for (let i = 0; i < message.serviceItems.length; ++i) {
                    let error = $root.appliancepb.ServiceItem.verify(message.serviceItems[i]);
                    if (error)
                        return "serviceItems." + error;
                }
            }
            if (message.messages != null && message.hasOwnProperty("messages")) {
                if (!Array.isArray(message.messages))
                    return "messages: array expected";
                for (let i = 0; i < message.messages.length; ++i)
                    if (!$util.isString(message.messages[i]))
                        return "messages: string[] expected";
            }
            if (message.tenantAbbreviation != null && message.hasOwnProperty("tenantAbbreviation"))
                if (!$util.isString(message.tenantAbbreviation))
                    return "tenantAbbreviation: string expected";
            if (message.address != null && message.hasOwnProperty("address")) {
                let error = $root.appliancepb.Address.verify(message.address);
                if (error)
                    return "address." + error;
            }
            return null;
        };

        /**
         * Creates a DispatchInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.DispatchInfoResponse} DispatchInfoResponse
         */
        DispatchInfoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.DispatchInfoResponse)
                return object;
            let message = new $root.appliancepb.DispatchInfoResponse();
            if (object.contractID != null)
                message.contractID = String(object.contractID);
            if (object.addressID != null)
                message.addressID = String(object.addressID);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.customerID != null)
                message.customerID = String(object.customerID);
            if (object.dispatchID != null)
                message.dispatchID = String(object.dispatchID);
            if (object.serviceItems) {
                if (!Array.isArray(object.serviceItems))
                    throw TypeError(".appliancepb.DispatchInfoResponse.serviceItems: array expected");
                message.serviceItems = [];
                for (let i = 0; i < object.serviceItems.length; ++i) {
                    if (typeof object.serviceItems[i] !== "object")
                        throw TypeError(".appliancepb.DispatchInfoResponse.serviceItems: object expected");
                    message.serviceItems[i] = $root.appliancepb.ServiceItem.fromObject(object.serviceItems[i]);
                }
            }
            if (object.messages) {
                if (!Array.isArray(object.messages))
                    throw TypeError(".appliancepb.DispatchInfoResponse.messages: array expected");
                message.messages = [];
                for (let i = 0; i < object.messages.length; ++i)
                    message.messages[i] = String(object.messages[i]);
            }
            if (object.tenantAbbreviation != null)
                message.tenantAbbreviation = String(object.tenantAbbreviation);
            if (object.address != null) {
                if (typeof object.address !== "object")
                    throw TypeError(".appliancepb.DispatchInfoResponse.address: object expected");
                message.address = $root.appliancepb.Address.fromObject(object.address);
            }
            return message;
        };

        /**
         * Creates a plain object from a DispatchInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.DispatchInfoResponse
         * @static
         * @param {appliancepb.DispatchInfoResponse} message DispatchInfoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DispatchInfoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.serviceItems = [];
                object.messages = [];
            }
            if (options.defaults) {
                object.contractID = "";
                object.addressID = "";
                object.firstName = "";
                object.lastName = "";
                object.phone = "";
                object.customerID = "";
                object.dispatchID = "";
                object.tenantAbbreviation = "";
                object.address = null;
            }
            if (message.contractID != null && message.hasOwnProperty("contractID"))
                object.contractID = message.contractID;
            if (message.addressID != null && message.hasOwnProperty("addressID"))
                object.addressID = message.addressID;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.customerID != null && message.hasOwnProperty("customerID"))
                object.customerID = message.customerID;
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                object.dispatchID = message.dispatchID;
            if (message.serviceItems && message.serviceItems.length) {
                object.serviceItems = [];
                for (let j = 0; j < message.serviceItems.length; ++j)
                    object.serviceItems[j] = $root.appliancepb.ServiceItem.toObject(message.serviceItems[j], options);
            }
            if (message.messages && message.messages.length) {
                object.messages = [];
                for (let j = 0; j < message.messages.length; ++j)
                    object.messages[j] = message.messages[j];
            }
            if (message.tenantAbbreviation != null && message.hasOwnProperty("tenantAbbreviation"))
                object.tenantAbbreviation = message.tenantAbbreviation;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = $root.appliancepb.Address.toObject(message.address, options);
            return object;
        };

        /**
         * Converts this DispatchInfoResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.DispatchInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DispatchInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DispatchInfoResponse;
    })();

    appliancepb.ServiceItem = (function() {

        /**
         * Properties of a ServiceItem.
         * @memberof appliancepb
         * @interface IServiceItem
         * @property {string|null} [itemID] ServiceItem itemID
         * @property {Array.<string>|null} [partRequestIDs] ServiceItem partRequestIDs
         * @property {number|null} [serviceLimit] ServiceItem serviceLimit
         */

        /**
         * Constructs a new ServiceItem.
         * @memberof appliancepb
         * @classdesc Represents a ServiceItem.
         * @implements IServiceItem
         * @constructor
         * @param {appliancepb.IServiceItem=} [properties] Properties to set
         */
        function ServiceItem(properties) {
            this.partRequestIDs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceItem itemID.
         * @member {string} itemID
         * @memberof appliancepb.ServiceItem
         * @instance
         */
        ServiceItem.prototype.itemID = "";

        /**
         * ServiceItem partRequestIDs.
         * @member {Array.<string>} partRequestIDs
         * @memberof appliancepb.ServiceItem
         * @instance
         */
        ServiceItem.prototype.partRequestIDs = $util.emptyArray;

        /**
         * ServiceItem serviceLimit.
         * @member {number} serviceLimit
         * @memberof appliancepb.ServiceItem
         * @instance
         */
        ServiceItem.prototype.serviceLimit = 0;

        /**
         * Creates a new ServiceItem instance using the specified properties.
         * @function create
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {appliancepb.IServiceItem=} [properties] Properties to set
         * @returns {appliancepb.ServiceItem} ServiceItem instance
         */
        ServiceItem.create = function create(properties) {
            return new ServiceItem(properties);
        };

        /**
         * Encodes the specified ServiceItem message. Does not implicitly {@link appliancepb.ServiceItem.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {appliancepb.IServiceItem} message ServiceItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemID != null && Object.hasOwnProperty.call(message, "itemID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.itemID);
            if (message.partRequestIDs != null && message.partRequestIDs.length)
                for (let i = 0; i < message.partRequestIDs.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.partRequestIDs[i]);
            if (message.serviceLimit != null && Object.hasOwnProperty.call(message, "serviceLimit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.serviceLimit);
            return writer;
        };

        /**
         * Encodes the specified ServiceItem message, length delimited. Does not implicitly {@link appliancepb.ServiceItem.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {appliancepb.IServiceItem} message ServiceItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServiceItem message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ServiceItem} ServiceItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ServiceItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.itemID = reader.string();
                    break;
                case 2:
                    if (!(message.partRequestIDs && message.partRequestIDs.length))
                        message.partRequestIDs = [];
                    message.partRequestIDs.push(reader.string());
                    break;
                case 3:
                    message.serviceLimit = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServiceItem message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ServiceItem} ServiceItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServiceItem message.
         * @function verify
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServiceItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                if (!$util.isString(message.itemID))
                    return "itemID: string expected";
            if (message.partRequestIDs != null && message.hasOwnProperty("partRequestIDs")) {
                if (!Array.isArray(message.partRequestIDs))
                    return "partRequestIDs: array expected";
                for (let i = 0; i < message.partRequestIDs.length; ++i)
                    if (!$util.isString(message.partRequestIDs[i]))
                        return "partRequestIDs: string[] expected";
            }
            if (message.serviceLimit != null && message.hasOwnProperty("serviceLimit"))
                if (!$util.isInteger(message.serviceLimit))
                    return "serviceLimit: integer expected";
            return null;
        };

        /**
         * Creates a ServiceItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ServiceItem} ServiceItem
         */
        ServiceItem.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ServiceItem)
                return object;
            let message = new $root.appliancepb.ServiceItem();
            if (object.itemID != null)
                message.itemID = String(object.itemID);
            if (object.partRequestIDs) {
                if (!Array.isArray(object.partRequestIDs))
                    throw TypeError(".appliancepb.ServiceItem.partRequestIDs: array expected");
                message.partRequestIDs = [];
                for (let i = 0; i < object.partRequestIDs.length; ++i)
                    message.partRequestIDs[i] = String(object.partRequestIDs[i]);
            }
            if (object.serviceLimit != null)
                message.serviceLimit = object.serviceLimit | 0;
            return message;
        };

        /**
         * Creates a plain object from a ServiceItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ServiceItem
         * @static
         * @param {appliancepb.ServiceItem} message ServiceItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.partRequestIDs = [];
            if (options.defaults) {
                object.itemID = "";
                object.serviceLimit = 0;
            }
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                object.itemID = message.itemID;
            if (message.partRequestIDs && message.partRequestIDs.length) {
                object.partRequestIDs = [];
                for (let j = 0; j < message.partRequestIDs.length; ++j)
                    object.partRequestIDs[j] = message.partRequestIDs[j];
            }
            if (message.serviceLimit != null && message.hasOwnProperty("serviceLimit"))
                object.serviceLimit = message.serviceLimit;
            return object;
        };

        /**
         * Converts this ServiceItem to JSON.
         * @function toJSON
         * @memberof appliancepb.ServiceItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServiceItem;
    })();

    appliancepb.CartItem = (function() {

        /**
         * Properties of a CartItem.
         * @memberof appliancepb
         * @interface ICartItem
         * @property {string|null} [vendor] CartItem vendor
         * @property {string|null} [sku] CartItem sku
         * @property {number|null} [quantity] CartItem quantity
         * @property {number|null} [unitPrice] CartItem unitPrice
         * @property {number|null} [unitTax] CartItem unitTax
         * @property {string|null} [category] CartItem category
         * @property {string|null} [subcategory] CartItem subcategory
         * @property {string|null} [description] CartItem description
         * @property {boolean|null} [isAvailableForZip] CartItem isAvailableForZip
         * @property {number|null} [originalPrice] CartItem originalPrice
         * @property {string|null} [overrideReason] CartItem overrideReason
         */

        /**
         * Constructs a new CartItem.
         * @memberof appliancepb
         * @classdesc Represents a CartItem.
         * @implements ICartItem
         * @constructor
         * @param {appliancepb.ICartItem=} [properties] Properties to set
         */
        function CartItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CartItem vendor.
         * @member {string} vendor
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.vendor = "";

        /**
         * CartItem sku.
         * @member {string} sku
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.sku = "";

        /**
         * CartItem quantity.
         * @member {number} quantity
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.quantity = 0;

        /**
         * CartItem unitPrice.
         * @member {number} unitPrice
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.unitPrice = 0;

        /**
         * CartItem unitTax.
         * @member {number} unitTax
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.unitTax = 0;

        /**
         * CartItem category.
         * @member {string} category
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.category = "";

        /**
         * CartItem subcategory.
         * @member {string} subcategory
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.subcategory = "";

        /**
         * CartItem description.
         * @member {string} description
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.description = "";

        /**
         * CartItem isAvailableForZip.
         * @member {boolean} isAvailableForZip
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.isAvailableForZip = false;

        /**
         * CartItem originalPrice.
         * @member {number} originalPrice
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.originalPrice = 0;

        /**
         * CartItem overrideReason.
         * @member {string} overrideReason
         * @memberof appliancepb.CartItem
         * @instance
         */
        CartItem.prototype.overrideReason = "";

        /**
         * Creates a new CartItem instance using the specified properties.
         * @function create
         * @memberof appliancepb.CartItem
         * @static
         * @param {appliancepb.ICartItem=} [properties] Properties to set
         * @returns {appliancepb.CartItem} CartItem instance
         */
        CartItem.create = function create(properties) {
            return new CartItem(properties);
        };

        /**
         * Encodes the specified CartItem message. Does not implicitly {@link appliancepb.CartItem.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.CartItem
         * @static
         * @param {appliancepb.ICartItem} message CartItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CartItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.vendor != null && Object.hasOwnProperty.call(message, "vendor"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.vendor);
            if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sku);
            if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.quantity);
            if (message.unitPrice != null && Object.hasOwnProperty.call(message, "unitPrice"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.unitPrice);
            if (message.unitTax != null && Object.hasOwnProperty.call(message, "unitTax"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.unitTax);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.category);
            if (message.subcategory != null && Object.hasOwnProperty.call(message, "subcategory"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.subcategory);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.description);
            if (message.isAvailableForZip != null && Object.hasOwnProperty.call(message, "isAvailableForZip"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isAvailableForZip);
            if (message.originalPrice != null && Object.hasOwnProperty.call(message, "originalPrice"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.originalPrice);
            if (message.overrideReason != null && Object.hasOwnProperty.call(message, "overrideReason"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.overrideReason);
            return writer;
        };

        /**
         * Encodes the specified CartItem message, length delimited. Does not implicitly {@link appliancepb.CartItem.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.CartItem
         * @static
         * @param {appliancepb.ICartItem} message CartItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CartItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CartItem message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.CartItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.CartItem} CartItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CartItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.CartItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.vendor = reader.string();
                    break;
                case 2:
                    message.sku = reader.string();
                    break;
                case 3:
                    message.quantity = reader.int32();
                    break;
                case 4:
                    message.unitPrice = reader.int32();
                    break;
                case 5:
                    message.unitTax = reader.int32();
                    break;
                case 6:
                    message.category = reader.string();
                    break;
                case 7:
                    message.subcategory = reader.string();
                    break;
                case 8:
                    message.description = reader.string();
                    break;
                case 9:
                    message.isAvailableForZip = reader.bool();
                    break;
                case 10:
                    message.originalPrice = reader.int32();
                    break;
                case 11:
                    message.overrideReason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CartItem message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.CartItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.CartItem} CartItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CartItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CartItem message.
         * @function verify
         * @memberof appliancepb.CartItem
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CartItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                if (!$util.isString(message.vendor))
                    return "vendor: string expected";
            if (message.sku != null && message.hasOwnProperty("sku"))
                if (!$util.isString(message.sku))
                    return "sku: string expected";
            if (message.quantity != null && message.hasOwnProperty("quantity"))
                if (!$util.isInteger(message.quantity))
                    return "quantity: integer expected";
            if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                if (!$util.isInteger(message.unitPrice))
                    return "unitPrice: integer expected";
            if (message.unitTax != null && message.hasOwnProperty("unitTax"))
                if (!$util.isInteger(message.unitTax))
                    return "unitTax: integer expected";
            if (message.category != null && message.hasOwnProperty("category"))
                if (!$util.isString(message.category))
                    return "category: string expected";
            if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                if (!$util.isString(message.subcategory))
                    return "subcategory: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.isAvailableForZip != null && message.hasOwnProperty("isAvailableForZip"))
                if (typeof message.isAvailableForZip !== "boolean")
                    return "isAvailableForZip: boolean expected";
            if (message.originalPrice != null && message.hasOwnProperty("originalPrice"))
                if (!$util.isInteger(message.originalPrice))
                    return "originalPrice: integer expected";
            if (message.overrideReason != null && message.hasOwnProperty("overrideReason"))
                if (!$util.isString(message.overrideReason))
                    return "overrideReason: string expected";
            return null;
        };

        /**
         * Creates a CartItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.CartItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.CartItem} CartItem
         */
        CartItem.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.CartItem)
                return object;
            let message = new $root.appliancepb.CartItem();
            if (object.vendor != null)
                message.vendor = String(object.vendor);
            if (object.sku != null)
                message.sku = String(object.sku);
            if (object.quantity != null)
                message.quantity = object.quantity | 0;
            if (object.unitPrice != null)
                message.unitPrice = object.unitPrice | 0;
            if (object.unitTax != null)
                message.unitTax = object.unitTax | 0;
            if (object.category != null)
                message.category = String(object.category);
            if (object.subcategory != null)
                message.subcategory = String(object.subcategory);
            if (object.description != null)
                message.description = String(object.description);
            if (object.isAvailableForZip != null)
                message.isAvailableForZip = Boolean(object.isAvailableForZip);
            if (object.originalPrice != null)
                message.originalPrice = object.originalPrice | 0;
            if (object.overrideReason != null)
                message.overrideReason = String(object.overrideReason);
            return message;
        };

        /**
         * Creates a plain object from a CartItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.CartItem
         * @static
         * @param {appliancepb.CartItem} message CartItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CartItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.vendor = "";
                object.sku = "";
                object.quantity = 0;
                object.unitPrice = 0;
                object.unitTax = 0;
                object.category = "";
                object.subcategory = "";
                object.description = "";
                object.isAvailableForZip = false;
                object.originalPrice = 0;
                object.overrideReason = "";
            }
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                object.vendor = message.vendor;
            if (message.sku != null && message.hasOwnProperty("sku"))
                object.sku = message.sku;
            if (message.quantity != null && message.hasOwnProperty("quantity"))
                object.quantity = message.quantity;
            if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                object.unitPrice = message.unitPrice;
            if (message.unitTax != null && message.hasOwnProperty("unitTax"))
                object.unitTax = message.unitTax;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = message.category;
            if (message.subcategory != null && message.hasOwnProperty("subcategory"))
                object.subcategory = message.subcategory;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.isAvailableForZip != null && message.hasOwnProperty("isAvailableForZip"))
                object.isAvailableForZip = message.isAvailableForZip;
            if (message.originalPrice != null && message.hasOwnProperty("originalPrice"))
                object.originalPrice = message.originalPrice;
            if (message.overrideReason != null && message.hasOwnProperty("overrideReason"))
                object.overrideReason = message.overrideReason;
            return object;
        };

        /**
         * Converts this CartItem to JSON.
         * @function toJSON
         * @memberof appliancepb.CartItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CartItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CartItem;
    })();

    appliancepb.Cart = (function() {

        /**
         * Properties of a Cart.
         * @memberof appliancepb
         * @interface ICart
         * @property {Array.<appliancepb.ICartItem>|null} [cartItems] Cart cartItems
         * @property {appliancepb.ITaxDetail|null} [taxDetail] Cart taxDetail
         */

        /**
         * Constructs a new Cart.
         * @memberof appliancepb
         * @classdesc Represents a Cart.
         * @implements ICart
         * @constructor
         * @param {appliancepb.ICart=} [properties] Properties to set
         */
        function Cart(properties) {
            this.cartItems = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Cart cartItems.
         * @member {Array.<appliancepb.ICartItem>} cartItems
         * @memberof appliancepb.Cart
         * @instance
         */
        Cart.prototype.cartItems = $util.emptyArray;

        /**
         * Cart taxDetail.
         * @member {appliancepb.ITaxDetail|null|undefined} taxDetail
         * @memberof appliancepb.Cart
         * @instance
         */
        Cart.prototype.taxDetail = null;

        /**
         * Creates a new Cart instance using the specified properties.
         * @function create
         * @memberof appliancepb.Cart
         * @static
         * @param {appliancepb.ICart=} [properties] Properties to set
         * @returns {appliancepb.Cart} Cart instance
         */
        Cart.create = function create(properties) {
            return new Cart(properties);
        };

        /**
         * Encodes the specified Cart message. Does not implicitly {@link appliancepb.Cart.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Cart
         * @static
         * @param {appliancepb.ICart} message Cart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Cart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cartItems != null && message.cartItems.length)
                for (let i = 0; i < message.cartItems.length; ++i)
                    $root.appliancepb.CartItem.encode(message.cartItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.taxDetail != null && Object.hasOwnProperty.call(message, "taxDetail"))
                $root.appliancepb.TaxDetail.encode(message.taxDetail, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Cart message, length delimited. Does not implicitly {@link appliancepb.Cart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Cart
         * @static
         * @param {appliancepb.ICart} message Cart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Cart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Cart message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Cart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Cart} Cart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Cart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Cart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.cartItems && message.cartItems.length))
                        message.cartItems = [];
                    message.cartItems.push($root.appliancepb.CartItem.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.taxDetail = $root.appliancepb.TaxDetail.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Cart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Cart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Cart} Cart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Cart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Cart message.
         * @function verify
         * @memberof appliancepb.Cart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Cart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cartItems != null && message.hasOwnProperty("cartItems")) {
                if (!Array.isArray(message.cartItems))
                    return "cartItems: array expected";
                for (let i = 0; i < message.cartItems.length; ++i) {
                    let error = $root.appliancepb.CartItem.verify(message.cartItems[i]);
                    if (error)
                        return "cartItems." + error;
                }
            }
            if (message.taxDetail != null && message.hasOwnProperty("taxDetail")) {
                let error = $root.appliancepb.TaxDetail.verify(message.taxDetail);
                if (error)
                    return "taxDetail." + error;
            }
            return null;
        };

        /**
         * Creates a Cart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Cart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Cart} Cart
         */
        Cart.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Cart)
                return object;
            let message = new $root.appliancepb.Cart();
            if (object.cartItems) {
                if (!Array.isArray(object.cartItems))
                    throw TypeError(".appliancepb.Cart.cartItems: array expected");
                message.cartItems = [];
                for (let i = 0; i < object.cartItems.length; ++i) {
                    if (typeof object.cartItems[i] !== "object")
                        throw TypeError(".appliancepb.Cart.cartItems: object expected");
                    message.cartItems[i] = $root.appliancepb.CartItem.fromObject(object.cartItems[i]);
                }
            }
            if (object.taxDetail != null) {
                if (typeof object.taxDetail !== "object")
                    throw TypeError(".appliancepb.Cart.taxDetail: object expected");
                message.taxDetail = $root.appliancepb.TaxDetail.fromObject(object.taxDetail);
            }
            return message;
        };

        /**
         * Creates a plain object from a Cart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Cart
         * @static
         * @param {appliancepb.Cart} message Cart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Cart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.cartItems = [];
            if (options.defaults)
                object.taxDetail = null;
            if (message.cartItems && message.cartItems.length) {
                object.cartItems = [];
                for (let j = 0; j < message.cartItems.length; ++j)
                    object.cartItems[j] = $root.appliancepb.CartItem.toObject(message.cartItems[j], options);
            }
            if (message.taxDetail != null && message.hasOwnProperty("taxDetail"))
                object.taxDetail = $root.appliancepb.TaxDetail.toObject(message.taxDetail, options);
            return object;
        };

        /**
         * Converts this Cart to JSON.
         * @function toJSON
         * @memberof appliancepb.Cart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Cart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Cart;
    })();

    appliancepb.PriceInformation = (function() {

        /**
         * Properties of a PriceInformation.
         * @memberof appliancepb
         * @interface IPriceInformation
         * @property {string|null} [productId] PriceInformation productId
         * @property {number|null} [basePrice] PriceInformation basePrice
         * @property {number|null} [accessoriesFee] PriceInformation accessoriesFee
         * @property {number|null} [moveApplianceFee] PriceInformation moveApplianceFee
         * @property {number|null} [disconnectFee] PriceInformation disconnectFee
         * @property {number|null} [installFee] PriceInformation installFee
         * @property {number|null} [deliveryFee] PriceInformation deliveryFee
         * @property {number|null} [disposalFee] PriceInformation disposalFee
         * @property {number|null} [adminFee] PriceInformation adminFee
         * @property {number|null} [upgradeFee] PriceInformation upgradeFee
         * @property {number|null} [taxes] PriceInformation taxes
         * @property {number|null} [discount] PriceInformation discount
         * @property {number|null} [miscellaneous] PriceInformation miscellaneous
         */

        /**
         * Constructs a new PriceInformation.
         * @memberof appliancepb
         * @classdesc Represents a PriceInformation.
         * @implements IPriceInformation
         * @constructor
         * @param {appliancepb.IPriceInformation=} [properties] Properties to set
         */
        function PriceInformation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceInformation productId.
         * @member {string} productId
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.productId = "";

        /**
         * PriceInformation basePrice.
         * @member {number} basePrice
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.basePrice = 0;

        /**
         * PriceInformation accessoriesFee.
         * @member {number} accessoriesFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.accessoriesFee = 0;

        /**
         * PriceInformation moveApplianceFee.
         * @member {number} moveApplianceFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.moveApplianceFee = 0;

        /**
         * PriceInformation disconnectFee.
         * @member {number} disconnectFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.disconnectFee = 0;

        /**
         * PriceInformation installFee.
         * @member {number} installFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.installFee = 0;

        /**
         * PriceInformation deliveryFee.
         * @member {number} deliveryFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.deliveryFee = 0;

        /**
         * PriceInformation disposalFee.
         * @member {number} disposalFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.disposalFee = 0;

        /**
         * PriceInformation adminFee.
         * @member {number} adminFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.adminFee = 0;

        /**
         * PriceInformation upgradeFee.
         * @member {number} upgradeFee
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.upgradeFee = 0;

        /**
         * PriceInformation taxes.
         * @member {number} taxes
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.taxes = 0;

        /**
         * PriceInformation discount.
         * @member {number} discount
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.discount = 0;

        /**
         * PriceInformation miscellaneous.
         * @member {number} miscellaneous
         * @memberof appliancepb.PriceInformation
         * @instance
         */
        PriceInformation.prototype.miscellaneous = 0;

        /**
         * Creates a new PriceInformation instance using the specified properties.
         * @function create
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {appliancepb.IPriceInformation=} [properties] Properties to set
         * @returns {appliancepb.PriceInformation} PriceInformation instance
         */
        PriceInformation.create = function create(properties) {
            return new PriceInformation(properties);
        };

        /**
         * Encodes the specified PriceInformation message. Does not implicitly {@link appliancepb.PriceInformation.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {appliancepb.IPriceInformation} message PriceInformation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceInformation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productId);
            if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.basePrice);
            if (message.accessoriesFee != null && Object.hasOwnProperty.call(message, "accessoriesFee"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.accessoriesFee);
            if (message.moveApplianceFee != null && Object.hasOwnProperty.call(message, "moveApplianceFee"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.moveApplianceFee);
            if (message.disconnectFee != null && Object.hasOwnProperty.call(message, "disconnectFee"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.disconnectFee);
            if (message.installFee != null && Object.hasOwnProperty.call(message, "installFee"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.installFee);
            if (message.deliveryFee != null && Object.hasOwnProperty.call(message, "deliveryFee"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.deliveryFee);
            if (message.disposalFee != null && Object.hasOwnProperty.call(message, "disposalFee"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.disposalFee);
            if (message.adminFee != null && Object.hasOwnProperty.call(message, "adminFee"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.adminFee);
            if (message.upgradeFee != null && Object.hasOwnProperty.call(message, "upgradeFee"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.upgradeFee);
            if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.taxes);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.discount);
            if (message.miscellaneous != null && Object.hasOwnProperty.call(message, "miscellaneous"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.miscellaneous);
            return writer;
        };

        /**
         * Encodes the specified PriceInformation message, length delimited. Does not implicitly {@link appliancepb.PriceInformation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {appliancepb.IPriceInformation} message PriceInformation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceInformation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PriceInformation message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.PriceInformation} PriceInformation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceInformation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.PriceInformation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = reader.string();
                    break;
                case 2:
                    message.basePrice = reader.int32();
                    break;
                case 3:
                    message.accessoriesFee = reader.int32();
                    break;
                case 4:
                    message.moveApplianceFee = reader.int32();
                    break;
                case 5:
                    message.disconnectFee = reader.int32();
                    break;
                case 6:
                    message.installFee = reader.int32();
                    break;
                case 7:
                    message.deliveryFee = reader.int32();
                    break;
                case 8:
                    message.disposalFee = reader.int32();
                    break;
                case 9:
                    message.adminFee = reader.int32();
                    break;
                case 10:
                    message.upgradeFee = reader.int32();
                    break;
                case 11:
                    message.taxes = reader.int32();
                    break;
                case 12:
                    message.discount = reader.int32();
                    break;
                case 13:
                    message.miscellaneous = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PriceInformation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.PriceInformation} PriceInformation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceInformation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PriceInformation message.
         * @function verify
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PriceInformation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.productId != null && message.hasOwnProperty("productId"))
                if (!$util.isString(message.productId))
                    return "productId: string expected";
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                if (!$util.isInteger(message.basePrice))
                    return "basePrice: integer expected";
            if (message.accessoriesFee != null && message.hasOwnProperty("accessoriesFee"))
                if (!$util.isInteger(message.accessoriesFee))
                    return "accessoriesFee: integer expected";
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                if (!$util.isInteger(message.moveApplianceFee))
                    return "moveApplianceFee: integer expected";
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                if (!$util.isInteger(message.disconnectFee))
                    return "disconnectFee: integer expected";
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                if (!$util.isInteger(message.installFee))
                    return "installFee: integer expected";
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                if (!$util.isInteger(message.deliveryFee))
                    return "deliveryFee: integer expected";
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                if (!$util.isInteger(message.disposalFee))
                    return "disposalFee: integer expected";
            if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                if (!$util.isInteger(message.adminFee))
                    return "adminFee: integer expected";
            if (message.upgradeFee != null && message.hasOwnProperty("upgradeFee"))
                if (!$util.isInteger(message.upgradeFee))
                    return "upgradeFee: integer expected";
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                if (!$util.isInteger(message.taxes))
                    return "taxes: integer expected";
            if (message.discount != null && message.hasOwnProperty("discount"))
                if (!$util.isInteger(message.discount))
                    return "discount: integer expected";
            if (message.miscellaneous != null && message.hasOwnProperty("miscellaneous"))
                if (!$util.isInteger(message.miscellaneous))
                    return "miscellaneous: integer expected";
            return null;
        };

        /**
         * Creates a PriceInformation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.PriceInformation} PriceInformation
         */
        PriceInformation.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.PriceInformation)
                return object;
            let message = new $root.appliancepb.PriceInformation();
            if (object.productId != null)
                message.productId = String(object.productId);
            if (object.basePrice != null)
                message.basePrice = object.basePrice | 0;
            if (object.accessoriesFee != null)
                message.accessoriesFee = object.accessoriesFee | 0;
            if (object.moveApplianceFee != null)
                message.moveApplianceFee = object.moveApplianceFee | 0;
            if (object.disconnectFee != null)
                message.disconnectFee = object.disconnectFee | 0;
            if (object.installFee != null)
                message.installFee = object.installFee | 0;
            if (object.deliveryFee != null)
                message.deliveryFee = object.deliveryFee | 0;
            if (object.disposalFee != null)
                message.disposalFee = object.disposalFee | 0;
            if (object.adminFee != null)
                message.adminFee = object.adminFee | 0;
            if (object.upgradeFee != null)
                message.upgradeFee = object.upgradeFee | 0;
            if (object.taxes != null)
                message.taxes = object.taxes | 0;
            if (object.discount != null)
                message.discount = object.discount | 0;
            if (object.miscellaneous != null)
                message.miscellaneous = object.miscellaneous | 0;
            return message;
        };

        /**
         * Creates a plain object from a PriceInformation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.PriceInformation
         * @static
         * @param {appliancepb.PriceInformation} message PriceInformation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PriceInformation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.productId = "";
                object.basePrice = 0;
                object.accessoriesFee = 0;
                object.moveApplianceFee = 0;
                object.disconnectFee = 0;
                object.installFee = 0;
                object.deliveryFee = 0;
                object.disposalFee = 0;
                object.adminFee = 0;
                object.upgradeFee = 0;
                object.taxes = 0;
                object.discount = 0;
                object.miscellaneous = 0;
            }
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = message.productId;
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                object.basePrice = message.basePrice;
            if (message.accessoriesFee != null && message.hasOwnProperty("accessoriesFee"))
                object.accessoriesFee = message.accessoriesFee;
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                object.moveApplianceFee = message.moveApplianceFee;
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                object.disconnectFee = message.disconnectFee;
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                object.installFee = message.installFee;
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                object.deliveryFee = message.deliveryFee;
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                object.disposalFee = message.disposalFee;
            if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                object.adminFee = message.adminFee;
            if (message.upgradeFee != null && message.hasOwnProperty("upgradeFee"))
                object.upgradeFee = message.upgradeFee;
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                object.taxes = message.taxes;
            if (message.discount != null && message.hasOwnProperty("discount"))
                object.discount = message.discount;
            if (message.miscellaneous != null && message.hasOwnProperty("miscellaneous"))
                object.miscellaneous = message.miscellaneous;
            return object;
        };

        /**
         * Converts this PriceInformation to JSON.
         * @function toJSON
         * @memberof appliancepb.PriceInformation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PriceInformation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PriceInformation;
    })();

    appliancepb.ApplianceOrder = (function() {

        /**
         * Properties of an ApplianceOrder.
         * @memberof appliancepb
         * @interface IApplianceOrder
         * @property {string|null} [recordId] ApplianceOrder recordId
         * @property {string|null} [recordType] ApplianceOrder recordType
         * @property {google.protobuf.ITimestamp|null} [recordCreateDate] ApplianceOrder recordCreateDate
         * @property {string|null} [companyCode] ApplianceOrder companyCode
         * @property {appliancepb.IContract|null} [contract] ApplianceOrder contract
         * @property {string|null} [dispatchId] ApplianceOrder dispatchId
         * @property {string|null} [partRequestId] ApplianceOrder partRequestId
         * @property {appliancepb.ICustomer|null} [customer] ApplianceOrder customer
         * @property {string|null} [replacementCategory] ApplianceOrder replacementCategory
         * @property {string|null} [recommendedModel] ApplianceOrder recommendedModel
         * @property {appliancepb.IAgent|null} [offerAgent] ApplianceOrder offerAgent
         * @property {number|null} [cilValue] ApplianceOrder cilValue
         * @property {boolean|null} [agentModifiedPrice] ApplianceOrder agentModifiedPrice
         * @property {string|null} [billingState] ApplianceOrder billingState
         * @property {string|null} [paymentAttemptId] ApplianceOrder paymentAttemptId
         * @property {google.protobuf.ITimestamp|null} [paymentDate] ApplianceOrder paymentDate
         * @property {string|null} [purchasedBrand] ApplianceOrder purchasedBrand
         * @property {string|null} [purchasedModel] ApplianceOrder purchasedModel
         * @property {appliancepb.IPriceInformation|null} [purchasePrice] ApplianceOrder purchasePrice
         * @property {number|null} [customerPaidAmount] ApplianceOrder customerPaidAmount
         * @property {string|null} [orderedBy] ApplianceOrder orderedBy
         * @property {google.protobuf.ITimestamp|null} [orderDate] ApplianceOrder orderDate
         * @property {string|null} [vendor] ApplianceOrder vendor
         * @property {string|null} [vendorOrderNumber] ApplianceOrder vendorOrderNumber
         * @property {appliancepb.ICart|null} [cart] ApplianceOrder cart
         * @property {string|null} [comments] ApplianceOrder comments
         */

        /**
         * Constructs a new ApplianceOrder.
         * @memberof appliancepb
         * @classdesc Represents an ApplianceOrder.
         * @implements IApplianceOrder
         * @constructor
         * @param {appliancepb.IApplianceOrder=} [properties] Properties to set
         */
        function ApplianceOrder(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApplianceOrder recordId.
         * @member {string} recordId
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.recordId = "";

        /**
         * ApplianceOrder recordType.
         * @member {string} recordType
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.recordType = "";

        /**
         * ApplianceOrder recordCreateDate.
         * @member {google.protobuf.ITimestamp|null|undefined} recordCreateDate
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.recordCreateDate = null;

        /**
         * ApplianceOrder companyCode.
         * @member {string} companyCode
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.companyCode = "";

        /**
         * ApplianceOrder contract.
         * @member {appliancepb.IContract|null|undefined} contract
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.contract = null;

        /**
         * ApplianceOrder dispatchId.
         * @member {string} dispatchId
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.dispatchId = "";

        /**
         * ApplianceOrder partRequestId.
         * @member {string} partRequestId
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.partRequestId = "";

        /**
         * ApplianceOrder customer.
         * @member {appliancepb.ICustomer|null|undefined} customer
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.customer = null;

        /**
         * ApplianceOrder replacementCategory.
         * @member {string} replacementCategory
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.replacementCategory = "";

        /**
         * ApplianceOrder recommendedModel.
         * @member {string} recommendedModel
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.recommendedModel = "";

        /**
         * ApplianceOrder offerAgent.
         * @member {appliancepb.IAgent|null|undefined} offerAgent
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.offerAgent = null;

        /**
         * ApplianceOrder cilValue.
         * @member {number} cilValue
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.cilValue = 0;

        /**
         * ApplianceOrder agentModifiedPrice.
         * @member {boolean} agentModifiedPrice
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.agentModifiedPrice = false;

        /**
         * ApplianceOrder billingState.
         * @member {string} billingState
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.billingState = "";

        /**
         * ApplianceOrder paymentAttemptId.
         * @member {string} paymentAttemptId
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.paymentAttemptId = "";

        /**
         * ApplianceOrder paymentDate.
         * @member {google.protobuf.ITimestamp|null|undefined} paymentDate
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.paymentDate = null;

        /**
         * ApplianceOrder purchasedBrand.
         * @member {string} purchasedBrand
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.purchasedBrand = "";

        /**
         * ApplianceOrder purchasedModel.
         * @member {string} purchasedModel
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.purchasedModel = "";

        /**
         * ApplianceOrder purchasePrice.
         * @member {appliancepb.IPriceInformation|null|undefined} purchasePrice
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.purchasePrice = null;

        /**
         * ApplianceOrder customerPaidAmount.
         * @member {number} customerPaidAmount
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.customerPaidAmount = 0;

        /**
         * ApplianceOrder orderedBy.
         * @member {string} orderedBy
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.orderedBy = "";

        /**
         * ApplianceOrder orderDate.
         * @member {google.protobuf.ITimestamp|null|undefined} orderDate
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.orderDate = null;

        /**
         * ApplianceOrder vendor.
         * @member {string} vendor
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.vendor = "";

        /**
         * ApplianceOrder vendorOrderNumber.
         * @member {string} vendorOrderNumber
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.vendorOrderNumber = "";

        /**
         * ApplianceOrder cart.
         * @member {appliancepb.ICart|null|undefined} cart
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.cart = null;

        /**
         * ApplianceOrder comments.
         * @member {string} comments
         * @memberof appliancepb.ApplianceOrder
         * @instance
         */
        ApplianceOrder.prototype.comments = "";

        /**
         * Creates a new ApplianceOrder instance using the specified properties.
         * @function create
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {appliancepb.IApplianceOrder=} [properties] Properties to set
         * @returns {appliancepb.ApplianceOrder} ApplianceOrder instance
         */
        ApplianceOrder.create = function create(properties) {
            return new ApplianceOrder(properties);
        };

        /**
         * Encodes the specified ApplianceOrder message. Does not implicitly {@link appliancepb.ApplianceOrder.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {appliancepb.IApplianceOrder} message ApplianceOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplianceOrder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.recordId != null && Object.hasOwnProperty.call(message, "recordId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.recordId);
            if (message.recordType != null && Object.hasOwnProperty.call(message, "recordType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.recordType);
            if (message.recordCreateDate != null && Object.hasOwnProperty.call(message, "recordCreateDate"))
                $root.google.protobuf.Timestamp.encode(message.recordCreateDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.companyCode != null && Object.hasOwnProperty.call(message, "companyCode"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.companyCode);
            if (message.contract != null && Object.hasOwnProperty.call(message, "contract"))
                $root.appliancepb.Contract.encode(message.contract, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.dispatchId != null && Object.hasOwnProperty.call(message, "dispatchId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.dispatchId);
            if (message.partRequestId != null && Object.hasOwnProperty.call(message, "partRequestId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.partRequestId);
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.appliancepb.Customer.encode(message.customer, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.replacementCategory != null && Object.hasOwnProperty.call(message, "replacementCategory"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.replacementCategory);
            if (message.recommendedModel != null && Object.hasOwnProperty.call(message, "recommendedModel"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.recommendedModel);
            if (message.offerAgent != null && Object.hasOwnProperty.call(message, "offerAgent"))
                $root.appliancepb.Agent.encode(message.offerAgent, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.cilValue != null && Object.hasOwnProperty.call(message, "cilValue"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.cilValue);
            if (message.agentModifiedPrice != null && Object.hasOwnProperty.call(message, "agentModifiedPrice"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.agentModifiedPrice);
            if (message.billingState != null && Object.hasOwnProperty.call(message, "billingState"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.billingState);
            if (message.paymentAttemptId != null && Object.hasOwnProperty.call(message, "paymentAttemptId"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.paymentAttemptId);
            if (message.paymentDate != null && Object.hasOwnProperty.call(message, "paymentDate"))
                $root.google.protobuf.Timestamp.encode(message.paymentDate, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.purchasedBrand != null && Object.hasOwnProperty.call(message, "purchasedBrand"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.purchasedBrand);
            if (message.purchasedModel != null && Object.hasOwnProperty.call(message, "purchasedModel"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.purchasedModel);
            if (message.purchasePrice != null && Object.hasOwnProperty.call(message, "purchasePrice"))
                $root.appliancepb.PriceInformation.encode(message.purchasePrice, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.customerPaidAmount != null && Object.hasOwnProperty.call(message, "customerPaidAmount"))
                writer.uint32(/* id 20, wireType 0 =*/160).int32(message.customerPaidAmount);
            if (message.orderedBy != null && Object.hasOwnProperty.call(message, "orderedBy"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.orderedBy);
            if (message.orderDate != null && Object.hasOwnProperty.call(message, "orderDate"))
                $root.google.protobuf.Timestamp.encode(message.orderDate, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.vendor != null && Object.hasOwnProperty.call(message, "vendor"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.vendor);
            if (message.vendorOrderNumber != null && Object.hasOwnProperty.call(message, "vendorOrderNumber"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.vendorOrderNumber);
            if (message.cart != null && Object.hasOwnProperty.call(message, "cart"))
                $root.appliancepb.Cart.encode(message.cart, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.comments);
            return writer;
        };

        /**
         * Encodes the specified ApplianceOrder message, length delimited. Does not implicitly {@link appliancepb.ApplianceOrder.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {appliancepb.IApplianceOrder} message ApplianceOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplianceOrder.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApplianceOrder message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ApplianceOrder} ApplianceOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplianceOrder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ApplianceOrder();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.recordId = reader.string();
                    break;
                case 2:
                    message.recordType = reader.string();
                    break;
                case 3:
                    message.recordCreateDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.companyCode = reader.string();
                    break;
                case 5:
                    message.contract = $root.appliancepb.Contract.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.dispatchId = reader.string();
                    break;
                case 7:
                    message.partRequestId = reader.string();
                    break;
                case 8:
                    message.customer = $root.appliancepb.Customer.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.replacementCategory = reader.string();
                    break;
                case 10:
                    message.recommendedModel = reader.string();
                    break;
                case 11:
                    message.offerAgent = $root.appliancepb.Agent.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.cilValue = reader.int32();
                    break;
                case 13:
                    message.agentModifiedPrice = reader.bool();
                    break;
                case 14:
                    message.billingState = reader.string();
                    break;
                case 15:
                    message.paymentAttemptId = reader.string();
                    break;
                case 16:
                    message.paymentDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.purchasedBrand = reader.string();
                    break;
                case 18:
                    message.purchasedModel = reader.string();
                    break;
                case 19:
                    message.purchasePrice = $root.appliancepb.PriceInformation.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.customerPaidAmount = reader.int32();
                    break;
                case 21:
                    message.orderedBy = reader.string();
                    break;
                case 22:
                    message.orderDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.vendor = reader.string();
                    break;
                case 24:
                    message.vendorOrderNumber = reader.string();
                    break;
                case 25:
                    message.cart = $root.appliancepb.Cart.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.comments = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApplianceOrder message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ApplianceOrder} ApplianceOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplianceOrder.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApplianceOrder message.
         * @function verify
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApplianceOrder.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                if (!$util.isString(message.recordId))
                    return "recordId: string expected";
            if (message.recordType != null && message.hasOwnProperty("recordType"))
                if (!$util.isString(message.recordType))
                    return "recordType: string expected";
            if (message.recordCreateDate != null && message.hasOwnProperty("recordCreateDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.recordCreateDate);
                if (error)
                    return "recordCreateDate." + error;
            }
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                if (!$util.isString(message.companyCode))
                    return "companyCode: string expected";
            if (message.contract != null && message.hasOwnProperty("contract")) {
                let error = $root.appliancepb.Contract.verify(message.contract);
                if (error)
                    return "contract." + error;
            }
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                if (!$util.isString(message.dispatchId))
                    return "dispatchId: string expected";
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                if (!$util.isString(message.partRequestId))
                    return "partRequestId: string expected";
            if (message.customer != null && message.hasOwnProperty("customer")) {
                let error = $root.appliancepb.Customer.verify(message.customer);
                if (error)
                    return "customer." + error;
            }
            if (message.replacementCategory != null && message.hasOwnProperty("replacementCategory"))
                if (!$util.isString(message.replacementCategory))
                    return "replacementCategory: string expected";
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel"))
                if (!$util.isString(message.recommendedModel))
                    return "recommendedModel: string expected";
            if (message.offerAgent != null && message.hasOwnProperty("offerAgent")) {
                let error = $root.appliancepb.Agent.verify(message.offerAgent);
                if (error)
                    return "offerAgent." + error;
            }
            if (message.cilValue != null && message.hasOwnProperty("cilValue"))
                if (!$util.isInteger(message.cilValue))
                    return "cilValue: integer expected";
            if (message.agentModifiedPrice != null && message.hasOwnProperty("agentModifiedPrice"))
                if (typeof message.agentModifiedPrice !== "boolean")
                    return "agentModifiedPrice: boolean expected";
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                if (!$util.isString(message.billingState))
                    return "billingState: string expected";
            if (message.paymentAttemptId != null && message.hasOwnProperty("paymentAttemptId"))
                if (!$util.isString(message.paymentAttemptId))
                    return "paymentAttemptId: string expected";
            if (message.paymentDate != null && message.hasOwnProperty("paymentDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.paymentDate);
                if (error)
                    return "paymentDate." + error;
            }
            if (message.purchasedBrand != null && message.hasOwnProperty("purchasedBrand"))
                if (!$util.isString(message.purchasedBrand))
                    return "purchasedBrand: string expected";
            if (message.purchasedModel != null && message.hasOwnProperty("purchasedModel"))
                if (!$util.isString(message.purchasedModel))
                    return "purchasedModel: string expected";
            if (message.purchasePrice != null && message.hasOwnProperty("purchasePrice")) {
                let error = $root.appliancepb.PriceInformation.verify(message.purchasePrice);
                if (error)
                    return "purchasePrice." + error;
            }
            if (message.customerPaidAmount != null && message.hasOwnProperty("customerPaidAmount"))
                if (!$util.isInteger(message.customerPaidAmount))
                    return "customerPaidAmount: integer expected";
            if (message.orderedBy != null && message.hasOwnProperty("orderedBy"))
                if (!$util.isString(message.orderedBy))
                    return "orderedBy: string expected";
            if (message.orderDate != null && message.hasOwnProperty("orderDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.orderDate);
                if (error)
                    return "orderDate." + error;
            }
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                if (!$util.isString(message.vendor))
                    return "vendor: string expected";
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                if (!$util.isString(message.vendorOrderNumber))
                    return "vendorOrderNumber: string expected";
            if (message.cart != null && message.hasOwnProperty("cart")) {
                let error = $root.appliancepb.Cart.verify(message.cart);
                if (error)
                    return "cart." + error;
            }
            if (message.comments != null && message.hasOwnProperty("comments"))
                if (!$util.isString(message.comments))
                    return "comments: string expected";
            return null;
        };

        /**
         * Creates an ApplianceOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ApplianceOrder} ApplianceOrder
         */
        ApplianceOrder.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ApplianceOrder)
                return object;
            let message = new $root.appliancepb.ApplianceOrder();
            if (object.recordId != null)
                message.recordId = String(object.recordId);
            if (object.recordType != null)
                message.recordType = String(object.recordType);
            if (object.recordCreateDate != null) {
                if (typeof object.recordCreateDate !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.recordCreateDate: object expected");
                message.recordCreateDate = $root.google.protobuf.Timestamp.fromObject(object.recordCreateDate);
            }
            if (object.companyCode != null)
                message.companyCode = String(object.companyCode);
            if (object.contract != null) {
                if (typeof object.contract !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.contract: object expected");
                message.contract = $root.appliancepb.Contract.fromObject(object.contract);
            }
            if (object.dispatchId != null)
                message.dispatchId = String(object.dispatchId);
            if (object.partRequestId != null)
                message.partRequestId = String(object.partRequestId);
            if (object.customer != null) {
                if (typeof object.customer !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.customer: object expected");
                message.customer = $root.appliancepb.Customer.fromObject(object.customer);
            }
            if (object.replacementCategory != null)
                message.replacementCategory = String(object.replacementCategory);
            if (object.recommendedModel != null)
                message.recommendedModel = String(object.recommendedModel);
            if (object.offerAgent != null) {
                if (typeof object.offerAgent !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.offerAgent: object expected");
                message.offerAgent = $root.appliancepb.Agent.fromObject(object.offerAgent);
            }
            if (object.cilValue != null)
                message.cilValue = object.cilValue | 0;
            if (object.agentModifiedPrice != null)
                message.agentModifiedPrice = Boolean(object.agentModifiedPrice);
            if (object.billingState != null)
                message.billingState = String(object.billingState);
            if (object.paymentAttemptId != null)
                message.paymentAttemptId = String(object.paymentAttemptId);
            if (object.paymentDate != null) {
                if (typeof object.paymentDate !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.paymentDate: object expected");
                message.paymentDate = $root.google.protobuf.Timestamp.fromObject(object.paymentDate);
            }
            if (object.purchasedBrand != null)
                message.purchasedBrand = String(object.purchasedBrand);
            if (object.purchasedModel != null)
                message.purchasedModel = String(object.purchasedModel);
            if (object.purchasePrice != null) {
                if (typeof object.purchasePrice !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.purchasePrice: object expected");
                message.purchasePrice = $root.appliancepb.PriceInformation.fromObject(object.purchasePrice);
            }
            if (object.customerPaidAmount != null)
                message.customerPaidAmount = object.customerPaidAmount | 0;
            if (object.orderedBy != null)
                message.orderedBy = String(object.orderedBy);
            if (object.orderDate != null) {
                if (typeof object.orderDate !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.orderDate: object expected");
                message.orderDate = $root.google.protobuf.Timestamp.fromObject(object.orderDate);
            }
            if (object.vendor != null)
                message.vendor = String(object.vendor);
            if (object.vendorOrderNumber != null)
                message.vendorOrderNumber = String(object.vendorOrderNumber);
            if (object.cart != null) {
                if (typeof object.cart !== "object")
                    throw TypeError(".appliancepb.ApplianceOrder.cart: object expected");
                message.cart = $root.appliancepb.Cart.fromObject(object.cart);
            }
            if (object.comments != null)
                message.comments = String(object.comments);
            return message;
        };

        /**
         * Creates a plain object from an ApplianceOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ApplianceOrder
         * @static
         * @param {appliancepb.ApplianceOrder} message ApplianceOrder
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApplianceOrder.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.recordId = "";
                object.recordType = "";
                object.recordCreateDate = null;
                object.companyCode = "";
                object.contract = null;
                object.dispatchId = "";
                object.partRequestId = "";
                object.customer = null;
                object.replacementCategory = "";
                object.recommendedModel = "";
                object.offerAgent = null;
                object.cilValue = 0;
                object.agentModifiedPrice = false;
                object.billingState = "";
                object.paymentAttemptId = "";
                object.paymentDate = null;
                object.purchasedBrand = "";
                object.purchasedModel = "";
                object.purchasePrice = null;
                object.customerPaidAmount = 0;
                object.orderedBy = "";
                object.orderDate = null;
                object.vendor = "";
                object.vendorOrderNumber = "";
                object.cart = null;
                object.comments = "";
            }
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                object.recordId = message.recordId;
            if (message.recordType != null && message.hasOwnProperty("recordType"))
                object.recordType = message.recordType;
            if (message.recordCreateDate != null && message.hasOwnProperty("recordCreateDate"))
                object.recordCreateDate = $root.google.protobuf.Timestamp.toObject(message.recordCreateDate, options);
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                object.companyCode = message.companyCode;
            if (message.contract != null && message.hasOwnProperty("contract"))
                object.contract = $root.appliancepb.Contract.toObject(message.contract, options);
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                object.dispatchId = message.dispatchId;
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                object.partRequestId = message.partRequestId;
            if (message.customer != null && message.hasOwnProperty("customer"))
                object.customer = $root.appliancepb.Customer.toObject(message.customer, options);
            if (message.replacementCategory != null && message.hasOwnProperty("replacementCategory"))
                object.replacementCategory = message.replacementCategory;
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel"))
                object.recommendedModel = message.recommendedModel;
            if (message.offerAgent != null && message.hasOwnProperty("offerAgent"))
                object.offerAgent = $root.appliancepb.Agent.toObject(message.offerAgent, options);
            if (message.cilValue != null && message.hasOwnProperty("cilValue"))
                object.cilValue = message.cilValue;
            if (message.agentModifiedPrice != null && message.hasOwnProperty("agentModifiedPrice"))
                object.agentModifiedPrice = message.agentModifiedPrice;
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                object.billingState = message.billingState;
            if (message.paymentAttemptId != null && message.hasOwnProperty("paymentAttemptId"))
                object.paymentAttemptId = message.paymentAttemptId;
            if (message.paymentDate != null && message.hasOwnProperty("paymentDate"))
                object.paymentDate = $root.google.protobuf.Timestamp.toObject(message.paymentDate, options);
            if (message.purchasedBrand != null && message.hasOwnProperty("purchasedBrand"))
                object.purchasedBrand = message.purchasedBrand;
            if (message.purchasedModel != null && message.hasOwnProperty("purchasedModel"))
                object.purchasedModel = message.purchasedModel;
            if (message.purchasePrice != null && message.hasOwnProperty("purchasePrice"))
                object.purchasePrice = $root.appliancepb.PriceInformation.toObject(message.purchasePrice, options);
            if (message.customerPaidAmount != null && message.hasOwnProperty("customerPaidAmount"))
                object.customerPaidAmount = message.customerPaidAmount;
            if (message.orderedBy != null && message.hasOwnProperty("orderedBy"))
                object.orderedBy = message.orderedBy;
            if (message.orderDate != null && message.hasOwnProperty("orderDate"))
                object.orderDate = $root.google.protobuf.Timestamp.toObject(message.orderDate, options);
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                object.vendor = message.vendor;
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                object.vendorOrderNumber = message.vendorOrderNumber;
            if (message.cart != null && message.hasOwnProperty("cart"))
                object.cart = $root.appliancepb.Cart.toObject(message.cart, options);
            if (message.comments != null && message.hasOwnProperty("comments"))
                object.comments = message.comments;
            return object;
        };

        /**
         * Converts this ApplianceOrder to JSON.
         * @function toJSON
         * @memberof appliancepb.ApplianceOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApplianceOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ApplianceOrder;
    })();

    appliancepb.WPApplianceOrder = (function() {

        /**
         * Properties of a WPApplianceOrder.
         * @memberof appliancepb
         * @interface IWPApplianceOrder
         * @property {string|null} [wpOrderNumber] WPApplianceOrder wpOrderNumber
         * @property {string|null} [isUpgradeOrder] WPApplianceOrder isUpgradeOrder
         * @property {string|null} [disposalSelected] WPApplianceOrder disposalSelected
         * @property {string|null} [orderState] WPApplianceOrder orderState
         * @property {string|null} [dispatchID] WPApplianceOrder dispatchID
         * @property {string|null} [itemID] WPApplianceOrder itemID
         * @property {string|null} [partRequestID] WPApplianceOrder partRequestID
         * @property {string|null} [replacementBrand] WPApplianceOrder replacementBrand
         * @property {string|null} [contractNumber] WPApplianceOrder contractNumber
         * @property {string|null} [recommendedModel] WPApplianceOrder recommendedModel
         * @property {string|null} [customerBoughtModel] WPApplianceOrder customerBoughtModel
         * @property {string|null} [replacementItem] WPApplianceOrder replacementItem
         * @property {string|null} [orderCreatedDate] WPApplianceOrder orderCreatedDate
         * @property {string|null} [recommendationDate] WPApplianceOrder recommendationDate
         * @property {string|null} [phoneNumber] WPApplianceOrder phoneNumber
         * @property {string|null} [transactionAmount] WPApplianceOrder transactionAmount
         * @property {string|null} [adminFee] WPApplianceOrder adminFee
         * @property {string|null} [disposalFee] WPApplianceOrder disposalFee
         * @property {string|null} [transactionID] WPApplianceOrder transactionID
         * @property {string|null} [transactionDate] WPApplianceOrder transactionDate
         * @property {string|null} [companyCode] WPApplianceOrder companyCode
         * @property {string|null} [customerFirstName] WPApplianceOrder customerFirstName
         * @property {string|null} [customerLastName] WPApplianceOrder customerLastName
         * @property {string|null} [email] WPApplianceOrder email
         * @property {string|null} [billingState] WPApplianceOrder billingState
         * @property {string|null} [orderedBy] WPApplianceOrder orderedBy
         * @property {string|null} [comments] WPApplianceOrder comments
         * @property {string|null} [replacementID] WPApplianceOrder replacementID
         * @property {string|null} [orderStatus] WPApplianceOrder orderStatus
         * @property {string|null} [cilValue] WPApplianceOrder cilValue
         * @property {string|null} [basePrice] WPApplianceOrder basePrice
         * @property {string|null} [homeOwnerCost] WPApplianceOrder homeOwnerCost
         * @property {string|null} [disconnectFee] WPApplianceOrder disconnectFee
         * @property {string|null} [installFee] WPApplianceOrder installFee
         * @property {string|null} [deliveryFee] WPApplianceOrder deliveryFee
         * @property {string|null} [moveApplianceFee] WPApplianceOrder moveApplianceFee
         * @property {string|null} [restockingFee] WPApplianceOrder restockingFee
         * @property {string|null} [taxes] WPApplianceOrder taxes
         * @property {string|null} [serviceCodes] WPApplianceOrder serviceCodes
         * @property {string|null} [serviceFees] WPApplianceOrder serviceFees
         * @property {string|null} [recordID] WPApplianceOrder recordID
         */

        /**
         * Constructs a new WPApplianceOrder.
         * @memberof appliancepb
         * @classdesc Represents a WPApplianceOrder.
         * @implements IWPApplianceOrder
         * @constructor
         * @param {appliancepb.IWPApplianceOrder=} [properties] Properties to set
         */
        function WPApplianceOrder(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WPApplianceOrder wpOrderNumber.
         * @member {string} wpOrderNumber
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.wpOrderNumber = "";

        /**
         * WPApplianceOrder isUpgradeOrder.
         * @member {string} isUpgradeOrder
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.isUpgradeOrder = "";

        /**
         * WPApplianceOrder disposalSelected.
         * @member {string} disposalSelected
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.disposalSelected = "";

        /**
         * WPApplianceOrder orderState.
         * @member {string} orderState
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.orderState = "";

        /**
         * WPApplianceOrder dispatchID.
         * @member {string} dispatchID
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.dispatchID = "";

        /**
         * WPApplianceOrder itemID.
         * @member {string} itemID
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.itemID = "";

        /**
         * WPApplianceOrder partRequestID.
         * @member {string} partRequestID
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.partRequestID = "";

        /**
         * WPApplianceOrder replacementBrand.
         * @member {string} replacementBrand
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.replacementBrand = "";

        /**
         * WPApplianceOrder contractNumber.
         * @member {string} contractNumber
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.contractNumber = "";

        /**
         * WPApplianceOrder recommendedModel.
         * @member {string} recommendedModel
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.recommendedModel = "";

        /**
         * WPApplianceOrder customerBoughtModel.
         * @member {string} customerBoughtModel
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.customerBoughtModel = "";

        /**
         * WPApplianceOrder replacementItem.
         * @member {string} replacementItem
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.replacementItem = "";

        /**
         * WPApplianceOrder orderCreatedDate.
         * @member {string} orderCreatedDate
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.orderCreatedDate = "";

        /**
         * WPApplianceOrder recommendationDate.
         * @member {string} recommendationDate
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.recommendationDate = "";

        /**
         * WPApplianceOrder phoneNumber.
         * @member {string} phoneNumber
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.phoneNumber = "";

        /**
         * WPApplianceOrder transactionAmount.
         * @member {string} transactionAmount
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.transactionAmount = "";

        /**
         * WPApplianceOrder adminFee.
         * @member {string} adminFee
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.adminFee = "";

        /**
         * WPApplianceOrder disposalFee.
         * @member {string} disposalFee
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.disposalFee = "";

        /**
         * WPApplianceOrder transactionID.
         * @member {string} transactionID
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.transactionID = "";

        /**
         * WPApplianceOrder transactionDate.
         * @member {string} transactionDate
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.transactionDate = "";

        /**
         * WPApplianceOrder companyCode.
         * @member {string} companyCode
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.companyCode = "";

        /**
         * WPApplianceOrder customerFirstName.
         * @member {string} customerFirstName
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.customerFirstName = "";

        /**
         * WPApplianceOrder customerLastName.
         * @member {string} customerLastName
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.customerLastName = "";

        /**
         * WPApplianceOrder email.
         * @member {string} email
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.email = "";

        /**
         * WPApplianceOrder billingState.
         * @member {string} billingState
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.billingState = "";

        /**
         * WPApplianceOrder orderedBy.
         * @member {string} orderedBy
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.orderedBy = "";

        /**
         * WPApplianceOrder comments.
         * @member {string} comments
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.comments = "";

        /**
         * WPApplianceOrder replacementID.
         * @member {string} replacementID
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.replacementID = "";

        /**
         * WPApplianceOrder orderStatus.
         * @member {string} orderStatus
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.orderStatus = "";

        /**
         * WPApplianceOrder cilValue.
         * @member {string} cilValue
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.cilValue = "";

        /**
         * WPApplianceOrder basePrice.
         * @member {string} basePrice
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.basePrice = "";

        /**
         * WPApplianceOrder homeOwnerCost.
         * @member {string} homeOwnerCost
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.homeOwnerCost = "";

        /**
         * WPApplianceOrder disconnectFee.
         * @member {string} disconnectFee
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.disconnectFee = "";

        /**
         * WPApplianceOrder installFee.
         * @member {string} installFee
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.installFee = "";

        /**
         * WPApplianceOrder deliveryFee.
         * @member {string} deliveryFee
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.deliveryFee = "";

        /**
         * WPApplianceOrder moveApplianceFee.
         * @member {string} moveApplianceFee
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.moveApplianceFee = "";

        /**
         * WPApplianceOrder restockingFee.
         * @member {string} restockingFee
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.restockingFee = "";

        /**
         * WPApplianceOrder taxes.
         * @member {string} taxes
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.taxes = "";

        /**
         * WPApplianceOrder serviceCodes.
         * @member {string} serviceCodes
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.serviceCodes = "";

        /**
         * WPApplianceOrder serviceFees.
         * @member {string} serviceFees
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.serviceFees = "";

        /**
         * WPApplianceOrder recordID.
         * @member {string} recordID
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         */
        WPApplianceOrder.prototype.recordID = "";

        /**
         * Creates a new WPApplianceOrder instance using the specified properties.
         * @function create
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {appliancepb.IWPApplianceOrder=} [properties] Properties to set
         * @returns {appliancepb.WPApplianceOrder} WPApplianceOrder instance
         */
        WPApplianceOrder.create = function create(properties) {
            return new WPApplianceOrder(properties);
        };

        /**
         * Encodes the specified WPApplianceOrder message. Does not implicitly {@link appliancepb.WPApplianceOrder.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {appliancepb.IWPApplianceOrder} message WPApplianceOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WPApplianceOrder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.wpOrderNumber != null && Object.hasOwnProperty.call(message, "wpOrderNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.wpOrderNumber);
            if (message.isUpgradeOrder != null && Object.hasOwnProperty.call(message, "isUpgradeOrder"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.isUpgradeOrder);
            if (message.disposalSelected != null && Object.hasOwnProperty.call(message, "disposalSelected"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.disposalSelected);
            if (message.orderState != null && Object.hasOwnProperty.call(message, "orderState"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.orderState);
            if (message.dispatchID != null && Object.hasOwnProperty.call(message, "dispatchID"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.dispatchID);
            if (message.itemID != null && Object.hasOwnProperty.call(message, "itemID"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.itemID);
            if (message.partRequestID != null && Object.hasOwnProperty.call(message, "partRequestID"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.partRequestID);
            if (message.replacementBrand != null && Object.hasOwnProperty.call(message, "replacementBrand"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.replacementBrand);
            if (message.contractNumber != null && Object.hasOwnProperty.call(message, "contractNumber"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.contractNumber);
            if (message.recommendedModel != null && Object.hasOwnProperty.call(message, "recommendedModel"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.recommendedModel);
            if (message.customerBoughtModel != null && Object.hasOwnProperty.call(message, "customerBoughtModel"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.customerBoughtModel);
            if (message.replacementItem != null && Object.hasOwnProperty.call(message, "replacementItem"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.replacementItem);
            if (message.orderCreatedDate != null && Object.hasOwnProperty.call(message, "orderCreatedDate"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.orderCreatedDate);
            if (message.recommendationDate != null && Object.hasOwnProperty.call(message, "recommendationDate"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.recommendationDate);
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.phoneNumber);
            if (message.transactionAmount != null && Object.hasOwnProperty.call(message, "transactionAmount"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.transactionAmount);
            if (message.adminFee != null && Object.hasOwnProperty.call(message, "adminFee"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.adminFee);
            if (message.disposalFee != null && Object.hasOwnProperty.call(message, "disposalFee"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.disposalFee);
            if (message.transactionID != null && Object.hasOwnProperty.call(message, "transactionID"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.transactionID);
            if (message.transactionDate != null && Object.hasOwnProperty.call(message, "transactionDate"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.transactionDate);
            if (message.companyCode != null && Object.hasOwnProperty.call(message, "companyCode"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.companyCode);
            if (message.customerFirstName != null && Object.hasOwnProperty.call(message, "customerFirstName"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.customerFirstName);
            if (message.customerLastName != null && Object.hasOwnProperty.call(message, "customerLastName"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.customerLastName);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.email);
            if (message.billingState != null && Object.hasOwnProperty.call(message, "billingState"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.billingState);
            if (message.orderedBy != null && Object.hasOwnProperty.call(message, "orderedBy"))
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.orderedBy);
            if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                writer.uint32(/* id 27, wireType 2 =*/218).string(message.comments);
            if (message.replacementID != null && Object.hasOwnProperty.call(message, "replacementID"))
                writer.uint32(/* id 28, wireType 2 =*/226).string(message.replacementID);
            if (message.orderStatus != null && Object.hasOwnProperty.call(message, "orderStatus"))
                writer.uint32(/* id 29, wireType 2 =*/234).string(message.orderStatus);
            if (message.cilValue != null && Object.hasOwnProperty.call(message, "cilValue"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.cilValue);
            if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                writer.uint32(/* id 31, wireType 2 =*/250).string(message.basePrice);
            if (message.homeOwnerCost != null && Object.hasOwnProperty.call(message, "homeOwnerCost"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.homeOwnerCost);
            if (message.disconnectFee != null && Object.hasOwnProperty.call(message, "disconnectFee"))
                writer.uint32(/* id 33, wireType 2 =*/266).string(message.disconnectFee);
            if (message.installFee != null && Object.hasOwnProperty.call(message, "installFee"))
                writer.uint32(/* id 34, wireType 2 =*/274).string(message.installFee);
            if (message.deliveryFee != null && Object.hasOwnProperty.call(message, "deliveryFee"))
                writer.uint32(/* id 35, wireType 2 =*/282).string(message.deliveryFee);
            if (message.moveApplianceFee != null && Object.hasOwnProperty.call(message, "moveApplianceFee"))
                writer.uint32(/* id 36, wireType 2 =*/290).string(message.moveApplianceFee);
            if (message.restockingFee != null && Object.hasOwnProperty.call(message, "restockingFee"))
                writer.uint32(/* id 37, wireType 2 =*/298).string(message.restockingFee);
            if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                writer.uint32(/* id 38, wireType 2 =*/306).string(message.taxes);
            if (message.serviceCodes != null && Object.hasOwnProperty.call(message, "serviceCodes"))
                writer.uint32(/* id 39, wireType 2 =*/314).string(message.serviceCodes);
            if (message.serviceFees != null && Object.hasOwnProperty.call(message, "serviceFees"))
                writer.uint32(/* id 40, wireType 2 =*/322).string(message.serviceFees);
            if (message.recordID != null && Object.hasOwnProperty.call(message, "recordID"))
                writer.uint32(/* id 41, wireType 2 =*/330).string(message.recordID);
            return writer;
        };

        /**
         * Encodes the specified WPApplianceOrder message, length delimited. Does not implicitly {@link appliancepb.WPApplianceOrder.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {appliancepb.IWPApplianceOrder} message WPApplianceOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WPApplianceOrder.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WPApplianceOrder message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.WPApplianceOrder} WPApplianceOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WPApplianceOrder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.WPApplianceOrder();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.wpOrderNumber = reader.string();
                    break;
                case 2:
                    message.isUpgradeOrder = reader.string();
                    break;
                case 3:
                    message.disposalSelected = reader.string();
                    break;
                case 4:
                    message.orderState = reader.string();
                    break;
                case 5:
                    message.dispatchID = reader.string();
                    break;
                case 6:
                    message.itemID = reader.string();
                    break;
                case 7:
                    message.partRequestID = reader.string();
                    break;
                case 8:
                    message.replacementBrand = reader.string();
                    break;
                case 9:
                    message.contractNumber = reader.string();
                    break;
                case 10:
                    message.recommendedModel = reader.string();
                    break;
                case 11:
                    message.customerBoughtModel = reader.string();
                    break;
                case 12:
                    message.replacementItem = reader.string();
                    break;
                case 13:
                    message.orderCreatedDate = reader.string();
                    break;
                case 14:
                    message.recommendationDate = reader.string();
                    break;
                case 15:
                    message.phoneNumber = reader.string();
                    break;
                case 16:
                    message.transactionAmount = reader.string();
                    break;
                case 17:
                    message.adminFee = reader.string();
                    break;
                case 18:
                    message.disposalFee = reader.string();
                    break;
                case 19:
                    message.transactionID = reader.string();
                    break;
                case 20:
                    message.transactionDate = reader.string();
                    break;
                case 21:
                    message.companyCode = reader.string();
                    break;
                case 22:
                    message.customerFirstName = reader.string();
                    break;
                case 23:
                    message.customerLastName = reader.string();
                    break;
                case 24:
                    message.email = reader.string();
                    break;
                case 25:
                    message.billingState = reader.string();
                    break;
                case 26:
                    message.orderedBy = reader.string();
                    break;
                case 27:
                    message.comments = reader.string();
                    break;
                case 28:
                    message.replacementID = reader.string();
                    break;
                case 29:
                    message.orderStatus = reader.string();
                    break;
                case 30:
                    message.cilValue = reader.string();
                    break;
                case 31:
                    message.basePrice = reader.string();
                    break;
                case 32:
                    message.homeOwnerCost = reader.string();
                    break;
                case 33:
                    message.disconnectFee = reader.string();
                    break;
                case 34:
                    message.installFee = reader.string();
                    break;
                case 35:
                    message.deliveryFee = reader.string();
                    break;
                case 36:
                    message.moveApplianceFee = reader.string();
                    break;
                case 37:
                    message.restockingFee = reader.string();
                    break;
                case 38:
                    message.taxes = reader.string();
                    break;
                case 39:
                    message.serviceCodes = reader.string();
                    break;
                case 40:
                    message.serviceFees = reader.string();
                    break;
                case 41:
                    message.recordID = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WPApplianceOrder message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.WPApplianceOrder} WPApplianceOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WPApplianceOrder.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WPApplianceOrder message.
         * @function verify
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WPApplianceOrder.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.wpOrderNumber != null && message.hasOwnProperty("wpOrderNumber"))
                if (!$util.isString(message.wpOrderNumber))
                    return "wpOrderNumber: string expected";
            if (message.isUpgradeOrder != null && message.hasOwnProperty("isUpgradeOrder"))
                if (!$util.isString(message.isUpgradeOrder))
                    return "isUpgradeOrder: string expected";
            if (message.disposalSelected != null && message.hasOwnProperty("disposalSelected"))
                if (!$util.isString(message.disposalSelected))
                    return "disposalSelected: string expected";
            if (message.orderState != null && message.hasOwnProperty("orderState"))
                if (!$util.isString(message.orderState))
                    return "orderState: string expected";
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                if (!$util.isString(message.dispatchID))
                    return "dispatchID: string expected";
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                if (!$util.isString(message.itemID))
                    return "itemID: string expected";
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                if (!$util.isString(message.partRequestID))
                    return "partRequestID: string expected";
            if (message.replacementBrand != null && message.hasOwnProperty("replacementBrand"))
                if (!$util.isString(message.replacementBrand))
                    return "replacementBrand: string expected";
            if (message.contractNumber != null && message.hasOwnProperty("contractNumber"))
                if (!$util.isString(message.contractNumber))
                    return "contractNumber: string expected";
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel"))
                if (!$util.isString(message.recommendedModel))
                    return "recommendedModel: string expected";
            if (message.customerBoughtModel != null && message.hasOwnProperty("customerBoughtModel"))
                if (!$util.isString(message.customerBoughtModel))
                    return "customerBoughtModel: string expected";
            if (message.replacementItem != null && message.hasOwnProperty("replacementItem"))
                if (!$util.isString(message.replacementItem))
                    return "replacementItem: string expected";
            if (message.orderCreatedDate != null && message.hasOwnProperty("orderCreatedDate"))
                if (!$util.isString(message.orderCreatedDate))
                    return "orderCreatedDate: string expected";
            if (message.recommendationDate != null && message.hasOwnProperty("recommendationDate"))
                if (!$util.isString(message.recommendationDate))
                    return "recommendationDate: string expected";
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                if (!$util.isString(message.phoneNumber))
                    return "phoneNumber: string expected";
            if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                if (!$util.isString(message.transactionAmount))
                    return "transactionAmount: string expected";
            if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                if (!$util.isString(message.adminFee))
                    return "adminFee: string expected";
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                if (!$util.isString(message.disposalFee))
                    return "disposalFee: string expected";
            if (message.transactionID != null && message.hasOwnProperty("transactionID"))
                if (!$util.isString(message.transactionID))
                    return "transactionID: string expected";
            if (message.transactionDate != null && message.hasOwnProperty("transactionDate"))
                if (!$util.isString(message.transactionDate))
                    return "transactionDate: string expected";
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                if (!$util.isString(message.companyCode))
                    return "companyCode: string expected";
            if (message.customerFirstName != null && message.hasOwnProperty("customerFirstName"))
                if (!$util.isString(message.customerFirstName))
                    return "customerFirstName: string expected";
            if (message.customerLastName != null && message.hasOwnProperty("customerLastName"))
                if (!$util.isString(message.customerLastName))
                    return "customerLastName: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                if (!$util.isString(message.billingState))
                    return "billingState: string expected";
            if (message.orderedBy != null && message.hasOwnProperty("orderedBy"))
                if (!$util.isString(message.orderedBy))
                    return "orderedBy: string expected";
            if (message.comments != null && message.hasOwnProperty("comments"))
                if (!$util.isString(message.comments))
                    return "comments: string expected";
            if (message.replacementID != null && message.hasOwnProperty("replacementID"))
                if (!$util.isString(message.replacementID))
                    return "replacementID: string expected";
            if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                if (!$util.isString(message.orderStatus))
                    return "orderStatus: string expected";
            if (message.cilValue != null && message.hasOwnProperty("cilValue"))
                if (!$util.isString(message.cilValue))
                    return "cilValue: string expected";
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                if (!$util.isString(message.basePrice))
                    return "basePrice: string expected";
            if (message.homeOwnerCost != null && message.hasOwnProperty("homeOwnerCost"))
                if (!$util.isString(message.homeOwnerCost))
                    return "homeOwnerCost: string expected";
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                if (!$util.isString(message.disconnectFee))
                    return "disconnectFee: string expected";
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                if (!$util.isString(message.installFee))
                    return "installFee: string expected";
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                if (!$util.isString(message.deliveryFee))
                    return "deliveryFee: string expected";
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                if (!$util.isString(message.moveApplianceFee))
                    return "moveApplianceFee: string expected";
            if (message.restockingFee != null && message.hasOwnProperty("restockingFee"))
                if (!$util.isString(message.restockingFee))
                    return "restockingFee: string expected";
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                if (!$util.isString(message.taxes))
                    return "taxes: string expected";
            if (message.serviceCodes != null && message.hasOwnProperty("serviceCodes"))
                if (!$util.isString(message.serviceCodes))
                    return "serviceCodes: string expected";
            if (message.serviceFees != null && message.hasOwnProperty("serviceFees"))
                if (!$util.isString(message.serviceFees))
                    return "serviceFees: string expected";
            if (message.recordID != null && message.hasOwnProperty("recordID"))
                if (!$util.isString(message.recordID))
                    return "recordID: string expected";
            return null;
        };

        /**
         * Creates a WPApplianceOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.WPApplianceOrder} WPApplianceOrder
         */
        WPApplianceOrder.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.WPApplianceOrder)
                return object;
            let message = new $root.appliancepb.WPApplianceOrder();
            if (object.wpOrderNumber != null)
                message.wpOrderNumber = String(object.wpOrderNumber);
            if (object.isUpgradeOrder != null)
                message.isUpgradeOrder = String(object.isUpgradeOrder);
            if (object.disposalSelected != null)
                message.disposalSelected = String(object.disposalSelected);
            if (object.orderState != null)
                message.orderState = String(object.orderState);
            if (object.dispatchID != null)
                message.dispatchID = String(object.dispatchID);
            if (object.itemID != null)
                message.itemID = String(object.itemID);
            if (object.partRequestID != null)
                message.partRequestID = String(object.partRequestID);
            if (object.replacementBrand != null)
                message.replacementBrand = String(object.replacementBrand);
            if (object.contractNumber != null)
                message.contractNumber = String(object.contractNumber);
            if (object.recommendedModel != null)
                message.recommendedModel = String(object.recommendedModel);
            if (object.customerBoughtModel != null)
                message.customerBoughtModel = String(object.customerBoughtModel);
            if (object.replacementItem != null)
                message.replacementItem = String(object.replacementItem);
            if (object.orderCreatedDate != null)
                message.orderCreatedDate = String(object.orderCreatedDate);
            if (object.recommendationDate != null)
                message.recommendationDate = String(object.recommendationDate);
            if (object.phoneNumber != null)
                message.phoneNumber = String(object.phoneNumber);
            if (object.transactionAmount != null)
                message.transactionAmount = String(object.transactionAmount);
            if (object.adminFee != null)
                message.adminFee = String(object.adminFee);
            if (object.disposalFee != null)
                message.disposalFee = String(object.disposalFee);
            if (object.transactionID != null)
                message.transactionID = String(object.transactionID);
            if (object.transactionDate != null)
                message.transactionDate = String(object.transactionDate);
            if (object.companyCode != null)
                message.companyCode = String(object.companyCode);
            if (object.customerFirstName != null)
                message.customerFirstName = String(object.customerFirstName);
            if (object.customerLastName != null)
                message.customerLastName = String(object.customerLastName);
            if (object.email != null)
                message.email = String(object.email);
            if (object.billingState != null)
                message.billingState = String(object.billingState);
            if (object.orderedBy != null)
                message.orderedBy = String(object.orderedBy);
            if (object.comments != null)
                message.comments = String(object.comments);
            if (object.replacementID != null)
                message.replacementID = String(object.replacementID);
            if (object.orderStatus != null)
                message.orderStatus = String(object.orderStatus);
            if (object.cilValue != null)
                message.cilValue = String(object.cilValue);
            if (object.basePrice != null)
                message.basePrice = String(object.basePrice);
            if (object.homeOwnerCost != null)
                message.homeOwnerCost = String(object.homeOwnerCost);
            if (object.disconnectFee != null)
                message.disconnectFee = String(object.disconnectFee);
            if (object.installFee != null)
                message.installFee = String(object.installFee);
            if (object.deliveryFee != null)
                message.deliveryFee = String(object.deliveryFee);
            if (object.moveApplianceFee != null)
                message.moveApplianceFee = String(object.moveApplianceFee);
            if (object.restockingFee != null)
                message.restockingFee = String(object.restockingFee);
            if (object.taxes != null)
                message.taxes = String(object.taxes);
            if (object.serviceCodes != null)
                message.serviceCodes = String(object.serviceCodes);
            if (object.serviceFees != null)
                message.serviceFees = String(object.serviceFees);
            if (object.recordID != null)
                message.recordID = String(object.recordID);
            return message;
        };

        /**
         * Creates a plain object from a WPApplianceOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.WPApplianceOrder
         * @static
         * @param {appliancepb.WPApplianceOrder} message WPApplianceOrder
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WPApplianceOrder.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.wpOrderNumber = "";
                object.isUpgradeOrder = "";
                object.disposalSelected = "";
                object.orderState = "";
                object.dispatchID = "";
                object.itemID = "";
                object.partRequestID = "";
                object.replacementBrand = "";
                object.contractNumber = "";
                object.recommendedModel = "";
                object.customerBoughtModel = "";
                object.replacementItem = "";
                object.orderCreatedDate = "";
                object.recommendationDate = "";
                object.phoneNumber = "";
                object.transactionAmount = "";
                object.adminFee = "";
                object.disposalFee = "";
                object.transactionID = "";
                object.transactionDate = "";
                object.companyCode = "";
                object.customerFirstName = "";
                object.customerLastName = "";
                object.email = "";
                object.billingState = "";
                object.orderedBy = "";
                object.comments = "";
                object.replacementID = "";
                object.orderStatus = "";
                object.cilValue = "";
                object.basePrice = "";
                object.homeOwnerCost = "";
                object.disconnectFee = "";
                object.installFee = "";
                object.deliveryFee = "";
                object.moveApplianceFee = "";
                object.restockingFee = "";
                object.taxes = "";
                object.serviceCodes = "";
                object.serviceFees = "";
                object.recordID = "";
            }
            if (message.wpOrderNumber != null && message.hasOwnProperty("wpOrderNumber"))
                object.wpOrderNumber = message.wpOrderNumber;
            if (message.isUpgradeOrder != null && message.hasOwnProperty("isUpgradeOrder"))
                object.isUpgradeOrder = message.isUpgradeOrder;
            if (message.disposalSelected != null && message.hasOwnProperty("disposalSelected"))
                object.disposalSelected = message.disposalSelected;
            if (message.orderState != null && message.hasOwnProperty("orderState"))
                object.orderState = message.orderState;
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                object.dispatchID = message.dispatchID;
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                object.itemID = message.itemID;
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                object.partRequestID = message.partRequestID;
            if (message.replacementBrand != null && message.hasOwnProperty("replacementBrand"))
                object.replacementBrand = message.replacementBrand;
            if (message.contractNumber != null && message.hasOwnProperty("contractNumber"))
                object.contractNumber = message.contractNumber;
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel"))
                object.recommendedModel = message.recommendedModel;
            if (message.customerBoughtModel != null && message.hasOwnProperty("customerBoughtModel"))
                object.customerBoughtModel = message.customerBoughtModel;
            if (message.replacementItem != null && message.hasOwnProperty("replacementItem"))
                object.replacementItem = message.replacementItem;
            if (message.orderCreatedDate != null && message.hasOwnProperty("orderCreatedDate"))
                object.orderCreatedDate = message.orderCreatedDate;
            if (message.recommendationDate != null && message.hasOwnProperty("recommendationDate"))
                object.recommendationDate = message.recommendationDate;
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                object.phoneNumber = message.phoneNumber;
            if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                object.transactionAmount = message.transactionAmount;
            if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                object.adminFee = message.adminFee;
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                object.disposalFee = message.disposalFee;
            if (message.transactionID != null && message.hasOwnProperty("transactionID"))
                object.transactionID = message.transactionID;
            if (message.transactionDate != null && message.hasOwnProperty("transactionDate"))
                object.transactionDate = message.transactionDate;
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                object.companyCode = message.companyCode;
            if (message.customerFirstName != null && message.hasOwnProperty("customerFirstName"))
                object.customerFirstName = message.customerFirstName;
            if (message.customerLastName != null && message.hasOwnProperty("customerLastName"))
                object.customerLastName = message.customerLastName;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                object.billingState = message.billingState;
            if (message.orderedBy != null && message.hasOwnProperty("orderedBy"))
                object.orderedBy = message.orderedBy;
            if (message.comments != null && message.hasOwnProperty("comments"))
                object.comments = message.comments;
            if (message.replacementID != null && message.hasOwnProperty("replacementID"))
                object.replacementID = message.replacementID;
            if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                object.orderStatus = message.orderStatus;
            if (message.cilValue != null && message.hasOwnProperty("cilValue"))
                object.cilValue = message.cilValue;
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                object.basePrice = message.basePrice;
            if (message.homeOwnerCost != null && message.hasOwnProperty("homeOwnerCost"))
                object.homeOwnerCost = message.homeOwnerCost;
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                object.disconnectFee = message.disconnectFee;
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                object.installFee = message.installFee;
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                object.deliveryFee = message.deliveryFee;
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                object.moveApplianceFee = message.moveApplianceFee;
            if (message.restockingFee != null && message.hasOwnProperty("restockingFee"))
                object.restockingFee = message.restockingFee;
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                object.taxes = message.taxes;
            if (message.serviceCodes != null && message.hasOwnProperty("serviceCodes"))
                object.serviceCodes = message.serviceCodes;
            if (message.serviceFees != null && message.hasOwnProperty("serviceFees"))
                object.serviceFees = message.serviceFees;
            if (message.recordID != null && message.hasOwnProperty("recordID"))
                object.recordID = message.recordID;
            return object;
        };

        /**
         * Converts this WPApplianceOrder to JSON.
         * @function toJSON
         * @memberof appliancepb.WPApplianceOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WPApplianceOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WPApplianceOrder;
    })();

    appliancepb.GEApplianceOrder = (function() {

        /**
         * Properties of a GEApplianceOrder.
         * @memberof appliancepb
         * @interface IGEApplianceOrder
         * @property {string|null} [geOrderNumber] GEApplianceOrder geOrderNumber
         * @property {string|null} [isUpgradeOrder] GEApplianceOrder isUpgradeOrder
         * @property {string|null} [disposalSelected] GEApplianceOrder disposalSelected
         * @property {string|null} [orderState] GEApplianceOrder orderState
         * @property {string|null} [dispatchID] GEApplianceOrder dispatchID
         * @property {string|null} [itemID] GEApplianceOrder itemID
         * @property {string|null} [partRequestID] GEApplianceOrder partRequestID
         * @property {string|null} [contractNumber] GEApplianceOrder contractNumber
         * @property {string|null} [recommendedModel] GEApplianceOrder recommendedModel
         * @property {string|null} [customerBoughtModel] GEApplianceOrder customerBoughtModel
         * @property {string|null} [replacementItem] GEApplianceOrder replacementItem
         * @property {string|null} [orderCreatedDate] GEApplianceOrder orderCreatedDate
         * @property {string|null} [recommendationDate] GEApplianceOrder recommendationDate
         * @property {string|null} [phoneNumber] GEApplianceOrder phoneNumber
         * @property {string|null} [transactionAmount] GEApplianceOrder transactionAmount
         * @property {string|null} [adminFee] GEApplianceOrder adminFee
         * @property {string|null} [disposalFee] GEApplianceOrder disposalFee
         * @property {string|null} [transactionID] GEApplianceOrder transactionID
         * @property {string|null} [transactionDate] GEApplianceOrder transactionDate
         * @property {string|null} [companyCode] GEApplianceOrder companyCode
         * @property {string|null} [customerFirstName] GEApplianceOrder customerFirstName
         * @property {string|null} [customerLastName] GEApplianceOrder customerLastName
         * @property {string|null} [email] GEApplianceOrder email
         * @property {string|null} [billingState] GEApplianceOrder billingState
         * @property {string|null} [installerName] GEApplianceOrder installerName
         * @property {string|null} [installerAddress1] GEApplianceOrder installerAddress1
         * @property {string|null} [installerCity] GEApplianceOrder installerCity
         * @property {string|null} [installerZip] GEApplianceOrder installerZip
         * @property {string|null} [installerState] GEApplianceOrder installerState
         * @property {string|null} [installerPhone] GEApplianceOrder installerPhone
         * @property {string|null} [orderedBy] GEApplianceOrder orderedBy
         * @property {string|null} [comments] GEApplianceOrder comments
         * @property {string|null} [replacementID] GEApplianceOrder replacementID
         * @property {string|null} [orderStatus] GEApplianceOrder orderStatus
         * @property {string|null} [cilValue] GEApplianceOrder cilValue
         * @property {string|null} [basePrice] GEApplianceOrder basePrice
         * @property {string|null} [homeOwnerCost] GEApplianceOrder homeOwnerCost
         * @property {string|null} [disconnectFee] GEApplianceOrder disconnectFee
         * @property {string|null} [installFee] GEApplianceOrder installFee
         * @property {string|null} [deliveryFee] GEApplianceOrder deliveryFee
         * @property {string|null} [moveApplianceFee] GEApplianceOrder moveApplianceFee
         * @property {string|null} [restockingFee] GEApplianceOrder restockingFee
         * @property {string|null} [taxes] GEApplianceOrder taxes
         * @property {string|null} [serviceCodes] GEApplianceOrder serviceCodes
         * @property {string|null} [serviceFees] GEApplianceOrder serviceFees
         * @property {string|null} [recordID] GEApplianceOrder recordID
         */

        /**
         * Constructs a new GEApplianceOrder.
         * @memberof appliancepb
         * @classdesc Represents a GEApplianceOrder.
         * @implements IGEApplianceOrder
         * @constructor
         * @param {appliancepb.IGEApplianceOrder=} [properties] Properties to set
         */
        function GEApplianceOrder(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GEApplianceOrder geOrderNumber.
         * @member {string} geOrderNumber
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.geOrderNumber = "";

        /**
         * GEApplianceOrder isUpgradeOrder.
         * @member {string} isUpgradeOrder
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.isUpgradeOrder = "";

        /**
         * GEApplianceOrder disposalSelected.
         * @member {string} disposalSelected
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.disposalSelected = "";

        /**
         * GEApplianceOrder orderState.
         * @member {string} orderState
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.orderState = "";

        /**
         * GEApplianceOrder dispatchID.
         * @member {string} dispatchID
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.dispatchID = "";

        /**
         * GEApplianceOrder itemID.
         * @member {string} itemID
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.itemID = "";

        /**
         * GEApplianceOrder partRequestID.
         * @member {string} partRequestID
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.partRequestID = "";

        /**
         * GEApplianceOrder contractNumber.
         * @member {string} contractNumber
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.contractNumber = "";

        /**
         * GEApplianceOrder recommendedModel.
         * @member {string} recommendedModel
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.recommendedModel = "";

        /**
         * GEApplianceOrder customerBoughtModel.
         * @member {string} customerBoughtModel
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.customerBoughtModel = "";

        /**
         * GEApplianceOrder replacementItem.
         * @member {string} replacementItem
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.replacementItem = "";

        /**
         * GEApplianceOrder orderCreatedDate.
         * @member {string} orderCreatedDate
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.orderCreatedDate = "";

        /**
         * GEApplianceOrder recommendationDate.
         * @member {string} recommendationDate
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.recommendationDate = "";

        /**
         * GEApplianceOrder phoneNumber.
         * @member {string} phoneNumber
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.phoneNumber = "";

        /**
         * GEApplianceOrder transactionAmount.
         * @member {string} transactionAmount
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.transactionAmount = "";

        /**
         * GEApplianceOrder adminFee.
         * @member {string} adminFee
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.adminFee = "";

        /**
         * GEApplianceOrder disposalFee.
         * @member {string} disposalFee
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.disposalFee = "";

        /**
         * GEApplianceOrder transactionID.
         * @member {string} transactionID
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.transactionID = "";

        /**
         * GEApplianceOrder transactionDate.
         * @member {string} transactionDate
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.transactionDate = "";

        /**
         * GEApplianceOrder companyCode.
         * @member {string} companyCode
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.companyCode = "";

        /**
         * GEApplianceOrder customerFirstName.
         * @member {string} customerFirstName
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.customerFirstName = "";

        /**
         * GEApplianceOrder customerLastName.
         * @member {string} customerLastName
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.customerLastName = "";

        /**
         * GEApplianceOrder email.
         * @member {string} email
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.email = "";

        /**
         * GEApplianceOrder billingState.
         * @member {string} billingState
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.billingState = "";

        /**
         * GEApplianceOrder installerName.
         * @member {string} installerName
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.installerName = "";

        /**
         * GEApplianceOrder installerAddress1.
         * @member {string} installerAddress1
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.installerAddress1 = "";

        /**
         * GEApplianceOrder installerCity.
         * @member {string} installerCity
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.installerCity = "";

        /**
         * GEApplianceOrder installerZip.
         * @member {string} installerZip
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.installerZip = "";

        /**
         * GEApplianceOrder installerState.
         * @member {string} installerState
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.installerState = "";

        /**
         * GEApplianceOrder installerPhone.
         * @member {string} installerPhone
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.installerPhone = "";

        /**
         * GEApplianceOrder orderedBy.
         * @member {string} orderedBy
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.orderedBy = "";

        /**
         * GEApplianceOrder comments.
         * @member {string} comments
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.comments = "";

        /**
         * GEApplianceOrder replacementID.
         * @member {string} replacementID
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.replacementID = "";

        /**
         * GEApplianceOrder orderStatus.
         * @member {string} orderStatus
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.orderStatus = "";

        /**
         * GEApplianceOrder cilValue.
         * @member {string} cilValue
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.cilValue = "";

        /**
         * GEApplianceOrder basePrice.
         * @member {string} basePrice
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.basePrice = "";

        /**
         * GEApplianceOrder homeOwnerCost.
         * @member {string} homeOwnerCost
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.homeOwnerCost = "";

        /**
         * GEApplianceOrder disconnectFee.
         * @member {string} disconnectFee
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.disconnectFee = "";

        /**
         * GEApplianceOrder installFee.
         * @member {string} installFee
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.installFee = "";

        /**
         * GEApplianceOrder deliveryFee.
         * @member {string} deliveryFee
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.deliveryFee = "";

        /**
         * GEApplianceOrder moveApplianceFee.
         * @member {string} moveApplianceFee
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.moveApplianceFee = "";

        /**
         * GEApplianceOrder restockingFee.
         * @member {string} restockingFee
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.restockingFee = "";

        /**
         * GEApplianceOrder taxes.
         * @member {string} taxes
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.taxes = "";

        /**
         * GEApplianceOrder serviceCodes.
         * @member {string} serviceCodes
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.serviceCodes = "";

        /**
         * GEApplianceOrder serviceFees.
         * @member {string} serviceFees
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.serviceFees = "";

        /**
         * GEApplianceOrder recordID.
         * @member {string} recordID
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         */
        GEApplianceOrder.prototype.recordID = "";

        /**
         * Creates a new GEApplianceOrder instance using the specified properties.
         * @function create
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {appliancepb.IGEApplianceOrder=} [properties] Properties to set
         * @returns {appliancepb.GEApplianceOrder} GEApplianceOrder instance
         */
        GEApplianceOrder.create = function create(properties) {
            return new GEApplianceOrder(properties);
        };

        /**
         * Encodes the specified GEApplianceOrder message. Does not implicitly {@link appliancepb.GEApplianceOrder.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {appliancepb.IGEApplianceOrder} message GEApplianceOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GEApplianceOrder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.geOrderNumber != null && Object.hasOwnProperty.call(message, "geOrderNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.geOrderNumber);
            if (message.isUpgradeOrder != null && Object.hasOwnProperty.call(message, "isUpgradeOrder"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.isUpgradeOrder);
            if (message.disposalSelected != null && Object.hasOwnProperty.call(message, "disposalSelected"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.disposalSelected);
            if (message.orderState != null && Object.hasOwnProperty.call(message, "orderState"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.orderState);
            if (message.dispatchID != null && Object.hasOwnProperty.call(message, "dispatchID"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.dispatchID);
            if (message.itemID != null && Object.hasOwnProperty.call(message, "itemID"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.itemID);
            if (message.partRequestID != null && Object.hasOwnProperty.call(message, "partRequestID"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.partRequestID);
            if (message.contractNumber != null && Object.hasOwnProperty.call(message, "contractNumber"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.contractNumber);
            if (message.recommendedModel != null && Object.hasOwnProperty.call(message, "recommendedModel"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.recommendedModel);
            if (message.customerBoughtModel != null && Object.hasOwnProperty.call(message, "customerBoughtModel"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.customerBoughtModel);
            if (message.replacementItem != null && Object.hasOwnProperty.call(message, "replacementItem"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.replacementItem);
            if (message.orderCreatedDate != null && Object.hasOwnProperty.call(message, "orderCreatedDate"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.orderCreatedDate);
            if (message.recommendationDate != null && Object.hasOwnProperty.call(message, "recommendationDate"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.recommendationDate);
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.phoneNumber);
            if (message.transactionAmount != null && Object.hasOwnProperty.call(message, "transactionAmount"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.transactionAmount);
            if (message.adminFee != null && Object.hasOwnProperty.call(message, "adminFee"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.adminFee);
            if (message.disposalFee != null && Object.hasOwnProperty.call(message, "disposalFee"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.disposalFee);
            if (message.transactionID != null && Object.hasOwnProperty.call(message, "transactionID"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.transactionID);
            if (message.transactionDate != null && Object.hasOwnProperty.call(message, "transactionDate"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.transactionDate);
            if (message.companyCode != null && Object.hasOwnProperty.call(message, "companyCode"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.companyCode);
            if (message.customerFirstName != null && Object.hasOwnProperty.call(message, "customerFirstName"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.customerFirstName);
            if (message.customerLastName != null && Object.hasOwnProperty.call(message, "customerLastName"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.customerLastName);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.email);
            if (message.billingState != null && Object.hasOwnProperty.call(message, "billingState"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.billingState);
            if (message.installerName != null && Object.hasOwnProperty.call(message, "installerName"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.installerName);
            if (message.installerAddress1 != null && Object.hasOwnProperty.call(message, "installerAddress1"))
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.installerAddress1);
            if (message.installerCity != null && Object.hasOwnProperty.call(message, "installerCity"))
                writer.uint32(/* id 27, wireType 2 =*/218).string(message.installerCity);
            if (message.installerZip != null && Object.hasOwnProperty.call(message, "installerZip"))
                writer.uint32(/* id 28, wireType 2 =*/226).string(message.installerZip);
            if (message.installerState != null && Object.hasOwnProperty.call(message, "installerState"))
                writer.uint32(/* id 29, wireType 2 =*/234).string(message.installerState);
            if (message.installerPhone != null && Object.hasOwnProperty.call(message, "installerPhone"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.installerPhone);
            if (message.orderedBy != null && Object.hasOwnProperty.call(message, "orderedBy"))
                writer.uint32(/* id 31, wireType 2 =*/250).string(message.orderedBy);
            if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.comments);
            if (message.replacementID != null && Object.hasOwnProperty.call(message, "replacementID"))
                writer.uint32(/* id 33, wireType 2 =*/266).string(message.replacementID);
            if (message.orderStatus != null && Object.hasOwnProperty.call(message, "orderStatus"))
                writer.uint32(/* id 34, wireType 2 =*/274).string(message.orderStatus);
            if (message.cilValue != null && Object.hasOwnProperty.call(message, "cilValue"))
                writer.uint32(/* id 35, wireType 2 =*/282).string(message.cilValue);
            if (message.basePrice != null && Object.hasOwnProperty.call(message, "basePrice"))
                writer.uint32(/* id 36, wireType 2 =*/290).string(message.basePrice);
            if (message.homeOwnerCost != null && Object.hasOwnProperty.call(message, "homeOwnerCost"))
                writer.uint32(/* id 37, wireType 2 =*/298).string(message.homeOwnerCost);
            if (message.disconnectFee != null && Object.hasOwnProperty.call(message, "disconnectFee"))
                writer.uint32(/* id 38, wireType 2 =*/306).string(message.disconnectFee);
            if (message.installFee != null && Object.hasOwnProperty.call(message, "installFee"))
                writer.uint32(/* id 39, wireType 2 =*/314).string(message.installFee);
            if (message.deliveryFee != null && Object.hasOwnProperty.call(message, "deliveryFee"))
                writer.uint32(/* id 40, wireType 2 =*/322).string(message.deliveryFee);
            if (message.moveApplianceFee != null && Object.hasOwnProperty.call(message, "moveApplianceFee"))
                writer.uint32(/* id 41, wireType 2 =*/330).string(message.moveApplianceFee);
            if (message.restockingFee != null && Object.hasOwnProperty.call(message, "restockingFee"))
                writer.uint32(/* id 42, wireType 2 =*/338).string(message.restockingFee);
            if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                writer.uint32(/* id 43, wireType 2 =*/346).string(message.taxes);
            if (message.serviceCodes != null && Object.hasOwnProperty.call(message, "serviceCodes"))
                writer.uint32(/* id 44, wireType 2 =*/354).string(message.serviceCodes);
            if (message.serviceFees != null && Object.hasOwnProperty.call(message, "serviceFees"))
                writer.uint32(/* id 45, wireType 2 =*/362).string(message.serviceFees);
            if (message.recordID != null && Object.hasOwnProperty.call(message, "recordID"))
                writer.uint32(/* id 46, wireType 2 =*/370).string(message.recordID);
            return writer;
        };

        /**
         * Encodes the specified GEApplianceOrder message, length delimited. Does not implicitly {@link appliancepb.GEApplianceOrder.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {appliancepb.IGEApplianceOrder} message GEApplianceOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GEApplianceOrder.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GEApplianceOrder message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.GEApplianceOrder} GEApplianceOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GEApplianceOrder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.GEApplianceOrder();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.geOrderNumber = reader.string();
                    break;
                case 2:
                    message.isUpgradeOrder = reader.string();
                    break;
                case 3:
                    message.disposalSelected = reader.string();
                    break;
                case 4:
                    message.orderState = reader.string();
                    break;
                case 5:
                    message.dispatchID = reader.string();
                    break;
                case 6:
                    message.itemID = reader.string();
                    break;
                case 7:
                    message.partRequestID = reader.string();
                    break;
                case 8:
                    message.contractNumber = reader.string();
                    break;
                case 9:
                    message.recommendedModel = reader.string();
                    break;
                case 10:
                    message.customerBoughtModel = reader.string();
                    break;
                case 11:
                    message.replacementItem = reader.string();
                    break;
                case 12:
                    message.orderCreatedDate = reader.string();
                    break;
                case 13:
                    message.recommendationDate = reader.string();
                    break;
                case 14:
                    message.phoneNumber = reader.string();
                    break;
                case 15:
                    message.transactionAmount = reader.string();
                    break;
                case 16:
                    message.adminFee = reader.string();
                    break;
                case 17:
                    message.disposalFee = reader.string();
                    break;
                case 18:
                    message.transactionID = reader.string();
                    break;
                case 19:
                    message.transactionDate = reader.string();
                    break;
                case 20:
                    message.companyCode = reader.string();
                    break;
                case 21:
                    message.customerFirstName = reader.string();
                    break;
                case 22:
                    message.customerLastName = reader.string();
                    break;
                case 23:
                    message.email = reader.string();
                    break;
                case 24:
                    message.billingState = reader.string();
                    break;
                case 25:
                    message.installerName = reader.string();
                    break;
                case 26:
                    message.installerAddress1 = reader.string();
                    break;
                case 27:
                    message.installerCity = reader.string();
                    break;
                case 28:
                    message.installerZip = reader.string();
                    break;
                case 29:
                    message.installerState = reader.string();
                    break;
                case 30:
                    message.installerPhone = reader.string();
                    break;
                case 31:
                    message.orderedBy = reader.string();
                    break;
                case 32:
                    message.comments = reader.string();
                    break;
                case 33:
                    message.replacementID = reader.string();
                    break;
                case 34:
                    message.orderStatus = reader.string();
                    break;
                case 35:
                    message.cilValue = reader.string();
                    break;
                case 36:
                    message.basePrice = reader.string();
                    break;
                case 37:
                    message.homeOwnerCost = reader.string();
                    break;
                case 38:
                    message.disconnectFee = reader.string();
                    break;
                case 39:
                    message.installFee = reader.string();
                    break;
                case 40:
                    message.deliveryFee = reader.string();
                    break;
                case 41:
                    message.moveApplianceFee = reader.string();
                    break;
                case 42:
                    message.restockingFee = reader.string();
                    break;
                case 43:
                    message.taxes = reader.string();
                    break;
                case 44:
                    message.serviceCodes = reader.string();
                    break;
                case 45:
                    message.serviceFees = reader.string();
                    break;
                case 46:
                    message.recordID = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GEApplianceOrder message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.GEApplianceOrder} GEApplianceOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GEApplianceOrder.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GEApplianceOrder message.
         * @function verify
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GEApplianceOrder.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.geOrderNumber != null && message.hasOwnProperty("geOrderNumber"))
                if (!$util.isString(message.geOrderNumber))
                    return "geOrderNumber: string expected";
            if (message.isUpgradeOrder != null && message.hasOwnProperty("isUpgradeOrder"))
                if (!$util.isString(message.isUpgradeOrder))
                    return "isUpgradeOrder: string expected";
            if (message.disposalSelected != null && message.hasOwnProperty("disposalSelected"))
                if (!$util.isString(message.disposalSelected))
                    return "disposalSelected: string expected";
            if (message.orderState != null && message.hasOwnProperty("orderState"))
                if (!$util.isString(message.orderState))
                    return "orderState: string expected";
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                if (!$util.isString(message.dispatchID))
                    return "dispatchID: string expected";
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                if (!$util.isString(message.itemID))
                    return "itemID: string expected";
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                if (!$util.isString(message.partRequestID))
                    return "partRequestID: string expected";
            if (message.contractNumber != null && message.hasOwnProperty("contractNumber"))
                if (!$util.isString(message.contractNumber))
                    return "contractNumber: string expected";
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel"))
                if (!$util.isString(message.recommendedModel))
                    return "recommendedModel: string expected";
            if (message.customerBoughtModel != null && message.hasOwnProperty("customerBoughtModel"))
                if (!$util.isString(message.customerBoughtModel))
                    return "customerBoughtModel: string expected";
            if (message.replacementItem != null && message.hasOwnProperty("replacementItem"))
                if (!$util.isString(message.replacementItem))
                    return "replacementItem: string expected";
            if (message.orderCreatedDate != null && message.hasOwnProperty("orderCreatedDate"))
                if (!$util.isString(message.orderCreatedDate))
                    return "orderCreatedDate: string expected";
            if (message.recommendationDate != null && message.hasOwnProperty("recommendationDate"))
                if (!$util.isString(message.recommendationDate))
                    return "recommendationDate: string expected";
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                if (!$util.isString(message.phoneNumber))
                    return "phoneNumber: string expected";
            if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                if (!$util.isString(message.transactionAmount))
                    return "transactionAmount: string expected";
            if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                if (!$util.isString(message.adminFee))
                    return "adminFee: string expected";
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                if (!$util.isString(message.disposalFee))
                    return "disposalFee: string expected";
            if (message.transactionID != null && message.hasOwnProperty("transactionID"))
                if (!$util.isString(message.transactionID))
                    return "transactionID: string expected";
            if (message.transactionDate != null && message.hasOwnProperty("transactionDate"))
                if (!$util.isString(message.transactionDate))
                    return "transactionDate: string expected";
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                if (!$util.isString(message.companyCode))
                    return "companyCode: string expected";
            if (message.customerFirstName != null && message.hasOwnProperty("customerFirstName"))
                if (!$util.isString(message.customerFirstName))
                    return "customerFirstName: string expected";
            if (message.customerLastName != null && message.hasOwnProperty("customerLastName"))
                if (!$util.isString(message.customerLastName))
                    return "customerLastName: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                if (!$util.isString(message.billingState))
                    return "billingState: string expected";
            if (message.installerName != null && message.hasOwnProperty("installerName"))
                if (!$util.isString(message.installerName))
                    return "installerName: string expected";
            if (message.installerAddress1 != null && message.hasOwnProperty("installerAddress1"))
                if (!$util.isString(message.installerAddress1))
                    return "installerAddress1: string expected";
            if (message.installerCity != null && message.hasOwnProperty("installerCity"))
                if (!$util.isString(message.installerCity))
                    return "installerCity: string expected";
            if (message.installerZip != null && message.hasOwnProperty("installerZip"))
                if (!$util.isString(message.installerZip))
                    return "installerZip: string expected";
            if (message.installerState != null && message.hasOwnProperty("installerState"))
                if (!$util.isString(message.installerState))
                    return "installerState: string expected";
            if (message.installerPhone != null && message.hasOwnProperty("installerPhone"))
                if (!$util.isString(message.installerPhone))
                    return "installerPhone: string expected";
            if (message.orderedBy != null && message.hasOwnProperty("orderedBy"))
                if (!$util.isString(message.orderedBy))
                    return "orderedBy: string expected";
            if (message.comments != null && message.hasOwnProperty("comments"))
                if (!$util.isString(message.comments))
                    return "comments: string expected";
            if (message.replacementID != null && message.hasOwnProperty("replacementID"))
                if (!$util.isString(message.replacementID))
                    return "replacementID: string expected";
            if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                if (!$util.isString(message.orderStatus))
                    return "orderStatus: string expected";
            if (message.cilValue != null && message.hasOwnProperty("cilValue"))
                if (!$util.isString(message.cilValue))
                    return "cilValue: string expected";
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                if (!$util.isString(message.basePrice))
                    return "basePrice: string expected";
            if (message.homeOwnerCost != null && message.hasOwnProperty("homeOwnerCost"))
                if (!$util.isString(message.homeOwnerCost))
                    return "homeOwnerCost: string expected";
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                if (!$util.isString(message.disconnectFee))
                    return "disconnectFee: string expected";
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                if (!$util.isString(message.installFee))
                    return "installFee: string expected";
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                if (!$util.isString(message.deliveryFee))
                    return "deliveryFee: string expected";
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                if (!$util.isString(message.moveApplianceFee))
                    return "moveApplianceFee: string expected";
            if (message.restockingFee != null && message.hasOwnProperty("restockingFee"))
                if (!$util.isString(message.restockingFee))
                    return "restockingFee: string expected";
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                if (!$util.isString(message.taxes))
                    return "taxes: string expected";
            if (message.serviceCodes != null && message.hasOwnProperty("serviceCodes"))
                if (!$util.isString(message.serviceCodes))
                    return "serviceCodes: string expected";
            if (message.serviceFees != null && message.hasOwnProperty("serviceFees"))
                if (!$util.isString(message.serviceFees))
                    return "serviceFees: string expected";
            if (message.recordID != null && message.hasOwnProperty("recordID"))
                if (!$util.isString(message.recordID))
                    return "recordID: string expected";
            return null;
        };

        /**
         * Creates a GEApplianceOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.GEApplianceOrder} GEApplianceOrder
         */
        GEApplianceOrder.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.GEApplianceOrder)
                return object;
            let message = new $root.appliancepb.GEApplianceOrder();
            if (object.geOrderNumber != null)
                message.geOrderNumber = String(object.geOrderNumber);
            if (object.isUpgradeOrder != null)
                message.isUpgradeOrder = String(object.isUpgradeOrder);
            if (object.disposalSelected != null)
                message.disposalSelected = String(object.disposalSelected);
            if (object.orderState != null)
                message.orderState = String(object.orderState);
            if (object.dispatchID != null)
                message.dispatchID = String(object.dispatchID);
            if (object.itemID != null)
                message.itemID = String(object.itemID);
            if (object.partRequestID != null)
                message.partRequestID = String(object.partRequestID);
            if (object.contractNumber != null)
                message.contractNumber = String(object.contractNumber);
            if (object.recommendedModel != null)
                message.recommendedModel = String(object.recommendedModel);
            if (object.customerBoughtModel != null)
                message.customerBoughtModel = String(object.customerBoughtModel);
            if (object.replacementItem != null)
                message.replacementItem = String(object.replacementItem);
            if (object.orderCreatedDate != null)
                message.orderCreatedDate = String(object.orderCreatedDate);
            if (object.recommendationDate != null)
                message.recommendationDate = String(object.recommendationDate);
            if (object.phoneNumber != null)
                message.phoneNumber = String(object.phoneNumber);
            if (object.transactionAmount != null)
                message.transactionAmount = String(object.transactionAmount);
            if (object.adminFee != null)
                message.adminFee = String(object.adminFee);
            if (object.disposalFee != null)
                message.disposalFee = String(object.disposalFee);
            if (object.transactionID != null)
                message.transactionID = String(object.transactionID);
            if (object.transactionDate != null)
                message.transactionDate = String(object.transactionDate);
            if (object.companyCode != null)
                message.companyCode = String(object.companyCode);
            if (object.customerFirstName != null)
                message.customerFirstName = String(object.customerFirstName);
            if (object.customerLastName != null)
                message.customerLastName = String(object.customerLastName);
            if (object.email != null)
                message.email = String(object.email);
            if (object.billingState != null)
                message.billingState = String(object.billingState);
            if (object.installerName != null)
                message.installerName = String(object.installerName);
            if (object.installerAddress1 != null)
                message.installerAddress1 = String(object.installerAddress1);
            if (object.installerCity != null)
                message.installerCity = String(object.installerCity);
            if (object.installerZip != null)
                message.installerZip = String(object.installerZip);
            if (object.installerState != null)
                message.installerState = String(object.installerState);
            if (object.installerPhone != null)
                message.installerPhone = String(object.installerPhone);
            if (object.orderedBy != null)
                message.orderedBy = String(object.orderedBy);
            if (object.comments != null)
                message.comments = String(object.comments);
            if (object.replacementID != null)
                message.replacementID = String(object.replacementID);
            if (object.orderStatus != null)
                message.orderStatus = String(object.orderStatus);
            if (object.cilValue != null)
                message.cilValue = String(object.cilValue);
            if (object.basePrice != null)
                message.basePrice = String(object.basePrice);
            if (object.homeOwnerCost != null)
                message.homeOwnerCost = String(object.homeOwnerCost);
            if (object.disconnectFee != null)
                message.disconnectFee = String(object.disconnectFee);
            if (object.installFee != null)
                message.installFee = String(object.installFee);
            if (object.deliveryFee != null)
                message.deliveryFee = String(object.deliveryFee);
            if (object.moveApplianceFee != null)
                message.moveApplianceFee = String(object.moveApplianceFee);
            if (object.restockingFee != null)
                message.restockingFee = String(object.restockingFee);
            if (object.taxes != null)
                message.taxes = String(object.taxes);
            if (object.serviceCodes != null)
                message.serviceCodes = String(object.serviceCodes);
            if (object.serviceFees != null)
                message.serviceFees = String(object.serviceFees);
            if (object.recordID != null)
                message.recordID = String(object.recordID);
            return message;
        };

        /**
         * Creates a plain object from a GEApplianceOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.GEApplianceOrder
         * @static
         * @param {appliancepb.GEApplianceOrder} message GEApplianceOrder
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GEApplianceOrder.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.geOrderNumber = "";
                object.isUpgradeOrder = "";
                object.disposalSelected = "";
                object.orderState = "";
                object.dispatchID = "";
                object.itemID = "";
                object.partRequestID = "";
                object.contractNumber = "";
                object.recommendedModel = "";
                object.customerBoughtModel = "";
                object.replacementItem = "";
                object.orderCreatedDate = "";
                object.recommendationDate = "";
                object.phoneNumber = "";
                object.transactionAmount = "";
                object.adminFee = "";
                object.disposalFee = "";
                object.transactionID = "";
                object.transactionDate = "";
                object.companyCode = "";
                object.customerFirstName = "";
                object.customerLastName = "";
                object.email = "";
                object.billingState = "";
                object.installerName = "";
                object.installerAddress1 = "";
                object.installerCity = "";
                object.installerZip = "";
                object.installerState = "";
                object.installerPhone = "";
                object.orderedBy = "";
                object.comments = "";
                object.replacementID = "";
                object.orderStatus = "";
                object.cilValue = "";
                object.basePrice = "";
                object.homeOwnerCost = "";
                object.disconnectFee = "";
                object.installFee = "";
                object.deliveryFee = "";
                object.moveApplianceFee = "";
                object.restockingFee = "";
                object.taxes = "";
                object.serviceCodes = "";
                object.serviceFees = "";
                object.recordID = "";
            }
            if (message.geOrderNumber != null && message.hasOwnProperty("geOrderNumber"))
                object.geOrderNumber = message.geOrderNumber;
            if (message.isUpgradeOrder != null && message.hasOwnProperty("isUpgradeOrder"))
                object.isUpgradeOrder = message.isUpgradeOrder;
            if (message.disposalSelected != null && message.hasOwnProperty("disposalSelected"))
                object.disposalSelected = message.disposalSelected;
            if (message.orderState != null && message.hasOwnProperty("orderState"))
                object.orderState = message.orderState;
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                object.dispatchID = message.dispatchID;
            if (message.itemID != null && message.hasOwnProperty("itemID"))
                object.itemID = message.itemID;
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                object.partRequestID = message.partRequestID;
            if (message.contractNumber != null && message.hasOwnProperty("contractNumber"))
                object.contractNumber = message.contractNumber;
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel"))
                object.recommendedModel = message.recommendedModel;
            if (message.customerBoughtModel != null && message.hasOwnProperty("customerBoughtModel"))
                object.customerBoughtModel = message.customerBoughtModel;
            if (message.replacementItem != null && message.hasOwnProperty("replacementItem"))
                object.replacementItem = message.replacementItem;
            if (message.orderCreatedDate != null && message.hasOwnProperty("orderCreatedDate"))
                object.orderCreatedDate = message.orderCreatedDate;
            if (message.recommendationDate != null && message.hasOwnProperty("recommendationDate"))
                object.recommendationDate = message.recommendationDate;
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                object.phoneNumber = message.phoneNumber;
            if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                object.transactionAmount = message.transactionAmount;
            if (message.adminFee != null && message.hasOwnProperty("adminFee"))
                object.adminFee = message.adminFee;
            if (message.disposalFee != null && message.hasOwnProperty("disposalFee"))
                object.disposalFee = message.disposalFee;
            if (message.transactionID != null && message.hasOwnProperty("transactionID"))
                object.transactionID = message.transactionID;
            if (message.transactionDate != null && message.hasOwnProperty("transactionDate"))
                object.transactionDate = message.transactionDate;
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                object.companyCode = message.companyCode;
            if (message.customerFirstName != null && message.hasOwnProperty("customerFirstName"))
                object.customerFirstName = message.customerFirstName;
            if (message.customerLastName != null && message.hasOwnProperty("customerLastName"))
                object.customerLastName = message.customerLastName;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                object.billingState = message.billingState;
            if (message.installerName != null && message.hasOwnProperty("installerName"))
                object.installerName = message.installerName;
            if (message.installerAddress1 != null && message.hasOwnProperty("installerAddress1"))
                object.installerAddress1 = message.installerAddress1;
            if (message.installerCity != null && message.hasOwnProperty("installerCity"))
                object.installerCity = message.installerCity;
            if (message.installerZip != null && message.hasOwnProperty("installerZip"))
                object.installerZip = message.installerZip;
            if (message.installerState != null && message.hasOwnProperty("installerState"))
                object.installerState = message.installerState;
            if (message.installerPhone != null && message.hasOwnProperty("installerPhone"))
                object.installerPhone = message.installerPhone;
            if (message.orderedBy != null && message.hasOwnProperty("orderedBy"))
                object.orderedBy = message.orderedBy;
            if (message.comments != null && message.hasOwnProperty("comments"))
                object.comments = message.comments;
            if (message.replacementID != null && message.hasOwnProperty("replacementID"))
                object.replacementID = message.replacementID;
            if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                object.orderStatus = message.orderStatus;
            if (message.cilValue != null && message.hasOwnProperty("cilValue"))
                object.cilValue = message.cilValue;
            if (message.basePrice != null && message.hasOwnProperty("basePrice"))
                object.basePrice = message.basePrice;
            if (message.homeOwnerCost != null && message.hasOwnProperty("homeOwnerCost"))
                object.homeOwnerCost = message.homeOwnerCost;
            if (message.disconnectFee != null && message.hasOwnProperty("disconnectFee"))
                object.disconnectFee = message.disconnectFee;
            if (message.installFee != null && message.hasOwnProperty("installFee"))
                object.installFee = message.installFee;
            if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                object.deliveryFee = message.deliveryFee;
            if (message.moveApplianceFee != null && message.hasOwnProperty("moveApplianceFee"))
                object.moveApplianceFee = message.moveApplianceFee;
            if (message.restockingFee != null && message.hasOwnProperty("restockingFee"))
                object.restockingFee = message.restockingFee;
            if (message.taxes != null && message.hasOwnProperty("taxes"))
                object.taxes = message.taxes;
            if (message.serviceCodes != null && message.hasOwnProperty("serviceCodes"))
                object.serviceCodes = message.serviceCodes;
            if (message.serviceFees != null && message.hasOwnProperty("serviceFees"))
                object.serviceFees = message.serviceFees;
            if (message.recordID != null && message.hasOwnProperty("recordID"))
                object.recordID = message.recordID;
            return object;
        };

        /**
         * Converts this GEApplianceOrder to JSON.
         * @function toJSON
         * @memberof appliancepb.GEApplianceOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GEApplianceOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GEApplianceOrder;
    })();

    appliancepb.OrderAction = (function() {

        /**
         * Properties of an OrderAction.
         * @memberof appliancepb
         * @interface IOrderAction
         * @property {string|null} [action] OrderAction action
         * @property {string|null} [comment] OrderAction comment
         */

        /**
         * Constructs a new OrderAction.
         * @memberof appliancepb
         * @classdesc Represents an OrderAction.
         * @implements IOrderAction
         * @constructor
         * @param {appliancepb.IOrderAction=} [properties] Properties to set
         */
        function OrderAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderAction action.
         * @member {string} action
         * @memberof appliancepb.OrderAction
         * @instance
         */
        OrderAction.prototype.action = "";

        /**
         * OrderAction comment.
         * @member {string} comment
         * @memberof appliancepb.OrderAction
         * @instance
         */
        OrderAction.prototype.comment = "";

        /**
         * Creates a new OrderAction instance using the specified properties.
         * @function create
         * @memberof appliancepb.OrderAction
         * @static
         * @param {appliancepb.IOrderAction=} [properties] Properties to set
         * @returns {appliancepb.OrderAction} OrderAction instance
         */
        OrderAction.create = function create(properties) {
            return new OrderAction(properties);
        };

        /**
         * Encodes the specified OrderAction message. Does not implicitly {@link appliancepb.OrderAction.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.OrderAction
         * @static
         * @param {appliancepb.IOrderAction} message OrderAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.action);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.comment);
            return writer;
        };

        /**
         * Encodes the specified OrderAction message, length delimited. Does not implicitly {@link appliancepb.OrderAction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.OrderAction
         * @static
         * @param {appliancepb.IOrderAction} message OrderAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderAction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OrderAction message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.OrderAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.OrderAction} OrderAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.OrderAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.action = reader.string();
                    break;
                case 2:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OrderAction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.OrderAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.OrderAction} OrderAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderAction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OrderAction message.
         * @function verify
         * @memberof appliancepb.OrderAction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OrderAction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.action != null && message.hasOwnProperty("action"))
                if (!$util.isString(message.action))
                    return "action: string expected";
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (!$util.isString(message.comment))
                    return "comment: string expected";
            return null;
        };

        /**
         * Creates an OrderAction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.OrderAction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.OrderAction} OrderAction
         */
        OrderAction.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.OrderAction)
                return object;
            let message = new $root.appliancepb.OrderAction();
            if (object.action != null)
                message.action = String(object.action);
            if (object.comment != null)
                message.comment = String(object.comment);
            return message;
        };

        /**
         * Creates a plain object from an OrderAction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.OrderAction
         * @static
         * @param {appliancepb.OrderAction} message OrderAction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OrderAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.action = "";
                object.comment = "";
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            return object;
        };

        /**
         * Converts this OrderAction to JSON.
         * @function toJSON
         * @memberof appliancepb.OrderAction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OrderAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrderAction;
    })();

    appliancepb.PatchReplacement = (function() {

        /**
         * Properties of a PatchReplacement.
         * @memberof appliancepb
         * @interface IPatchReplacement
         * @property {string|null} [status] PatchReplacement status
         * @property {Array.<appliancepb.IOrderAction>|null} [orderActions] PatchReplacement orderActions
         */

        /**
         * Constructs a new PatchReplacement.
         * @memberof appliancepb
         * @classdesc Represents a PatchReplacement.
         * @implements IPatchReplacement
         * @constructor
         * @param {appliancepb.IPatchReplacement=} [properties] Properties to set
         */
        function PatchReplacement(properties) {
            this.orderActions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PatchReplacement status.
         * @member {string} status
         * @memberof appliancepb.PatchReplacement
         * @instance
         */
        PatchReplacement.prototype.status = "";

        /**
         * PatchReplacement orderActions.
         * @member {Array.<appliancepb.IOrderAction>} orderActions
         * @memberof appliancepb.PatchReplacement
         * @instance
         */
        PatchReplacement.prototype.orderActions = $util.emptyArray;

        /**
         * Creates a new PatchReplacement instance using the specified properties.
         * @function create
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {appliancepb.IPatchReplacement=} [properties] Properties to set
         * @returns {appliancepb.PatchReplacement} PatchReplacement instance
         */
        PatchReplacement.create = function create(properties) {
            return new PatchReplacement(properties);
        };

        /**
         * Encodes the specified PatchReplacement message. Does not implicitly {@link appliancepb.PatchReplacement.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {appliancepb.IPatchReplacement} message PatchReplacement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchReplacement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.orderActions != null && message.orderActions.length)
                for (let i = 0; i < message.orderActions.length; ++i)
                    $root.appliancepb.OrderAction.encode(message.orderActions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PatchReplacement message, length delimited. Does not implicitly {@link appliancepb.PatchReplacement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {appliancepb.IPatchReplacement} message PatchReplacement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchReplacement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PatchReplacement message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.PatchReplacement} PatchReplacement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchReplacement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.PatchReplacement();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.string();
                    break;
                case 2:
                    if (!(message.orderActions && message.orderActions.length))
                        message.orderActions = [];
                    message.orderActions.push($root.appliancepb.OrderAction.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PatchReplacement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.PatchReplacement} PatchReplacement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchReplacement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PatchReplacement message.
         * @function verify
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PatchReplacement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.orderActions != null && message.hasOwnProperty("orderActions")) {
                if (!Array.isArray(message.orderActions))
                    return "orderActions: array expected";
                for (let i = 0; i < message.orderActions.length; ++i) {
                    let error = $root.appliancepb.OrderAction.verify(message.orderActions[i]);
                    if (error)
                        return "orderActions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PatchReplacement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.PatchReplacement} PatchReplacement
         */
        PatchReplacement.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.PatchReplacement)
                return object;
            let message = new $root.appliancepb.PatchReplacement();
            if (object.status != null)
                message.status = String(object.status);
            if (object.orderActions) {
                if (!Array.isArray(object.orderActions))
                    throw TypeError(".appliancepb.PatchReplacement.orderActions: array expected");
                message.orderActions = [];
                for (let i = 0; i < object.orderActions.length; ++i) {
                    if (typeof object.orderActions[i] !== "object")
                        throw TypeError(".appliancepb.PatchReplacement.orderActions: object expected");
                    message.orderActions[i] = $root.appliancepb.OrderAction.fromObject(object.orderActions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PatchReplacement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.PatchReplacement
         * @static
         * @param {appliancepb.PatchReplacement} message PatchReplacement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PatchReplacement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.orderActions = [];
            if (options.defaults)
                object.status = "";
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.orderActions && message.orderActions.length) {
                object.orderActions = [];
                for (let j = 0; j < message.orderActions.length; ++j)
                    object.orderActions[j] = $root.appliancepb.OrderAction.toObject(message.orderActions[j], options);
            }
            return object;
        };

        /**
         * Converts this PatchReplacement to JSON.
         * @function toJSON
         * @memberof appliancepb.PatchReplacement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PatchReplacement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PatchReplacement;
    })();

    appliancepb.Categories = (function() {

        /**
         * Properties of a Categories.
         * @memberof appliancepb
         * @interface ICategories
         * @property {Array.<string>|null} [categories] Categories categories
         * @property {Array.<appliancepb.IPair>|null} [mappedItemIDs] Categories mappedItemIDs
         * @property {Array.<appliancepb.IKeyValues>|null} [subcategoriesByCategory] Categories subcategoriesByCategory
         */

        /**
         * Constructs a new Categories.
         * @memberof appliancepb
         * @classdesc Represents a Categories.
         * @implements ICategories
         * @constructor
         * @param {appliancepb.ICategories=} [properties] Properties to set
         */
        function Categories(properties) {
            this.categories = [];
            this.mappedItemIDs = [];
            this.subcategoriesByCategory = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Categories categories.
         * @member {Array.<string>} categories
         * @memberof appliancepb.Categories
         * @instance
         */
        Categories.prototype.categories = $util.emptyArray;

        /**
         * Categories mappedItemIDs.
         * @member {Array.<appliancepb.IPair>} mappedItemIDs
         * @memberof appliancepb.Categories
         * @instance
         */
        Categories.prototype.mappedItemIDs = $util.emptyArray;

        /**
         * Categories subcategoriesByCategory.
         * @member {Array.<appliancepb.IKeyValues>} subcategoriesByCategory
         * @memberof appliancepb.Categories
         * @instance
         */
        Categories.prototype.subcategoriesByCategory = $util.emptyArray;

        /**
         * Creates a new Categories instance using the specified properties.
         * @function create
         * @memberof appliancepb.Categories
         * @static
         * @param {appliancepb.ICategories=} [properties] Properties to set
         * @returns {appliancepb.Categories} Categories instance
         */
        Categories.create = function create(properties) {
            return new Categories(properties);
        };

        /**
         * Encodes the specified Categories message. Does not implicitly {@link appliancepb.Categories.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Categories
         * @static
         * @param {appliancepb.ICategories} message Categories message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Categories.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.categories != null && message.categories.length)
                for (let i = 0; i < message.categories.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.categories[i]);
            if (message.mappedItemIDs != null && message.mappedItemIDs.length)
                for (let i = 0; i < message.mappedItemIDs.length; ++i)
                    $root.appliancepb.Pair.encode(message.mappedItemIDs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.subcategoriesByCategory != null && message.subcategoriesByCategory.length)
                for (let i = 0; i < message.subcategoriesByCategory.length; ++i)
                    $root.appliancepb.KeyValues.encode(message.subcategoriesByCategory[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Categories message, length delimited. Does not implicitly {@link appliancepb.Categories.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Categories
         * @static
         * @param {appliancepb.ICategories} message Categories message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Categories.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Categories message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Categories
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Categories} Categories
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Categories.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Categories();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.categories && message.categories.length))
                        message.categories = [];
                    message.categories.push(reader.string());
                    break;
                case 2:
                    if (!(message.mappedItemIDs && message.mappedItemIDs.length))
                        message.mappedItemIDs = [];
                    message.mappedItemIDs.push($root.appliancepb.Pair.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (!(message.subcategoriesByCategory && message.subcategoriesByCategory.length))
                        message.subcategoriesByCategory = [];
                    message.subcategoriesByCategory.push($root.appliancepb.KeyValues.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Categories message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Categories
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Categories} Categories
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Categories.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Categories message.
         * @function verify
         * @memberof appliancepb.Categories
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Categories.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.categories != null && message.hasOwnProperty("categories")) {
                if (!Array.isArray(message.categories))
                    return "categories: array expected";
                for (let i = 0; i < message.categories.length; ++i)
                    if (!$util.isString(message.categories[i]))
                        return "categories: string[] expected";
            }
            if (message.mappedItemIDs != null && message.hasOwnProperty("mappedItemIDs")) {
                if (!Array.isArray(message.mappedItemIDs))
                    return "mappedItemIDs: array expected";
                for (let i = 0; i < message.mappedItemIDs.length; ++i) {
                    let error = $root.appliancepb.Pair.verify(message.mappedItemIDs[i]);
                    if (error)
                        return "mappedItemIDs." + error;
                }
            }
            if (message.subcategoriesByCategory != null && message.hasOwnProperty("subcategoriesByCategory")) {
                if (!Array.isArray(message.subcategoriesByCategory))
                    return "subcategoriesByCategory: array expected";
                for (let i = 0; i < message.subcategoriesByCategory.length; ++i) {
                    let error = $root.appliancepb.KeyValues.verify(message.subcategoriesByCategory[i]);
                    if (error)
                        return "subcategoriesByCategory." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Categories message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Categories
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Categories} Categories
         */
        Categories.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Categories)
                return object;
            let message = new $root.appliancepb.Categories();
            if (object.categories) {
                if (!Array.isArray(object.categories))
                    throw TypeError(".appliancepb.Categories.categories: array expected");
                message.categories = [];
                for (let i = 0; i < object.categories.length; ++i)
                    message.categories[i] = String(object.categories[i]);
            }
            if (object.mappedItemIDs) {
                if (!Array.isArray(object.mappedItemIDs))
                    throw TypeError(".appliancepb.Categories.mappedItemIDs: array expected");
                message.mappedItemIDs = [];
                for (let i = 0; i < object.mappedItemIDs.length; ++i) {
                    if (typeof object.mappedItemIDs[i] !== "object")
                        throw TypeError(".appliancepb.Categories.mappedItemIDs: object expected");
                    message.mappedItemIDs[i] = $root.appliancepb.Pair.fromObject(object.mappedItemIDs[i]);
                }
            }
            if (object.subcategoriesByCategory) {
                if (!Array.isArray(object.subcategoriesByCategory))
                    throw TypeError(".appliancepb.Categories.subcategoriesByCategory: array expected");
                message.subcategoriesByCategory = [];
                for (let i = 0; i < object.subcategoriesByCategory.length; ++i) {
                    if (typeof object.subcategoriesByCategory[i] !== "object")
                        throw TypeError(".appliancepb.Categories.subcategoriesByCategory: object expected");
                    message.subcategoriesByCategory[i] = $root.appliancepb.KeyValues.fromObject(object.subcategoriesByCategory[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Categories message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Categories
         * @static
         * @param {appliancepb.Categories} message Categories
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Categories.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.categories = [];
                object.mappedItemIDs = [];
                object.subcategoriesByCategory = [];
            }
            if (message.categories && message.categories.length) {
                object.categories = [];
                for (let j = 0; j < message.categories.length; ++j)
                    object.categories[j] = message.categories[j];
            }
            if (message.mappedItemIDs && message.mappedItemIDs.length) {
                object.mappedItemIDs = [];
                for (let j = 0; j < message.mappedItemIDs.length; ++j)
                    object.mappedItemIDs[j] = $root.appliancepb.Pair.toObject(message.mappedItemIDs[j], options);
            }
            if (message.subcategoriesByCategory && message.subcategoriesByCategory.length) {
                object.subcategoriesByCategory = [];
                for (let j = 0; j < message.subcategoriesByCategory.length; ++j)
                    object.subcategoriesByCategory[j] = $root.appliancepb.KeyValues.toObject(message.subcategoriesByCategory[j], options);
            }
            return object;
        };

        /**
         * Converts this Categories to JSON.
         * @function toJSON
         * @memberof appliancepb.Categories
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Categories.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Categories;
    })();

    appliancepb.Vendors = (function() {

        /**
         * Properties of a Vendors.
         * @memberof appliancepb
         * @interface IVendors
         * @property {Array.<appliancepb.IPair>|null} [vendors] Vendors vendors
         */

        /**
         * Constructs a new Vendors.
         * @memberof appliancepb
         * @classdesc Represents a Vendors.
         * @implements IVendors
         * @constructor
         * @param {appliancepb.IVendors=} [properties] Properties to set
         */
        function Vendors(properties) {
            this.vendors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Vendors vendors.
         * @member {Array.<appliancepb.IPair>} vendors
         * @memberof appliancepb.Vendors
         * @instance
         */
        Vendors.prototype.vendors = $util.emptyArray;

        /**
         * Creates a new Vendors instance using the specified properties.
         * @function create
         * @memberof appliancepb.Vendors
         * @static
         * @param {appliancepb.IVendors=} [properties] Properties to set
         * @returns {appliancepb.Vendors} Vendors instance
         */
        Vendors.create = function create(properties) {
            return new Vendors(properties);
        };

        /**
         * Encodes the specified Vendors message. Does not implicitly {@link appliancepb.Vendors.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Vendors
         * @static
         * @param {appliancepb.IVendors} message Vendors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Vendors.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.vendors != null && message.vendors.length)
                for (let i = 0; i < message.vendors.length; ++i)
                    $root.appliancepb.Pair.encode(message.vendors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Vendors message, length delimited. Does not implicitly {@link appliancepb.Vendors.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Vendors
         * @static
         * @param {appliancepb.IVendors} message Vendors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Vendors.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Vendors message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Vendors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Vendors} Vendors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Vendors.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Vendors();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.vendors && message.vendors.length))
                        message.vendors = [];
                    message.vendors.push($root.appliancepb.Pair.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Vendors message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Vendors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Vendors} Vendors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Vendors.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Vendors message.
         * @function verify
         * @memberof appliancepb.Vendors
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Vendors.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.vendors != null && message.hasOwnProperty("vendors")) {
                if (!Array.isArray(message.vendors))
                    return "vendors: array expected";
                for (let i = 0; i < message.vendors.length; ++i) {
                    let error = $root.appliancepb.Pair.verify(message.vendors[i]);
                    if (error)
                        return "vendors." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Vendors message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Vendors
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Vendors} Vendors
         */
        Vendors.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Vendors)
                return object;
            let message = new $root.appliancepb.Vendors();
            if (object.vendors) {
                if (!Array.isArray(object.vendors))
                    throw TypeError(".appliancepb.Vendors.vendors: array expected");
                message.vendors = [];
                for (let i = 0; i < object.vendors.length; ++i) {
                    if (typeof object.vendors[i] !== "object")
                        throw TypeError(".appliancepb.Vendors.vendors: object expected");
                    message.vendors[i] = $root.appliancepb.Pair.fromObject(object.vendors[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Vendors message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Vendors
         * @static
         * @param {appliancepb.Vendors} message Vendors
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Vendors.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.vendors = [];
            if (message.vendors && message.vendors.length) {
                object.vendors = [];
                for (let j = 0; j < message.vendors.length; ++j)
                    object.vendors[j] = $root.appliancepb.Pair.toObject(message.vendors[j], options);
            }
            return object;
        };

        /**
         * Converts this Vendors to JSON.
         * @function toJSON
         * @memberof appliancepb.Vendors
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Vendors.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Vendors;
    })();

    appliancepb.OrderStatus = (function() {

        /**
         * Properties of an OrderStatus.
         * @memberof appliancepb
         * @interface IOrderStatus
         * @property {string|null} [orderID] OrderStatus orderID
         * @property {string|null} [status] OrderStatus status
         * @property {string|null} [vendorName] OrderStatus vendorName
         * @property {google.protobuf.ITimestamp|null} [orderDate] OrderStatus orderDate
         * @property {appliancepb.OrderStatus.Progress|null} [progress] OrderStatus progress
         * @property {google.protobuf.ITimestamp|null} [deliveryDate] OrderStatus deliveryDate
         * @property {appliancepb.ICustomer|null} [customer] OrderStatus customer
         * @property {appliancepb.IProduct|null} [product] OrderStatus product
         * @property {string|null} [dispatchID] OrderStatus dispatchID
         * @property {string|null} [partRequestID] OrderStatus partRequestID
         */

        /**
         * Constructs a new OrderStatus.
         * @memberof appliancepb
         * @classdesc Represents an OrderStatus.
         * @implements IOrderStatus
         * @constructor
         * @param {appliancepb.IOrderStatus=} [properties] Properties to set
         */
        function OrderStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderStatus orderID.
         * @member {string} orderID
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.orderID = "";

        /**
         * OrderStatus status.
         * @member {string} status
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.status = "";

        /**
         * OrderStatus vendorName.
         * @member {string} vendorName
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.vendorName = "";

        /**
         * OrderStatus orderDate.
         * @member {google.protobuf.ITimestamp|null|undefined} orderDate
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.orderDate = null;

        /**
         * OrderStatus progress.
         * @member {appliancepb.OrderStatus.Progress} progress
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.progress = 0;

        /**
         * OrderStatus deliveryDate.
         * @member {google.protobuf.ITimestamp|null|undefined} deliveryDate
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.deliveryDate = null;

        /**
         * OrderStatus customer.
         * @member {appliancepb.ICustomer|null|undefined} customer
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.customer = null;

        /**
         * OrderStatus product.
         * @member {appliancepb.IProduct|null|undefined} product
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.product = null;

        /**
         * OrderStatus dispatchID.
         * @member {string} dispatchID
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.dispatchID = "";

        /**
         * OrderStatus partRequestID.
         * @member {string} partRequestID
         * @memberof appliancepb.OrderStatus
         * @instance
         */
        OrderStatus.prototype.partRequestID = "";

        /**
         * Creates a new OrderStatus instance using the specified properties.
         * @function create
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {appliancepb.IOrderStatus=} [properties] Properties to set
         * @returns {appliancepb.OrderStatus} OrderStatus instance
         */
        OrderStatus.create = function create(properties) {
            return new OrderStatus(properties);
        };

        /**
         * Encodes the specified OrderStatus message. Does not implicitly {@link appliancepb.OrderStatus.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {appliancepb.IOrderStatus} message OrderStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderID != null && Object.hasOwnProperty.call(message, "orderID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderID);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.vendorName != null && Object.hasOwnProperty.call(message, "vendorName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.vendorName);
            if (message.orderDate != null && Object.hasOwnProperty.call(message, "orderDate"))
                $root.google.protobuf.Timestamp.encode(message.orderDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.progress);
            if (message.deliveryDate != null && Object.hasOwnProperty.call(message, "deliveryDate"))
                $root.google.protobuf.Timestamp.encode(message.deliveryDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.appliancepb.Customer.encode(message.customer, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                $root.appliancepb.Product.encode(message.product, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.dispatchID != null && Object.hasOwnProperty.call(message, "dispatchID"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.dispatchID);
            if (message.partRequestID != null && Object.hasOwnProperty.call(message, "partRequestID"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.partRequestID);
            return writer;
        };

        /**
         * Encodes the specified OrderStatus message, length delimited. Does not implicitly {@link appliancepb.OrderStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {appliancepb.IOrderStatus} message OrderStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OrderStatus message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.OrderStatus} OrderStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.OrderStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderID = reader.string();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                case 3:
                    message.vendorName = reader.string();
                    break;
                case 4:
                    message.orderDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.progress = reader.int32();
                    break;
                case 6:
                    message.deliveryDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.customer = $root.appliancepb.Customer.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.product = $root.appliancepb.Product.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.dispatchID = reader.string();
                    break;
                case 10:
                    message.partRequestID = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OrderStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.OrderStatus} OrderStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OrderStatus message.
         * @function verify
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OrderStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.orderID != null && message.hasOwnProperty("orderID"))
                if (!$util.isString(message.orderID))
                    return "orderID: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.vendorName != null && message.hasOwnProperty("vendorName"))
                if (!$util.isString(message.vendorName))
                    return "vendorName: string expected";
            if (message.orderDate != null && message.hasOwnProperty("orderDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.orderDate);
                if (error)
                    return "orderDate." + error;
            }
            if (message.progress != null && message.hasOwnProperty("progress"))
                switch (message.progress) {
                default:
                    return "progress: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.deliveryDate);
                if (error)
                    return "deliveryDate." + error;
            }
            if (message.customer != null && message.hasOwnProperty("customer")) {
                let error = $root.appliancepb.Customer.verify(message.customer);
                if (error)
                    return "customer." + error;
            }
            if (message.product != null && message.hasOwnProperty("product")) {
                let error = $root.appliancepb.Product.verify(message.product);
                if (error)
                    return "product." + error;
            }
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                if (!$util.isString(message.dispatchID))
                    return "dispatchID: string expected";
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                if (!$util.isString(message.partRequestID))
                    return "partRequestID: string expected";
            return null;
        };

        /**
         * Creates an OrderStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.OrderStatus} OrderStatus
         */
        OrderStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.OrderStatus)
                return object;
            let message = new $root.appliancepb.OrderStatus();
            if (object.orderID != null)
                message.orderID = String(object.orderID);
            if (object.status != null)
                message.status = String(object.status);
            if (object.vendorName != null)
                message.vendorName = String(object.vendorName);
            if (object.orderDate != null) {
                if (typeof object.orderDate !== "object")
                    throw TypeError(".appliancepb.OrderStatus.orderDate: object expected");
                message.orderDate = $root.google.protobuf.Timestamp.fromObject(object.orderDate);
            }
            switch (object.progress) {
            case "UNKNOWN":
            case 0:
                message.progress = 0;
                break;
            case "ORDERED":
            case 1:
                message.progress = 1;
                break;
            case "SHIPPED":
            case 2:
                message.progress = 2;
                break;
            case "DELIVERED":
            case 3:
                message.progress = 3;
                break;
            }
            if (object.deliveryDate != null) {
                if (typeof object.deliveryDate !== "object")
                    throw TypeError(".appliancepb.OrderStatus.deliveryDate: object expected");
                message.deliveryDate = $root.google.protobuf.Timestamp.fromObject(object.deliveryDate);
            }
            if (object.customer != null) {
                if (typeof object.customer !== "object")
                    throw TypeError(".appliancepb.OrderStatus.customer: object expected");
                message.customer = $root.appliancepb.Customer.fromObject(object.customer);
            }
            if (object.product != null) {
                if (typeof object.product !== "object")
                    throw TypeError(".appliancepb.OrderStatus.product: object expected");
                message.product = $root.appliancepb.Product.fromObject(object.product);
            }
            if (object.dispatchID != null)
                message.dispatchID = String(object.dispatchID);
            if (object.partRequestID != null)
                message.partRequestID = String(object.partRequestID);
            return message;
        };

        /**
         * Creates a plain object from an OrderStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.OrderStatus
         * @static
         * @param {appliancepb.OrderStatus} message OrderStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OrderStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.orderID = "";
                object.status = "";
                object.vendorName = "";
                object.orderDate = null;
                object.progress = options.enums === String ? "UNKNOWN" : 0;
                object.deliveryDate = null;
                object.customer = null;
                object.product = null;
                object.dispatchID = "";
                object.partRequestID = "";
            }
            if (message.orderID != null && message.hasOwnProperty("orderID"))
                object.orderID = message.orderID;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.vendorName != null && message.hasOwnProperty("vendorName"))
                object.vendorName = message.vendorName;
            if (message.orderDate != null && message.hasOwnProperty("orderDate"))
                object.orderDate = $root.google.protobuf.Timestamp.toObject(message.orderDate, options);
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.enums === String ? $root.appliancepb.OrderStatus.Progress[message.progress] : message.progress;
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate"))
                object.deliveryDate = $root.google.protobuf.Timestamp.toObject(message.deliveryDate, options);
            if (message.customer != null && message.hasOwnProperty("customer"))
                object.customer = $root.appliancepb.Customer.toObject(message.customer, options);
            if (message.product != null && message.hasOwnProperty("product"))
                object.product = $root.appliancepb.Product.toObject(message.product, options);
            if (message.dispatchID != null && message.hasOwnProperty("dispatchID"))
                object.dispatchID = message.dispatchID;
            if (message.partRequestID != null && message.hasOwnProperty("partRequestID"))
                object.partRequestID = message.partRequestID;
            return object;
        };

        /**
         * Converts this OrderStatus to JSON.
         * @function toJSON
         * @memberof appliancepb.OrderStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OrderStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Progress enum.
         * @name appliancepb.OrderStatus.Progress
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} ORDERED=1 ORDERED value
         * @property {number} SHIPPED=2 SHIPPED value
         * @property {number} DELIVERED=3 DELIVERED value
         */
        OrderStatus.Progress = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "ORDERED"] = 1;
            values[valuesById[2] = "SHIPPED"] = 2;
            values[valuesById[3] = "DELIVERED"] = 3;
            return values;
        })();

        return OrderStatus;
    })();

    appliancepb.Notification = (function() {

        /**
         * Properties of a Notification.
         * @memberof appliancepb
         * @interface INotification
         * @property {boolean|null} [customerShopLink] Notification customerShopLink
         * @property {boolean|null} [customerPaymentConfirmation] Notification customerPaymentConfirmation
         * @property {boolean|null} [customerOrderConfirmation] Notification customerOrderConfirmation
         * @property {boolean|null} [customerInstallerConfirmation] Notification customerInstallerConfirmation
         */

        /**
         * Constructs a new Notification.
         * @memberof appliancepb
         * @classdesc Represents a Notification.
         * @implements INotification
         * @constructor
         * @param {appliancepb.INotification=} [properties] Properties to set
         */
        function Notification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Notification customerShopLink.
         * @member {boolean} customerShopLink
         * @memberof appliancepb.Notification
         * @instance
         */
        Notification.prototype.customerShopLink = false;

        /**
         * Notification customerPaymentConfirmation.
         * @member {boolean} customerPaymentConfirmation
         * @memberof appliancepb.Notification
         * @instance
         */
        Notification.prototype.customerPaymentConfirmation = false;

        /**
         * Notification customerOrderConfirmation.
         * @member {boolean} customerOrderConfirmation
         * @memberof appliancepb.Notification
         * @instance
         */
        Notification.prototype.customerOrderConfirmation = false;

        /**
         * Notification customerInstallerConfirmation.
         * @member {boolean} customerInstallerConfirmation
         * @memberof appliancepb.Notification
         * @instance
         */
        Notification.prototype.customerInstallerConfirmation = false;

        /**
         * Creates a new Notification instance using the specified properties.
         * @function create
         * @memberof appliancepb.Notification
         * @static
         * @param {appliancepb.INotification=} [properties] Properties to set
         * @returns {appliancepb.Notification} Notification instance
         */
        Notification.create = function create(properties) {
            return new Notification(properties);
        };

        /**
         * Encodes the specified Notification message. Does not implicitly {@link appliancepb.Notification.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Notification
         * @static
         * @param {appliancepb.INotification} message Notification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Notification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customerShopLink != null && Object.hasOwnProperty.call(message, "customerShopLink"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.customerShopLink);
            if (message.customerPaymentConfirmation != null && Object.hasOwnProperty.call(message, "customerPaymentConfirmation"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.customerPaymentConfirmation);
            if (message.customerOrderConfirmation != null && Object.hasOwnProperty.call(message, "customerOrderConfirmation"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.customerOrderConfirmation);
            if (message.customerInstallerConfirmation != null && Object.hasOwnProperty.call(message, "customerInstallerConfirmation"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.customerInstallerConfirmation);
            return writer;
        };

        /**
         * Encodes the specified Notification message, length delimited. Does not implicitly {@link appliancepb.Notification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Notification
         * @static
         * @param {appliancepb.INotification} message Notification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Notification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Notification message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Notification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Notification} Notification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Notification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Notification();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.customerShopLink = reader.bool();
                    break;
                case 2:
                    message.customerPaymentConfirmation = reader.bool();
                    break;
                case 3:
                    message.customerOrderConfirmation = reader.bool();
                    break;
                case 4:
                    message.customerInstallerConfirmation = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Notification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Notification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Notification} Notification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Notification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Notification message.
         * @function verify
         * @memberof appliancepb.Notification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Notification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.customerShopLink != null && message.hasOwnProperty("customerShopLink"))
                if (typeof message.customerShopLink !== "boolean")
                    return "customerShopLink: boolean expected";
            if (message.customerPaymentConfirmation != null && message.hasOwnProperty("customerPaymentConfirmation"))
                if (typeof message.customerPaymentConfirmation !== "boolean")
                    return "customerPaymentConfirmation: boolean expected";
            if (message.customerOrderConfirmation != null && message.hasOwnProperty("customerOrderConfirmation"))
                if (typeof message.customerOrderConfirmation !== "boolean")
                    return "customerOrderConfirmation: boolean expected";
            if (message.customerInstallerConfirmation != null && message.hasOwnProperty("customerInstallerConfirmation"))
                if (typeof message.customerInstallerConfirmation !== "boolean")
                    return "customerInstallerConfirmation: boolean expected";
            return null;
        };

        /**
         * Creates a Notification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Notification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Notification} Notification
         */
        Notification.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Notification)
                return object;
            let message = new $root.appliancepb.Notification();
            if (object.customerShopLink != null)
                message.customerShopLink = Boolean(object.customerShopLink);
            if (object.customerPaymentConfirmation != null)
                message.customerPaymentConfirmation = Boolean(object.customerPaymentConfirmation);
            if (object.customerOrderConfirmation != null)
                message.customerOrderConfirmation = Boolean(object.customerOrderConfirmation);
            if (object.customerInstallerConfirmation != null)
                message.customerInstallerConfirmation = Boolean(object.customerInstallerConfirmation);
            return message;
        };

        /**
         * Creates a plain object from a Notification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Notification
         * @static
         * @param {appliancepb.Notification} message Notification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Notification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.customerShopLink = false;
                object.customerPaymentConfirmation = false;
                object.customerOrderConfirmation = false;
                object.customerInstallerConfirmation = false;
            }
            if (message.customerShopLink != null && message.hasOwnProperty("customerShopLink"))
                object.customerShopLink = message.customerShopLink;
            if (message.customerPaymentConfirmation != null && message.hasOwnProperty("customerPaymentConfirmation"))
                object.customerPaymentConfirmation = message.customerPaymentConfirmation;
            if (message.customerOrderConfirmation != null && message.hasOwnProperty("customerOrderConfirmation"))
                object.customerOrderConfirmation = message.customerOrderConfirmation;
            if (message.customerInstallerConfirmation != null && message.hasOwnProperty("customerInstallerConfirmation"))
                object.customerInstallerConfirmation = message.customerInstallerConfirmation;
            return object;
        };

        /**
         * Converts this Notification to JSON.
         * @function toJSON
         * @memberof appliancepb.Notification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Notification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Notification;
    })();

    appliancepb.PostPaymentRequest = (function() {

        /**
         * Properties of a PostPaymentRequest.
         * @memberof appliancepb
         * @interface IPostPaymentRequest
         * @property {appliancepb.IPaymentDetails|null} [paymentDetails] PostPaymentRequest paymentDetails
         * @property {string|null} [replacementId] PostPaymentRequest replacementId
         * @property {appliancepb.INotification|null} [notification] PostPaymentRequest notification
         */

        /**
         * Constructs a new PostPaymentRequest.
         * @memberof appliancepb
         * @classdesc Represents a PostPaymentRequest.
         * @implements IPostPaymentRequest
         * @constructor
         * @param {appliancepb.IPostPaymentRequest=} [properties] Properties to set
         */
        function PostPaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PostPaymentRequest paymentDetails.
         * @member {appliancepb.IPaymentDetails|null|undefined} paymentDetails
         * @memberof appliancepb.PostPaymentRequest
         * @instance
         */
        PostPaymentRequest.prototype.paymentDetails = null;

        /**
         * PostPaymentRequest replacementId.
         * @member {string} replacementId
         * @memberof appliancepb.PostPaymentRequest
         * @instance
         */
        PostPaymentRequest.prototype.replacementId = "";

        /**
         * PostPaymentRequest notification.
         * @member {appliancepb.INotification|null|undefined} notification
         * @memberof appliancepb.PostPaymentRequest
         * @instance
         */
        PostPaymentRequest.prototype.notification = null;

        /**
         * Creates a new PostPaymentRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {appliancepb.IPostPaymentRequest=} [properties] Properties to set
         * @returns {appliancepb.PostPaymentRequest} PostPaymentRequest instance
         */
        PostPaymentRequest.create = function create(properties) {
            return new PostPaymentRequest(properties);
        };

        /**
         * Encodes the specified PostPaymentRequest message. Does not implicitly {@link appliancepb.PostPaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {appliancepb.IPostPaymentRequest} message PostPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostPaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentDetails != null && Object.hasOwnProperty.call(message, "paymentDetails"))
                $root.appliancepb.PaymentDetails.encode(message.paymentDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.replacementId != null && Object.hasOwnProperty.call(message, "replacementId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.replacementId);
            if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                $root.appliancepb.Notification.encode(message.notification, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PostPaymentRequest message, length delimited. Does not implicitly {@link appliancepb.PostPaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {appliancepb.IPostPaymentRequest} message PostPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostPaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PostPaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.PostPaymentRequest} PostPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostPaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.PostPaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.paymentDetails = $root.appliancepb.PaymentDetails.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.replacementId = reader.string();
                    break;
                case 3:
                    message.notification = $root.appliancepb.Notification.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PostPaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.PostPaymentRequest} PostPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostPaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PostPaymentRequest message.
         * @function verify
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PostPaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails")) {
                let error = $root.appliancepb.PaymentDetails.verify(message.paymentDetails);
                if (error)
                    return "paymentDetails." + error;
            }
            if (message.replacementId != null && message.hasOwnProperty("replacementId"))
                if (!$util.isString(message.replacementId))
                    return "replacementId: string expected";
            if (message.notification != null && message.hasOwnProperty("notification")) {
                let error = $root.appliancepb.Notification.verify(message.notification);
                if (error)
                    return "notification." + error;
            }
            return null;
        };

        /**
         * Creates a PostPaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.PostPaymentRequest} PostPaymentRequest
         */
        PostPaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.PostPaymentRequest)
                return object;
            let message = new $root.appliancepb.PostPaymentRequest();
            if (object.paymentDetails != null) {
                if (typeof object.paymentDetails !== "object")
                    throw TypeError(".appliancepb.PostPaymentRequest.paymentDetails: object expected");
                message.paymentDetails = $root.appliancepb.PaymentDetails.fromObject(object.paymentDetails);
            }
            if (object.replacementId != null)
                message.replacementId = String(object.replacementId);
            if (object.notification != null) {
                if (typeof object.notification !== "object")
                    throw TypeError(".appliancepb.PostPaymentRequest.notification: object expected");
                message.notification = $root.appliancepb.Notification.fromObject(object.notification);
            }
            return message;
        };

        /**
         * Creates a plain object from a PostPaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.PostPaymentRequest
         * @static
         * @param {appliancepb.PostPaymentRequest} message PostPaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PostPaymentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.paymentDetails = null;
                object.replacementId = "";
                object.notification = null;
            }
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails"))
                object.paymentDetails = $root.appliancepb.PaymentDetails.toObject(message.paymentDetails, options);
            if (message.replacementId != null && message.hasOwnProperty("replacementId"))
                object.replacementId = message.replacementId;
            if (message.notification != null && message.hasOwnProperty("notification"))
                object.notification = $root.appliancepb.Notification.toObject(message.notification, options);
            return object;
        };

        /**
         * Converts this PostPaymentRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.PostPaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PostPaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PostPaymentRequest;
    })();

    appliancepb.Contract = (function() {

        /**
         * Properties of a Contract.
         * @memberof appliancepb
         * @interface IContract
         * @property {string|null} [id] Contract id
         * @property {boolean|null} [hasDisposalCoverage] Contract hasDisposalCoverage
         */

        /**
         * Constructs a new Contract.
         * @memberof appliancepb
         * @classdesc Represents a Contract.
         * @implements IContract
         * @constructor
         * @param {appliancepb.IContract=} [properties] Properties to set
         */
        function Contract(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Contract id.
         * @member {string} id
         * @memberof appliancepb.Contract
         * @instance
         */
        Contract.prototype.id = "";

        /**
         * Contract hasDisposalCoverage.
         * @member {boolean} hasDisposalCoverage
         * @memberof appliancepb.Contract
         * @instance
         */
        Contract.prototype.hasDisposalCoverage = false;

        /**
         * Creates a new Contract instance using the specified properties.
         * @function create
         * @memberof appliancepb.Contract
         * @static
         * @param {appliancepb.IContract=} [properties] Properties to set
         * @returns {appliancepb.Contract} Contract instance
         */
        Contract.create = function create(properties) {
            return new Contract(properties);
        };

        /**
         * Encodes the specified Contract message. Does not implicitly {@link appliancepb.Contract.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Contract
         * @static
         * @param {appliancepb.IContract} message Contract message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Contract.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.hasDisposalCoverage != null && Object.hasOwnProperty.call(message, "hasDisposalCoverage"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hasDisposalCoverage);
            return writer;
        };

        /**
         * Encodes the specified Contract message, length delimited. Does not implicitly {@link appliancepb.Contract.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Contract
         * @static
         * @param {appliancepb.IContract} message Contract message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Contract.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Contract message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Contract
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Contract} Contract
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Contract.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Contract();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.hasDisposalCoverage = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Contract message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Contract
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Contract} Contract
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Contract.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Contract message.
         * @function verify
         * @memberof appliancepb.Contract
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Contract.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.hasDisposalCoverage != null && message.hasOwnProperty("hasDisposalCoverage"))
                if (typeof message.hasDisposalCoverage !== "boolean")
                    return "hasDisposalCoverage: boolean expected";
            return null;
        };

        /**
         * Creates a Contract message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Contract
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Contract} Contract
         */
        Contract.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Contract)
                return object;
            let message = new $root.appliancepb.Contract();
            if (object.id != null)
                message.id = String(object.id);
            if (object.hasDisposalCoverage != null)
                message.hasDisposalCoverage = Boolean(object.hasDisposalCoverage);
            return message;
        };

        /**
         * Creates a plain object from a Contract message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Contract
         * @static
         * @param {appliancepb.Contract} message Contract
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Contract.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.hasDisposalCoverage = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.hasDisposalCoverage != null && message.hasOwnProperty("hasDisposalCoverage"))
                object.hasDisposalCoverage = message.hasDisposalCoverage;
            return object;
        };

        /**
         * Converts this Contract to JSON.
         * @function toJSON
         * @memberof appliancepb.Contract
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Contract.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Contract;
    })();

    appliancepb.PriceSetter = (function() {

        /**
         * Properties of a PriceSetter.
         * @memberof appliancepb
         * @interface IPriceSetter
         * @property {number|null} [price] PriceSetter price
         * @property {boolean|null} [apply] PriceSetter apply
         */

        /**
         * Constructs a new PriceSetter.
         * @memberof appliancepb
         * @classdesc Represents a PriceSetter.
         * @implements IPriceSetter
         * @constructor
         * @param {appliancepb.IPriceSetter=} [properties] Properties to set
         */
        function PriceSetter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceSetter price.
         * @member {number} price
         * @memberof appliancepb.PriceSetter
         * @instance
         */
        PriceSetter.prototype.price = 0;

        /**
         * PriceSetter apply.
         * @member {boolean} apply
         * @memberof appliancepb.PriceSetter
         * @instance
         */
        PriceSetter.prototype.apply = false;

        /**
         * Creates a new PriceSetter instance using the specified properties.
         * @function create
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {appliancepb.IPriceSetter=} [properties] Properties to set
         * @returns {appliancepb.PriceSetter} PriceSetter instance
         */
        PriceSetter.create = function create(properties) {
            return new PriceSetter(properties);
        };

        /**
         * Encodes the specified PriceSetter message. Does not implicitly {@link appliancepb.PriceSetter.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {appliancepb.IPriceSetter} message PriceSetter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceSetter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.price);
            if (message.apply != null && Object.hasOwnProperty.call(message, "apply"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.apply);
            return writer;
        };

        /**
         * Encodes the specified PriceSetter message, length delimited. Does not implicitly {@link appliancepb.PriceSetter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {appliancepb.IPriceSetter} message PriceSetter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceSetter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PriceSetter message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.PriceSetter} PriceSetter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceSetter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.PriceSetter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.price = reader.int32();
                    break;
                case 2:
                    message.apply = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PriceSetter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.PriceSetter} PriceSetter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceSetter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PriceSetter message.
         * @function verify
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PriceSetter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.price != null && message.hasOwnProperty("price"))
                if (!$util.isInteger(message.price))
                    return "price: integer expected";
            if (message.apply != null && message.hasOwnProperty("apply"))
                if (typeof message.apply !== "boolean")
                    return "apply: boolean expected";
            return null;
        };

        /**
         * Creates a PriceSetter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.PriceSetter} PriceSetter
         */
        PriceSetter.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.PriceSetter)
                return object;
            let message = new $root.appliancepb.PriceSetter();
            if (object.price != null)
                message.price = object.price | 0;
            if (object.apply != null)
                message.apply = Boolean(object.apply);
            return message;
        };

        /**
         * Creates a plain object from a PriceSetter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.PriceSetter
         * @static
         * @param {appliancepb.PriceSetter} message PriceSetter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PriceSetter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.price = 0;
                object.apply = false;
            }
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = message.price;
            if (message.apply != null && message.hasOwnProperty("apply"))
                object.apply = message.apply;
            return object;
        };

        /**
         * Converts this PriceSetter to JSON.
         * @function toJSON
         * @memberof appliancepb.PriceSetter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PriceSetter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PriceSetter;
    })();

    appliancepb.Order = (function() {

        /**
         * Properties of an Order.
         * @memberof appliancepb
         * @interface IOrder
         * @property {string|null} [vendorOrderNumber] Order vendorOrderNumber
         * @property {string|null} [poNumber] Order poNumber
         * @property {string|null} [orderBy] Order orderBy
         * @property {google.protobuf.ITimestamp|null} [orderDate] Order orderDate
         * @property {google.protobuf.ITimestamp|null} [deliveryDate] Order deliveryDate
         * @property {string|null} [deliveryInstructions] Order deliveryInstructions
         * @property {string|null} [vendor] Order vendor
         * @property {string|null} [status] Order status
         * @property {google.protobuf.ITimestamp|null} [statusDate] Order statusDate
         * @property {number|null} [orderAttempts] Order orderAttempts
         */

        /**
         * Constructs a new Order.
         * @memberof appliancepb
         * @classdesc Represents an Order.
         * @implements IOrder
         * @constructor
         * @param {appliancepb.IOrder=} [properties] Properties to set
         */
        function Order(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Order vendorOrderNumber.
         * @member {string} vendorOrderNumber
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.vendorOrderNumber = "";

        /**
         * Order poNumber.
         * @member {string} poNumber
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.poNumber = "";

        /**
         * Order orderBy.
         * @member {string} orderBy
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.orderBy = "";

        /**
         * Order orderDate.
         * @member {google.protobuf.ITimestamp|null|undefined} orderDate
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.orderDate = null;

        /**
         * Order deliveryDate.
         * @member {google.protobuf.ITimestamp|null|undefined} deliveryDate
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.deliveryDate = null;

        /**
         * Order deliveryInstructions.
         * @member {string} deliveryInstructions
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.deliveryInstructions = "";

        /**
         * Order vendor.
         * @member {string} vendor
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.vendor = "";

        /**
         * Order status.
         * @member {string} status
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.status = "";

        /**
         * Order statusDate.
         * @member {google.protobuf.ITimestamp|null|undefined} statusDate
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.statusDate = null;

        /**
         * Order orderAttempts.
         * @member {number} orderAttempts
         * @memberof appliancepb.Order
         * @instance
         */
        Order.prototype.orderAttempts = 0;

        /**
         * Creates a new Order instance using the specified properties.
         * @function create
         * @memberof appliancepb.Order
         * @static
         * @param {appliancepb.IOrder=} [properties] Properties to set
         * @returns {appliancepb.Order} Order instance
         */
        Order.create = function create(properties) {
            return new Order(properties);
        };

        /**
         * Encodes the specified Order message. Does not implicitly {@link appliancepb.Order.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Order
         * @static
         * @param {appliancepb.IOrder} message Order message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Order.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.vendorOrderNumber != null && Object.hasOwnProperty.call(message, "vendorOrderNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.vendorOrderNumber);
            if (message.poNumber != null && Object.hasOwnProperty.call(message, "poNumber"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.poNumber);
            if (message.orderBy != null && Object.hasOwnProperty.call(message, "orderBy"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.orderBy);
            if (message.orderDate != null && Object.hasOwnProperty.call(message, "orderDate"))
                $root.google.protobuf.Timestamp.encode(message.orderDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.deliveryDate != null && Object.hasOwnProperty.call(message, "deliveryDate"))
                $root.google.protobuf.Timestamp.encode(message.deliveryDate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.deliveryInstructions != null && Object.hasOwnProperty.call(message, "deliveryInstructions"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.deliveryInstructions);
            if (message.vendor != null && Object.hasOwnProperty.call(message, "vendor"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.vendor);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.status);
            if (message.statusDate != null && Object.hasOwnProperty.call(message, "statusDate"))
                $root.google.protobuf.Timestamp.encode(message.statusDate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.orderAttempts != null && Object.hasOwnProperty.call(message, "orderAttempts"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.orderAttempts);
            return writer;
        };

        /**
         * Encodes the specified Order message, length delimited. Does not implicitly {@link appliancepb.Order.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Order
         * @static
         * @param {appliancepb.IOrder} message Order message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Order.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Order message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Order
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Order} Order
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Order.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Order();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.vendorOrderNumber = reader.string();
                    break;
                case 2:
                    message.poNumber = reader.string();
                    break;
                case 3:
                    message.orderBy = reader.string();
                    break;
                case 4:
                    message.orderDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.deliveryDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.deliveryInstructions = reader.string();
                    break;
                case 7:
                    message.vendor = reader.string();
                    break;
                case 8:
                    message.status = reader.string();
                    break;
                case 9:
                    message.statusDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.orderAttempts = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Order message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Order
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Order} Order
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Order.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Order message.
         * @function verify
         * @memberof appliancepb.Order
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Order.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                if (!$util.isString(message.vendorOrderNumber))
                    return "vendorOrderNumber: string expected";
            if (message.poNumber != null && message.hasOwnProperty("poNumber"))
                if (!$util.isString(message.poNumber))
                    return "poNumber: string expected";
            if (message.orderBy != null && message.hasOwnProperty("orderBy"))
                if (!$util.isString(message.orderBy))
                    return "orderBy: string expected";
            if (message.orderDate != null && message.hasOwnProperty("orderDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.orderDate);
                if (error)
                    return "orderDate." + error;
            }
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.deliveryDate);
                if (error)
                    return "deliveryDate." + error;
            }
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                if (!$util.isString(message.deliveryInstructions))
                    return "deliveryInstructions: string expected";
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                if (!$util.isString(message.vendor))
                    return "vendor: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.statusDate != null && message.hasOwnProperty("statusDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.statusDate);
                if (error)
                    return "statusDate." + error;
            }
            if (message.orderAttempts != null && message.hasOwnProperty("orderAttempts"))
                if (!$util.isInteger(message.orderAttempts))
                    return "orderAttempts: integer expected";
            return null;
        };

        /**
         * Creates an Order message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Order
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Order} Order
         */
        Order.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Order)
                return object;
            let message = new $root.appliancepb.Order();
            if (object.vendorOrderNumber != null)
                message.vendorOrderNumber = String(object.vendorOrderNumber);
            if (object.poNumber != null)
                message.poNumber = String(object.poNumber);
            if (object.orderBy != null)
                message.orderBy = String(object.orderBy);
            if (object.orderDate != null) {
                if (typeof object.orderDate !== "object")
                    throw TypeError(".appliancepb.Order.orderDate: object expected");
                message.orderDate = $root.google.protobuf.Timestamp.fromObject(object.orderDate);
            }
            if (object.deliveryDate != null) {
                if (typeof object.deliveryDate !== "object")
                    throw TypeError(".appliancepb.Order.deliveryDate: object expected");
                message.deliveryDate = $root.google.protobuf.Timestamp.fromObject(object.deliveryDate);
            }
            if (object.deliveryInstructions != null)
                message.deliveryInstructions = String(object.deliveryInstructions);
            if (object.vendor != null)
                message.vendor = String(object.vendor);
            if (object.status != null)
                message.status = String(object.status);
            if (object.statusDate != null) {
                if (typeof object.statusDate !== "object")
                    throw TypeError(".appliancepb.Order.statusDate: object expected");
                message.statusDate = $root.google.protobuf.Timestamp.fromObject(object.statusDate);
            }
            if (object.orderAttempts != null)
                message.orderAttempts = object.orderAttempts | 0;
            return message;
        };

        /**
         * Creates a plain object from an Order message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Order
         * @static
         * @param {appliancepb.Order} message Order
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Order.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.vendorOrderNumber = "";
                object.poNumber = "";
                object.orderBy = "";
                object.orderDate = null;
                object.deliveryDate = null;
                object.deliveryInstructions = "";
                object.vendor = "";
                object.status = "";
                object.statusDate = null;
                object.orderAttempts = 0;
            }
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                object.vendorOrderNumber = message.vendorOrderNumber;
            if (message.poNumber != null && message.hasOwnProperty("poNumber"))
                object.poNumber = message.poNumber;
            if (message.orderBy != null && message.hasOwnProperty("orderBy"))
                object.orderBy = message.orderBy;
            if (message.orderDate != null && message.hasOwnProperty("orderDate"))
                object.orderDate = $root.google.protobuf.Timestamp.toObject(message.orderDate, options);
            if (message.deliveryDate != null && message.hasOwnProperty("deliveryDate"))
                object.deliveryDate = $root.google.protobuf.Timestamp.toObject(message.deliveryDate, options);
            if (message.deliveryInstructions != null && message.hasOwnProperty("deliveryInstructions"))
                object.deliveryInstructions = message.deliveryInstructions;
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                object.vendor = message.vendor;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.statusDate != null && message.hasOwnProperty("statusDate"))
                object.statusDate = $root.google.protobuf.Timestamp.toObject(message.statusDate, options);
            if (message.orderAttempts != null && message.hasOwnProperty("orderAttempts"))
                object.orderAttempts = message.orderAttempts;
            return object;
        };

        /**
         * Converts this Order to JSON.
         * @function toJSON
         * @memberof appliancepb.Order
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Order.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Order;
    })();

    appliancepb.CreateReplacementRequest = (function() {

        /**
         * Properties of a CreateReplacementRequest.
         * @memberof appliancepb
         * @interface ICreateReplacementRequest
         * @property {string|null} [dispatchId] CreateReplacementRequest dispatchId
         * @property {string|null} [partRequestId] CreateReplacementRequest partRequestId
         * @property {appliancepb.IContract|null} [contract] CreateReplacementRequest contract
         * @property {string|null} [itemId] CreateReplacementRequest itemId
         * @property {appliancepb.ICustomer|null} [customer] CreateReplacementRequest customer
         * @property {appliancepb.INotification|null} [notification] CreateReplacementRequest notification
         * @property {appliancepb.IProduct|null} [likeForLike] CreateReplacementRequest likeForLike
         * @property {appliancepb.IOrder|null} [order] CreateReplacementRequest order
         * @property {appliancepb.CreateReplacementRequest.IShoppingCart|null} [shoppingCart] CreateReplacementRequest shoppingCart
         * @property {string|null} [note] CreateReplacementRequest note
         * @property {Array.<appliancepb.IProductAccessory>|null} [productAccessories] CreateReplacementRequest productAccessories
         */

        /**
         * Constructs a new CreateReplacementRequest.
         * @memberof appliancepb
         * @classdesc Represents a CreateReplacementRequest.
         * @implements ICreateReplacementRequest
         * @constructor
         * @param {appliancepb.ICreateReplacementRequest=} [properties] Properties to set
         */
        function CreateReplacementRequest(properties) {
            this.productAccessories = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateReplacementRequest dispatchId.
         * @member {string} dispatchId
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.dispatchId = "";

        /**
         * CreateReplacementRequest partRequestId.
         * @member {string} partRequestId
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.partRequestId = "";

        /**
         * CreateReplacementRequest contract.
         * @member {appliancepb.IContract|null|undefined} contract
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.contract = null;

        /**
         * CreateReplacementRequest itemId.
         * @member {string} itemId
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.itemId = "";

        /**
         * CreateReplacementRequest customer.
         * @member {appliancepb.ICustomer|null|undefined} customer
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.customer = null;

        /**
         * CreateReplacementRequest notification.
         * @member {appliancepb.INotification|null|undefined} notification
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.notification = null;

        /**
         * CreateReplacementRequest likeForLike.
         * @member {appliancepb.IProduct|null|undefined} likeForLike
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.likeForLike = null;

        /**
         * CreateReplacementRequest order.
         * @member {appliancepb.IOrder|null|undefined} order
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.order = null;

        /**
         * CreateReplacementRequest shoppingCart.
         * @member {appliancepb.CreateReplacementRequest.IShoppingCart|null|undefined} shoppingCart
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.shoppingCart = null;

        /**
         * CreateReplacementRequest note.
         * @member {string} note
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.note = "";

        /**
         * CreateReplacementRequest productAccessories.
         * @member {Array.<appliancepb.IProductAccessory>} productAccessories
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         */
        CreateReplacementRequest.prototype.productAccessories = $util.emptyArray;

        /**
         * Creates a new CreateReplacementRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {appliancepb.ICreateReplacementRequest=} [properties] Properties to set
         * @returns {appliancepb.CreateReplacementRequest} CreateReplacementRequest instance
         */
        CreateReplacementRequest.create = function create(properties) {
            return new CreateReplacementRequest(properties);
        };

        /**
         * Encodes the specified CreateReplacementRequest message. Does not implicitly {@link appliancepb.CreateReplacementRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {appliancepb.ICreateReplacementRequest} message CreateReplacementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateReplacementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dispatchId != null && Object.hasOwnProperty.call(message, "dispatchId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dispatchId);
            if (message.partRequestId != null && Object.hasOwnProperty.call(message, "partRequestId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.partRequestId);
            if (message.contract != null && Object.hasOwnProperty.call(message, "contract"))
                $root.appliancepb.Contract.encode(message.contract, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.itemId != null && Object.hasOwnProperty.call(message, "itemId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.itemId);
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.appliancepb.Customer.encode(message.customer, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.notification != null && Object.hasOwnProperty.call(message, "notification"))
                $root.appliancepb.Notification.encode(message.notification, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.likeForLike != null && Object.hasOwnProperty.call(message, "likeForLike"))
                $root.appliancepb.Product.encode(message.likeForLike, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                $root.appliancepb.Order.encode(message.order, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.shoppingCart != null && Object.hasOwnProperty.call(message, "shoppingCart"))
                $root.appliancepb.CreateReplacementRequest.ShoppingCart.encode(message.shoppingCart, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.note);
            if (message.productAccessories != null && message.productAccessories.length)
                for (let i = 0; i < message.productAccessories.length; ++i)
                    $root.appliancepb.ProductAccessory.encode(message.productAccessories[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateReplacementRequest message, length delimited. Does not implicitly {@link appliancepb.CreateReplacementRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {appliancepb.ICreateReplacementRequest} message CreateReplacementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateReplacementRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateReplacementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.CreateReplacementRequest} CreateReplacementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateReplacementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.CreateReplacementRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.dispatchId = reader.string();
                    break;
                case 2:
                    message.partRequestId = reader.string();
                    break;
                case 3:
                    message.contract = $root.appliancepb.Contract.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.itemId = reader.string();
                    break;
                case 5:
                    message.customer = $root.appliancepb.Customer.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.notification = $root.appliancepb.Notification.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.likeForLike = $root.appliancepb.Product.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.order = $root.appliancepb.Order.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.shoppingCart = $root.appliancepb.CreateReplacementRequest.ShoppingCart.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.note = reader.string();
                    break;
                case 11:
                    if (!(message.productAccessories && message.productAccessories.length))
                        message.productAccessories = [];
                    message.productAccessories.push($root.appliancepb.ProductAccessory.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateReplacementRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.CreateReplacementRequest} CreateReplacementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateReplacementRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateReplacementRequest message.
         * @function verify
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateReplacementRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                if (!$util.isString(message.dispatchId))
                    return "dispatchId: string expected";
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                if (!$util.isString(message.partRequestId))
                    return "partRequestId: string expected";
            if (message.contract != null && message.hasOwnProperty("contract")) {
                let error = $root.appliancepb.Contract.verify(message.contract);
                if (error)
                    return "contract." + error;
            }
            if (message.itemId != null && message.hasOwnProperty("itemId"))
                if (!$util.isString(message.itemId))
                    return "itemId: string expected";
            if (message.customer != null && message.hasOwnProperty("customer")) {
                let error = $root.appliancepb.Customer.verify(message.customer);
                if (error)
                    return "customer." + error;
            }
            if (message.notification != null && message.hasOwnProperty("notification")) {
                let error = $root.appliancepb.Notification.verify(message.notification);
                if (error)
                    return "notification." + error;
            }
            if (message.likeForLike != null && message.hasOwnProperty("likeForLike")) {
                let error = $root.appliancepb.Product.verify(message.likeForLike);
                if (error)
                    return "likeForLike." + error;
            }
            if (message.order != null && message.hasOwnProperty("order")) {
                let error = $root.appliancepb.Order.verify(message.order);
                if (error)
                    return "order." + error;
            }
            if (message.shoppingCart != null && message.hasOwnProperty("shoppingCart")) {
                let error = $root.appliancepb.CreateReplacementRequest.ShoppingCart.verify(message.shoppingCart);
                if (error)
                    return "shoppingCart." + error;
            }
            if (message.note != null && message.hasOwnProperty("note"))
                if (!$util.isString(message.note))
                    return "note: string expected";
            if (message.productAccessories != null && message.hasOwnProperty("productAccessories")) {
                if (!Array.isArray(message.productAccessories))
                    return "productAccessories: array expected";
                for (let i = 0; i < message.productAccessories.length; ++i) {
                    let error = $root.appliancepb.ProductAccessory.verify(message.productAccessories[i]);
                    if (error)
                        return "productAccessories." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CreateReplacementRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.CreateReplacementRequest} CreateReplacementRequest
         */
        CreateReplacementRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.CreateReplacementRequest)
                return object;
            let message = new $root.appliancepb.CreateReplacementRequest();
            if (object.dispatchId != null)
                message.dispatchId = String(object.dispatchId);
            if (object.partRequestId != null)
                message.partRequestId = String(object.partRequestId);
            if (object.contract != null) {
                if (typeof object.contract !== "object")
                    throw TypeError(".appliancepb.CreateReplacementRequest.contract: object expected");
                message.contract = $root.appliancepb.Contract.fromObject(object.contract);
            }
            if (object.itemId != null)
                message.itemId = String(object.itemId);
            if (object.customer != null) {
                if (typeof object.customer !== "object")
                    throw TypeError(".appliancepb.CreateReplacementRequest.customer: object expected");
                message.customer = $root.appliancepb.Customer.fromObject(object.customer);
            }
            if (object.notification != null) {
                if (typeof object.notification !== "object")
                    throw TypeError(".appliancepb.CreateReplacementRequest.notification: object expected");
                message.notification = $root.appliancepb.Notification.fromObject(object.notification);
            }
            if (object.likeForLike != null) {
                if (typeof object.likeForLike !== "object")
                    throw TypeError(".appliancepb.CreateReplacementRequest.likeForLike: object expected");
                message.likeForLike = $root.appliancepb.Product.fromObject(object.likeForLike);
            }
            if (object.order != null) {
                if (typeof object.order !== "object")
                    throw TypeError(".appliancepb.CreateReplacementRequest.order: object expected");
                message.order = $root.appliancepb.Order.fromObject(object.order);
            }
            if (object.shoppingCart != null) {
                if (typeof object.shoppingCart !== "object")
                    throw TypeError(".appliancepb.CreateReplacementRequest.shoppingCart: object expected");
                message.shoppingCart = $root.appliancepb.CreateReplacementRequest.ShoppingCart.fromObject(object.shoppingCart);
            }
            if (object.note != null)
                message.note = String(object.note);
            if (object.productAccessories) {
                if (!Array.isArray(object.productAccessories))
                    throw TypeError(".appliancepb.CreateReplacementRequest.productAccessories: array expected");
                message.productAccessories = [];
                for (let i = 0; i < object.productAccessories.length; ++i) {
                    if (typeof object.productAccessories[i] !== "object")
                        throw TypeError(".appliancepb.CreateReplacementRequest.productAccessories: object expected");
                    message.productAccessories[i] = $root.appliancepb.ProductAccessory.fromObject(object.productAccessories[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateReplacementRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.CreateReplacementRequest
         * @static
         * @param {appliancepb.CreateReplacementRequest} message CreateReplacementRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateReplacementRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.productAccessories = [];
            if (options.defaults) {
                object.dispatchId = "";
                object.partRequestId = "";
                object.contract = null;
                object.itemId = "";
                object.customer = null;
                object.notification = null;
                object.likeForLike = null;
                object.order = null;
                object.shoppingCart = null;
                object.note = "";
            }
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                object.dispatchId = message.dispatchId;
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                object.partRequestId = message.partRequestId;
            if (message.contract != null && message.hasOwnProperty("contract"))
                object.contract = $root.appliancepb.Contract.toObject(message.contract, options);
            if (message.itemId != null && message.hasOwnProperty("itemId"))
                object.itemId = message.itemId;
            if (message.customer != null && message.hasOwnProperty("customer"))
                object.customer = $root.appliancepb.Customer.toObject(message.customer, options);
            if (message.notification != null && message.hasOwnProperty("notification"))
                object.notification = $root.appliancepb.Notification.toObject(message.notification, options);
            if (message.likeForLike != null && message.hasOwnProperty("likeForLike"))
                object.likeForLike = $root.appliancepb.Product.toObject(message.likeForLike, options);
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = $root.appliancepb.Order.toObject(message.order, options);
            if (message.shoppingCart != null && message.hasOwnProperty("shoppingCart"))
                object.shoppingCart = $root.appliancepb.CreateReplacementRequest.ShoppingCart.toObject(message.shoppingCart, options);
            if (message.note != null && message.hasOwnProperty("note"))
                object.note = message.note;
            if (message.productAccessories && message.productAccessories.length) {
                object.productAccessories = [];
                for (let j = 0; j < message.productAccessories.length; ++j)
                    object.productAccessories[j] = $root.appliancepb.ProductAccessory.toObject(message.productAccessories[j], options);
            }
            return object;
        };

        /**
         * Converts this CreateReplacementRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.CreateReplacementRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateReplacementRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CreateReplacementRequest.ShoppingCart = (function() {

            /**
             * Properties of a ShoppingCart.
             * @memberof appliancepb.CreateReplacementRequest
             * @interface IShoppingCart
             * @property {Array.<appliancepb.CreateReplacementRequest.ShoppingCart.IShoppingCartItem>|null} [shoppingCartItems] ShoppingCart shoppingCartItems
             */

            /**
             * Constructs a new ShoppingCart.
             * @memberof appliancepb.CreateReplacementRequest
             * @classdesc Represents a ShoppingCart.
             * @implements IShoppingCart
             * @constructor
             * @param {appliancepb.CreateReplacementRequest.IShoppingCart=} [properties] Properties to set
             */
            function ShoppingCart(properties) {
                this.shoppingCartItems = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ShoppingCart shoppingCartItems.
             * @member {Array.<appliancepb.CreateReplacementRequest.ShoppingCart.IShoppingCartItem>} shoppingCartItems
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @instance
             */
            ShoppingCart.prototype.shoppingCartItems = $util.emptyArray;

            /**
             * Creates a new ShoppingCart instance using the specified properties.
             * @function create
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {appliancepb.CreateReplacementRequest.IShoppingCart=} [properties] Properties to set
             * @returns {appliancepb.CreateReplacementRequest.ShoppingCart} ShoppingCart instance
             */
            ShoppingCart.create = function create(properties) {
                return new ShoppingCart(properties);
            };

            /**
             * Encodes the specified ShoppingCart message. Does not implicitly {@link appliancepb.CreateReplacementRequest.ShoppingCart.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {appliancepb.CreateReplacementRequest.IShoppingCart} message ShoppingCart message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShoppingCart.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.shoppingCartItems != null && message.shoppingCartItems.length)
                    for (let i = 0; i < message.shoppingCartItems.length; ++i)
                        $root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.encode(message.shoppingCartItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ShoppingCart message, length delimited. Does not implicitly {@link appliancepb.CreateReplacementRequest.ShoppingCart.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {appliancepb.CreateReplacementRequest.IShoppingCart} message ShoppingCart message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ShoppingCart.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ShoppingCart message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.CreateReplacementRequest.ShoppingCart} ShoppingCart
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShoppingCart.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.CreateReplacementRequest.ShoppingCart();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.shoppingCartItems && message.shoppingCartItems.length))
                            message.shoppingCartItems = [];
                        message.shoppingCartItems.push($root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ShoppingCart message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.CreateReplacementRequest.ShoppingCart} ShoppingCart
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ShoppingCart.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ShoppingCart message.
             * @function verify
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ShoppingCart.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.shoppingCartItems != null && message.hasOwnProperty("shoppingCartItems")) {
                    if (!Array.isArray(message.shoppingCartItems))
                        return "shoppingCartItems: array expected";
                    for (let i = 0; i < message.shoppingCartItems.length; ++i) {
                        let error = $root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.verify(message.shoppingCartItems[i]);
                        if (error)
                            return "shoppingCartItems." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ShoppingCart message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.CreateReplacementRequest.ShoppingCart} ShoppingCart
             */
            ShoppingCart.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.CreateReplacementRequest.ShoppingCart)
                    return object;
                let message = new $root.appliancepb.CreateReplacementRequest.ShoppingCart();
                if (object.shoppingCartItems) {
                    if (!Array.isArray(object.shoppingCartItems))
                        throw TypeError(".appliancepb.CreateReplacementRequest.ShoppingCart.shoppingCartItems: array expected");
                    message.shoppingCartItems = [];
                    for (let i = 0; i < object.shoppingCartItems.length; ++i) {
                        if (typeof object.shoppingCartItems[i] !== "object")
                            throw TypeError(".appliancepb.CreateReplacementRequest.ShoppingCart.shoppingCartItems: object expected");
                        message.shoppingCartItems[i] = $root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.fromObject(object.shoppingCartItems[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ShoppingCart message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @static
             * @param {appliancepb.CreateReplacementRequest.ShoppingCart} message ShoppingCart
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ShoppingCart.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.shoppingCartItems = [];
                if (message.shoppingCartItems && message.shoppingCartItems.length) {
                    object.shoppingCartItems = [];
                    for (let j = 0; j < message.shoppingCartItems.length; ++j)
                        object.shoppingCartItems[j] = $root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.toObject(message.shoppingCartItems[j], options);
                }
                return object;
            };

            /**
             * Converts this ShoppingCart to JSON.
             * @function toJSON
             * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ShoppingCart.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ShoppingCart.ShoppingCartItem = (function() {

                /**
                 * Properties of a ShoppingCartItem.
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
                 * @interface IShoppingCartItem
                 * @property {appliancepb.IProduct|null} [product] ShoppingCartItem product
                 * @property {number|null} [quantity] ShoppingCartItem quantity
                 * @property {appliancepb.IPriceSetter|null} [finalPrice] ShoppingCartItem finalPrice
                 */

                /**
                 * Constructs a new ShoppingCartItem.
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart
                 * @classdesc Represents a ShoppingCartItem.
                 * @implements IShoppingCartItem
                 * @constructor
                 * @param {appliancepb.CreateReplacementRequest.ShoppingCart.IShoppingCartItem=} [properties] Properties to set
                 */
                function ShoppingCartItem(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ShoppingCartItem product.
                 * @member {appliancepb.IProduct|null|undefined} product
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @instance
                 */
                ShoppingCartItem.prototype.product = null;

                /**
                 * ShoppingCartItem quantity.
                 * @member {number} quantity
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @instance
                 */
                ShoppingCartItem.prototype.quantity = 0;

                /**
                 * ShoppingCartItem finalPrice.
                 * @member {appliancepb.IPriceSetter|null|undefined} finalPrice
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @instance
                 */
                ShoppingCartItem.prototype.finalPrice = null;

                /**
                 * Creates a new ShoppingCartItem instance using the specified properties.
                 * @function create
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {appliancepb.CreateReplacementRequest.ShoppingCart.IShoppingCartItem=} [properties] Properties to set
                 * @returns {appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem} ShoppingCartItem instance
                 */
                ShoppingCartItem.create = function create(properties) {
                    return new ShoppingCartItem(properties);
                };

                /**
                 * Encodes the specified ShoppingCartItem message. Does not implicitly {@link appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.verify|verify} messages.
                 * @function encode
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {appliancepb.CreateReplacementRequest.ShoppingCart.IShoppingCartItem} message ShoppingCartItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ShoppingCartItem.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                        $root.appliancepb.Product.encode(message.product, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.quantity);
                    if (message.finalPrice != null && Object.hasOwnProperty.call(message, "finalPrice"))
                        $root.appliancepb.PriceSetter.encode(message.finalPrice, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ShoppingCartItem message, length delimited. Does not implicitly {@link appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {appliancepb.CreateReplacementRequest.ShoppingCart.IShoppingCartItem} message ShoppingCartItem message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ShoppingCartItem.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ShoppingCartItem message from the specified reader or buffer.
                 * @function decode
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem} ShoppingCartItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ShoppingCartItem.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.product = $root.appliancepb.Product.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.quantity = reader.int32();
                            break;
                        case 3:
                            message.finalPrice = $root.appliancepb.PriceSetter.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ShoppingCartItem message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem} ShoppingCartItem
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ShoppingCartItem.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ShoppingCartItem message.
                 * @function verify
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ShoppingCartItem.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.product != null && message.hasOwnProperty("product")) {
                        let error = $root.appliancepb.Product.verify(message.product);
                        if (error)
                            return "product." + error;
                    }
                    if (message.quantity != null && message.hasOwnProperty("quantity"))
                        if (!$util.isInteger(message.quantity))
                            return "quantity: integer expected";
                    if (message.finalPrice != null && message.hasOwnProperty("finalPrice")) {
                        let error = $root.appliancepb.PriceSetter.verify(message.finalPrice);
                        if (error)
                            return "finalPrice." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ShoppingCartItem message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem} ShoppingCartItem
                 */
                ShoppingCartItem.fromObject = function fromObject(object) {
                    if (object instanceof $root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem)
                        return object;
                    let message = new $root.appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem();
                    if (object.product != null) {
                        if (typeof object.product !== "object")
                            throw TypeError(".appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.product: object expected");
                        message.product = $root.appliancepb.Product.fromObject(object.product);
                    }
                    if (object.quantity != null)
                        message.quantity = object.quantity | 0;
                    if (object.finalPrice != null) {
                        if (typeof object.finalPrice !== "object")
                            throw TypeError(".appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem.finalPrice: object expected");
                        message.finalPrice = $root.appliancepb.PriceSetter.fromObject(object.finalPrice);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ShoppingCartItem message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @static
                 * @param {appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem} message ShoppingCartItem
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ShoppingCartItem.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.product = null;
                        object.quantity = 0;
                        object.finalPrice = null;
                    }
                    if (message.product != null && message.hasOwnProperty("product"))
                        object.product = $root.appliancepb.Product.toObject(message.product, options);
                    if (message.quantity != null && message.hasOwnProperty("quantity"))
                        object.quantity = message.quantity;
                    if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                        object.finalPrice = $root.appliancepb.PriceSetter.toObject(message.finalPrice, options);
                    return object;
                };

                /**
                 * Converts this ShoppingCartItem to JSON.
                 * @function toJSON
                 * @memberof appliancepb.CreateReplacementRequest.ShoppingCart.ShoppingCartItem
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ShoppingCartItem.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ShoppingCartItem;
            })();

            return ShoppingCart;
        })();

        return CreateReplacementRequest;
    })();

    appliancepb.CreateReplacementResponse = (function() {

        /**
         * Properties of a CreateReplacementResponse.
         * @memberof appliancepb
         * @interface ICreateReplacementResponse
         * @property {string|null} [replacementId] CreateReplacementResponse replacementId
         * @property {string|null} [shopLink] CreateReplacementResponse shopLink
         */

        /**
         * Constructs a new CreateReplacementResponse.
         * @memberof appliancepb
         * @classdesc Represents a CreateReplacementResponse.
         * @implements ICreateReplacementResponse
         * @constructor
         * @param {appliancepb.ICreateReplacementResponse=} [properties] Properties to set
         */
        function CreateReplacementResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateReplacementResponse replacementId.
         * @member {string} replacementId
         * @memberof appliancepb.CreateReplacementResponse
         * @instance
         */
        CreateReplacementResponse.prototype.replacementId = "";

        /**
         * CreateReplacementResponse shopLink.
         * @member {string} shopLink
         * @memberof appliancepb.CreateReplacementResponse
         * @instance
         */
        CreateReplacementResponse.prototype.shopLink = "";

        /**
         * Creates a new CreateReplacementResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {appliancepb.ICreateReplacementResponse=} [properties] Properties to set
         * @returns {appliancepb.CreateReplacementResponse} CreateReplacementResponse instance
         */
        CreateReplacementResponse.create = function create(properties) {
            return new CreateReplacementResponse(properties);
        };

        /**
         * Encodes the specified CreateReplacementResponse message. Does not implicitly {@link appliancepb.CreateReplacementResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {appliancepb.ICreateReplacementResponse} message CreateReplacementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateReplacementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.replacementId != null && Object.hasOwnProperty.call(message, "replacementId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.replacementId);
            if (message.shopLink != null && Object.hasOwnProperty.call(message, "shopLink"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.shopLink);
            return writer;
        };

        /**
         * Encodes the specified CreateReplacementResponse message, length delimited. Does not implicitly {@link appliancepb.CreateReplacementResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {appliancepb.ICreateReplacementResponse} message CreateReplacementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateReplacementResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateReplacementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.CreateReplacementResponse} CreateReplacementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateReplacementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.CreateReplacementResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.replacementId = reader.string();
                    break;
                case 2:
                    message.shopLink = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateReplacementResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.CreateReplacementResponse} CreateReplacementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateReplacementResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateReplacementResponse message.
         * @function verify
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateReplacementResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.replacementId != null && message.hasOwnProperty("replacementId"))
                if (!$util.isString(message.replacementId))
                    return "replacementId: string expected";
            if (message.shopLink != null && message.hasOwnProperty("shopLink"))
                if (!$util.isString(message.shopLink))
                    return "shopLink: string expected";
            return null;
        };

        /**
         * Creates a CreateReplacementResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.CreateReplacementResponse} CreateReplacementResponse
         */
        CreateReplacementResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.CreateReplacementResponse)
                return object;
            let message = new $root.appliancepb.CreateReplacementResponse();
            if (object.replacementId != null)
                message.replacementId = String(object.replacementId);
            if (object.shopLink != null)
                message.shopLink = String(object.shopLink);
            return message;
        };

        /**
         * Creates a plain object from a CreateReplacementResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.CreateReplacementResponse
         * @static
         * @param {appliancepb.CreateReplacementResponse} message CreateReplacementResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateReplacementResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.replacementId = "";
                object.shopLink = "";
            }
            if (message.replacementId != null && message.hasOwnProperty("replacementId"))
                object.replacementId = message.replacementId;
            if (message.shopLink != null && message.hasOwnProperty("shopLink"))
                object.shopLink = message.shopLink;
            return object;
        };

        /**
         * Converts this CreateReplacementResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.CreateReplacementResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateReplacementResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateReplacementResponse;
    })();

    appliancepb.OutrightPaymentRequest = (function() {

        /**
         * Properties of an OutrightPaymentRequest.
         * @memberof appliancepb
         * @interface IOutrightPaymentRequest
         * @property {appliancepb.IContract|null} [contract] OutrightPaymentRequest contract
         * @property {appliancepb.ICustomer|null} [customer] OutrightPaymentRequest customer
         * @property {string|null} [vendor] OutrightPaymentRequest vendor
         * @property {appliancepb.IPaymentDetails|null} [paymentDetails] OutrightPaymentRequest paymentDetails
         * @property {string|null} [recordId] OutrightPaymentRequest recordId
         * @property {string|null} [modelNumber] OutrightPaymentRequest modelNumber
         * @property {google.protobuf.ITimestamp|null} [orderDate] OutrightPaymentRequest orderDate
         * @property {appliancepb.OutrightPaymentRequest.PaymentType|null} [paymentType] OutrightPaymentRequest paymentType
         * @property {string|null} [dispatchId] OutrightPaymentRequest dispatchId
         * @property {appliancepb.IOrder|null} [order] OutrightPaymentRequest order
         * @property {appliancepb.ICart|null} [cart] OutrightPaymentRequest cart
         * @property {string|null} [partRequestId] OutrightPaymentRequest partRequestId
         */

        /**
         * Constructs a new OutrightPaymentRequest.
         * @memberof appliancepb
         * @classdesc Represents an OutrightPaymentRequest.
         * @implements IOutrightPaymentRequest
         * @constructor
         * @param {appliancepb.IOutrightPaymentRequest=} [properties] Properties to set
         */
        function OutrightPaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutrightPaymentRequest contract.
         * @member {appliancepb.IContract|null|undefined} contract
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.contract = null;

        /**
         * OutrightPaymentRequest customer.
         * @member {appliancepb.ICustomer|null|undefined} customer
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.customer = null;

        /**
         * OutrightPaymentRequest vendor.
         * @member {string} vendor
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.vendor = "";

        /**
         * OutrightPaymentRequest paymentDetails.
         * @member {appliancepb.IPaymentDetails|null|undefined} paymentDetails
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.paymentDetails = null;

        /**
         * OutrightPaymentRequest recordId.
         * @member {string} recordId
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.recordId = "";

        /**
         * OutrightPaymentRequest modelNumber.
         * @member {string} modelNumber
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.modelNumber = "";

        /**
         * OutrightPaymentRequest orderDate.
         * @member {google.protobuf.ITimestamp|null|undefined} orderDate
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.orderDate = null;

        /**
         * OutrightPaymentRequest paymentType.
         * @member {appliancepb.OutrightPaymentRequest.PaymentType} paymentType
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.paymentType = 0;

        /**
         * OutrightPaymentRequest dispatchId.
         * @member {string} dispatchId
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.dispatchId = "";

        /**
         * OutrightPaymentRequest order.
         * @member {appliancepb.IOrder|null|undefined} order
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.order = null;

        /**
         * OutrightPaymentRequest cart.
         * @member {appliancepb.ICart|null|undefined} cart
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.cart = null;

        /**
         * OutrightPaymentRequest partRequestId.
         * @member {string} partRequestId
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         */
        OutrightPaymentRequest.prototype.partRequestId = "";

        /**
         * Creates a new OutrightPaymentRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {appliancepb.IOutrightPaymentRequest=} [properties] Properties to set
         * @returns {appliancepb.OutrightPaymentRequest} OutrightPaymentRequest instance
         */
        OutrightPaymentRequest.create = function create(properties) {
            return new OutrightPaymentRequest(properties);
        };

        /**
         * Encodes the specified OutrightPaymentRequest message. Does not implicitly {@link appliancepb.OutrightPaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {appliancepb.IOutrightPaymentRequest} message OutrightPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.contract != null && Object.hasOwnProperty.call(message, "contract"))
                $root.appliancepb.Contract.encode(message.contract, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.appliancepb.Customer.encode(message.customer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.vendor != null && Object.hasOwnProperty.call(message, "vendor"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.vendor);
            if (message.paymentDetails != null && Object.hasOwnProperty.call(message, "paymentDetails"))
                $root.appliancepb.PaymentDetails.encode(message.paymentDetails, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.recordId != null && Object.hasOwnProperty.call(message, "recordId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.recordId);
            if (message.modelNumber != null && Object.hasOwnProperty.call(message, "modelNumber"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.modelNumber);
            if (message.orderDate != null && Object.hasOwnProperty.call(message, "orderDate"))
                $root.google.protobuf.Timestamp.encode(message.orderDate, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.paymentType);
            if (message.dispatchId != null && Object.hasOwnProperty.call(message, "dispatchId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.dispatchId);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                $root.appliancepb.Order.encode(message.order, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.cart != null && Object.hasOwnProperty.call(message, "cart"))
                $root.appliancepb.Cart.encode(message.cart, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.partRequestId != null && Object.hasOwnProperty.call(message, "partRequestId"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.partRequestId);
            return writer;
        };

        /**
         * Encodes the specified OutrightPaymentRequest message, length delimited. Does not implicitly {@link appliancepb.OutrightPaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {appliancepb.IOutrightPaymentRequest} message OutrightPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutrightPaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.OutrightPaymentRequest} OutrightPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.OutrightPaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.contract = $root.appliancepb.Contract.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.customer = $root.appliancepb.Customer.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.vendor = reader.string();
                    break;
                case 4:
                    message.paymentDetails = $root.appliancepb.PaymentDetails.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.recordId = reader.string();
                    break;
                case 6:
                    message.modelNumber = reader.string();
                    break;
                case 7:
                    message.orderDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.paymentType = reader.int32();
                    break;
                case 9:
                    message.dispatchId = reader.string();
                    break;
                case 10:
                    message.order = $root.appliancepb.Order.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.cart = $root.appliancepb.Cart.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.partRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutrightPaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.OutrightPaymentRequest} OutrightPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutrightPaymentRequest message.
         * @function verify
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutrightPaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.contract != null && message.hasOwnProperty("contract")) {
                let error = $root.appliancepb.Contract.verify(message.contract);
                if (error)
                    return "contract." + error;
            }
            if (message.customer != null && message.hasOwnProperty("customer")) {
                let error = $root.appliancepb.Customer.verify(message.customer);
                if (error)
                    return "customer." + error;
            }
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                if (!$util.isString(message.vendor))
                    return "vendor: string expected";
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails")) {
                let error = $root.appliancepb.PaymentDetails.verify(message.paymentDetails);
                if (error)
                    return "paymentDetails." + error;
            }
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                if (!$util.isString(message.recordId))
                    return "recordId: string expected";
            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                if (!$util.isString(message.modelNumber))
                    return "modelNumber: string expected";
            if (message.orderDate != null && message.hasOwnProperty("orderDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.orderDate);
                if (error)
                    return "orderDate." + error;
            }
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                switch (message.paymentType) {
                default:
                    return "paymentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                if (!$util.isString(message.dispatchId))
                    return "dispatchId: string expected";
            if (message.order != null && message.hasOwnProperty("order")) {
                let error = $root.appliancepb.Order.verify(message.order);
                if (error)
                    return "order." + error;
            }
            if (message.cart != null && message.hasOwnProperty("cart")) {
                let error = $root.appliancepb.Cart.verify(message.cart);
                if (error)
                    return "cart." + error;
            }
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                if (!$util.isString(message.partRequestId))
                    return "partRequestId: string expected";
            return null;
        };

        /**
         * Creates an OutrightPaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.OutrightPaymentRequest} OutrightPaymentRequest
         */
        OutrightPaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.OutrightPaymentRequest)
                return object;
            let message = new $root.appliancepb.OutrightPaymentRequest();
            if (object.contract != null) {
                if (typeof object.contract !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRequest.contract: object expected");
                message.contract = $root.appliancepb.Contract.fromObject(object.contract);
            }
            if (object.customer != null) {
                if (typeof object.customer !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRequest.customer: object expected");
                message.customer = $root.appliancepb.Customer.fromObject(object.customer);
            }
            if (object.vendor != null)
                message.vendor = String(object.vendor);
            if (object.paymentDetails != null) {
                if (typeof object.paymentDetails !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRequest.paymentDetails: object expected");
                message.paymentDetails = $root.appliancepb.PaymentDetails.fromObject(object.paymentDetails);
            }
            if (object.recordId != null)
                message.recordId = String(object.recordId);
            if (object.modelNumber != null)
                message.modelNumber = String(object.modelNumber);
            if (object.orderDate != null) {
                if (typeof object.orderDate !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRequest.orderDate: object expected");
                message.orderDate = $root.google.protobuf.Timestamp.fromObject(object.orderDate);
            }
            switch (object.paymentType) {
            case "PAYMENT_TYPE_UNSPECIFIED":
            case 0:
                message.paymentType = 0;
                break;
            case "PAYMENT_TYPE_OUTRIGHT_PAYMENT":
            case 1:
                message.paymentType = 1;
                break;
            case "PAYMENT_TYPE_EMPLOYEE_ORDER":
            case 2:
                message.paymentType = 2;
                break;
            case "PAYMENT_TYPE_ACCESSORIES":
            case 3:
                message.paymentType = 3;
                break;
            case "PAYMENT_TYPE_RESTOCKING_FEE":
            case 4:
                message.paymentType = 4;
                break;
            }
            if (object.dispatchId != null)
                message.dispatchId = String(object.dispatchId);
            if (object.order != null) {
                if (typeof object.order !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRequest.order: object expected");
                message.order = $root.appliancepb.Order.fromObject(object.order);
            }
            if (object.cart != null) {
                if (typeof object.cart !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRequest.cart: object expected");
                message.cart = $root.appliancepb.Cart.fromObject(object.cart);
            }
            if (object.partRequestId != null)
                message.partRequestId = String(object.partRequestId);
            return message;
        };

        /**
         * Creates a plain object from an OutrightPaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.OutrightPaymentRequest
         * @static
         * @param {appliancepb.OutrightPaymentRequest} message OutrightPaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutrightPaymentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.contract = null;
                object.customer = null;
                object.vendor = "";
                object.paymentDetails = null;
                object.recordId = "";
                object.modelNumber = "";
                object.orderDate = null;
                object.paymentType = options.enums === String ? "PAYMENT_TYPE_UNSPECIFIED" : 0;
                object.dispatchId = "";
                object.order = null;
                object.cart = null;
                object.partRequestId = "";
            }
            if (message.contract != null && message.hasOwnProperty("contract"))
                object.contract = $root.appliancepb.Contract.toObject(message.contract, options);
            if (message.customer != null && message.hasOwnProperty("customer"))
                object.customer = $root.appliancepb.Customer.toObject(message.customer, options);
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                object.vendor = message.vendor;
            if (message.paymentDetails != null && message.hasOwnProperty("paymentDetails"))
                object.paymentDetails = $root.appliancepb.PaymentDetails.toObject(message.paymentDetails, options);
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                object.recordId = message.recordId;
            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                object.modelNumber = message.modelNumber;
            if (message.orderDate != null && message.hasOwnProperty("orderDate"))
                object.orderDate = $root.google.protobuf.Timestamp.toObject(message.orderDate, options);
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                object.paymentType = options.enums === String ? $root.appliancepb.OutrightPaymentRequest.PaymentType[message.paymentType] : message.paymentType;
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                object.dispatchId = message.dispatchId;
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = $root.appliancepb.Order.toObject(message.order, options);
            if (message.cart != null && message.hasOwnProperty("cart"))
                object.cart = $root.appliancepb.Cart.toObject(message.cart, options);
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                object.partRequestId = message.partRequestId;
            return object;
        };

        /**
         * Converts this OutrightPaymentRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.OutrightPaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutrightPaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * PaymentType enum.
         * @name appliancepb.OutrightPaymentRequest.PaymentType
         * @enum {number}
         * @property {number} PAYMENT_TYPE_UNSPECIFIED=0 PAYMENT_TYPE_UNSPECIFIED value
         * @property {number} PAYMENT_TYPE_OUTRIGHT_PAYMENT=1 PAYMENT_TYPE_OUTRIGHT_PAYMENT value
         * @property {number} PAYMENT_TYPE_EMPLOYEE_ORDER=2 PAYMENT_TYPE_EMPLOYEE_ORDER value
         * @property {number} PAYMENT_TYPE_ACCESSORIES=3 PAYMENT_TYPE_ACCESSORIES value
         * @property {number} PAYMENT_TYPE_RESTOCKING_FEE=4 PAYMENT_TYPE_RESTOCKING_FEE value
         */
        OutrightPaymentRequest.PaymentType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PAYMENT_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "PAYMENT_TYPE_OUTRIGHT_PAYMENT"] = 1;
            values[valuesById[2] = "PAYMENT_TYPE_EMPLOYEE_ORDER"] = 2;
            values[valuesById[3] = "PAYMENT_TYPE_ACCESSORIES"] = 3;
            values[valuesById[4] = "PAYMENT_TYPE_RESTOCKING_FEE"] = 4;
            return values;
        })();

        return OutrightPaymentRequest;
    })();

    appliancepb.OutrightPaymentResponse = (function() {

        /**
         * Properties of an OutrightPaymentResponse.
         * @memberof appliancepb
         * @interface IOutrightPaymentResponse
         * @property {string|null} [outrightPaymentId] OutrightPaymentResponse outrightPaymentId
         */

        /**
         * Constructs a new OutrightPaymentResponse.
         * @memberof appliancepb
         * @classdesc Represents an OutrightPaymentResponse.
         * @implements IOutrightPaymentResponse
         * @constructor
         * @param {appliancepb.IOutrightPaymentResponse=} [properties] Properties to set
         */
        function OutrightPaymentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutrightPaymentResponse outrightPaymentId.
         * @member {string} outrightPaymentId
         * @memberof appliancepb.OutrightPaymentResponse
         * @instance
         */
        OutrightPaymentResponse.prototype.outrightPaymentId = "";

        /**
         * Creates a new OutrightPaymentResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {appliancepb.IOutrightPaymentResponse=} [properties] Properties to set
         * @returns {appliancepb.OutrightPaymentResponse} OutrightPaymentResponse instance
         */
        OutrightPaymentResponse.create = function create(properties) {
            return new OutrightPaymentResponse(properties);
        };

        /**
         * Encodes the specified OutrightPaymentResponse message. Does not implicitly {@link appliancepb.OutrightPaymentResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {appliancepb.IOutrightPaymentResponse} message OutrightPaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outrightPaymentId != null && Object.hasOwnProperty.call(message, "outrightPaymentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.outrightPaymentId);
            return writer;
        };

        /**
         * Encodes the specified OutrightPaymentResponse message, length delimited. Does not implicitly {@link appliancepb.OutrightPaymentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {appliancepb.IOutrightPaymentResponse} message OutrightPaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutrightPaymentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.OutrightPaymentResponse} OutrightPaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.OutrightPaymentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.outrightPaymentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutrightPaymentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.OutrightPaymentResponse} OutrightPaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutrightPaymentResponse message.
         * @function verify
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutrightPaymentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outrightPaymentId != null && message.hasOwnProperty("outrightPaymentId"))
                if (!$util.isString(message.outrightPaymentId))
                    return "outrightPaymentId: string expected";
            return null;
        };

        /**
         * Creates an OutrightPaymentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.OutrightPaymentResponse} OutrightPaymentResponse
         */
        OutrightPaymentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.OutrightPaymentResponse)
                return object;
            let message = new $root.appliancepb.OutrightPaymentResponse();
            if (object.outrightPaymentId != null)
                message.outrightPaymentId = String(object.outrightPaymentId);
            return message;
        };

        /**
         * Creates a plain object from an OutrightPaymentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.OutrightPaymentResponse
         * @static
         * @param {appliancepb.OutrightPaymentResponse} message OutrightPaymentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutrightPaymentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.outrightPaymentId = "";
            if (message.outrightPaymentId != null && message.hasOwnProperty("outrightPaymentId"))
                object.outrightPaymentId = message.outrightPaymentId;
            return object;
        };

        /**
         * Converts this OutrightPaymentResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.OutrightPaymentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutrightPaymentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OutrightPaymentResponse;
    })();

    appliancepb.Agent = (function() {

        /**
         * Properties of an Agent.
         * @memberof appliancepb
         * @interface IAgent
         * @property {string|null} [uniqueName] Agent uniqueName
         */

        /**
         * Constructs a new Agent.
         * @memberof appliancepb
         * @classdesc Represents an Agent.
         * @implements IAgent
         * @constructor
         * @param {appliancepb.IAgent=} [properties] Properties to set
         */
        function Agent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Agent uniqueName.
         * @member {string} uniqueName
         * @memberof appliancepb.Agent
         * @instance
         */
        Agent.prototype.uniqueName = "";

        /**
         * Creates a new Agent instance using the specified properties.
         * @function create
         * @memberof appliancepb.Agent
         * @static
         * @param {appliancepb.IAgent=} [properties] Properties to set
         * @returns {appliancepb.Agent} Agent instance
         */
        Agent.create = function create(properties) {
            return new Agent(properties);
        };

        /**
         * Encodes the specified Agent message. Does not implicitly {@link appliancepb.Agent.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Agent
         * @static
         * @param {appliancepb.IAgent} message Agent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Agent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uniqueName != null && Object.hasOwnProperty.call(message, "uniqueName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uniqueName);
            return writer;
        };

        /**
         * Encodes the specified Agent message, length delimited. Does not implicitly {@link appliancepb.Agent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Agent
         * @static
         * @param {appliancepb.IAgent} message Agent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Agent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Agent message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Agent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Agent} Agent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Agent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Agent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uniqueName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Agent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Agent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Agent} Agent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Agent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Agent message.
         * @function verify
         * @memberof appliancepb.Agent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Agent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uniqueName != null && message.hasOwnProperty("uniqueName"))
                if (!$util.isString(message.uniqueName))
                    return "uniqueName: string expected";
            return null;
        };

        /**
         * Creates an Agent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Agent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Agent} Agent
         */
        Agent.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Agent)
                return object;
            let message = new $root.appliancepb.Agent();
            if (object.uniqueName != null)
                message.uniqueName = String(object.uniqueName);
            return message;
        };

        /**
         * Creates a plain object from an Agent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Agent
         * @static
         * @param {appliancepb.Agent} message Agent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Agent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.uniqueName = "";
            if (message.uniqueName != null && message.hasOwnProperty("uniqueName"))
                object.uniqueName = message.uniqueName;
            return object;
        };

        /**
         * Converts this Agent to JSON.
         * @function toJSON
         * @memberof appliancepb.Agent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Agent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Agent;
    })();

    appliancepb.OutrightPaymentRecord = (function() {

        /**
         * Properties of an OutrightPaymentRecord.
         * @memberof appliancepb
         * @interface IOutrightPaymentRecord
         * @property {string|null} [id] OutrightPaymentRecord id
         * @property {string|null} [contractId] OutrightPaymentRecord contractId
         * @property {string|null} [paymentId] OutrightPaymentRecord paymentId
         * @property {string|null} [companyCode] OutrightPaymentRecord companyCode
         * @property {string|null} [vendor] OutrightPaymentRecord vendor
         * @property {string|null} [modelNumber] OutrightPaymentRecord modelNumber
         * @property {string|null} [billingState] OutrightPaymentRecord billingState
         * @property {number|null} [amount] OutrightPaymentRecord amount
         * @property {google.protobuf.ITimestamp|null} [orderDate] OutrightPaymentRecord orderDate
         * @property {google.protobuf.ITimestamp|null} [created] OutrightPaymentRecord created
         * @property {appliancepb.IAgent|null} [agent] OutrightPaymentRecord agent
         * @property {string|null} [paymentType] OutrightPaymentRecord paymentType
         * @property {string|null} [orderNumber] OutrightPaymentRecord orderNumber
         * @property {string|null} [dispatchId] OutrightPaymentRecord dispatchId
         * @property {appliancepb.ICart|null} [cart] OutrightPaymentRecord cart
         * @property {string|null} [partRequestId] OutrightPaymentRecord partRequestId
         * @property {appliancepb.ICustomer|null} [customer] OutrightPaymentRecord customer
         * @property {string|null} [status] OutrightPaymentRecord status
         * @property {appliancepb.IOrder|null} [order] OutrightPaymentRecord order
         * @property {google.protobuf.ITimestamp|null} [updated] OutrightPaymentRecord updated
         */

        /**
         * Constructs a new OutrightPaymentRecord.
         * @memberof appliancepb
         * @classdesc Represents an OutrightPaymentRecord.
         * @implements IOutrightPaymentRecord
         * @constructor
         * @param {appliancepb.IOutrightPaymentRecord=} [properties] Properties to set
         */
        function OutrightPaymentRecord(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutrightPaymentRecord id.
         * @member {string} id
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.id = "";

        /**
         * OutrightPaymentRecord contractId.
         * @member {string} contractId
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.contractId = "";

        /**
         * OutrightPaymentRecord paymentId.
         * @member {string} paymentId
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.paymentId = "";

        /**
         * OutrightPaymentRecord companyCode.
         * @member {string} companyCode
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.companyCode = "";

        /**
         * OutrightPaymentRecord vendor.
         * @member {string} vendor
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.vendor = "";

        /**
         * OutrightPaymentRecord modelNumber.
         * @member {string} modelNumber
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.modelNumber = "";

        /**
         * OutrightPaymentRecord billingState.
         * @member {string} billingState
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.billingState = "";

        /**
         * OutrightPaymentRecord amount.
         * @member {number} amount
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.amount = 0;

        /**
         * OutrightPaymentRecord orderDate.
         * @member {google.protobuf.ITimestamp|null|undefined} orderDate
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.orderDate = null;

        /**
         * OutrightPaymentRecord created.
         * @member {google.protobuf.ITimestamp|null|undefined} created
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.created = null;

        /**
         * OutrightPaymentRecord agent.
         * @member {appliancepb.IAgent|null|undefined} agent
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.agent = null;

        /**
         * OutrightPaymentRecord paymentType.
         * @member {string} paymentType
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.paymentType = "";

        /**
         * OutrightPaymentRecord orderNumber.
         * @member {string} orderNumber
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.orderNumber = "";

        /**
         * OutrightPaymentRecord dispatchId.
         * @member {string} dispatchId
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.dispatchId = "";

        /**
         * OutrightPaymentRecord cart.
         * @member {appliancepb.ICart|null|undefined} cart
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.cart = null;

        /**
         * OutrightPaymentRecord partRequestId.
         * @member {string} partRequestId
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.partRequestId = "";

        /**
         * OutrightPaymentRecord customer.
         * @member {appliancepb.ICustomer|null|undefined} customer
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.customer = null;

        /**
         * OutrightPaymentRecord status.
         * @member {string} status
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.status = "";

        /**
         * OutrightPaymentRecord order.
         * @member {appliancepb.IOrder|null|undefined} order
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.order = null;

        /**
         * OutrightPaymentRecord updated.
         * @member {google.protobuf.ITimestamp|null|undefined} updated
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         */
        OutrightPaymentRecord.prototype.updated = null;

        /**
         * Creates a new OutrightPaymentRecord instance using the specified properties.
         * @function create
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {appliancepb.IOutrightPaymentRecord=} [properties] Properties to set
         * @returns {appliancepb.OutrightPaymentRecord} OutrightPaymentRecord instance
         */
        OutrightPaymentRecord.create = function create(properties) {
            return new OutrightPaymentRecord(properties);
        };

        /**
         * Encodes the specified OutrightPaymentRecord message. Does not implicitly {@link appliancepb.OutrightPaymentRecord.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {appliancepb.IOutrightPaymentRecord} message OutrightPaymentRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentRecord.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.contractId != null && Object.hasOwnProperty.call(message, "contractId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contractId);
            if (message.paymentId != null && Object.hasOwnProperty.call(message, "paymentId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.paymentId);
            if (message.companyCode != null && Object.hasOwnProperty.call(message, "companyCode"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.companyCode);
            if (message.vendor != null && Object.hasOwnProperty.call(message, "vendor"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.vendor);
            if (message.modelNumber != null && Object.hasOwnProperty.call(message, "modelNumber"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.modelNumber);
            if (message.billingState != null && Object.hasOwnProperty.call(message, "billingState"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.billingState);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.amount);
            if (message.orderDate != null && Object.hasOwnProperty.call(message, "orderDate"))
                $root.google.protobuf.Timestamp.encode(message.orderDate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                $root.google.protobuf.Timestamp.encode(message.created, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.agent != null && Object.hasOwnProperty.call(message, "agent"))
                $root.appliancepb.Agent.encode(message.agent, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.paymentType);
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.orderNumber);
            if (message.dispatchId != null && Object.hasOwnProperty.call(message, "dispatchId"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.dispatchId);
            if (message.cart != null && Object.hasOwnProperty.call(message, "cart"))
                $root.appliancepb.Cart.encode(message.cart, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.partRequestId != null && Object.hasOwnProperty.call(message, "partRequestId"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.partRequestId);
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.appliancepb.Customer.encode(message.customer, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.status);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                $root.appliancepb.Order.encode(message.order, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
                $root.google.protobuf.Timestamp.encode(message.updated, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified OutrightPaymentRecord message, length delimited. Does not implicitly {@link appliancepb.OutrightPaymentRecord.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {appliancepb.IOutrightPaymentRecord} message OutrightPaymentRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentRecord.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutrightPaymentRecord message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.OutrightPaymentRecord} OutrightPaymentRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentRecord.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.OutrightPaymentRecord();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.contractId = reader.string();
                    break;
                case 3:
                    message.paymentId = reader.string();
                    break;
                case 4:
                    message.companyCode = reader.string();
                    break;
                case 5:
                    message.vendor = reader.string();
                    break;
                case 6:
                    message.modelNumber = reader.string();
                    break;
                case 7:
                    message.billingState = reader.string();
                    break;
                case 8:
                    message.amount = reader.int32();
                    break;
                case 9:
                    message.orderDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.created = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.agent = $root.appliancepb.Agent.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.paymentType = reader.string();
                    break;
                case 13:
                    message.orderNumber = reader.string();
                    break;
                case 14:
                    message.dispatchId = reader.string();
                    break;
                case 15:
                    message.cart = $root.appliancepb.Cart.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.partRequestId = reader.string();
                    break;
                case 17:
                    message.customer = $root.appliancepb.Customer.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.status = reader.string();
                    break;
                case 19:
                    message.order = $root.appliancepb.Order.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.updated = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutrightPaymentRecord message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.OutrightPaymentRecord} OutrightPaymentRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentRecord.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutrightPaymentRecord message.
         * @function verify
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutrightPaymentRecord.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.contractId != null && message.hasOwnProperty("contractId"))
                if (!$util.isString(message.contractId))
                    return "contractId: string expected";
            if (message.paymentId != null && message.hasOwnProperty("paymentId"))
                if (!$util.isString(message.paymentId))
                    return "paymentId: string expected";
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                if (!$util.isString(message.companyCode))
                    return "companyCode: string expected";
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                if (!$util.isString(message.vendor))
                    return "vendor: string expected";
            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                if (!$util.isString(message.modelNumber))
                    return "modelNumber: string expected";
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                if (!$util.isString(message.billingState))
                    return "billingState: string expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount))
                    return "amount: integer expected";
            if (message.orderDate != null && message.hasOwnProperty("orderDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.orderDate);
                if (error)
                    return "orderDate." + error;
            }
            if (message.created != null && message.hasOwnProperty("created")) {
                let error = $root.google.protobuf.Timestamp.verify(message.created);
                if (error)
                    return "created." + error;
            }
            if (message.agent != null && message.hasOwnProperty("agent")) {
                let error = $root.appliancepb.Agent.verify(message.agent);
                if (error)
                    return "agent." + error;
            }
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                if (!$util.isString(message.paymentType))
                    return "paymentType: string expected";
            if (message.orderNumber != null && message.hasOwnProperty("orderNumber"))
                if (!$util.isString(message.orderNumber))
                    return "orderNumber: string expected";
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                if (!$util.isString(message.dispatchId))
                    return "dispatchId: string expected";
            if (message.cart != null && message.hasOwnProperty("cart")) {
                let error = $root.appliancepb.Cart.verify(message.cart);
                if (error)
                    return "cart." + error;
            }
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                if (!$util.isString(message.partRequestId))
                    return "partRequestId: string expected";
            if (message.customer != null && message.hasOwnProperty("customer")) {
                let error = $root.appliancepb.Customer.verify(message.customer);
                if (error)
                    return "customer." + error;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.order != null && message.hasOwnProperty("order")) {
                let error = $root.appliancepb.Order.verify(message.order);
                if (error)
                    return "order." + error;
            }
            if (message.updated != null && message.hasOwnProperty("updated")) {
                let error = $root.google.protobuf.Timestamp.verify(message.updated);
                if (error)
                    return "updated." + error;
            }
            return null;
        };

        /**
         * Creates an OutrightPaymentRecord message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.OutrightPaymentRecord} OutrightPaymentRecord
         */
        OutrightPaymentRecord.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.OutrightPaymentRecord)
                return object;
            let message = new $root.appliancepb.OutrightPaymentRecord();
            if (object.id != null)
                message.id = String(object.id);
            if (object.contractId != null)
                message.contractId = String(object.contractId);
            if (object.paymentId != null)
                message.paymentId = String(object.paymentId);
            if (object.companyCode != null)
                message.companyCode = String(object.companyCode);
            if (object.vendor != null)
                message.vendor = String(object.vendor);
            if (object.modelNumber != null)
                message.modelNumber = String(object.modelNumber);
            if (object.billingState != null)
                message.billingState = String(object.billingState);
            if (object.amount != null)
                message.amount = object.amount | 0;
            if (object.orderDate != null) {
                if (typeof object.orderDate !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRecord.orderDate: object expected");
                message.orderDate = $root.google.protobuf.Timestamp.fromObject(object.orderDate);
            }
            if (object.created != null) {
                if (typeof object.created !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRecord.created: object expected");
                message.created = $root.google.protobuf.Timestamp.fromObject(object.created);
            }
            if (object.agent != null) {
                if (typeof object.agent !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRecord.agent: object expected");
                message.agent = $root.appliancepb.Agent.fromObject(object.agent);
            }
            if (object.paymentType != null)
                message.paymentType = String(object.paymentType);
            if (object.orderNumber != null)
                message.orderNumber = String(object.orderNumber);
            if (object.dispatchId != null)
                message.dispatchId = String(object.dispatchId);
            if (object.cart != null) {
                if (typeof object.cart !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRecord.cart: object expected");
                message.cart = $root.appliancepb.Cart.fromObject(object.cart);
            }
            if (object.partRequestId != null)
                message.partRequestId = String(object.partRequestId);
            if (object.customer != null) {
                if (typeof object.customer !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRecord.customer: object expected");
                message.customer = $root.appliancepb.Customer.fromObject(object.customer);
            }
            if (object.status != null)
                message.status = String(object.status);
            if (object.order != null) {
                if (typeof object.order !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRecord.order: object expected");
                message.order = $root.appliancepb.Order.fromObject(object.order);
            }
            if (object.updated != null) {
                if (typeof object.updated !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentRecord.updated: object expected");
                message.updated = $root.google.protobuf.Timestamp.fromObject(object.updated);
            }
            return message;
        };

        /**
         * Creates a plain object from an OutrightPaymentRecord message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.OutrightPaymentRecord
         * @static
         * @param {appliancepb.OutrightPaymentRecord} message OutrightPaymentRecord
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutrightPaymentRecord.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.contractId = "";
                object.paymentId = "";
                object.companyCode = "";
                object.vendor = "";
                object.modelNumber = "";
                object.billingState = "";
                object.amount = 0;
                object.orderDate = null;
                object.created = null;
                object.agent = null;
                object.paymentType = "";
                object.orderNumber = "";
                object.dispatchId = "";
                object.cart = null;
                object.partRequestId = "";
                object.customer = null;
                object.status = "";
                object.order = null;
                object.updated = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.contractId != null && message.hasOwnProperty("contractId"))
                object.contractId = message.contractId;
            if (message.paymentId != null && message.hasOwnProperty("paymentId"))
                object.paymentId = message.paymentId;
            if (message.companyCode != null && message.hasOwnProperty("companyCode"))
                object.companyCode = message.companyCode;
            if (message.vendor != null && message.hasOwnProperty("vendor"))
                object.vendor = message.vendor;
            if (message.modelNumber != null && message.hasOwnProperty("modelNumber"))
                object.modelNumber = message.modelNumber;
            if (message.billingState != null && message.hasOwnProperty("billingState"))
                object.billingState = message.billingState;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = message.amount;
            if (message.orderDate != null && message.hasOwnProperty("orderDate"))
                object.orderDate = $root.google.protobuf.Timestamp.toObject(message.orderDate, options);
            if (message.created != null && message.hasOwnProperty("created"))
                object.created = $root.google.protobuf.Timestamp.toObject(message.created, options);
            if (message.agent != null && message.hasOwnProperty("agent"))
                object.agent = $root.appliancepb.Agent.toObject(message.agent, options);
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                object.paymentType = message.paymentType;
            if (message.orderNumber != null && message.hasOwnProperty("orderNumber"))
                object.orderNumber = message.orderNumber;
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                object.dispatchId = message.dispatchId;
            if (message.cart != null && message.hasOwnProperty("cart"))
                object.cart = $root.appliancepb.Cart.toObject(message.cart, options);
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                object.partRequestId = message.partRequestId;
            if (message.customer != null && message.hasOwnProperty("customer"))
                object.customer = $root.appliancepb.Customer.toObject(message.customer, options);
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = $root.appliancepb.Order.toObject(message.order, options);
            if (message.updated != null && message.hasOwnProperty("updated"))
                object.updated = $root.google.protobuf.Timestamp.toObject(message.updated, options);
            return object;
        };

        /**
         * Converts this OutrightPaymentRecord to JSON.
         * @function toJSON
         * @memberof appliancepb.OutrightPaymentRecord
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutrightPaymentRecord.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OutrightPaymentRecord;
    })();

    appliancepb.GetOutrightPaymentResponse = (function() {

        /**
         * Properties of a GetOutrightPaymentResponse.
         * @memberof appliancepb
         * @interface IGetOutrightPaymentResponse
         * @property {Array.<appliancepb.IOutrightPaymentRecord>|null} [outrightPayments] GetOutrightPaymentResponse outrightPayments
         * @property {appliancepb.IPagination|null} [pagination] GetOutrightPaymentResponse pagination
         */

        /**
         * Constructs a new GetOutrightPaymentResponse.
         * @memberof appliancepb
         * @classdesc Represents a GetOutrightPaymentResponse.
         * @implements IGetOutrightPaymentResponse
         * @constructor
         * @param {appliancepb.IGetOutrightPaymentResponse=} [properties] Properties to set
         */
        function GetOutrightPaymentResponse(properties) {
            this.outrightPayments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOutrightPaymentResponse outrightPayments.
         * @member {Array.<appliancepb.IOutrightPaymentRecord>} outrightPayments
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @instance
         */
        GetOutrightPaymentResponse.prototype.outrightPayments = $util.emptyArray;

        /**
         * GetOutrightPaymentResponse pagination.
         * @member {appliancepb.IPagination|null|undefined} pagination
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @instance
         */
        GetOutrightPaymentResponse.prototype.pagination = null;

        /**
         * Creates a new GetOutrightPaymentResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {appliancepb.IGetOutrightPaymentResponse=} [properties] Properties to set
         * @returns {appliancepb.GetOutrightPaymentResponse} GetOutrightPaymentResponse instance
         */
        GetOutrightPaymentResponse.create = function create(properties) {
            return new GetOutrightPaymentResponse(properties);
        };

        /**
         * Encodes the specified GetOutrightPaymentResponse message. Does not implicitly {@link appliancepb.GetOutrightPaymentResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {appliancepb.IGetOutrightPaymentResponse} message GetOutrightPaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOutrightPaymentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outrightPayments != null && message.outrightPayments.length)
                for (let i = 0; i < message.outrightPayments.length; ++i)
                    $root.appliancepb.OutrightPaymentRecord.encode(message.outrightPayments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.appliancepb.Pagination.encode(message.pagination, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetOutrightPaymentResponse message, length delimited. Does not implicitly {@link appliancepb.GetOutrightPaymentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {appliancepb.IGetOutrightPaymentResponse} message GetOutrightPaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOutrightPaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetOutrightPaymentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.GetOutrightPaymentResponse} GetOutrightPaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOutrightPaymentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.GetOutrightPaymentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.outrightPayments && message.outrightPayments.length))
                        message.outrightPayments = [];
                    message.outrightPayments.push($root.appliancepb.OutrightPaymentRecord.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.pagination = $root.appliancepb.Pagination.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetOutrightPaymentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.GetOutrightPaymentResponse} GetOutrightPaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOutrightPaymentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetOutrightPaymentResponse message.
         * @function verify
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetOutrightPaymentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outrightPayments != null && message.hasOwnProperty("outrightPayments")) {
                if (!Array.isArray(message.outrightPayments))
                    return "outrightPayments: array expected";
                for (let i = 0; i < message.outrightPayments.length; ++i) {
                    let error = $root.appliancepb.OutrightPaymentRecord.verify(message.outrightPayments[i]);
                    if (error)
                        return "outrightPayments." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.appliancepb.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            return null;
        };

        /**
         * Creates a GetOutrightPaymentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.GetOutrightPaymentResponse} GetOutrightPaymentResponse
         */
        GetOutrightPaymentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.GetOutrightPaymentResponse)
                return object;
            let message = new $root.appliancepb.GetOutrightPaymentResponse();
            if (object.outrightPayments) {
                if (!Array.isArray(object.outrightPayments))
                    throw TypeError(".appliancepb.GetOutrightPaymentResponse.outrightPayments: array expected");
                message.outrightPayments = [];
                for (let i = 0; i < object.outrightPayments.length; ++i) {
                    if (typeof object.outrightPayments[i] !== "object")
                        throw TypeError(".appliancepb.GetOutrightPaymentResponse.outrightPayments: object expected");
                    message.outrightPayments[i] = $root.appliancepb.OutrightPaymentRecord.fromObject(object.outrightPayments[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".appliancepb.GetOutrightPaymentResponse.pagination: object expected");
                message.pagination = $root.appliancepb.Pagination.fromObject(object.pagination);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetOutrightPaymentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @static
         * @param {appliancepb.GetOutrightPaymentResponse} message GetOutrightPaymentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetOutrightPaymentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.outrightPayments = [];
            if (options.defaults)
                object.pagination = null;
            if (message.outrightPayments && message.outrightPayments.length) {
                object.outrightPayments = [];
                for (let j = 0; j < message.outrightPayments.length; ++j)
                    object.outrightPayments[j] = $root.appliancepb.OutrightPaymentRecord.toObject(message.outrightPayments[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.appliancepb.Pagination.toObject(message.pagination, options);
            return object;
        };

        /**
         * Converts this GetOutrightPaymentResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.GetOutrightPaymentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetOutrightPaymentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetOutrightPaymentResponse;
    })();

    appliancepb.OutrightPaymentOrderResponse = (function() {

        /**
         * Properties of an OutrightPaymentOrderResponse.
         * @memberof appliancepb
         * @interface IOutrightPaymentOrderResponse
         * @property {appliancepb.IOutrightPaymentRecord|null} [outrightPayment] OutrightPaymentOrderResponse outrightPayment
         * @property {string|null} [errorMsg] OutrightPaymentOrderResponse errorMsg
         */

        /**
         * Constructs a new OutrightPaymentOrderResponse.
         * @memberof appliancepb
         * @classdesc Represents an OutrightPaymentOrderResponse.
         * @implements IOutrightPaymentOrderResponse
         * @constructor
         * @param {appliancepb.IOutrightPaymentOrderResponse=} [properties] Properties to set
         */
        function OutrightPaymentOrderResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutrightPaymentOrderResponse outrightPayment.
         * @member {appliancepb.IOutrightPaymentRecord|null|undefined} outrightPayment
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @instance
         */
        OutrightPaymentOrderResponse.prototype.outrightPayment = null;

        /**
         * OutrightPaymentOrderResponse errorMsg.
         * @member {string} errorMsg
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @instance
         */
        OutrightPaymentOrderResponse.prototype.errorMsg = "";

        /**
         * Creates a new OutrightPaymentOrderResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {appliancepb.IOutrightPaymentOrderResponse=} [properties] Properties to set
         * @returns {appliancepb.OutrightPaymentOrderResponse} OutrightPaymentOrderResponse instance
         */
        OutrightPaymentOrderResponse.create = function create(properties) {
            return new OutrightPaymentOrderResponse(properties);
        };

        /**
         * Encodes the specified OutrightPaymentOrderResponse message. Does not implicitly {@link appliancepb.OutrightPaymentOrderResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {appliancepb.IOutrightPaymentOrderResponse} message OutrightPaymentOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outrightPayment != null && Object.hasOwnProperty.call(message, "outrightPayment"))
                $root.appliancepb.OutrightPaymentRecord.encode(message.outrightPayment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.errorMsg != null && Object.hasOwnProperty.call(message, "errorMsg"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMsg);
            return writer;
        };

        /**
         * Encodes the specified OutrightPaymentOrderResponse message, length delimited. Does not implicitly {@link appliancepb.OutrightPaymentOrderResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {appliancepb.IOutrightPaymentOrderResponse} message OutrightPaymentOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentOrderResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutrightPaymentOrderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.OutrightPaymentOrderResponse} OutrightPaymentOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.OutrightPaymentOrderResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.outrightPayment = $root.appliancepb.OutrightPaymentRecord.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.errorMsg = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutrightPaymentOrderResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.OutrightPaymentOrderResponse} OutrightPaymentOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentOrderResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutrightPaymentOrderResponse message.
         * @function verify
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutrightPaymentOrderResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outrightPayment != null && message.hasOwnProperty("outrightPayment")) {
                let error = $root.appliancepb.OutrightPaymentRecord.verify(message.outrightPayment);
                if (error)
                    return "outrightPayment." + error;
            }
            if (message.errorMsg != null && message.hasOwnProperty("errorMsg"))
                if (!$util.isString(message.errorMsg))
                    return "errorMsg: string expected";
            return null;
        };

        /**
         * Creates an OutrightPaymentOrderResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.OutrightPaymentOrderResponse} OutrightPaymentOrderResponse
         */
        OutrightPaymentOrderResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.OutrightPaymentOrderResponse)
                return object;
            let message = new $root.appliancepb.OutrightPaymentOrderResponse();
            if (object.outrightPayment != null) {
                if (typeof object.outrightPayment !== "object")
                    throw TypeError(".appliancepb.OutrightPaymentOrderResponse.outrightPayment: object expected");
                message.outrightPayment = $root.appliancepb.OutrightPaymentRecord.fromObject(object.outrightPayment);
            }
            if (object.errorMsg != null)
                message.errorMsg = String(object.errorMsg);
            return message;
        };

        /**
         * Creates a plain object from an OutrightPaymentOrderResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @static
         * @param {appliancepb.OutrightPaymentOrderResponse} message OutrightPaymentOrderResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutrightPaymentOrderResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.outrightPayment = null;
                object.errorMsg = "";
            }
            if (message.outrightPayment != null && message.hasOwnProperty("outrightPayment"))
                object.outrightPayment = $root.appliancepb.OutrightPaymentRecord.toObject(message.outrightPayment, options);
            if (message.errorMsg != null && message.hasOwnProperty("errorMsg"))
                object.errorMsg = message.errorMsg;
            return object;
        };

        /**
         * Converts this OutrightPaymentOrderResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.OutrightPaymentOrderResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutrightPaymentOrderResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OutrightPaymentOrderResponse;
    })();

    /**
     * OutrightPaymentStatus enum.
     * @name appliancepb.OutrightPaymentStatus
     * @enum {number}
     * @property {number} OUTRIGHT_PAYMENT_STATUS_UNSPECIFIED=0 OUTRIGHT_PAYMENT_STATUS_UNSPECIFIED value
     * @property {number} OUTRIGHT_PAYMENT_STATUS_PURCHASED=1 OUTRIGHT_PAYMENT_STATUS_PURCHASED value
     * @property {number} OUTRIGHT_PAYMENT_STATUS_ORDERED=2 OUTRIGHT_PAYMENT_STATUS_ORDERED value
     * @property {number} OUTRIGHT_PAYMENT_STATUS_ORDER_ERROR=3 OUTRIGHT_PAYMENT_STATUS_ORDER_ERROR value
     * @property {number} OUTRIGHT_PAYMENT_STATUS_ORDER_CANCELLED=4 OUTRIGHT_PAYMENT_STATUS_ORDER_CANCELLED value
     * @property {number} OUTRIGHT_PAYMENT_STATUS_DELIVERED=5 OUTRIGHT_PAYMENT_STATUS_DELIVERED value
     */
    appliancepb.OutrightPaymentStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OUTRIGHT_PAYMENT_STATUS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "OUTRIGHT_PAYMENT_STATUS_PURCHASED"] = 1;
        values[valuesById[2] = "OUTRIGHT_PAYMENT_STATUS_ORDERED"] = 2;
        values[valuesById[3] = "OUTRIGHT_PAYMENT_STATUS_ORDER_ERROR"] = 3;
        values[valuesById[4] = "OUTRIGHT_PAYMENT_STATUS_ORDER_CANCELLED"] = 4;
        values[valuesById[5] = "OUTRIGHT_PAYMENT_STATUS_DELIVERED"] = 5;
        return values;
    })();

    appliancepb.OutrightPaymentPatchRequest = (function() {

        /**
         * Properties of an OutrightPaymentPatchRequest.
         * @memberof appliancepb
         * @interface IOutrightPaymentPatchRequest
         * @property {appliancepb.OutrightPaymentStatus|null} [status] OutrightPaymentPatchRequest status
         * @property {string|null} [vendorOrderNumber] OutrightPaymentPatchRequest vendorOrderNumber
         */

        /**
         * Constructs a new OutrightPaymentPatchRequest.
         * @memberof appliancepb
         * @classdesc Represents an OutrightPaymentPatchRequest.
         * @implements IOutrightPaymentPatchRequest
         * @constructor
         * @param {appliancepb.IOutrightPaymentPatchRequest=} [properties] Properties to set
         */
        function OutrightPaymentPatchRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutrightPaymentPatchRequest status.
         * @member {appliancepb.OutrightPaymentStatus} status
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @instance
         */
        OutrightPaymentPatchRequest.prototype.status = 0;

        /**
         * OutrightPaymentPatchRequest vendorOrderNumber.
         * @member {string} vendorOrderNumber
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @instance
         */
        OutrightPaymentPatchRequest.prototype.vendorOrderNumber = "";

        /**
         * Creates a new OutrightPaymentPatchRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {appliancepb.IOutrightPaymentPatchRequest=} [properties] Properties to set
         * @returns {appliancepb.OutrightPaymentPatchRequest} OutrightPaymentPatchRequest instance
         */
        OutrightPaymentPatchRequest.create = function create(properties) {
            return new OutrightPaymentPatchRequest(properties);
        };

        /**
         * Encodes the specified OutrightPaymentPatchRequest message. Does not implicitly {@link appliancepb.OutrightPaymentPatchRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {appliancepb.IOutrightPaymentPatchRequest} message OutrightPaymentPatchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentPatchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.vendorOrderNumber != null && Object.hasOwnProperty.call(message, "vendorOrderNumber"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.vendorOrderNumber);
            return writer;
        };

        /**
         * Encodes the specified OutrightPaymentPatchRequest message, length delimited. Does not implicitly {@link appliancepb.OutrightPaymentPatchRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {appliancepb.IOutrightPaymentPatchRequest} message OutrightPaymentPatchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutrightPaymentPatchRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutrightPaymentPatchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.OutrightPaymentPatchRequest} OutrightPaymentPatchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentPatchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.OutrightPaymentPatchRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.vendorOrderNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutrightPaymentPatchRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.OutrightPaymentPatchRequest} OutrightPaymentPatchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutrightPaymentPatchRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutrightPaymentPatchRequest message.
         * @function verify
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutrightPaymentPatchRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                if (!$util.isString(message.vendorOrderNumber))
                    return "vendorOrderNumber: string expected";
            return null;
        };

        /**
         * Creates an OutrightPaymentPatchRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.OutrightPaymentPatchRequest} OutrightPaymentPatchRequest
         */
        OutrightPaymentPatchRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.OutrightPaymentPatchRequest)
                return object;
            let message = new $root.appliancepb.OutrightPaymentPatchRequest();
            switch (object.status) {
            case "OUTRIGHT_PAYMENT_STATUS_UNSPECIFIED":
            case 0:
                message.status = 0;
                break;
            case "OUTRIGHT_PAYMENT_STATUS_PURCHASED":
            case 1:
                message.status = 1;
                break;
            case "OUTRIGHT_PAYMENT_STATUS_ORDERED":
            case 2:
                message.status = 2;
                break;
            case "OUTRIGHT_PAYMENT_STATUS_ORDER_ERROR":
            case 3:
                message.status = 3;
                break;
            case "OUTRIGHT_PAYMENT_STATUS_ORDER_CANCELLED":
            case 4:
                message.status = 4;
                break;
            case "OUTRIGHT_PAYMENT_STATUS_DELIVERED":
            case 5:
                message.status = 5;
                break;
            }
            if (object.vendorOrderNumber != null)
                message.vendorOrderNumber = String(object.vendorOrderNumber);
            return message;
        };

        /**
         * Creates a plain object from an OutrightPaymentPatchRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @static
         * @param {appliancepb.OutrightPaymentPatchRequest} message OutrightPaymentPatchRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutrightPaymentPatchRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "OUTRIGHT_PAYMENT_STATUS_UNSPECIFIED" : 0;
                object.vendorOrderNumber = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.appliancepb.OutrightPaymentStatus[message.status] : message.status;
            if (message.vendorOrderNumber != null && message.hasOwnProperty("vendorOrderNumber"))
                object.vendorOrderNumber = message.vendorOrderNumber;
            return object;
        };

        /**
         * Converts this OutrightPaymentPatchRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.OutrightPaymentPatchRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutrightPaymentPatchRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OutrightPaymentPatchRequest;
    })();

    appliancepb.Banner = (function() {

        /**
         * Properties of a Banner.
         * @memberof appliancepb
         * @interface IBanner
         * @property {string|null} [id] Banner id
         * @property {string|null} [message] Banner message
         * @property {appliancepb.Banner.Severity|null} [severity] Banner severity
         * @property {google.protobuf.ITimestamp|null} [activate] Banner activate
         * @property {google.protobuf.ITimestamp|null} [expire] Banner expire
         */

        /**
         * Constructs a new Banner.
         * @memberof appliancepb
         * @classdesc Represents a Banner.
         * @implements IBanner
         * @constructor
         * @param {appliancepb.IBanner=} [properties] Properties to set
         */
        function Banner(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Banner id.
         * @member {string} id
         * @memberof appliancepb.Banner
         * @instance
         */
        Banner.prototype.id = "";

        /**
         * Banner message.
         * @member {string} message
         * @memberof appliancepb.Banner
         * @instance
         */
        Banner.prototype.message = "";

        /**
         * Banner severity.
         * @member {appliancepb.Banner.Severity} severity
         * @memberof appliancepb.Banner
         * @instance
         */
        Banner.prototype.severity = 0;

        /**
         * Banner activate.
         * @member {google.protobuf.ITimestamp|null|undefined} activate
         * @memberof appliancepb.Banner
         * @instance
         */
        Banner.prototype.activate = null;

        /**
         * Banner expire.
         * @member {google.protobuf.ITimestamp|null|undefined} expire
         * @memberof appliancepb.Banner
         * @instance
         */
        Banner.prototype.expire = null;

        /**
         * Creates a new Banner instance using the specified properties.
         * @function create
         * @memberof appliancepb.Banner
         * @static
         * @param {appliancepb.IBanner=} [properties] Properties to set
         * @returns {appliancepb.Banner} Banner instance
         */
        Banner.create = function create(properties) {
            return new Banner(properties);
        };

        /**
         * Encodes the specified Banner message. Does not implicitly {@link appliancepb.Banner.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.Banner
         * @static
         * @param {appliancepb.IBanner} message Banner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Banner.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.severity != null && Object.hasOwnProperty.call(message, "severity"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.severity);
            if (message.activate != null && Object.hasOwnProperty.call(message, "activate"))
                $root.google.protobuf.Timestamp.encode(message.activate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.expire != null && Object.hasOwnProperty.call(message, "expire"))
                $root.google.protobuf.Timestamp.encode(message.expire, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Banner message, length delimited. Does not implicitly {@link appliancepb.Banner.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.Banner
         * @static
         * @param {appliancepb.IBanner} message Banner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Banner.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Banner message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.Banner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.Banner} Banner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Banner.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.Banner();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.severity = reader.int32();
                    break;
                case 4:
                    message.activate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.expire = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Banner message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.Banner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.Banner} Banner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Banner.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Banner message.
         * @function verify
         * @memberof appliancepb.Banner
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Banner.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.severity != null && message.hasOwnProperty("severity"))
                switch (message.severity) {
                default:
                    return "severity: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.activate != null && message.hasOwnProperty("activate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.activate);
                if (error)
                    return "activate." + error;
            }
            if (message.expire != null && message.hasOwnProperty("expire")) {
                let error = $root.google.protobuf.Timestamp.verify(message.expire);
                if (error)
                    return "expire." + error;
            }
            return null;
        };

        /**
         * Creates a Banner message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.Banner
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.Banner} Banner
         */
        Banner.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.Banner)
                return object;
            let message = new $root.appliancepb.Banner();
            if (object.id != null)
                message.id = String(object.id);
            if (object.message != null)
                message.message = String(object.message);
            switch (object.severity) {
            case "SEVERITY_UNSPECIFIED":
            case 0:
                message.severity = 0;
                break;
            case "SEVERITY_INFO":
            case 1:
                message.severity = 1;
                break;
            case "SEVERITY_CAUTION":
            case 2:
                message.severity = 2;
                break;
            case "SEVERITY_WARNING":
            case 3:
                message.severity = 3;
                break;
            }
            if (object.activate != null) {
                if (typeof object.activate !== "object")
                    throw TypeError(".appliancepb.Banner.activate: object expected");
                message.activate = $root.google.protobuf.Timestamp.fromObject(object.activate);
            }
            if (object.expire != null) {
                if (typeof object.expire !== "object")
                    throw TypeError(".appliancepb.Banner.expire: object expected");
                message.expire = $root.google.protobuf.Timestamp.fromObject(object.expire);
            }
            return message;
        };

        /**
         * Creates a plain object from a Banner message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.Banner
         * @static
         * @param {appliancepb.Banner} message Banner
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Banner.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.message = "";
                object.severity = options.enums === String ? "SEVERITY_UNSPECIFIED" : 0;
                object.activate = null;
                object.expire = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.severity != null && message.hasOwnProperty("severity"))
                object.severity = options.enums === String ? $root.appliancepb.Banner.Severity[message.severity] : message.severity;
            if (message.activate != null && message.hasOwnProperty("activate"))
                object.activate = $root.google.protobuf.Timestamp.toObject(message.activate, options);
            if (message.expire != null && message.hasOwnProperty("expire"))
                object.expire = $root.google.protobuf.Timestamp.toObject(message.expire, options);
            return object;
        };

        /**
         * Converts this Banner to JSON.
         * @function toJSON
         * @memberof appliancepb.Banner
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Banner.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Severity enum.
         * @name appliancepb.Banner.Severity
         * @enum {number}
         * @property {number} SEVERITY_UNSPECIFIED=0 SEVERITY_UNSPECIFIED value
         * @property {number} SEVERITY_INFO=1 SEVERITY_INFO value
         * @property {number} SEVERITY_CAUTION=2 SEVERITY_CAUTION value
         * @property {number} SEVERITY_WARNING=3 SEVERITY_WARNING value
         */
        Banner.Severity = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SEVERITY_UNSPECIFIED"] = 0;
            values[valuesById[1] = "SEVERITY_INFO"] = 1;
            values[valuesById[2] = "SEVERITY_CAUTION"] = 2;
            values[valuesById[3] = "SEVERITY_WARNING"] = 3;
            return values;
        })();

        return Banner;
    })();

    appliancepb.GetBannerResponse = (function() {

        /**
         * Properties of a GetBannerResponse.
         * @memberof appliancepb
         * @interface IGetBannerResponse
         * @property {Array.<appliancepb.IBanner>|null} [banners] GetBannerResponse banners
         */

        /**
         * Constructs a new GetBannerResponse.
         * @memberof appliancepb
         * @classdesc Represents a GetBannerResponse.
         * @implements IGetBannerResponse
         * @constructor
         * @param {appliancepb.IGetBannerResponse=} [properties] Properties to set
         */
        function GetBannerResponse(properties) {
            this.banners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBannerResponse banners.
         * @member {Array.<appliancepb.IBanner>} banners
         * @memberof appliancepb.GetBannerResponse
         * @instance
         */
        GetBannerResponse.prototype.banners = $util.emptyArray;

        /**
         * Creates a new GetBannerResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {appliancepb.IGetBannerResponse=} [properties] Properties to set
         * @returns {appliancepb.GetBannerResponse} GetBannerResponse instance
         */
        GetBannerResponse.create = function create(properties) {
            return new GetBannerResponse(properties);
        };

        /**
         * Encodes the specified GetBannerResponse message. Does not implicitly {@link appliancepb.GetBannerResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {appliancepb.IGetBannerResponse} message GetBannerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBannerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.banners != null && message.banners.length)
                for (let i = 0; i < message.banners.length; ++i)
                    $root.appliancepb.Banner.encode(message.banners[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetBannerResponse message, length delimited. Does not implicitly {@link appliancepb.GetBannerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {appliancepb.IGetBannerResponse} message GetBannerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBannerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBannerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.GetBannerResponse} GetBannerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBannerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.GetBannerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.banners && message.banners.length))
                        message.banners = [];
                    message.banners.push($root.appliancepb.Banner.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBannerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.GetBannerResponse} GetBannerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBannerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBannerResponse message.
         * @function verify
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBannerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.banners != null && message.hasOwnProperty("banners")) {
                if (!Array.isArray(message.banners))
                    return "banners: array expected";
                for (let i = 0; i < message.banners.length; ++i) {
                    let error = $root.appliancepb.Banner.verify(message.banners[i]);
                    if (error)
                        return "banners." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetBannerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.GetBannerResponse} GetBannerResponse
         */
        GetBannerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.GetBannerResponse)
                return object;
            let message = new $root.appliancepb.GetBannerResponse();
            if (object.banners) {
                if (!Array.isArray(object.banners))
                    throw TypeError(".appliancepb.GetBannerResponse.banners: array expected");
                message.banners = [];
                for (let i = 0; i < object.banners.length; ++i) {
                    if (typeof object.banners[i] !== "object")
                        throw TypeError(".appliancepb.GetBannerResponse.banners: object expected");
                    message.banners[i] = $root.appliancepb.Banner.fromObject(object.banners[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetBannerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.GetBannerResponse
         * @static
         * @param {appliancepb.GetBannerResponse} message GetBannerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBannerResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.banners = [];
            if (message.banners && message.banners.length) {
                object.banners = [];
                for (let j = 0; j < message.banners.length; ++j)
                    object.banners[j] = $root.appliancepb.Banner.toObject(message.banners[j], options);
            }
            return object;
        };

        /**
         * Converts this GetBannerResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.GetBannerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBannerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBannerResponse;
    })();

    appliancepb.UpdateBannersRequest = (function() {

        /**
         * Properties of an UpdateBannersRequest.
         * @memberof appliancepb
         * @interface IUpdateBannersRequest
         * @property {Array.<appliancepb.IBanner>|null} [banners] UpdateBannersRequest banners
         */

        /**
         * Constructs a new UpdateBannersRequest.
         * @memberof appliancepb
         * @classdesc Represents an UpdateBannersRequest.
         * @implements IUpdateBannersRequest
         * @constructor
         * @param {appliancepb.IUpdateBannersRequest=} [properties] Properties to set
         */
        function UpdateBannersRequest(properties) {
            this.banners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateBannersRequest banners.
         * @member {Array.<appliancepb.IBanner>} banners
         * @memberof appliancepb.UpdateBannersRequest
         * @instance
         */
        UpdateBannersRequest.prototype.banners = $util.emptyArray;

        /**
         * Creates a new UpdateBannersRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {appliancepb.IUpdateBannersRequest=} [properties] Properties to set
         * @returns {appliancepb.UpdateBannersRequest} UpdateBannersRequest instance
         */
        UpdateBannersRequest.create = function create(properties) {
            return new UpdateBannersRequest(properties);
        };

        /**
         * Encodes the specified UpdateBannersRequest message. Does not implicitly {@link appliancepb.UpdateBannersRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {appliancepb.IUpdateBannersRequest} message UpdateBannersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateBannersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.banners != null && message.banners.length)
                for (let i = 0; i < message.banners.length; ++i)
                    $root.appliancepb.Banner.encode(message.banners[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateBannersRequest message, length delimited. Does not implicitly {@link appliancepb.UpdateBannersRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {appliancepb.IUpdateBannersRequest} message UpdateBannersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateBannersRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateBannersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.UpdateBannersRequest} UpdateBannersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateBannersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.UpdateBannersRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.banners && message.banners.length))
                        message.banners = [];
                    message.banners.push($root.appliancepb.Banner.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateBannersRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.UpdateBannersRequest} UpdateBannersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateBannersRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateBannersRequest message.
         * @function verify
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateBannersRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.banners != null && message.hasOwnProperty("banners")) {
                if (!Array.isArray(message.banners))
                    return "banners: array expected";
                for (let i = 0; i < message.banners.length; ++i) {
                    let error = $root.appliancepb.Banner.verify(message.banners[i]);
                    if (error)
                        return "banners." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpdateBannersRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.UpdateBannersRequest} UpdateBannersRequest
         */
        UpdateBannersRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.UpdateBannersRequest)
                return object;
            let message = new $root.appliancepb.UpdateBannersRequest();
            if (object.banners) {
                if (!Array.isArray(object.banners))
                    throw TypeError(".appliancepb.UpdateBannersRequest.banners: array expected");
                message.banners = [];
                for (let i = 0; i < object.banners.length; ++i) {
                    if (typeof object.banners[i] !== "object")
                        throw TypeError(".appliancepb.UpdateBannersRequest.banners: object expected");
                    message.banners[i] = $root.appliancepb.Banner.fromObject(object.banners[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateBannersRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.UpdateBannersRequest
         * @static
         * @param {appliancepb.UpdateBannersRequest} message UpdateBannersRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateBannersRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.banners = [];
            if (message.banners && message.banners.length) {
                object.banners = [];
                for (let j = 0; j < message.banners.length; ++j)
                    object.banners[j] = $root.appliancepb.Banner.toObject(message.banners[j], options);
            }
            return object;
        };

        /**
         * Converts this UpdateBannersRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.UpdateBannersRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateBannersRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateBannersRequest;
    })();

    appliancepb.UpdateBannersResponse = (function() {

        /**
         * Properties of an UpdateBannersResponse.
         * @memberof appliancepb
         * @interface IUpdateBannersResponse
         * @property {Array.<appliancepb.IBanner>|null} [failedBanners] UpdateBannersResponse failedBanners
         * @property {string|null} [failureMessage] UpdateBannersResponse failureMessage
         */

        /**
         * Constructs a new UpdateBannersResponse.
         * @memberof appliancepb
         * @classdesc Represents an UpdateBannersResponse.
         * @implements IUpdateBannersResponse
         * @constructor
         * @param {appliancepb.IUpdateBannersResponse=} [properties] Properties to set
         */
        function UpdateBannersResponse(properties) {
            this.failedBanners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateBannersResponse failedBanners.
         * @member {Array.<appliancepb.IBanner>} failedBanners
         * @memberof appliancepb.UpdateBannersResponse
         * @instance
         */
        UpdateBannersResponse.prototype.failedBanners = $util.emptyArray;

        /**
         * UpdateBannersResponse failureMessage.
         * @member {string} failureMessage
         * @memberof appliancepb.UpdateBannersResponse
         * @instance
         */
        UpdateBannersResponse.prototype.failureMessage = "";

        /**
         * Creates a new UpdateBannersResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {appliancepb.IUpdateBannersResponse=} [properties] Properties to set
         * @returns {appliancepb.UpdateBannersResponse} UpdateBannersResponse instance
         */
        UpdateBannersResponse.create = function create(properties) {
            return new UpdateBannersResponse(properties);
        };

        /**
         * Encodes the specified UpdateBannersResponse message. Does not implicitly {@link appliancepb.UpdateBannersResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {appliancepb.IUpdateBannersResponse} message UpdateBannersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateBannersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.failedBanners != null && message.failedBanners.length)
                for (let i = 0; i < message.failedBanners.length; ++i)
                    $root.appliancepb.Banner.encode(message.failedBanners[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.failureMessage != null && Object.hasOwnProperty.call(message, "failureMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.failureMessage);
            return writer;
        };

        /**
         * Encodes the specified UpdateBannersResponse message, length delimited. Does not implicitly {@link appliancepb.UpdateBannersResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {appliancepb.IUpdateBannersResponse} message UpdateBannersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateBannersResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateBannersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.UpdateBannersResponse} UpdateBannersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateBannersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.UpdateBannersResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.failedBanners && message.failedBanners.length))
                        message.failedBanners = [];
                    message.failedBanners.push($root.appliancepb.Banner.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.failureMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateBannersResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.UpdateBannersResponse} UpdateBannersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateBannersResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateBannersResponse message.
         * @function verify
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateBannersResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.failedBanners != null && message.hasOwnProperty("failedBanners")) {
                if (!Array.isArray(message.failedBanners))
                    return "failedBanners: array expected";
                for (let i = 0; i < message.failedBanners.length; ++i) {
                    let error = $root.appliancepb.Banner.verify(message.failedBanners[i]);
                    if (error)
                        return "failedBanners." + error;
                }
            }
            if (message.failureMessage != null && message.hasOwnProperty("failureMessage"))
                if (!$util.isString(message.failureMessage))
                    return "failureMessage: string expected";
            return null;
        };

        /**
         * Creates an UpdateBannersResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.UpdateBannersResponse} UpdateBannersResponse
         */
        UpdateBannersResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.UpdateBannersResponse)
                return object;
            let message = new $root.appliancepb.UpdateBannersResponse();
            if (object.failedBanners) {
                if (!Array.isArray(object.failedBanners))
                    throw TypeError(".appliancepb.UpdateBannersResponse.failedBanners: array expected");
                message.failedBanners = [];
                for (let i = 0; i < object.failedBanners.length; ++i) {
                    if (typeof object.failedBanners[i] !== "object")
                        throw TypeError(".appliancepb.UpdateBannersResponse.failedBanners: object expected");
                    message.failedBanners[i] = $root.appliancepb.Banner.fromObject(object.failedBanners[i]);
                }
            }
            if (object.failureMessage != null)
                message.failureMessage = String(object.failureMessage);
            return message;
        };

        /**
         * Creates a plain object from an UpdateBannersResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.UpdateBannersResponse
         * @static
         * @param {appliancepb.UpdateBannersResponse} message UpdateBannersResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateBannersResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.failedBanners = [];
            if (options.defaults)
                object.failureMessage = "";
            if (message.failedBanners && message.failedBanners.length) {
                object.failedBanners = [];
                for (let j = 0; j < message.failedBanners.length; ++j)
                    object.failedBanners[j] = $root.appliancepb.Banner.toObject(message.failedBanners[j], options);
            }
            if (message.failureMessage != null && message.hasOwnProperty("failureMessage"))
                object.failureMessage = message.failureMessage;
            return object;
        };

        /**
         * Converts this UpdateBannersResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.UpdateBannersResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateBannersResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateBannersResponse;
    })();

    appliancepb.ReplacementStatus = (function() {

        /**
         * Properties of a ReplacementStatus.
         * @memberof appliancepb
         * @interface IReplacementStatus
         * @property {string|null} [status] ReplacementStatus status
         * @property {string|null} [meaning] ReplacementStatus meaning
         */

        /**
         * Constructs a new ReplacementStatus.
         * @memberof appliancepb
         * @classdesc Represents a ReplacementStatus.
         * @implements IReplacementStatus
         * @constructor
         * @param {appliancepb.IReplacementStatus=} [properties] Properties to set
         */
        function ReplacementStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReplacementStatus status.
         * @member {string} status
         * @memberof appliancepb.ReplacementStatus
         * @instance
         */
        ReplacementStatus.prototype.status = "";

        /**
         * ReplacementStatus meaning.
         * @member {string} meaning
         * @memberof appliancepb.ReplacementStatus
         * @instance
         */
        ReplacementStatus.prototype.meaning = "";

        /**
         * Creates a new ReplacementStatus instance using the specified properties.
         * @function create
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {appliancepb.IReplacementStatus=} [properties] Properties to set
         * @returns {appliancepb.ReplacementStatus} ReplacementStatus instance
         */
        ReplacementStatus.create = function create(properties) {
            return new ReplacementStatus(properties);
        };

        /**
         * Encodes the specified ReplacementStatus message. Does not implicitly {@link appliancepb.ReplacementStatus.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {appliancepb.IReplacementStatus} message ReplacementStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.meaning != null && Object.hasOwnProperty.call(message, "meaning"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.meaning);
            return writer;
        };

        /**
         * Encodes the specified ReplacementStatus message, length delimited. Does not implicitly {@link appliancepb.ReplacementStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {appliancepb.IReplacementStatus} message ReplacementStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReplacementStatus message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ReplacementStatus} ReplacementStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.string();
                    break;
                case 2:
                    message.meaning = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReplacementStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ReplacementStatus} ReplacementStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReplacementStatus message.
         * @function verify
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReplacementStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.meaning != null && message.hasOwnProperty("meaning"))
                if (!$util.isString(message.meaning))
                    return "meaning: string expected";
            return null;
        };

        /**
         * Creates a ReplacementStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ReplacementStatus} ReplacementStatus
         */
        ReplacementStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ReplacementStatus)
                return object;
            let message = new $root.appliancepb.ReplacementStatus();
            if (object.status != null)
                message.status = String(object.status);
            if (object.meaning != null)
                message.meaning = String(object.meaning);
            return message;
        };

        /**
         * Creates a plain object from a ReplacementStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ReplacementStatus
         * @static
         * @param {appliancepb.ReplacementStatus} message ReplacementStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReplacementStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = "";
                object.meaning = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.meaning != null && message.hasOwnProperty("meaning"))
                object.meaning = message.meaning;
            return object;
        };

        /**
         * Converts this ReplacementStatus to JSON.
         * @function toJSON
         * @memberof appliancepb.ReplacementStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReplacementStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReplacementStatus;
    })();

    appliancepb.GetReplacementStatusesResponse = (function() {

        /**
         * Properties of a GetReplacementStatusesResponse.
         * @memberof appliancepb
         * @interface IGetReplacementStatusesResponse
         * @property {Array.<appliancepb.IReplacementStatus>|null} [statuses] GetReplacementStatusesResponse statuses
         */

        /**
         * Constructs a new GetReplacementStatusesResponse.
         * @memberof appliancepb
         * @classdesc Represents a GetReplacementStatusesResponse.
         * @implements IGetReplacementStatusesResponse
         * @constructor
         * @param {appliancepb.IGetReplacementStatusesResponse=} [properties] Properties to set
         */
        function GetReplacementStatusesResponse(properties) {
            this.statuses = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetReplacementStatusesResponse statuses.
         * @member {Array.<appliancepb.IReplacementStatus>} statuses
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @instance
         */
        GetReplacementStatusesResponse.prototype.statuses = $util.emptyArray;

        /**
         * Creates a new GetReplacementStatusesResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {appliancepb.IGetReplacementStatusesResponse=} [properties] Properties to set
         * @returns {appliancepb.GetReplacementStatusesResponse} GetReplacementStatusesResponse instance
         */
        GetReplacementStatusesResponse.create = function create(properties) {
            return new GetReplacementStatusesResponse(properties);
        };

        /**
         * Encodes the specified GetReplacementStatusesResponse message. Does not implicitly {@link appliancepb.GetReplacementStatusesResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {appliancepb.IGetReplacementStatusesResponse} message GetReplacementStatusesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetReplacementStatusesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.statuses != null && message.statuses.length)
                for (let i = 0; i < message.statuses.length; ++i)
                    $root.appliancepb.ReplacementStatus.encode(message.statuses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetReplacementStatusesResponse message, length delimited. Does not implicitly {@link appliancepb.GetReplacementStatusesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {appliancepb.IGetReplacementStatusesResponse} message GetReplacementStatusesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetReplacementStatusesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetReplacementStatusesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.GetReplacementStatusesResponse} GetReplacementStatusesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetReplacementStatusesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.GetReplacementStatusesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.statuses && message.statuses.length))
                        message.statuses = [];
                    message.statuses.push($root.appliancepb.ReplacementStatus.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetReplacementStatusesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.GetReplacementStatusesResponse} GetReplacementStatusesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetReplacementStatusesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetReplacementStatusesResponse message.
         * @function verify
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetReplacementStatusesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.statuses != null && message.hasOwnProperty("statuses")) {
                if (!Array.isArray(message.statuses))
                    return "statuses: array expected";
                for (let i = 0; i < message.statuses.length; ++i) {
                    let error = $root.appliancepb.ReplacementStatus.verify(message.statuses[i]);
                    if (error)
                        return "statuses." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetReplacementStatusesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.GetReplacementStatusesResponse} GetReplacementStatusesResponse
         */
        GetReplacementStatusesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.GetReplacementStatusesResponse)
                return object;
            let message = new $root.appliancepb.GetReplacementStatusesResponse();
            if (object.statuses) {
                if (!Array.isArray(object.statuses))
                    throw TypeError(".appliancepb.GetReplacementStatusesResponse.statuses: array expected");
                message.statuses = [];
                for (let i = 0; i < object.statuses.length; ++i) {
                    if (typeof object.statuses[i] !== "object")
                        throw TypeError(".appliancepb.GetReplacementStatusesResponse.statuses: object expected");
                    message.statuses[i] = $root.appliancepb.ReplacementStatus.fromObject(object.statuses[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetReplacementStatusesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @static
         * @param {appliancepb.GetReplacementStatusesResponse} message GetReplacementStatusesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetReplacementStatusesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.statuses = [];
            if (message.statuses && message.statuses.length) {
                object.statuses = [];
                for (let j = 0; j < message.statuses.length; ++j)
                    object.statuses[j] = $root.appliancepb.ReplacementStatus.toObject(message.statuses[j], options);
            }
            return object;
        };

        /**
         * Converts this GetReplacementStatusesResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.GetReplacementStatusesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetReplacementStatusesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetReplacementStatusesResponse;
    })();

    appliancepb.ProductAccessory = (function() {

        /**
         * Properties of a ProductAccessory.
         * @memberof appliancepb
         * @interface IProductAccessory
         * @property {appliancepb.ProductAccessory.ProductCategory|null} [productCategory] ProductAccessory productCategory
         * @property {appliancepb.ProductAccessory.ProductSubcategory|null} [productSubcategory] ProductAccessory productSubcategory
         * @property {appliancepb.IDimensions|null} [dimensions] ProductAccessory dimensions
         */

        /**
         * Constructs a new ProductAccessory.
         * @memberof appliancepb
         * @classdesc Represents a ProductAccessory.
         * @implements IProductAccessory
         * @constructor
         * @param {appliancepb.IProductAccessory=} [properties] Properties to set
         */
        function ProductAccessory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductAccessory productCategory.
         * @member {appliancepb.ProductAccessory.ProductCategory} productCategory
         * @memberof appliancepb.ProductAccessory
         * @instance
         */
        ProductAccessory.prototype.productCategory = 0;

        /**
         * ProductAccessory productSubcategory.
         * @member {appliancepb.ProductAccessory.ProductSubcategory} productSubcategory
         * @memberof appliancepb.ProductAccessory
         * @instance
         */
        ProductAccessory.prototype.productSubcategory = 0;

        /**
         * ProductAccessory dimensions.
         * @member {appliancepb.IDimensions|null|undefined} dimensions
         * @memberof appliancepb.ProductAccessory
         * @instance
         */
        ProductAccessory.prototype.dimensions = null;

        /**
         * Creates a new ProductAccessory instance using the specified properties.
         * @function create
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {appliancepb.IProductAccessory=} [properties] Properties to set
         * @returns {appliancepb.ProductAccessory} ProductAccessory instance
         */
        ProductAccessory.create = function create(properties) {
            return new ProductAccessory(properties);
        };

        /**
         * Encodes the specified ProductAccessory message. Does not implicitly {@link appliancepb.ProductAccessory.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {appliancepb.IProductAccessory} message ProductAccessory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductAccessory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productCategory != null && Object.hasOwnProperty.call(message, "productCategory"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.productCategory);
            if (message.productSubcategory != null && Object.hasOwnProperty.call(message, "productSubcategory"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.productSubcategory);
            if (message.dimensions != null && Object.hasOwnProperty.call(message, "dimensions"))
                $root.appliancepb.Dimensions.encode(message.dimensions, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProductAccessory message, length delimited. Does not implicitly {@link appliancepb.ProductAccessory.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {appliancepb.IProductAccessory} message ProductAccessory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductAccessory.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProductAccessory message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ProductAccessory} ProductAccessory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductAccessory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ProductAccessory();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productCategory = reader.int32();
                    break;
                case 2:
                    message.productSubcategory = reader.int32();
                    break;
                case 3:
                    message.dimensions = $root.appliancepb.Dimensions.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProductAccessory message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ProductAccessory} ProductAccessory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductAccessory.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProductAccessory message.
         * @function verify
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProductAccessory.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.productCategory != null && message.hasOwnProperty("productCategory"))
                switch (message.productCategory) {
                default:
                    return "productCategory: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.productSubcategory != null && message.hasOwnProperty("productSubcategory"))
                switch (message.productSubcategory) {
                default:
                    return "productSubcategory: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.dimensions != null && message.hasOwnProperty("dimensions")) {
                let error = $root.appliancepb.Dimensions.verify(message.dimensions);
                if (error)
                    return "dimensions." + error;
            }
            return null;
        };

        /**
         * Creates a ProductAccessory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ProductAccessory} ProductAccessory
         */
        ProductAccessory.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ProductAccessory)
                return object;
            let message = new $root.appliancepb.ProductAccessory();
            switch (object.productCategory) {
            case "PRODUCT_CATEGORY_UNSPECIFIED":
            case 0:
                message.productCategory = 0;
                break;
            case "PRODUCT_CATEGORY_MICROWAVE_ACCESSORIES":
            case 1:
                message.productCategory = 1;
                break;
            case "PRODUCT_CATEGORY_REFRIGERATOR_ACCESSORIES":
            case 2:
                message.productCategory = 2;
                break;
            }
            switch (object.productSubcategory) {
            case "PRODUCT_SUBCATEGORY_UNSPECIFIED":
            case 0:
                message.productSubcategory = 0;
                break;
            case "PRODUCT_SUBCATEGORY_TRIM_KIT":
            case 1:
                message.productSubcategory = 1;
                break;
            case "PRODUCT_SUBCATEGORY_ICEMAKER":
            case 2:
                message.productSubcategory = 2;
                break;
            }
            if (object.dimensions != null) {
                if (typeof object.dimensions !== "object")
                    throw TypeError(".appliancepb.ProductAccessory.dimensions: object expected");
                message.dimensions = $root.appliancepb.Dimensions.fromObject(object.dimensions);
            }
            return message;
        };

        /**
         * Creates a plain object from a ProductAccessory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ProductAccessory
         * @static
         * @param {appliancepb.ProductAccessory} message ProductAccessory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProductAccessory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.productCategory = options.enums === String ? "PRODUCT_CATEGORY_UNSPECIFIED" : 0;
                object.productSubcategory = options.enums === String ? "PRODUCT_SUBCATEGORY_UNSPECIFIED" : 0;
                object.dimensions = null;
            }
            if (message.productCategory != null && message.hasOwnProperty("productCategory"))
                object.productCategory = options.enums === String ? $root.appliancepb.ProductAccessory.ProductCategory[message.productCategory] : message.productCategory;
            if (message.productSubcategory != null && message.hasOwnProperty("productSubcategory"))
                object.productSubcategory = options.enums === String ? $root.appliancepb.ProductAccessory.ProductSubcategory[message.productSubcategory] : message.productSubcategory;
            if (message.dimensions != null && message.hasOwnProperty("dimensions"))
                object.dimensions = $root.appliancepb.Dimensions.toObject(message.dimensions, options);
            return object;
        };

        /**
         * Converts this ProductAccessory to JSON.
         * @function toJSON
         * @memberof appliancepb.ProductAccessory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProductAccessory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * ProductCategory enum.
         * @name appliancepb.ProductAccessory.ProductCategory
         * @enum {number}
         * @property {number} PRODUCT_CATEGORY_UNSPECIFIED=0 PRODUCT_CATEGORY_UNSPECIFIED value
         * @property {number} PRODUCT_CATEGORY_MICROWAVE_ACCESSORIES=1 PRODUCT_CATEGORY_MICROWAVE_ACCESSORIES value
         * @property {number} PRODUCT_CATEGORY_REFRIGERATOR_ACCESSORIES=2 PRODUCT_CATEGORY_REFRIGERATOR_ACCESSORIES value
         */
        ProductAccessory.ProductCategory = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PRODUCT_CATEGORY_UNSPECIFIED"] = 0;
            values[valuesById[1] = "PRODUCT_CATEGORY_MICROWAVE_ACCESSORIES"] = 1;
            values[valuesById[2] = "PRODUCT_CATEGORY_REFRIGERATOR_ACCESSORIES"] = 2;
            return values;
        })();

        /**
         * ProductSubcategory enum.
         * @name appliancepb.ProductAccessory.ProductSubcategory
         * @enum {number}
         * @property {number} PRODUCT_SUBCATEGORY_UNSPECIFIED=0 PRODUCT_SUBCATEGORY_UNSPECIFIED value
         * @property {number} PRODUCT_SUBCATEGORY_TRIM_KIT=1 PRODUCT_SUBCATEGORY_TRIM_KIT value
         * @property {number} PRODUCT_SUBCATEGORY_ICEMAKER=2 PRODUCT_SUBCATEGORY_ICEMAKER value
         */
        ProductAccessory.ProductSubcategory = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PRODUCT_SUBCATEGORY_UNSPECIFIED"] = 0;
            values[valuesById[1] = "PRODUCT_SUBCATEGORY_TRIM_KIT"] = 1;
            values[valuesById[2] = "PRODUCT_SUBCATEGORY_ICEMAKER"] = 2;
            return values;
        })();

        return ProductAccessory;
    })();

    appliancepb.FAQ = (function() {

        /**
         * Properties of a FAQ.
         * @memberof appliancepb
         * @interface IFAQ
         * @property {string|null} [ID] FAQ ID
         * @property {string|null} [question] FAQ question
         * @property {string|null} [answer] FAQ answer
         * @property {number|null} [sortOrder] FAQ sortOrder
         */

        /**
         * Constructs a new FAQ.
         * @memberof appliancepb
         * @classdesc Represents a FAQ.
         * @implements IFAQ
         * @constructor
         * @param {appliancepb.IFAQ=} [properties] Properties to set
         */
        function FAQ(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FAQ ID.
         * @member {string} ID
         * @memberof appliancepb.FAQ
         * @instance
         */
        FAQ.prototype.ID = "";

        /**
         * FAQ question.
         * @member {string} question
         * @memberof appliancepb.FAQ
         * @instance
         */
        FAQ.prototype.question = "";

        /**
         * FAQ answer.
         * @member {string} answer
         * @memberof appliancepb.FAQ
         * @instance
         */
        FAQ.prototype.answer = "";

        /**
         * FAQ sortOrder.
         * @member {number} sortOrder
         * @memberof appliancepb.FAQ
         * @instance
         */
        FAQ.prototype.sortOrder = 0;

        /**
         * Creates a new FAQ instance using the specified properties.
         * @function create
         * @memberof appliancepb.FAQ
         * @static
         * @param {appliancepb.IFAQ=} [properties] Properties to set
         * @returns {appliancepb.FAQ} FAQ instance
         */
        FAQ.create = function create(properties) {
            return new FAQ(properties);
        };

        /**
         * Encodes the specified FAQ message. Does not implicitly {@link appliancepb.FAQ.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.FAQ
         * @static
         * @param {appliancepb.IFAQ} message FAQ message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FAQ.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ID != null && Object.hasOwnProperty.call(message, "ID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ID);
            if (message.question != null && Object.hasOwnProperty.call(message, "question"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.question);
            if (message.answer != null && Object.hasOwnProperty.call(message, "answer"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.answer);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortOrder);
            return writer;
        };

        /**
         * Encodes the specified FAQ message, length delimited. Does not implicitly {@link appliancepb.FAQ.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.FAQ
         * @static
         * @param {appliancepb.IFAQ} message FAQ message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FAQ.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FAQ message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.FAQ
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.FAQ} FAQ
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FAQ.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.FAQ();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ID = reader.string();
                    break;
                case 2:
                    message.question = reader.string();
                    break;
                case 3:
                    message.answer = reader.string();
                    break;
                case 4:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FAQ message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.FAQ
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.FAQ} FAQ
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FAQ.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FAQ message.
         * @function verify
         * @memberof appliancepb.FAQ
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FAQ.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ID != null && message.hasOwnProperty("ID"))
                if (!$util.isString(message.ID))
                    return "ID: string expected";
            if (message.question != null && message.hasOwnProperty("question"))
                if (!$util.isString(message.question))
                    return "question: string expected";
            if (message.answer != null && message.hasOwnProperty("answer"))
                if (!$util.isString(message.answer))
                    return "answer: string expected";
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                if (!$util.isInteger(message.sortOrder))
                    return "sortOrder: integer expected";
            return null;
        };

        /**
         * Creates a FAQ message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.FAQ
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.FAQ} FAQ
         */
        FAQ.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.FAQ)
                return object;
            let message = new $root.appliancepb.FAQ();
            if (object.ID != null)
                message.ID = String(object.ID);
            if (object.question != null)
                message.question = String(object.question);
            if (object.answer != null)
                message.answer = String(object.answer);
            if (object.sortOrder != null)
                message.sortOrder = object.sortOrder | 0;
            return message;
        };

        /**
         * Creates a plain object from a FAQ message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.FAQ
         * @static
         * @param {appliancepb.FAQ} message FAQ
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FAQ.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ID = "";
                object.question = "";
                object.answer = "";
                object.sortOrder = 0;
            }
            if (message.ID != null && message.hasOwnProperty("ID"))
                object.ID = message.ID;
            if (message.question != null && message.hasOwnProperty("question"))
                object.question = message.question;
            if (message.answer != null && message.hasOwnProperty("answer"))
                object.answer = message.answer;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = message.sortOrder;
            return object;
        };

        /**
         * Converts this FAQ to JSON.
         * @function toJSON
         * @memberof appliancepb.FAQ
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FAQ.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FAQ;
    })();

    appliancepb.GetFAQResponse = (function() {

        /**
         * Properties of a GetFAQResponse.
         * @memberof appliancepb
         * @interface IGetFAQResponse
         * @property {Array.<appliancepb.IFAQ>|null} [faqs] GetFAQResponse faqs
         */

        /**
         * Constructs a new GetFAQResponse.
         * @memberof appliancepb
         * @classdesc Represents a GetFAQResponse.
         * @implements IGetFAQResponse
         * @constructor
         * @param {appliancepb.IGetFAQResponse=} [properties] Properties to set
         */
        function GetFAQResponse(properties) {
            this.faqs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetFAQResponse faqs.
         * @member {Array.<appliancepb.IFAQ>} faqs
         * @memberof appliancepb.GetFAQResponse
         * @instance
         */
        GetFAQResponse.prototype.faqs = $util.emptyArray;

        /**
         * Creates a new GetFAQResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {appliancepb.IGetFAQResponse=} [properties] Properties to set
         * @returns {appliancepb.GetFAQResponse} GetFAQResponse instance
         */
        GetFAQResponse.create = function create(properties) {
            return new GetFAQResponse(properties);
        };

        /**
         * Encodes the specified GetFAQResponse message. Does not implicitly {@link appliancepb.GetFAQResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {appliancepb.IGetFAQResponse} message GetFAQResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFAQResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.faqs != null && message.faqs.length)
                for (let i = 0; i < message.faqs.length; ++i)
                    $root.appliancepb.FAQ.encode(message.faqs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetFAQResponse message, length delimited. Does not implicitly {@link appliancepb.GetFAQResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {appliancepb.IGetFAQResponse} message GetFAQResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFAQResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetFAQResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.GetFAQResponse} GetFAQResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFAQResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.GetFAQResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.faqs && message.faqs.length))
                        message.faqs = [];
                    message.faqs.push($root.appliancepb.FAQ.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetFAQResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.GetFAQResponse} GetFAQResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFAQResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetFAQResponse message.
         * @function verify
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetFAQResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.faqs != null && message.hasOwnProperty("faqs")) {
                if (!Array.isArray(message.faqs))
                    return "faqs: array expected";
                for (let i = 0; i < message.faqs.length; ++i) {
                    let error = $root.appliancepb.FAQ.verify(message.faqs[i]);
                    if (error)
                        return "faqs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetFAQResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.GetFAQResponse} GetFAQResponse
         */
        GetFAQResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.GetFAQResponse)
                return object;
            let message = new $root.appliancepb.GetFAQResponse();
            if (object.faqs) {
                if (!Array.isArray(object.faqs))
                    throw TypeError(".appliancepb.GetFAQResponse.faqs: array expected");
                message.faqs = [];
                for (let i = 0; i < object.faqs.length; ++i) {
                    if (typeof object.faqs[i] !== "object")
                        throw TypeError(".appliancepb.GetFAQResponse.faqs: object expected");
                    message.faqs[i] = $root.appliancepb.FAQ.fromObject(object.faqs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetFAQResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.GetFAQResponse
         * @static
         * @param {appliancepb.GetFAQResponse} message GetFAQResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetFAQResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.faqs = [];
            if (message.faqs && message.faqs.length) {
                object.faqs = [];
                for (let j = 0; j < message.faqs.length; ++j)
                    object.faqs[j] = $root.appliancepb.FAQ.toObject(message.faqs[j], options);
            }
            return object;
        };

        /**
         * Converts this GetFAQResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.GetFAQResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetFAQResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetFAQResponse;
    })();

    appliancepb.UpdateFAQsRequest = (function() {

        /**
         * Properties of an UpdateFAQsRequest.
         * @memberof appliancepb
         * @interface IUpdateFAQsRequest
         * @property {Array.<appliancepb.IFAQ>|null} [faqs] UpdateFAQsRequest faqs
         */

        /**
         * Constructs a new UpdateFAQsRequest.
         * @memberof appliancepb
         * @classdesc Represents an UpdateFAQsRequest.
         * @implements IUpdateFAQsRequest
         * @constructor
         * @param {appliancepb.IUpdateFAQsRequest=} [properties] Properties to set
         */
        function UpdateFAQsRequest(properties) {
            this.faqs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateFAQsRequest faqs.
         * @member {Array.<appliancepb.IFAQ>} faqs
         * @memberof appliancepb.UpdateFAQsRequest
         * @instance
         */
        UpdateFAQsRequest.prototype.faqs = $util.emptyArray;

        /**
         * Creates a new UpdateFAQsRequest instance using the specified properties.
         * @function create
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {appliancepb.IUpdateFAQsRequest=} [properties] Properties to set
         * @returns {appliancepb.UpdateFAQsRequest} UpdateFAQsRequest instance
         */
        UpdateFAQsRequest.create = function create(properties) {
            return new UpdateFAQsRequest(properties);
        };

        /**
         * Encodes the specified UpdateFAQsRequest message. Does not implicitly {@link appliancepb.UpdateFAQsRequest.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {appliancepb.IUpdateFAQsRequest} message UpdateFAQsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFAQsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.faqs != null && message.faqs.length)
                for (let i = 0; i < message.faqs.length; ++i)
                    $root.appliancepb.FAQ.encode(message.faqs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateFAQsRequest message, length delimited. Does not implicitly {@link appliancepb.UpdateFAQsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {appliancepb.IUpdateFAQsRequest} message UpdateFAQsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFAQsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateFAQsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.UpdateFAQsRequest} UpdateFAQsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFAQsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.UpdateFAQsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.faqs && message.faqs.length))
                        message.faqs = [];
                    message.faqs.push($root.appliancepb.FAQ.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateFAQsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.UpdateFAQsRequest} UpdateFAQsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFAQsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateFAQsRequest message.
         * @function verify
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateFAQsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.faqs != null && message.hasOwnProperty("faqs")) {
                if (!Array.isArray(message.faqs))
                    return "faqs: array expected";
                for (let i = 0; i < message.faqs.length; ++i) {
                    let error = $root.appliancepb.FAQ.verify(message.faqs[i]);
                    if (error)
                        return "faqs." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpdateFAQsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.UpdateFAQsRequest} UpdateFAQsRequest
         */
        UpdateFAQsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.UpdateFAQsRequest)
                return object;
            let message = new $root.appliancepb.UpdateFAQsRequest();
            if (object.faqs) {
                if (!Array.isArray(object.faqs))
                    throw TypeError(".appliancepb.UpdateFAQsRequest.faqs: array expected");
                message.faqs = [];
                for (let i = 0; i < object.faqs.length; ++i) {
                    if (typeof object.faqs[i] !== "object")
                        throw TypeError(".appliancepb.UpdateFAQsRequest.faqs: object expected");
                    message.faqs[i] = $root.appliancepb.FAQ.fromObject(object.faqs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateFAQsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.UpdateFAQsRequest
         * @static
         * @param {appliancepb.UpdateFAQsRequest} message UpdateFAQsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateFAQsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.faqs = [];
            if (message.faqs && message.faqs.length) {
                object.faqs = [];
                for (let j = 0; j < message.faqs.length; ++j)
                    object.faqs[j] = $root.appliancepb.FAQ.toObject(message.faqs[j], options);
            }
            return object;
        };

        /**
         * Converts this UpdateFAQsRequest to JSON.
         * @function toJSON
         * @memberof appliancepb.UpdateFAQsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateFAQsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateFAQsRequest;
    })();

    appliancepb.UpdateFAQsResponse = (function() {

        /**
         * Properties of an UpdateFAQsResponse.
         * @memberof appliancepb
         * @interface IUpdateFAQsResponse
         * @property {Array.<appliancepb.IFAQ>|null} [faqs] UpdateFAQsResponse faqs
         * @property {string|null} [failureMessage] UpdateFAQsResponse failureMessage
         */

        /**
         * Constructs a new UpdateFAQsResponse.
         * @memberof appliancepb
         * @classdesc Represents an UpdateFAQsResponse.
         * @implements IUpdateFAQsResponse
         * @constructor
         * @param {appliancepb.IUpdateFAQsResponse=} [properties] Properties to set
         */
        function UpdateFAQsResponse(properties) {
            this.faqs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateFAQsResponse faqs.
         * @member {Array.<appliancepb.IFAQ>} faqs
         * @memberof appliancepb.UpdateFAQsResponse
         * @instance
         */
        UpdateFAQsResponse.prototype.faqs = $util.emptyArray;

        /**
         * UpdateFAQsResponse failureMessage.
         * @member {string} failureMessage
         * @memberof appliancepb.UpdateFAQsResponse
         * @instance
         */
        UpdateFAQsResponse.prototype.failureMessage = "";

        /**
         * Creates a new UpdateFAQsResponse instance using the specified properties.
         * @function create
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {appliancepb.IUpdateFAQsResponse=} [properties] Properties to set
         * @returns {appliancepb.UpdateFAQsResponse} UpdateFAQsResponse instance
         */
        UpdateFAQsResponse.create = function create(properties) {
            return new UpdateFAQsResponse(properties);
        };

        /**
         * Encodes the specified UpdateFAQsResponse message. Does not implicitly {@link appliancepb.UpdateFAQsResponse.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {appliancepb.IUpdateFAQsResponse} message UpdateFAQsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFAQsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.faqs != null && message.faqs.length)
                for (let i = 0; i < message.faqs.length; ++i)
                    $root.appliancepb.FAQ.encode(message.faqs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.failureMessage != null && Object.hasOwnProperty.call(message, "failureMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.failureMessage);
            return writer;
        };

        /**
         * Encodes the specified UpdateFAQsResponse message, length delimited. Does not implicitly {@link appliancepb.UpdateFAQsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {appliancepb.IUpdateFAQsResponse} message UpdateFAQsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFAQsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateFAQsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.UpdateFAQsResponse} UpdateFAQsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFAQsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.UpdateFAQsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.faqs && message.faqs.length))
                        message.faqs = [];
                    message.faqs.push($root.appliancepb.FAQ.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.failureMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateFAQsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.UpdateFAQsResponse} UpdateFAQsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFAQsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateFAQsResponse message.
         * @function verify
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateFAQsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.faqs != null && message.hasOwnProperty("faqs")) {
                if (!Array.isArray(message.faqs))
                    return "faqs: array expected";
                for (let i = 0; i < message.faqs.length; ++i) {
                    let error = $root.appliancepb.FAQ.verify(message.faqs[i]);
                    if (error)
                        return "faqs." + error;
                }
            }
            if (message.failureMessage != null && message.hasOwnProperty("failureMessage"))
                if (!$util.isString(message.failureMessage))
                    return "failureMessage: string expected";
            return null;
        };

        /**
         * Creates an UpdateFAQsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.UpdateFAQsResponse} UpdateFAQsResponse
         */
        UpdateFAQsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.UpdateFAQsResponse)
                return object;
            let message = new $root.appliancepb.UpdateFAQsResponse();
            if (object.faqs) {
                if (!Array.isArray(object.faqs))
                    throw TypeError(".appliancepb.UpdateFAQsResponse.faqs: array expected");
                message.faqs = [];
                for (let i = 0; i < object.faqs.length; ++i) {
                    if (typeof object.faqs[i] !== "object")
                        throw TypeError(".appliancepb.UpdateFAQsResponse.faqs: object expected");
                    message.faqs[i] = $root.appliancepb.FAQ.fromObject(object.faqs[i]);
                }
            }
            if (object.failureMessage != null)
                message.failureMessage = String(object.failureMessage);
            return message;
        };

        /**
         * Creates a plain object from an UpdateFAQsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.UpdateFAQsResponse
         * @static
         * @param {appliancepb.UpdateFAQsResponse} message UpdateFAQsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateFAQsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.faqs = [];
            if (options.defaults)
                object.failureMessage = "";
            if (message.faqs && message.faqs.length) {
                object.faqs = [];
                for (let j = 0; j < message.faqs.length; ++j)
                    object.faqs[j] = $root.appliancepb.FAQ.toObject(message.faqs[j], options);
            }
            if (message.failureMessage != null && message.hasOwnProperty("failureMessage"))
                object.failureMessage = message.failureMessage;
            return object;
        };

        /**
         * Converts this UpdateFAQsResponse to JSON.
         * @function toJSON
         * @memberof appliancepb.UpdateFAQsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateFAQsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateFAQsResponse;
    })();

    appliancepb.ReplacementOfferReport = (function() {

        /**
         * Properties of a ReplacementOfferReport.
         * @memberof appliancepb
         * @interface IReplacementOfferReport
         * @property {string|null} [replacementId] ReplacementOfferReport replacementId
         * @property {string|null} [dispatchId] ReplacementOfferReport dispatchId
         * @property {string|null} [partRequestId] ReplacementOfferReport partRequestId
         * @property {appliancepb.IAgent|null} [agent] ReplacementOfferReport agent
         * @property {number|null} [cashInLieu] ReplacementOfferReport cashInLieu
         * @property {appliancepb.ICartItem|null} [recommendedModel] ReplacementOfferReport recommendedModel
         * @property {appliancepb.ICart|null} [recommendedAccessories] ReplacementOfferReport recommendedAccessories
         * @property {google.protobuf.ITimestamp|null} [offerDate] ReplacementOfferReport offerDate
         * @property {string|null} [actionType] ReplacementOfferReport actionType
         */

        /**
         * Constructs a new ReplacementOfferReport.
         * @memberof appliancepb
         * @classdesc Represents a ReplacementOfferReport.
         * @implements IReplacementOfferReport
         * @constructor
         * @param {appliancepb.IReplacementOfferReport=} [properties] Properties to set
         */
        function ReplacementOfferReport(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReplacementOfferReport replacementId.
         * @member {string} replacementId
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.replacementId = "";

        /**
         * ReplacementOfferReport dispatchId.
         * @member {string} dispatchId
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.dispatchId = "";

        /**
         * ReplacementOfferReport partRequestId.
         * @member {string} partRequestId
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.partRequestId = "";

        /**
         * ReplacementOfferReport agent.
         * @member {appliancepb.IAgent|null|undefined} agent
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.agent = null;

        /**
         * ReplacementOfferReport cashInLieu.
         * @member {number} cashInLieu
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.cashInLieu = 0;

        /**
         * ReplacementOfferReport recommendedModel.
         * @member {appliancepb.ICartItem|null|undefined} recommendedModel
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.recommendedModel = null;

        /**
         * ReplacementOfferReport recommendedAccessories.
         * @member {appliancepb.ICart|null|undefined} recommendedAccessories
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.recommendedAccessories = null;

        /**
         * ReplacementOfferReport offerDate.
         * @member {google.protobuf.ITimestamp|null|undefined} offerDate
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.offerDate = null;

        /**
         * ReplacementOfferReport actionType.
         * @member {string} actionType
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         */
        ReplacementOfferReport.prototype.actionType = "";

        /**
         * Creates a new ReplacementOfferReport instance using the specified properties.
         * @function create
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {appliancepb.IReplacementOfferReport=} [properties] Properties to set
         * @returns {appliancepb.ReplacementOfferReport} ReplacementOfferReport instance
         */
        ReplacementOfferReport.create = function create(properties) {
            return new ReplacementOfferReport(properties);
        };

        /**
         * Encodes the specified ReplacementOfferReport message. Does not implicitly {@link appliancepb.ReplacementOfferReport.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {appliancepb.IReplacementOfferReport} message ReplacementOfferReport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementOfferReport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.replacementId != null && Object.hasOwnProperty.call(message, "replacementId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.replacementId);
            if (message.dispatchId != null && Object.hasOwnProperty.call(message, "dispatchId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.dispatchId);
            if (message.partRequestId != null && Object.hasOwnProperty.call(message, "partRequestId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.partRequestId);
            if (message.agent != null && Object.hasOwnProperty.call(message, "agent"))
                $root.appliancepb.Agent.encode(message.agent, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.cashInLieu != null && Object.hasOwnProperty.call(message, "cashInLieu"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.cashInLieu);
            if (message.recommendedModel != null && Object.hasOwnProperty.call(message, "recommendedModel"))
                $root.appliancepb.CartItem.encode(message.recommendedModel, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.recommendedAccessories != null && Object.hasOwnProperty.call(message, "recommendedAccessories"))
                $root.appliancepb.Cart.encode(message.recommendedAccessories, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.offerDate != null && Object.hasOwnProperty.call(message, "offerDate"))
                $root.google.protobuf.Timestamp.encode(message.offerDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.actionType != null && Object.hasOwnProperty.call(message, "actionType"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.actionType);
            return writer;
        };

        /**
         * Encodes the specified ReplacementOfferReport message, length delimited. Does not implicitly {@link appliancepb.ReplacementOfferReport.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {appliancepb.IReplacementOfferReport} message ReplacementOfferReport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReplacementOfferReport.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReplacementOfferReport message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.ReplacementOfferReport} ReplacementOfferReport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementOfferReport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.ReplacementOfferReport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.replacementId = reader.string();
                    break;
                case 2:
                    message.dispatchId = reader.string();
                    break;
                case 3:
                    message.partRequestId = reader.string();
                    break;
                case 4:
                    message.agent = $root.appliancepb.Agent.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.cashInLieu = reader.int32();
                    break;
                case 6:
                    message.recommendedModel = $root.appliancepb.CartItem.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.recommendedAccessories = $root.appliancepb.Cart.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.offerDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.actionType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReplacementOfferReport message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.ReplacementOfferReport} ReplacementOfferReport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReplacementOfferReport.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReplacementOfferReport message.
         * @function verify
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReplacementOfferReport.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.replacementId != null && message.hasOwnProperty("replacementId"))
                if (!$util.isString(message.replacementId))
                    return "replacementId: string expected";
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                if (!$util.isString(message.dispatchId))
                    return "dispatchId: string expected";
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                if (!$util.isString(message.partRequestId))
                    return "partRequestId: string expected";
            if (message.agent != null && message.hasOwnProperty("agent")) {
                let error = $root.appliancepb.Agent.verify(message.agent);
                if (error)
                    return "agent." + error;
            }
            if (message.cashInLieu != null && message.hasOwnProperty("cashInLieu"))
                if (!$util.isInteger(message.cashInLieu))
                    return "cashInLieu: integer expected";
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel")) {
                let error = $root.appliancepb.CartItem.verify(message.recommendedModel);
                if (error)
                    return "recommendedModel." + error;
            }
            if (message.recommendedAccessories != null && message.hasOwnProperty("recommendedAccessories")) {
                let error = $root.appliancepb.Cart.verify(message.recommendedAccessories);
                if (error)
                    return "recommendedAccessories." + error;
            }
            if (message.offerDate != null && message.hasOwnProperty("offerDate")) {
                let error = $root.google.protobuf.Timestamp.verify(message.offerDate);
                if (error)
                    return "offerDate." + error;
            }
            if (message.actionType != null && message.hasOwnProperty("actionType"))
                if (!$util.isString(message.actionType))
                    return "actionType: string expected";
            return null;
        };

        /**
         * Creates a ReplacementOfferReport message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.ReplacementOfferReport} ReplacementOfferReport
         */
        ReplacementOfferReport.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.ReplacementOfferReport)
                return object;
            let message = new $root.appliancepb.ReplacementOfferReport();
            if (object.replacementId != null)
                message.replacementId = String(object.replacementId);
            if (object.dispatchId != null)
                message.dispatchId = String(object.dispatchId);
            if (object.partRequestId != null)
                message.partRequestId = String(object.partRequestId);
            if (object.agent != null) {
                if (typeof object.agent !== "object")
                    throw TypeError(".appliancepb.ReplacementOfferReport.agent: object expected");
                message.agent = $root.appliancepb.Agent.fromObject(object.agent);
            }
            if (object.cashInLieu != null)
                message.cashInLieu = object.cashInLieu | 0;
            if (object.recommendedModel != null) {
                if (typeof object.recommendedModel !== "object")
                    throw TypeError(".appliancepb.ReplacementOfferReport.recommendedModel: object expected");
                message.recommendedModel = $root.appliancepb.CartItem.fromObject(object.recommendedModel);
            }
            if (object.recommendedAccessories != null) {
                if (typeof object.recommendedAccessories !== "object")
                    throw TypeError(".appliancepb.ReplacementOfferReport.recommendedAccessories: object expected");
                message.recommendedAccessories = $root.appliancepb.Cart.fromObject(object.recommendedAccessories);
            }
            if (object.offerDate != null) {
                if (typeof object.offerDate !== "object")
                    throw TypeError(".appliancepb.ReplacementOfferReport.offerDate: object expected");
                message.offerDate = $root.google.protobuf.Timestamp.fromObject(object.offerDate);
            }
            if (object.actionType != null)
                message.actionType = String(object.actionType);
            return message;
        };

        /**
         * Creates a plain object from a ReplacementOfferReport message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.ReplacementOfferReport
         * @static
         * @param {appliancepb.ReplacementOfferReport} message ReplacementOfferReport
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReplacementOfferReport.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.replacementId = "";
                object.dispatchId = "";
                object.partRequestId = "";
                object.agent = null;
                object.cashInLieu = 0;
                object.recommendedModel = null;
                object.recommendedAccessories = null;
                object.offerDate = null;
                object.actionType = "";
            }
            if (message.replacementId != null && message.hasOwnProperty("replacementId"))
                object.replacementId = message.replacementId;
            if (message.dispatchId != null && message.hasOwnProperty("dispatchId"))
                object.dispatchId = message.dispatchId;
            if (message.partRequestId != null && message.hasOwnProperty("partRequestId"))
                object.partRequestId = message.partRequestId;
            if (message.agent != null && message.hasOwnProperty("agent"))
                object.agent = $root.appliancepb.Agent.toObject(message.agent, options);
            if (message.cashInLieu != null && message.hasOwnProperty("cashInLieu"))
                object.cashInLieu = message.cashInLieu;
            if (message.recommendedModel != null && message.hasOwnProperty("recommendedModel"))
                object.recommendedModel = $root.appliancepb.CartItem.toObject(message.recommendedModel, options);
            if (message.recommendedAccessories != null && message.hasOwnProperty("recommendedAccessories"))
                object.recommendedAccessories = $root.appliancepb.Cart.toObject(message.recommendedAccessories, options);
            if (message.offerDate != null && message.hasOwnProperty("offerDate"))
                object.offerDate = $root.google.protobuf.Timestamp.toObject(message.offerDate, options);
            if (message.actionType != null && message.hasOwnProperty("actionType"))
                object.actionType = message.actionType;
            return object;
        };

        /**
         * Converts this ReplacementOfferReport to JSON.
         * @function toJSON
         * @memberof appliancepb.ReplacementOfferReport
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReplacementOfferReport.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReplacementOfferReport;
    })();

    appliancepb.TaxDetail = (function() {

        /**
         * Properties of a TaxDetail.
         * @memberof appliancepb
         * @interface ITaxDetail
         * @property {Array.<appliancepb.TaxDetail.ITaxRate>|null} [taxRates] TaxDetail taxRates
         * @property {string|null} [taxTotal] TaxDetail taxTotal
         */

        /**
         * Constructs a new TaxDetail.
         * @memberof appliancepb
         * @classdesc Represents a TaxDetail.
         * @implements ITaxDetail
         * @constructor
         * @param {appliancepb.ITaxDetail=} [properties] Properties to set
         */
        function TaxDetail(properties) {
            this.taxRates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TaxDetail taxRates.
         * @member {Array.<appliancepb.TaxDetail.ITaxRate>} taxRates
         * @memberof appliancepb.TaxDetail
         * @instance
         */
        TaxDetail.prototype.taxRates = $util.emptyArray;

        /**
         * TaxDetail taxTotal.
         * @member {string} taxTotal
         * @memberof appliancepb.TaxDetail
         * @instance
         */
        TaxDetail.prototype.taxTotal = "";

        /**
         * Creates a new TaxDetail instance using the specified properties.
         * @function create
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {appliancepb.ITaxDetail=} [properties] Properties to set
         * @returns {appliancepb.TaxDetail} TaxDetail instance
         */
        TaxDetail.create = function create(properties) {
            return new TaxDetail(properties);
        };

        /**
         * Encodes the specified TaxDetail message. Does not implicitly {@link appliancepb.TaxDetail.verify|verify} messages.
         * @function encode
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {appliancepb.ITaxDetail} message TaxDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaxDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taxRates != null && message.taxRates.length)
                for (let i = 0; i < message.taxRates.length; ++i)
                    $root.appliancepb.TaxDetail.TaxRate.encode(message.taxRates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.taxTotal != null && Object.hasOwnProperty.call(message, "taxTotal"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taxTotal);
            return writer;
        };

        /**
         * Encodes the specified TaxDetail message, length delimited. Does not implicitly {@link appliancepb.TaxDetail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {appliancepb.ITaxDetail} message TaxDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaxDetail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TaxDetail message from the specified reader or buffer.
         * @function decode
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {appliancepb.TaxDetail} TaxDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaxDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.TaxDetail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.taxRates && message.taxRates.length))
                        message.taxRates = [];
                    message.taxRates.push($root.appliancepb.TaxDetail.TaxRate.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.taxTotal = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TaxDetail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {appliancepb.TaxDetail} TaxDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaxDetail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TaxDetail message.
         * @function verify
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TaxDetail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.taxRates != null && message.hasOwnProperty("taxRates")) {
                if (!Array.isArray(message.taxRates))
                    return "taxRates: array expected";
                for (let i = 0; i < message.taxRates.length; ++i) {
                    let error = $root.appliancepb.TaxDetail.TaxRate.verify(message.taxRates[i]);
                    if (error)
                        return "taxRates." + error;
                }
            }
            if (message.taxTotal != null && message.hasOwnProperty("taxTotal"))
                if (!$util.isString(message.taxTotal))
                    return "taxTotal: string expected";
            return null;
        };

        /**
         * Creates a TaxDetail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {appliancepb.TaxDetail} TaxDetail
         */
        TaxDetail.fromObject = function fromObject(object) {
            if (object instanceof $root.appliancepb.TaxDetail)
                return object;
            let message = new $root.appliancepb.TaxDetail();
            if (object.taxRates) {
                if (!Array.isArray(object.taxRates))
                    throw TypeError(".appliancepb.TaxDetail.taxRates: array expected");
                message.taxRates = [];
                for (let i = 0; i < object.taxRates.length; ++i) {
                    if (typeof object.taxRates[i] !== "object")
                        throw TypeError(".appliancepb.TaxDetail.taxRates: object expected");
                    message.taxRates[i] = $root.appliancepb.TaxDetail.TaxRate.fromObject(object.taxRates[i]);
                }
            }
            if (object.taxTotal != null)
                message.taxTotal = String(object.taxTotal);
            return message;
        };

        /**
         * Creates a plain object from a TaxDetail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof appliancepb.TaxDetail
         * @static
         * @param {appliancepb.TaxDetail} message TaxDetail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TaxDetail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.taxRates = [];
            if (options.defaults)
                object.taxTotal = "";
            if (message.taxRates && message.taxRates.length) {
                object.taxRates = [];
                for (let j = 0; j < message.taxRates.length; ++j)
                    object.taxRates[j] = $root.appliancepb.TaxDetail.TaxRate.toObject(message.taxRates[j], options);
            }
            if (message.taxTotal != null && message.hasOwnProperty("taxTotal"))
                object.taxTotal = message.taxTotal;
            return object;
        };

        /**
         * Converts this TaxDetail to JSON.
         * @function toJSON
         * @memberof appliancepb.TaxDetail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TaxDetail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TaxDetail.TaxRate = (function() {

            /**
             * Properties of a TaxRate.
             * @memberof appliancepb.TaxDetail
             * @interface ITaxRate
             * @property {string|null} [jurisdiction] TaxRate jurisdiction
             * @property {string|null} [taxRate] TaxRate taxRate
             * @property {string|null} [taxType] TaxRate taxType
             */

            /**
             * Constructs a new TaxRate.
             * @memberof appliancepb.TaxDetail
             * @classdesc Represents a TaxRate.
             * @implements ITaxRate
             * @constructor
             * @param {appliancepb.TaxDetail.ITaxRate=} [properties] Properties to set
             */
            function TaxRate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TaxRate jurisdiction.
             * @member {string} jurisdiction
             * @memberof appliancepb.TaxDetail.TaxRate
             * @instance
             */
            TaxRate.prototype.jurisdiction = "";

            /**
             * TaxRate taxRate.
             * @member {string} taxRate
             * @memberof appliancepb.TaxDetail.TaxRate
             * @instance
             */
            TaxRate.prototype.taxRate = "";

            /**
             * TaxRate taxType.
             * @member {string} taxType
             * @memberof appliancepb.TaxDetail.TaxRate
             * @instance
             */
            TaxRate.prototype.taxType = "";

            /**
             * Creates a new TaxRate instance using the specified properties.
             * @function create
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {appliancepb.TaxDetail.ITaxRate=} [properties] Properties to set
             * @returns {appliancepb.TaxDetail.TaxRate} TaxRate instance
             */
            TaxRate.create = function create(properties) {
                return new TaxRate(properties);
            };

            /**
             * Encodes the specified TaxRate message. Does not implicitly {@link appliancepb.TaxDetail.TaxRate.verify|verify} messages.
             * @function encode
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {appliancepb.TaxDetail.ITaxRate} message TaxRate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaxRate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.jurisdiction != null && Object.hasOwnProperty.call(message, "jurisdiction"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.jurisdiction);
                if (message.taxRate != null && Object.hasOwnProperty.call(message, "taxRate"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.taxRate);
                if (message.taxType != null && Object.hasOwnProperty.call(message, "taxType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.taxType);
                return writer;
            };

            /**
             * Encodes the specified TaxRate message, length delimited. Does not implicitly {@link appliancepb.TaxDetail.TaxRate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {appliancepb.TaxDetail.ITaxRate} message TaxRate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaxRate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TaxRate message from the specified reader or buffer.
             * @function decode
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {appliancepb.TaxDetail.TaxRate} TaxRate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaxRate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.appliancepb.TaxDetail.TaxRate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.jurisdiction = reader.string();
                        break;
                    case 2:
                        message.taxRate = reader.string();
                        break;
                    case 3:
                        message.taxType = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TaxRate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {appliancepb.TaxDetail.TaxRate} TaxRate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaxRate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TaxRate message.
             * @function verify
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TaxRate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.jurisdiction != null && message.hasOwnProperty("jurisdiction"))
                    if (!$util.isString(message.jurisdiction))
                        return "jurisdiction: string expected";
                if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                    if (!$util.isString(message.taxRate))
                        return "taxRate: string expected";
                if (message.taxType != null && message.hasOwnProperty("taxType"))
                    if (!$util.isString(message.taxType))
                        return "taxType: string expected";
                return null;
            };

            /**
             * Creates a TaxRate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {appliancepb.TaxDetail.TaxRate} TaxRate
             */
            TaxRate.fromObject = function fromObject(object) {
                if (object instanceof $root.appliancepb.TaxDetail.TaxRate)
                    return object;
                let message = new $root.appliancepb.TaxDetail.TaxRate();
                if (object.jurisdiction != null)
                    message.jurisdiction = String(object.jurisdiction);
                if (object.taxRate != null)
                    message.taxRate = String(object.taxRate);
                if (object.taxType != null)
                    message.taxType = String(object.taxType);
                return message;
            };

            /**
             * Creates a plain object from a TaxRate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof appliancepb.TaxDetail.TaxRate
             * @static
             * @param {appliancepb.TaxDetail.TaxRate} message TaxRate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TaxRate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.jurisdiction = "";
                    object.taxRate = "";
                    object.taxType = "";
                }
                if (message.jurisdiction != null && message.hasOwnProperty("jurisdiction"))
                    object.jurisdiction = message.jurisdiction;
                if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                    object.taxRate = message.taxRate;
                if (message.taxType != null && message.hasOwnProperty("taxType"))
                    object.taxType = message.taxType;
                return object;
            };

            /**
             * Converts this TaxRate to JSON.
             * @function toJSON
             * @memberof appliancepb.TaxDetail.TaxRate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TaxRate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TaxRate;
        })();

        return TaxDetail;
    })();

    return appliancepb;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
