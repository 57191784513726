import React from 'react'
import { useAppContext } from '@ftdr/blueprint-components-react'
import { NavLink } from 'react-router-dom'
import { isHSA } from 'src/utils/tenant-helper'
import { useScrolledContext } from '../../hooks/use-isScrolled-context'
import { useDecision } from '@optimizely/react-sdk'

interface IMobileNav {
  setClose: () => void
}
const MobileNav: React.FC<IMobileNav> = ({ setClose }) => {
  const { isScrolled } = useScrolledContext()
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const [isOptiFlagOn] = useDecision('ecard_whitelist')
  const [isNewPaymentFormFlagOn] = useDecision('payment_form')

  return (
    <>
      <div
        className={`${
          isHSA() ? 'bg-white' : 'bg-picket-white'
        }  flex justify-center fixed w-full  left-0 bottom-0 right-0 z-50`}
        style={{
          height: !isScrolled ? 'calc(100% -80px)' : 'calc(100% - 48px)',
          top: !isScrolled ? 80 : 48,
        }}
      >
        <div className="overflow-auto w-full h-full">
          <ul className="text-center py-10">
            <li className="mb-4">
              <NavLink
                id="mobile-nav-payment-m"
                activeClassName="border-b-2 border-white"
                to={
                  isNewPaymentFormFlagOn.enabled ? '/payment' : '/quick-payment'
                }
                className={isHSA() ? 'text-primary' : 'text-interactive'}
                style={{ fontSize: '38px', padding: '5px 0' }}
                onClick={setClose}
              >
                {localizedText('NAVBAR_LINK_PAYMENT')}
              </NavLink>
            </li>

            <li className="mb-4">
              <NavLink
                id="mobile-nav-tracking-m"
                activeClassName="border-b-2 border-white"
                className={isHSA() ? 'text-primary' : 'text-interactive'}
                style={{ fontSize: '38px', padding: '5px 0' }}
                to="/status"
                onClick={setClose}
              >
                {localizedText('NAVBAR_LINK_TRACKING')}
              </NavLink>
            </li>

            <li className="mb-4">
              <NavLink
                id="mobile-nav-queue-m"
                activeClassName="border-b-2 border-white"
                className={isHSA() ? 'text-primary' : 'text-interactive'}
                style={{ fontSize: '38px', padding: '5px 0' }}
                to="/queue"
                onClick={setClose}
              >
                {localizedText('NAVBAR_LINK_QUEUE')}
              </NavLink>
            </li>
            {isOptiFlagOn.enabled && (
              <li className="mb-4">
                <NavLink
                  id="mobile-nav-ecard-m"
                  activeClassName="border-b-2 border-white"
                  className={isHSA() ? 'text-primary' : 'text-interactive'}
                  style={{ fontSize: '38px', padding: '5px 0' }}
                  to="/ecard"
                  onClick={setClose}
                >
                  {localizedText('NAVBAR_LINK_ECARD')}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                id="mobile-nav-agent-faq-m"
                className={isHSA() ? 'text-primary' : 'text-interactive'}
                style={{ fontSize: '38px', padding: '5px 0' }}
                to="/agent-faq"
                onClick={setClose}
              >
                {localizedText('NAVBAR_LINK_AGENTFAQ')}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default MobileNav
