import React, { useState, useEffect, useMemo } from 'react'
import {
  AccordionSection,
  Accordion,
  Input,
  useAppContext,
  IconSearch,
} from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import Debounce from './faq-helpers'
import { IContent } from '../../hooks/use-faq-content'
import './reset.css'
import { isAHS } from 'src/utils/tenant-helper'
interface Props {
  content: IContent[]
  isAgent: boolean
}
const Faq: React.FC<Props> = ({ content, isAgent }) => {
  console.log(isAgent)
  const color = isAHS() ? 'interactive' : 'primary'
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const [searchText, setSearchText] = useState<string>('')
  const [filteredContent, setFilteredContent] = useState<IContent[]>(content)

  const onSeacrhHandler = (text: string) => {
    const regex = new RegExp(text, 'gi')
    setFilteredContent(content.filter((v) => regex.test(v.label)))
  }
  const dbn = useMemo(
    () => new Debounce(onSeacrhHandler, searchText),

    //eslint-disable-next-line
    [searchText]
  )

  useEffect(() => {
    dbn.setDebounce()
    return () => {
      dbn.clearDebounce()
    }
  }, [searchText, dbn])
  return (
    <section id="agent-faq-section">
      <Text className="mb-6" variant="heading-03">
        {localizedText('NAVBAR_LINK_AGENTFAQ')}
      </Text>
      <Text className="mb-6 font-medium" variant="heading-05">
        {localizedText('FAQ_AGENT_DESCRIPTION')}
      </Text>

      <div
        className="md:flex items-start relative w-full mb-6 md:mb-10 shadow-raised"
        style={{
          borderRadius: '0.25rem',
        }}
      >
        <Input
          id="agent-faq-search-input"
          type="search"
          formField
          inputClassName="input-element-search  border-0"
          formFieldClassName="w-full"
          label=""
          placeholder="Describe your issue"
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value)
          }}
        />
        <IconSearch
          id="agent-faq-icon-button"
          size="24"
          className="absolute"
          style={{ top: '50%', transform: 'translateY(-50%)', right: '6px' }}
        />
      </div>
      {filteredContent.length > 0 ? (
        <div className="accordions-custom">
          <Accordion
            id="ecard-search-accordion-wrapper"
            mode="single"
            color={color}
            hideFirstSeparator={true}
          >
            {filteredContent.map(({ label, description, id }) => (
              <AccordionSection id={`agent-faq-${id}`} label={label} key={id}>
                <div
                  className="py-6 px-8 text-primary"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </AccordionSection>
            ))}
          </Accordion>
        </div>
      ) : (
        <Text
          id="agent-faq-404"
          className="py-6 font-normal"
          variant="heading-05"
        >
          {localizedText('FAQ_404')}
        </Text>
      )}
      {!isAgent ? (
        <Text
          className="mt-8"
          dangerouslySetInnerHTML={{
            __html: localizedText('FAQ_4_LINK'),
          }}
        />
      ) : null}
    </section>
  )
}

export default Faq
