import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from '../../../custom-fdr-components'
import { clearSaveError } from 'src/store/admin-store'

import {
  DateTimeInput,
  Select,
  TextArea,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import { initialBannersMessage, initialBannersTypes } from '../../initials'
import { AppState, MenuItem } from 'src/utils/shared-types'

import { useDispatch, useSelector } from 'react-redux'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { yupResolver } from '@hookform/resolvers/yup'
import { bannersSchema } from './validation'

interface IForm {
  severity: MenuItem
  message: MenuItem
  customMessage: string
  start: Date
  end: Date | null
}
interface Props {
  title: string
  initValues: IForm
  callBack: (banner: appliancepb.IBanner) => void
  type: 'edit' | 'save'
  id?: string
}
const BannerForm: React.FC<Props> = ({
  title,
  initValues,
  callBack,
  type,
  id,
}) => {
  const dispatch = useDispatch()
  const { saveLoading, saveError, editError, editLoading } = useSelector(
    (state: AppState) => state.admin
  )
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    defaultValues: initValues,
    resolver: yupResolver(bannersSchema),
  })
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const message = watch('message')

  const submitForm = (data: IForm) => {
    const banner = {
      severity: Number(data.severity.id),
      message: data.customMessage || data.message.label,
      activate: {
        seconds: data.start.getTime() / 1000,
        nanos: 0,
      },
      expire: {
        seconds: data.end?.getTime()! / 1000,
        nanos: 0,
      },
    }
    console.log(banner)
    callBack(type === 'save' ? banner : { ...banner, id })
  }

  useEffect(() => {
    dispatch(clearSaveError())
    //eslint-disable-next-line
  }, [])

  return (
    <div className="pb-4">
      <Text
        id="add-banners-title"
        className="font-display font-bold text-4 lg:text-4 mb-8"
        textTemplateKey={title}
      />
      <Controller
        name="severity"
        control={control}
        render={({ field }) => (
          <Select
            id={`${title}-add-banners-type`}
            required
            error={errors?.severity?.message}
            formField
            label={localizedText('ADMIN_LABEL_TYPE')}
            className="w-full md:w-56 mb-6"
            options={initialBannersTypes}
            selected={field.value}
            onSelect={field.onChange}
          />
        )}
      />
      <div style={{ maxWidth: 560 }} className="mb-5">
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <Select
              id={`${title}-add-banners-message`}
              required
              error={errors?.message?.message}
              formField
              label={localizedText('ADMIN_LABEL_MESSAGE')}
              className="w-full mb-6"
              options={initialBannersMessage}
              selected={field.value}
              onSelect={field.onChange}
            />
          )}
        />
        {message.id === '2' ? (
          <Controller
            name="customMessage"
            control={control}
            render={({ field }) => (
              <TextArea
                id={`${title}-add-banners-descritpion`}
                className="w-full mb-6"
                error={errors?.customMessage?.message}
                formField={true}
                label="Add Message"
                textAlign="left"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        ) : null}
      </div>
      <Controller
        name="start"
        control={control}
        render={({ field }) => (
          <DateTimeInput
            required
            id={`${title}-add-banners-date-in`}
            className="add-banner-date  mb-6"
            label="Start date"
            datePicker
            error={errors?.start?.message}
            showMonths={1}
            minDate={new Date()}
            selectedDate={field.value || new Date()}
            onDateSelect={field.onChange}
            hourPicker
            hourInterval={1}
            minutePicker
            minuteInterval={1}
          />
        )}
      />
      <Controller
        name="end"
        control={control}
        render={({ field }) => (
          <DateTimeInput
            required
            id={`${title}-add-banners-date-out`}
            className="add-banner-date mb-6"
            label="End Date"
            error={errors?.end?.message}
            datePicker
            showMonths={1}
            minDate={new Date()}
            selectedDate={field.value}
            onDateSelect={field.onChange}
            hourPicker
            hourInterval={1}
            minutePicker
            minuteInterval={1}
          />
        )}
      />

      <Button
        type="button"
        loading={type === 'save' ? saveLoading : editLoading}
        id={`${title}-add-banners-submit`}
        label={localizedText('ADMIN_LABEL_SAVE')}
        className="w-auto flex justify-center lg:inline-block mt-4"
        onClick={() => handleSubmit((data) => submitForm(data))()}
        style={{ lineHeight: 1 }}
      />
      {type === 'save' && !!saveError && (
        <p className="text-error mt-2 absolute">{saveError}</p>
      )}
      {type === 'edit' && !!editError && (
        <p className="text-error mt-2 absolute">{editError}</p>
      )}
    </div>
  )
}

export default BannerForm
