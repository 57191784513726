import React, { useCallback } from 'react'
import { useAdminContext } from '../../hooks/use-admin-context'
import { Select, useAppContext } from '@ftdr/blueprint-components-react'
import { MenuItem } from 'src/utils/shared-types'
import DownloadButton from './download-button'
import { api } from 'src/utils/api'
import { saveAs } from 'file-saver'
import { emptyCacheObject } from './initials'

interface IProps {
  showButton?: boolean
  includedVendors?: string[]
}

const Vendor: React.FC<IProps> = ({
  showButton = false,
  includedVendors = ['WP', 'GE'],
}) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const onClickHandler = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data: downloadCSV } = await api.getAdminData('/coupa')
      const file = new Blob([downloadCSV], { type: 'text/csv;charset=utf-8' })

      setError(null)
      setIsLoading(false)
      saveAs(file, `coupa`)
    } catch (error: any) {
      setError(error.message)
      setIsLoading(false)
    }

    //eslint-disable-next-line
  }, [])

  const {
    setIsLoading,
    setError,
    setRefreshMessage,
    vendors,
    adminFunction,
    selectedVendor,
    setSelectedVendor,
    vendorsAreLoading,
    setAddFileMessage,
    setIsFileDownloaded,
  } = useAdminContext()

  return (
    <div className="md:flex items-center">
      {adminFunction.value !== 'coupa' && (
        <Select
          id="vendor-select-type"
          required
          formField
          label={localizedText('ADMIN_LABEL_VENDOR')}
          className="w-full md:w-56 mb-6"
          options={vendors.filter((v) =>
            includedVendors.includes(v.id) ? true : false
          )}
          selected={selectedVendor}
          onSelect={(item) => {
            const option = { ...item } as MenuItem
            setSelectedVendor(option)
            setRefreshMessage(emptyCacheObject)
            setAddFileMessage(emptyCacheObject)
            setError(null)
            setIsFileDownloaded(false)
          }}
          disabled={vendorsAreLoading}
        />
      )}
      {showButton && (
        <DownloadButton
          className="m-0 md:ml-4"
          onClick={adminFunction.value === 'coupa' ? onClickHandler : undefined}
        />
      )}
    </div>
  )
}

export default Vendor
