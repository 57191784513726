import React, { useEffect, useMemo } from 'react'
import {
  Input,
  Select,
  useAppContext,
  SelectHandler,
  SelectOption,
} from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultValues, defaultStatus } from './queue-default-values'
import { queueSchema } from './queue-validation'
import { AppState, IQueueFormData } from 'src/utils/shared-types'
import { useGetVendors } from 'src/hooks/use-get-vendors'
import {
  arrOfQueueParamsKeys,
  formDataToParams,
  getPagination,
} from '../../helpers'
import qs from 'query-string'
import { useLocation } from 'react-router'
import { useTabsTableContext } from 'src/hooks/use-tabs-table-context'
import { generateFormData } from '../../helpers'
import { useSelector } from 'react-redux'

const QueueForm: React.FC = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const location = useLocation()
  const statuses = useSelector((state: AppState) => state.statuses.statuses)
  const statusError = useSelector((state: AppState) => state.statuses.error)
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm<IQueueFormData>({
    mode: 'onTouched',
    resolver: yupResolver(queueSchema),
    defaultValues,
  })

  const statusOptions = useMemo(
    () =>
      statuses?.map((elem) => ({
        id: elem.id,
        value: elem.value,
      })),
    [statuses]
  )

  const {
    setQueueCurrentPagination,
    getQueueData,
    queuePrevParamsString,
    queueCurrentPagination,
    shouldQueueDataReload,
    setShouldQueueDataReload,
    setIsQueueFormMounted,
    setQueueTableData,
  } = useTabsTableContext()

  const submitForm = async (
    data: IQueueFormData,
    updatedHistory: boolean = true
  ) => {
    const params = formDataToParams(data)

    getQueueData(params, updatedHistory)
  }

  const { isVendorsLoading, getVendors } = useGetVendors()

  useEffect(() => {
    if (statuses?.length && statuses.length > 0) {
      const {
        data: updatedFormData,
        allParamsObject,
        pagination,
      } = generateFormData(
        location.hash,
        defaultValues,
        arrOfQueueParamsKeys,
        statuses
      )

      //Preview params string for comparing with current one
      const prevParamsString = qs.stringify(
        qs.parse(queuePrevParamsString?.replace(/^#tab\d*/, '') || '')
      )

      //Current params string for comparing with previous one
      const currentParamsString = qs.stringify(allParamsObject)

      for (let key in updatedFormData) {
        setValue(key as keyof IQueueFormData, updatedFormData[key], {
          shouldValidate: true,
          shouldTouch: true,
        })
      }

      if (!currentParamsString && queuePrevParamsString !== null) {
        setQueueTableData([])
        setValue('status', defaultStatus, {
          shouldValidate: true,
          shouldTouch: true,
        })
      } else if (
        prevParamsString !== currentParamsString &&
        (queuePrevParamsString !== null || location.hash !== '#tab1')
      ) {
        handleSubmit((data) => {
          const updatedData = {
            ...data,
            itemsPerPage: '9',
            page: '1',
            ...pagination,
          }
          submitForm(updatedData, false)
        })()
      } else if (queuePrevParamsString == null) {
        setValue('status', defaultStatus, {
          shouldValidate: true,
          shouldTouch: true,
        })
      }
    }
    //eslint-disable-next-line
  }, [location.hash, statuses])

  useEffect(() => {
    setIsQueueFormMounted(true)
    return () => {
      setIsQueueFormMounted(false)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (shouldQueueDataReload) {
      const { data: updatedFormData } = generateFormData(
        location.hash,
        defaultValues,
        arrOfQueueParamsKeys,
        statuses || []
      )

      handleSubmit(() => {
        submitForm(updatedFormData as IQueueFormData, false)
      })()
      setShouldQueueDataReload(false)
    }
    //eslint-disable-next-line
  }, [shouldQueueDataReload])

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const itemsPerPage = queueCurrentPagination?.itemsPerPage
        const pagination = getPagination({
          items_per_page: `${itemsPerPage}`,
          page: '1',
        })

        submitForm({
          ...data,
          ...pagination,
        })
      })}
      className="mt-8 mb-8"
    >
      <div className="-mt-6 lg:-mt-3 md:p-8 md:border">
        <div className="my-8 grid lg:grid-cols-2 gap-4">
          <Controller
            name="dispatchID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-queue-dispatch"
                error={errors.dispatchID?.message}
                value={field.value}
                formField
                label={localizedText('QUEUE_DISPATCH_LABEL')}
                className="w-full"
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="vendor"
            control={control}
            render={({ field }) => (
              <Select
                id="queue-form-queue-vendor"
                autoComplete={true}
                error={errors.vendor?.message}
                selected={field.value}
                formField
                label={localizedText('PAYMENT_SEARCH_FORM_VENDOR_LABEL')}
                options={getVendors(['BH'], true)}
                onSelect={field.onChange as SelectHandler<SelectOption>}
                onBlur={field.onBlur}
                placeholder="Select an option"
                disabled={isVendorsLoading}
              />
            )}
          />
          <Controller
            name="adressID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-queue-address"
                error={errors.adressID?.message}
                value={field.value.trim()}
                formField
                label={localizedText('QUEUE_ADDRESS_LABEL')}
                className="w-full"
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="partRequestID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-queue-request"
                error={errors.partRequestID?.message}
                value={field.value.trim()}
                formField
                label={localizedText('QUEUE_SERVICE_REQUEST_LABEL')}
                className="w-full"
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            )}
          />
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Select
                id="queue-form-queue-status"
                error={
                  errors.status?.message || !statusError ? '' : statusError
                }
                selected={field.value}
                formField
                multiSelect
                options={statusOptions || []}
                label={localizedText('QUEUE_STATUS_LABEL')}
                className="w-full"
                onSelect={field.onChange as SelectHandler<SelectOption>}
                onBlur={field.onBlur}
                disabled={!statuses?.length || statuses.length === 0}
              />
            )}
          />
          <Controller
            name="replacementID"
            control={control}
            render={({ field }) => (
              <Input
                id="queue-form-queue-replacement"
                error={errors.replacementID?.message}
                value={field.value}
                formField
                label={localizedText('QUEUE_REPLACEMENT_LABEL')}
                className="w-full"
                onChange={(e) => field.onChange(e.target.value.trim())}
                onBlur={field.onBlur}
              />
            )}
          />
        </div>
        <Button
          id="queue-form-queue-submit"
          type="submit"
          label="Search"
          disabled={!isValid}
          onClick={() => {
            setQueueCurrentPagination({
              itemsPerPage: queueCurrentPagination?.itemsPerPage || 9,
              page: 1,
            })
          }}
        />
      </div>
    </form>
  )
}

export default QueueForm
