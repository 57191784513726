import React from 'react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { useAppContext } from '@ftdr/blueprint-components-react'
import { EcardSearch } from './ecard-search'

export const Ecard = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  return (
    <div className="-mt-6 lg:-mt-0">
      <Text variant="heading-03" className="mb-8">
        {localizedText('ECARD_TITLE')}
      </Text>
      <div className="mb-8">
        <EcardSearch />
      </div>
    </div>
  )
}
