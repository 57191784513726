import { Radio } from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { itempb } from 'src/services/protobuf-models/item-ms-protobuf-models'
import { ChangeAcceptedCategories } from 'src/store/survey-store'
import { api } from 'src/utils/api'
import { capitalizeText } from 'src/utils/helper'
import { RequestStatus } from 'src/utils/shared-types'
import { isHSA } from 'src/utils/tenant-helper'

interface ServiceItemSelectionProps {
  serviceItems: appliancepb.IServiceItem[]
  selected: itempb.IItem | null
  itemOptions: itempb.IItem[]
  setItemOptions: Dispatch<itempb.IItem[]>
  onSelect(item: itempb.IItem, index: number): void
}
export const ServiceItemSelection = ({
  serviceItems,
  selected,
  setItemOptions,
  itemOptions,
  onSelect,
}: ServiceItemSelectionProps) => {
  const color = isHSA() ? 'primary' : 'interactive'
  const dispatch = useDispatch()
  const [status, setStatus] = useState<RequestStatus>('loading')
  useEffect(() => {
    async function getItems() {
      try {
        const items = await Promise.all(
          serviceItems.map(async (serviceItem) => {
            const [item] = await api.getItems(serviceItem.itemID || '')
            return item
          })
        )
        const acceptedCategories: string[] = []
        items.forEach((v) => {
          if (v.id === 'b9a4fb75-38f9-4e9b-bb23-656488fde8f8') {
            acceptedCategories.push(
              'b9a4fb75-38f9-4e9b-bb23-656488fde8f8',
              '1ddd63d7-03e6-4c7b-9664-765df811851b',
              '27e0cfa1-b4bb-4520-86ed-909728772a80'
            )
          } else {
            acceptedCategories.push(v.id!)
          }
        })
        dispatch(ChangeAcceptedCategories(acceptedCategories))
        setItemOptions(items)

        setStatus('ok')
      } catch (err) {
        setStatus('error')
      }
    }
    getItems()
    //eslint-disable-next-line
  }, [serviceItems])

  return status === 'loading' ? (
    <ProgressIndicator size="small" data-testid="spinner" />
  ) : status === 'ok' ? (
    <div>
      <Text
        variant="label"
        className="mb-1"
        textTemplateKey="SURVEY_SERVICE_ITEM_LABEL"
      />
      {itemOptions.map((item, index) => (
        <div key={item?.id || new Date().getTime()}>
          <Radio
            id={`${item?.id}-service-item-selection-radio`}
            color={color}
            label={capitalizeText(item.name || '')}
            checked={selected?.id === item?.id}
            onChange={() => {
              onSelect(item, index)
            }}
          />
        </div>
      ))}
    </div>
  ) : (
    <Text
      id="service-item-selection-error"
      className="font-bold"
      color="error"
      textTemplateKey="ERROR_AGENT"
    />
  )
}
