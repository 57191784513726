import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState, Tenant } from 'src/utils/shared-types'

interface Props {
  onClose: () => void
  isGiftCard: boolean
}
export const TermsAndConditionsDialog = ({ onClose, isGiftCard }: Props) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const tenant = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.dispatch?.tenantAbbreviation || Tenant.AHS
  )

  return (
    <Dialog
      open
      modal
      className="overflow-auto"
      closeOnOutsideClick
      onClose={onClose}
      header={<Text textTemplateKey="TERMS_HEADER" variant="heading-04" />}
      actions={[
        <Button
          color="gray"
          variant="outlined"
          size="small"
          label={localizedText('TERMS_CLOSE_BTN')}
          onClick={onClose}
        />,
      ]}
    >
      {isGiftCard ? (
        <ul className="list-disc px-6">
          <li>
            <Text textTemplateKey="TERMS_ECARD_LINE_1" />
          </li>
          <li>
            <Text textTemplateKey="TERMS_ECARD_LINE_2" />
          </li>
          <li>
            <Text textTemplateKey="TERMS_ECARD_LINE_3" />
          </li>
          <li>
            <Text textTemplateKey="TERMS_ECARD_LINE_4" />
          </li>
        </ul>
      ) : (
        <ul className="list-disc px-6">
          <li>
            <Text textTemplateKey="TERMS_LINE_1" />
          </li>
          <li>
            <Text
              textTemplateKey="TERMS_LINE_2"
              textTemplateData={{ tenant }}
            />
          </li>
          <li>
            <Text
              textTemplateKey="TERMS_LINE_4"
              textTemplateData={{ tenant }}
            />
          </li>
          <li>
            <Text textTemplateKey="TERMS_LINE_5" />
          </li>
          <li>
            <Text
              textTemplateKey="TERMS_LINE_6"
              textTemplateData={{ tenant }}
            />
          </li>
        </ul>
      )}
    </Dialog>
  )
}
