import { states } from 'src/utils/states'
import { IStatus } from 'src/store/statuses-store/statuses.store.types'
import {
  MenuItem,
  IPaymentFormData,
  IQueueFormData,
  TPartialFormsData,
  IAnyObject,
} from 'src/utils/shared-types'
import { TPartialFormParams } from 'src/utils/shared-types'
import { MSTTimezoneOffset } from 'src/utils/internationalization-helper'
import qs from 'query-string'

export const arrOfPaymentParamsKeys = [
  'dispatch_id',
  'id',
  'contract_id',
  'payment_id',
  'company_code',
  'vendor',
  'model_number',
  'price_min',
  'price_max',
  'order_date_min',
  'order_date_max',
  'order_id',
]

export const arrOfQueueParamsKeys = [
  'dispatch_id',
  'status',
  'vendor',
  'adress_id',
  'part_request_id',
  'replacement_id',
]

export const generateStateObject = (value: string) => {
  let updatedObject: MenuItem
  updatedObject = states.find((elem) => elem.id === value) || {
    id: '',
    value: '',
    label: 'All states',
  }
  return updatedObject
}

export const generateStatusesObject = (
  value: string | string[],
  statuses: IStatus[]
) => {
  let updatedArray: Array<{ value: string }> = []

  if (typeof value === 'string') {
    value.split(',').forEach((status) => {
      const temp = statuses.find((obj) => obj.value === status)
      temp && updatedArray.push({ value: temp.value })
    })
  }
  return updatedArray.length ? updatedArray : ''
}

export const generateStatusesParam = (value: MenuItem[]) =>
  value.map(({ value }) => value).join()

export const generateVendorsObject = (
  value: 'GE' | 'WP' | 'PC' | undefined | string
) => {
  let updatedObject = {}

  switch (value) {
    case 'GE':
      updatedObject = {
        id: 'GE',
        value: 'GE',
        label: 'GE',
      }
      break
    case 'WP':
      updatedObject = {
        id: 'WP',
        value: 'Whirpool',
        label: 'Whirpool',
      }
      break
    case 'PC':
      updatedObject = {
        id: 'PC',
        value: 'ProConnect',
        label: 'ProConnect',
      }
      break

    default:
      updatedObject = {
        id: '',
        value: '',
        label: 'All vendors',
      }
  }

  return updatedObject as MenuItem
}

export const snakeToCamelCase = (str: string) => {
  if (/_id/.test(str)) {
    const fixedString = str.replace('_id', 'ID')
    return fixedString.replace(/_[a-z]/, (group) =>
      group.toUpperCase().replace('_', '')
    )
  }
  return str.replace(/_[a-z]/g, (group) => group.toUpperCase().replace('_', ''))
}

export const camelToSnakeCase = (str: string) => {
  if (/ID/.test(str)) {
    const fixedString = str.replace(/ID/g, '_id')
    return fixedString.replace(
      /[A-Z]/g,
      (letter: string) => `_${letter.toLowerCase()}`
    )
  }
  return str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)
}

export const paramsToFormData = (
  snakeCaseOBJ: TPartialFormParams,
  defaultValues: IPaymentFormData | IQueueFormData,
  statuses: IStatus[]
) => {
  const camelCaseOBJ: TPartialFormsData = {}

  Object.keys(snakeCaseOBJ).forEach((key) => {
    if (key === 'vendor') {
      camelCaseOBJ[key] = generateVendorsObject(snakeCaseOBJ[key])
    } else if (key === 'status') {
      camelCaseOBJ[key] = generateStatusesObject(snakeCaseOBJ[key], statuses)
    } else if (key === 'order_date_min' || key === 'order_date_max') {
      camelCaseOBJ[snakeToCamelCase(key)] = new Date(+snakeCaseOBJ[key] * 1000)
    } else if (key === 'price_min' || key === 'price_max') {
      camelCaseOBJ[snakeToCamelCase(key)] = snakeCaseOBJ[key]
    } else if (key === 'model_number' || key === 'company_code') {
      camelCaseOBJ[snakeToCamelCase(key)] = snakeCaseOBJ[key].toUpperCase()
    } else {
      camelCaseOBJ[snakeToCamelCase(key)] = snakeCaseOBJ[key]
    }
  })

  return { ...defaultValues, ...camelCaseOBJ }
}

export const formDataToParams = (camelCaseOBJ: TPartialFormsData) => {
  const snakeCaseOBJ: TPartialFormParams = {}

  for (let key in camelCaseOBJ) {
    if (key === 'vendor') {
      const tempOBJ = camelCaseOBJ[key]
      if (tempOBJ?.id) {
        snakeCaseOBJ[key] = camelCaseOBJ[key]!['id']
      }
    } else if (key === 'status') {
      const tempARR = camelCaseOBJ[key]
      if (tempARR?.length) {
        snakeCaseOBJ[key] = generateStatusesParam(tempARR)
      }
    } else if (key === 'orderDateMin' || key === 'orderDateMax') {
      const tempValue = camelCaseOBJ[key]

      if (tempValue) {
        snakeCaseOBJ[camelToSnakeCase(key)] =
          Date.parse(tempValue) / 1000 + MSTTimezoneOffset
      }
    } else if (key === 'priceMin' || key === 'priceMax') {
      const tempAmount = camelCaseOBJ[key]
      if (tempAmount) {
        snakeCaseOBJ[camelToSnakeCase(key)] = tempAmount
      }
    } else if (key === 'modelNumber' || key === 'companyCode') {
      const tempValue = camelCaseOBJ[key].toUpperCase()

      if (tempValue) {
        snakeCaseOBJ[camelToSnakeCase(key)] = tempValue
      }
    } else if (key === 'page' && camelCaseOBJ[key]) {
      const pageNumber = camelCaseOBJ[key]

      snakeCaseOBJ[camelToSnakeCase(key)] = pageNumber
    } else if (key === 'itemsPerPage') {
      const itemsPerPage = camelCaseOBJ[key]

      snakeCaseOBJ[camelToSnakeCase(key)] = itemsPerPage
    } else if (camelCaseOBJ[key]) {
      snakeCaseOBJ[camelToSnakeCase(key)] = camelCaseOBJ[key]
    }
  }

  return { ...snakeCaseOBJ }
}

export const removeUnusedKeys = (obj: IAnyObject, filterArr: string[]) => {
  const newObj = { ...obj }
  for (let key in newObj) {
    !filterArr.includes(key) && delete newObj[key]
  }
  return newObj
}

export const getPagination = (params: IAnyObject) => {
  const pagination = {}
  for (let key in params) {
    if (key === 'page') {
      pagination[key] =
        +params[key] > 0 && !isNaN(+params[key]) ? params[key] : '1'
    }
    if (key === 'items_per_page') {
      pagination['itemsPerPage'] = ['9', '30', '60'].includes(params[key])
        ? params[key]
        : '9'
    }
  }

  return pagination
}

export const generateFormData = (
  hash: string,
  defaultValues: IPaymentFormData | IQueueFormData,
  filters: string[],
  statuses: IStatus[]
) => {
  const allParams = qs.parse(hash.replace(/^#tab\d*/, ''))

  //Prepared pagination object from string params
  const pagination = getPagination(allParams)

  //Cleaned params object without pagination
  const paramsObject = removeUnusedKeys({ ...allParams }, filters)
  //Cleaned params object with pagination

  const allParamsObject = removeUnusedKeys({ ...allParams, ...pagination }, [
    ...filters,
    'page',
    'items_per_page',
  ])

  return {
    data: paramsToFormData(paramsObject, defaultValues, statuses),
    paramsObject,
    allParamsObject,
    pagination,
  }
}
